<template>
  <div class="work-detail">
    <the-header></the-header>
    <div class="content-body warp" v-loading="loading">
      <div class="back iconfont icon-arrow-left" @click="back()">
        返回上一页
      </div>
      <div class="rank_item" v-if="rankData_one.length>0">
        <div class="one">
          <rank-item
            :item="rankData_one[0]"
            :ranknum="1"
            style="width: 380px; height: 210px"
          ></rank-item>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
          "
        >
          <rank-item :item="rankData_one[1]" :ranknum="2"></rank-item>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
          "
        >
          <rank-item :item="rankData_one[2]" :ranknum="3"></rank-item>
        </div>
        <div class="rank-item">
          <rank-item
            v-for="(item, index) in rankData_two"
            :key="index"
            :ranknum="index + 4"
            :item="item"
            style="width: 380px; height: 210px; margin-left: 10px"
          ></rank-item>
        </div>
      </div>
      <el-empty description="暂无数据" v-else></el-empty>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import RankItem from "@/components/RankItem.vue";
import { matchMsg } from "../../http/api";
import { mapGetters } from "vuex";
import TheFooter from '@/components/TheFooter.vue'

export default {
  components: { TheHeader, RankItem,TheFooter },
  data() {
    return {
      rankData_one: "",
      rankData_two: "",
      loading: false,
    };
  },
  created() {
    this.getData();
    console.log("当前登录用户", this.getUserInfo);
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    /**
     * 获取赛事详情
     * type=2&rowId=3&userId=200000275
     */
    getData() {
      this.loading = true;
      this.loadErr=false
      let params = {
        type: 2,
        userId: this.getUserInfo ? this.getUserInfo.uid : "",
        rowId: this.$route.query.rowId,
      };
      matchMsg(params)
        .then((res) => {
          this.loading = false;

          console.log("返回的数据是", res);
          this.rankData_one = res.data[0].workCurriculumList ?res.data[0].workCurriculumList : "";
          this.rankData_two = res.data[0].workCurriculumList1 ? res.data[0].workCurriculumList1 : "";
        })
        .catch((err) => {
          console.log(err);
        });
    },
    likeTapSuccess(type, index, item) {
      if (type == undefined) {
        console.log(index);
        // console.log()
        for (let index = 0; index < this.rankData_one.length; index++) {
          if (this.rankData_one[index].PROJECT_ID == item.PROJECT_ID) {
            this.rankData_one[index].IS_LIKE =
              this.rankData_one[index].IS_LIKE == 0 ? 1 : 0;
            this.rankData_one[index].LIKE_TIMES =
              this.rankData_one[index].IS_LIKE == 1
                ? this.rankData_one[index].LIKE_TIMES + 1
                : this.rankData_one[index].LIKE_TIMES - 1;
            break;
          }
        }
        for (let index = 0; index < this.rankData_two.length; index++) {
          if (this.rankData_two[index].PROJECT_ID == item.PROJECT_ID) {
            this.rankData_two[index].IS_LIKE =
              this.rankData_two[index].IS_LIKE == 0 ? 1 : 0;
            this.rankData_two[index].LIKE_TIMES =
              this.rankData_two[index].IS_LIKE == 1
                ? this.rankData_two[index].LIKE_TIMES + 1
                : this.rankData_two[index].LIKE_TIMES - 1;
            break;
          }
        }
      }
    },
  },
  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
};
</script>

<style lang="scss">
.work-detail {
  .back {
    line-height: 103px;
    cursor: pointer;
  }

  .back::before {
    margin-right: 20px;
  }
  .rank_item {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .one {
      background-color: #f4f4f4;
      width: 100%;
      padding: 0px 33%;
    }
    .rank-item {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}
</style>