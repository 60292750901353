<template>
  <router-view></router-view>
</template>

<script>
export default {
    created() {
        if (this.$route.path == "/activity-index") {
            this.$router.replace("/activity-index/events-activity");
        }
    },
    watch: {
        $route(to, from) {
        //这里可以加你监听到路由改变时要触发的方法
        if (to.path == '/activity-index') {
            this.$router.replace('/activity-index/events-activity')
        }
        }
    },
}
</script>

<style>

</style>