<template>
  <div class="match-center">
    <the-header></the-header>
    <div class="pbl-banner" id="banner">
      <el-carousel>
        <el-carousel-item
          v-for="(item, index) in 2"
          :key="item"
          :class="'banner' + (index + 1)"
        >
          <div
            class="banner-con"
            :style="
              'background-image:url(' +
              require('../../assets/image/banner' + item + '.png') +
              ')'
            "
          ></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content-body" v-loading="loading" >
      <div class="match_item" v-if="macthData.length> 0"><match-item
        v-for="(item, index) in macthData"
        :key="index"
        :matchData="item"
        
      ></match-item>
      </div>
      
      <el-empty description="暂无数据" v-else />

      <div class="Pageturning">
        <the-pagination
          :total="total"
          @paginationChange="paginationChange"
        ></the-pagination>
      </div>
    </div>
           <the-footer></the-footer> 
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import MatchItem from "@/components/MatchItem.vue";
import ThePagination from "@/components/ThePagination.vue";
import { matchMsg } from "../../http/api";
import TheFooter from '@/components/TheFooter.vue'

export default {
  created() {
    this.getData();
  },
  data() {
    return {
      macthData: "",
      loading: false,
      total:0,
      page:1,
      pageSize:5
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let params = {
        //type=1&userId=1&pageNo=1&pageSize=5
        type: 1,
        userId: 1,
        // sort: "",
        pageNo: this.page,
        pageSize: this.pageSize,
        
      };
      matchMsg(params)
        .then((res) => {
          this.loading = false;
          console.log("返回的数据是", res);
          for (let index = 0; index < res.data.items.length; index++) {
          res.data.items[index].endTime = this.$util.formateDateTimesToZh(res.data.items[index].endTime, 3)
          res.data.items[index].startTime = this.$util.formateDateTimesToZh(res.data.items[index].startTime, 3)
          }
          this.macthData = res.data.items;
          this.total=res.data.totalCount
          console.log("macthData::", this.macthData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * 分页
     * @param {Number} page 分页数
     */
    paginationChange(page) {
      console.log(page);
      this.page = page;
      this.getData();
    },
  },
  components: { TheHeader, MatchItem, ThePagination,TheFooter },
};
</script>

<style lang="scss">
.match-center {
  .pbl-banner {
    .el-carousel__container {
      height: 452px;

      .el-carousel__arrow {
        background-color: rgba($color: #000000, $alpha: 0.6);
      }

      .el-carousel__item.banner1 {
        background-image: url(../../assets/image/banner1_repeat_bg.png);
        background-repeat: repeat-x;
        background-size: auto 100%;
      }

      .banner-con {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
  .content-body {
    width: 1190px;
    margin: 0 auto;
    min-height: 480px;
    .match_item{
    }
    .Pageturning {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
</style>