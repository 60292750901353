<template>
  <div class="tab-cell-item2 ub ub-ac">
      <el-image class="tab-img" :src="item.USER_HEADIMG" fit="cover" @click="studentTap(item.ROW_ID)"></el-image>
      <div class="cell-handel ub ub-ver">
          <div class="cell-title ub-f1"><div class="text-hidden-2">{{item.NICKNAME}}</div></div>
          <the-rate :score="item.score" :isDisabled="true"></the-rate>
          <div class="info">作品：{{item.workNum}}</div>
          <div class="info">粉丝：{{item.fansNum}}</div>
          <follow-author-btn :uuid="item.ROW_ID" :focus="item.focusState" v-if="getUserInfo.uid != item.ROW_ID ||  item.focusState == 1" @followAuthorSuccess="followAuthorSuccess"></follow-author-btn>
      </div>
  </div>
</template>

<script>
import LikeInfo from '@/components/LikeInfo.vue'
import TheRate from '@/components/TheRate.vue'
import FollowAuthorBtn from '@/components/FollowAuthorBtn.vue'
import { mapGetters } from 'vuex'
export default {
  components: { LikeInfo, TheRate, FollowAuthorBtn },
  props: {
      item: {
          type: Object,
          default:  () => {
              return {}
          }
      },

      isSelf: {
          type: Boolean,
          default: false
      }
  },

  created() {
      console.log(this.item)
      console.log(this.getUserInfo)
  },

  computed: {
    ...mapGetters(['getUserInfo'])
  },

  methods: {
      studentTap(uid) {
          console.log(uid)
          this.$router.push('/personal-center?uid=' + uid)
      },

      /**
       * 关注/取消关注成功
       */
      followAuthorSuccess(uid) {
          console.log(uid)
        //   this.$emit('followAuthorSuccess', uid)
          this.$parent.followAuthorSuccess(uid)
      },
  }

}
</script>

<style lang="scss">
.tab-cell-item2 {
    width: 385px;
    height: 183px;
    background-color: #fff;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 17px;
    margin-bottom: 17px;

    .tab-img {
        width: 131px;
        height: 131px;
        cursor: pointer;

        margin: 0 25px;
        img {
            width: 131px;
            height: 131px;
            border-radius: 50%;
        }
    }

    .cell-handel {
        padding: 0 12px 12px;

        .cell-title {
            font-weight: 700;
            margin-top: 15px;
            width: 129px;
        }

        .pbl-rate {
        }
        
        .info {
            font-size: 14px;
            color: #6e6e6e;
            margin-top: 2px;
        }

        .el-button {
            margin-top: 15px;
        }
    }
}
</style>