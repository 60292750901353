export default {
    state: {
        test: true
    },

    getters: {
        getTest(state) {
            return state.test
        },
    }
}