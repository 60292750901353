<template>
  <div class="activity-cell" @click="activityDetail(item.activityId, item.state)">
    <el-image lazy class="activity-item-pic" :src="item.url" fit="cover"></el-image>
    <div class="activity-item-title ub ub-ac"><div class="text-hidden">{{item.title}}</div></div> 
    <div class="activity-item-summary ub"><div class="text-hidden">{{item.content}}</div></div> 
    <div class="activity-item-info ub ub-ac">
        <span>作品</span>    
        <span v-if="item.createTime">最近更新：{{item.createTime}}</span>    
    </div> 
  </div>
</template>

<script>
import { activityMsg } from '../../../http/api'
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    methods: {
        activityDetail(activityId,state) {
            this.$router.push(`/activity-detail?activityId=${activityId}&state=${state}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.activity-cell {
    width: 285px;
    font-size: 14px;
    box-shadow: 0px 0px 8px #d5d5d5;
    border-radius: var(--item-border-radius);
    overflow: hidden;
    background-color: #fff;
    .activity-item-pic {
        width: 100%;
        height: 171px;
        cursor: pointer;
        float: left;
    }
    .activity-item-title {
        height: 50px;
        padding: 0 15px;
        box-sizing: border-box;
        cursor: pointer;
    }
    .activity-item-summary {
        height: 32px;
        padding: 0 15px 10px;
        box-sizing: border-box;
        font-size: 12px;
    }
    .activity-item-info {
        height: 33px;
        padding: 0 15px;
        box-sizing: border-box;
        border-top: 1px solid #f0f0f0;
        color: #bfbfbf;
        font-size: 12px;

        span {
            margin-right: 20px;
        }
    }
}
</style>