<template>
  <header :class="['pbl-header']">
      <div class="header-content ub ub-ac">
        <a href="/"><img class="header-logo" src="../assets/image/logo.png" alt=""></a>
        <div class="menu menu-left">
          <el-menu class="ub">
            <el-menu-item :class="[route == item.path ? 'act' : '']" index="1" v-for="(item, index) in routes" :key="index">
              <router-link v-bind:to="item.path" v-if="item.meta.isHeaderMenu">{{ item.meta.title }}</router-link>
              <div class="sub-menu2">
                  <el-menu-item class="ub" :index="(index + 1 + '') + (subIndex + 1 + '')" v-for="(subItem, subIndex) in item.children" :key="subIndex">
                    <router-link v-bind:to="subItem.path" class="menu-title" v-if="subItem.meta.isHeaderMenu">{{ subItem.meta.title }}</router-link>
                  </el-menu-item>
              </div>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="menu menu-fixed">
          <el-menu class="ub">
            <el-menu-item :class="[route == item.path ? 'act' : '']" index="1" v-for="(item, index) in routes" :key="index">
              <router-link v-bind:to="item.path" v-if="item.meta.isHeaderRightMenu">{{ item.meta.title }}</router-link>
              <div class="sub-menu">
                  <el-menu-item class="ub" :index="(index + 1 + '') + (subIndex + 1 + '')" v-for="(subItem, subIndex) in item.children" :key="subIndex">
                    <router-link v-bind:to="subItem.path" class="menu-title" v-if="subItem.meta.isHeaderMenu">{{ subItem.meta.title }}</router-link>
                  </el-menu-item>
              </div>
            </el-menu-item>
          </el-menu>
        </div>
        <div class="login">
          <ul class="ub not-login" v-if="!getUserInfo">
            <li @click="loginAlert(1)">登录</li>
            <li @click="loginAlert(3)">注册</li>
          </ul>
          <div class="ub" v-else>
            <the-message :num="Number(getUserInfo.MessageNum)" path="/message-center"></the-message>
            <div class="user-tag ub ub-ac ub-pc" >
              <div class="user-img" :style="'background-image: url(' + userImg + ');'"></div>
              <ul>
                <li><router-link to="/personal-center">个人中心</router-link></li>
                <li v-if="isClass"><router-link to="/class-manage">{{menuClassName}}</router-link></li>
                <li><router-link to="/account-info">账号设置</router-link></li>
                <li><a href="javascript:;" @click="signOut()">退出</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="creative-works ub ub-ac">
          <span>创作</span>
          <ul>
            <li><a :href="config.scratchUrl.create" target="_blank">Scratch</a></li>
            <li><router-link to="/personal-center">Python</router-link></li>
            <li><router-link to="/create-index">其它类型创作</router-link></li>
            <!-- <li><router-link to="/create-index?type=3">机器人编程作品</router-link></li>
            <li><router-link to="/create-index?type=4">STEAM作品</router-link></li>
            <li><router-link to="/create-index?type=5">绘本馆作品</router-link></li>
            <li><router-link to="/create-index?type=6">科普日视频</router-link></li> -->
          </ul>
        </div>
      </div>
  </header>
</template>

<script>
import config from '../http/config'
import { mapGetters } from 'vuex'
import TheMessage from './TheMessage.vue'
export default {
  components: {
    TheMessage
  },

  data() {
    return {
      fixedMenu: [{
        title: '资源库',
        path: '/shopping-index'
      }, {
        title: '关于',
        path: '/about-us'
      }, {
        title: '下载',
        path: '/product-down'
      }],
      activeIndex: 0,
      routes: [],
      route: '',
    }
  },

  created() {
    this.config = config
    console.log(config.scratchUrl.create)
  },

  mounted() {
    this.routes = this.$router.options.routes
    console.log(this.routes)
    
    
  },

  computed: {
    ...mapGetters([
      'getUserInfo'
    ]),

    userImg() {
      return this.getUserInfo.user_img ? this.getUserInfo.user_img : ''
    },

    /**
     * 只有学生和老师展示班级 0 学生 2 老师
     */
    isClass() {
      return this.getUserInfo.role == 0 || this.getUserInfo.role == 2 ? true : false
    },

    /**
     * 菜单名
     */
    menuClassName() {
      return this.getUserInfo.role == 0 ? '我的班级' : '班级管理'
    }
  },

  methods: {
    loginAlert(type) {
      sessionStorage.setItem('loginType', type)
      this.$store.commit('setLoginAlertState', true)
    },

    showUserInfo() {
      this.$store.dispatch('setUserInfoAlertShow', true)
    },

    signOut() {
      this.$confirm("是否退出登录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false
        })
        .then(() => {
          localStorage.clear();
          sessionStorage.clear()
          this.$store.commit('setUserInfo', null)
          console.log(this.$route)
          if (this.$route.meta.login) {
            this.$router.push( "/");
          }
          this.$router.go(0);
        })
    }
  },

  watch: {
    /* 'state.isLoginAlert' (newVal) {
       console.log(newVal)
     } */
  }
}
</script>

<style lang="scss">
.pbl-header {
  width: 100%;
  background-color: var(--el-color-primary);

  .header-content {
    width: 1200px;
    height: 90px;
    margin: 0 auto;

    .header-logo {
      width: 119px;
      margin: 0px 25px 0 8px
    }

    .menu {
      .el-menu {
        border: none;
        background-color: unset;

        .el-menu-item {
          height: 90px;
          color: #fff;
          font-size: 18px;
          padding: 0 !important;

          a {
            width: 100%;
            height: 90px;
            line-height: 90px;
            box-sizing: border-box;
            padding: 0 22px;
            color: #000;
            font-size: 16px;
          }

          .router-link-active {
            font-weight: bold;
            background-color: #282828;
            color: var(--el-color-primary);
          }

          .sub-menu {
            width: 106px;
            position: absolute;
            left: 50%;
            margin-left: -53px;
            top: 90px;
            z-index: 9999;
            display: none;
            box-shadow: 0px 0px 2px #cbcaca;

            .el-menu-item {
              background-color: #fff;
              height: auto;

              a {
                height: 40px;
                line-height: 40px;
                text-align: center;
              }

              a:hover {
                background-color: var(--el-color-primary);
              }

              .router-link-active {
                background-color: unset;
                color: #000000;
              }
            }
          }
          .sub-menu2 {
            width: 106px;
            position: absolute;
            left: 50%;
            margin-left: -53px;
            top: 90px;
            z-index: 9999;
            display: none;
            box-shadow: 0px 0px 2px #cbcaca;

            .el-menu-item {
              background-color: #fff;
              height: auto;

              a {
                height: 40px;
                line-height: 40px;
                padding: 0px;
                text-align: center;
              }

              a:hover {
                background-color: var(--el-color-primary);
              }

              .router-link-active {
                background-color: unset;
                color: #000000;
              }
            }
          }
        }

        .el-menu-item:hover {
          background-color: rgba(0, 0, 0, 0.3);
          .sub-menu {
            display: block;
          }
          .sub-menu2 {
            display: block;
          }
        }
      }
    }

    .menu-left {
      flex-grow: 1;
      .el-menu-item:first-child {
        a {
          padding: 0 40px;
        }
      }
    }

    .menu-fixed {
      .el-menu {
        .el-menu-item { 
          a {
            padding: 0 25px;
            font-size: 14px;
          }
        }
      }
    }

    .login {
      ul.not-login {
        li {
          padding: 0 25px;
          position: relative;
          cursor: pointer;
          font-size: 14px;
        }

        li:first-child::before {
          content: '';
          width: 1px;
          height: 20px;
          background-color: #000000;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -10px;
        }
      }

      .user-tag {
        width: 60px;
        height: 90px;
        cursor: pointer;
        position: relative;
        margin-right: 10px;

        .user-img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat;
          position: relative;
          background-color: #f4f4f4;
        }

        ul {
          width: 145px;
          background-color: #fff;
          position: absolute;
          left: 50%;
          margin-left: -72.5px;
          top: 90px;
          display: none;
          z-index: 99999;
          box-shadow: 0px 0px 2px #cbcaca;

          li {
            height: 38px;
            line-height: 38px;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
              text-align: center;
              color: #000;
            }
          }

          li:hover {
            background-color: var(--el-color-primary);
            a {
              color: #fff;
            }
          }
        }
      }

      .user-tag:hover {
        background: rgba(0, 0, 0, 0.3);

        ul {
          display: block;
        }
      }
    }

    .creative-works {
      height: 90px;

      span {
        display: block;
        width: 59px;
        height: 59px;
        background-color: #000000;
        border-radius: 50%;
        color: #fff;
        text-align: center;
        line-height: 59px;
        cursor: pointer;
        position: relative;
      }

      ul {
        width: 145px;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 90px;
        display: none;
        box-shadow: 0px 0px 2px #cbcaca;
        z-index: 99999;

        li {
          height: 38px;
          line-height: 38px;
          a {
            display: inline-block;
            width: 100%;
            height: 100%;
            text-align: center;
            color: #000;
          }
        }

        li:hover {
          background-color: var(--el-color-primary);
          a {
            color: #fff;
          }
        }
      }
    }

    .creative-works:hover {
      ul {
        display: block;
      }
    }
  }
}
</style>