<template>
  <div class="class-project">
        <div class="ub ub-wrap" v-if="classData.classList.length > 0">
           <div :class="['project-item ub ub-ver', (index + 1) % 2 == 0 ? 'no-margin' : '']" v-for="(item, index) in classData.classList" :key="index">
                <div class="project-item-title">{{item.TAG ? item.TAG : '-'}}</div>
                <div class="project-item-date ub ub-be">开始时间：{{timeFormat(item.BEGIN_TIME)}} - 结束时间：{{timeFormat(item.END_TIME)}}
                <div :class="['project-item-state', buttonText(item.state, 1)]">{{buttonText(item.state, 2)}}</div></div>
                <div class="project-item-content ub-f1">
                    <div class="title">项目内容</div>
                    <p class="text-hidden-3">{{item.TEXT_CONTENT}}</p>
                </div>
                <div class="project-item-handle ub ub-be ub-ac">
                    <work-name :name="item.PROJECT_NAME" :id="item.PROJECT_ID"></work-name>
                    <div class="project-button ub">
                        <el-button class="" disabled type="info" v-if="item.state == -1">已结束</el-button>
                        <div class="" v-if="getUserInfo.role == 0">
                            <el-button class="" disabled type="info" v-if="item.state == 0">未开始</el-button>
                            <el-button class="default-button four" v-if="item.state == 1 && getUserInfo.role != 2" @click="submitWork(index)" :loading="active == index && submitWorkLoading">提交作品</el-button>
                        </div>
                        <el-button class="default-button four" v-if="item.state == 0 && getUserInfo.role == 2"><router-link :to="'/project-created?cid=' + item.CLASS_NUM + '&cpid=' + item.ROW_ID">编辑</router-link></el-button>
                        <el-button class="default-button"><router-link :to="'/project-detail?cid=' + item.CLASS_NUM + '&cpid=' + item.ROW_ID">查看</router-link></el-button>
                    </div>
                </div>
            </div>
        </div>
        <the-empty v-else></the-empty>
        <select-work-dialog :isSelectWorkDialog="isSelectWorkDialog" @closeSelectDialog="closeSelectDialog" @workTap="workTap"></select-work-dialog>
  </div>
</template>

<script>
import { classMsg } from '../../../http/api'
import config from '../../../http/config'
import { mapGetters } from 'vuex'
import TheEmpty from '../../../components/TheEmpty.vue'
import SelectWorkDialog from './SelectWorkDialog.vue'
import WorkName from '@/components/WorkName.vue'
export default {
    props: {
        classData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },

    data() {
        return {
            isSelectWorkDialog: false,
            submitWorkLoading: false,
            active: 0,
            // activeProject: ''
        }
    },

    components: { TheEmpty, SelectWorkDialog, WorkName },

    computed: {
        ...mapGetters(['getUserInfo']),

        timeFormat() {
            return (time) => {
                return this.$util.formateDateTimesToZh(time, 1)
            }
        },

        buttonText() {
            return (state, type) => {
                switch (state) {
                    case 0:
                        return type == 1 ? 'not-started' : '未开始'
                    case 1:
                        return type == 1 ? 'started' : '进行中'
                    case -1:
                        return type == 1 ? 'has-ended' : '已结束'
                
                    default:
                        break;
                }
            }
        }
    },

    created() {
        console.log(this.classData)
    },

    methods: {
        /**
         * 提交作品
         */
        submitWork(index) {
            this.active = index
            this.isSelectWorkDialog = true
        },

        closeSelectDialog() {
            this.isSelectWorkDialog = false
        },

        workTap(item) {
            console.log(item)
            console.log(this.classData.classList[this.active])
            if (!this.$util.isDefine(this.classData.classList[this.active].work)) {
                this.submitWorkHttp(item)
            } else {
                this.$confirm(`确定用作品：<span>${item.NAME}</span>替换作品：<span>${this.classData.classList[this.active].work.workName}</span>吗？`, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    customClass: 'submit-work-confirm',
                    dangerouslyUseHTMLString: true
                })
                .then(() => {
                    this.submitWorkHttp(item)
                })
            }
        },

        submitWorkHttp(item) {
            this.closeSelectDialog()
            this.submitWorkLoading = true
            let params = {
                type: 16,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                tid: this.classData.classList[this.active].USER_ID,
                cid: this.classData.classList[this.active].CLASS_NUM,
                pid: item.PROJECT_ID,
                cpid: this.classData.classList[this.active].ROW_ID
            }
            classMsg(params)
                .then((res) => {
                console.log(res);
                this.submitWorkLoading = false
                this.$message({
                    type: 'success',
                    message: '提交成功'
                })
                setTimeout(() => {
                    this.$router.go(0)
                }, 1500)
            })
            .catch(() => {
                this.submitWorkLoading = false;
            });
        }
    }
}
</script>

<style lang="scss">
.class-project {
    .project-item {
        width: 566px;
        height: 302px;
        box-shadow: 0px 0px 8px #d5d5d5;
        border-radius: var(--item-border-radius);
        background-color: #ffffff;
        margin-right: 58px;
        margin-bottom: 24px;
        padding: 25px;
        box-sizing: border-box;
        font-size: 14px;

        .project-item-state.not-started {
            color: var(--el-color-five);
        }

        .project-item-state.started {
            color: green;
        }

        .project-item-state.has-ended {
            color: red;
        }

        .project-item-title {
            height: 30px;
            line-height: 30px;
            font-weight: bold;
            font-size: 14px;
        }

        .project-item-date {
             color: #bfbfbf;
             line-height: 30px;

            .project-item-state {
                font-weight: bold;
            }
         }

        .project-item-content {
            margin-top: 17px;
            .title {
                font-weight: bold;
                margin-bottom: 8px;
            }

            p {
                line-height: 22px;
            }
        }

        .project-item-handle {

            .project-button {
                .el-button {
                    margin-left: 10px;
                }
            }
        }
    }

    .project-item.no-margin {
        margin-right: 0px;
    }
}

.submit-work-confirm {
    .el-message-box__content {
        .el-message-box__message {
            span {
                color: var(--el-color-second);
                margin-right: 3px;
                font-weight: bold;
            }
        }
    }
}
</style>