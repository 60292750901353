<template>
  <div class="class-manage">
      <the-header></the-header>
      <div class="content-body warp ub ub-ver">
          <el-alert :title="elAlertTitle" type="error" center v-if="getUserInfo.cooperation != 0 && getUserInfo.role == 2" />
          <div class="class-manage-header ub ub-ac ub-be">
              <div class="title">{{title}}</div>
              <el-button class="default-button" @click="createdClass"  v-if="getUserInfo.cooperation == 0 ||  getUserInfo.role == 0">{{createdClassBtnText}}</el-button>
          </div>
          <div class="class-manage-list " v-loading="loading">
              <div v-if="classList">
                <div v-if="classList.length > 0">
                    <class-cell v-for="(item, index) in classList" 
                                :key="index" 
                                :item="item" 
                                :index="index" 
                                @selectStudent="selectStudent" 
                                @delectStudent="delectStudent"
                                @selectAllData="selectAllData"
                                @editClassName="editClassName"
                                @delectClass="delectClass"
                                @showClassMsg="showClassMsg"></class-cell>
                </div>
                <el-empty v-else description="暂无班级"></el-empty>
              </div>
              
          </div>
      </div>
      <class-dialog :isClassDialog="isClassDialog" 
                    :classDialogTitle="classDialogTitle" 
                    :classDialogPlaceholder="classDialogPlaceholder" 
                    :classDialogType="classDialogType"
                    @closeClassDialog="closeClassDialog"
                    @setClass="setClass"></class-dialog>
      <class-msg-dialog :isClassMsgDialog="isClassMsgDialog" :classInfo="classCurrent" @showClassMsg="showClassMsg" @reloadClass="reloadClass"></class-msg-dialog>
  </div>
</template>

<script>
import { teacherMsg, classMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import ClassCell from './components/ClassCell.vue'
import ClassDialog from './components/ClassDialog.vue'
import ClassMsgDialog from './components/ClassMsgDialog.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TheHeader, ClassCell, ClassDialog, ClassMsgDialog},
  
  data() {
      return {
        loading: false,
        classList: null,
        classCurrent: null,
        isClassDialog: false,
        classDialogTitle: '',
        classDialogPlaceholder: '',
        classDialogType: 1, // 1 创建班级 2 修改班级名称 3
        isClassMsgDialog: false
      }
  },

  computed: {
      ...mapGetters(['getUserInfo']),

      elAlertTitle() {
            switch (this.getUserInfo.cooperation) {
                case -1:
                    return '学校合作已到期，请联系客服！'
                case -2:
                    return '您的账号已被禁用，请联系客服！'
                default:
                    return '';
            }
      },

      showElAlert() {
        return 
      },

      title() {
          return this.getUserInfo.role == 0 ? '我的班级' : '班级管理'
      },

      createdClassBtnText() {
          return this.getUserInfo.role == 0 ? '加入新班级' : '创建新班级'
      }
  },

  created() {
      this.getData()
  },

  methods: {
      /**
       * 获取班级列表
       */
      getData() {
        this.loading = true
        let params;
        let http;
        if (this.getUserInfo.role == 0) {
            // 学生获取班级
            params = {
                type: 2,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
            }
            http = classMsg(params)
        } else {
            // 老师获取班级
            params = {
                type: 1,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                token: this.getUserInfo ? this.getUserInfo.token : '',
                sid: this.getUserInfo ? this.getUserInfo.school_id : ''
            }
            http = teacherMsg(params)
        }
        http.then((res) => {
          console.log(res);
          this.loading = false
          this.classList = res.classinfo
        })
        .catch(() => {
          this.loading = false;
        });
      },

      /**
       * 创建班级
       */
      createdClass() {
          this.classDialogType = 1
          this.isClassDialog = true
          this.classDialogTitle = this.getUserInfo.role == 0 ? "加入新班级" : "创建班级"
          this.classDialogPlaceholder = `请输入${this.getUserInfo.role == 0 ? '班级邀请码' : '班级名称'}`
      },

      /**
       * 修改班级名称
       */
      editClassName(index) {
          this.classDialogType = 2
          this.isClassDialog = true
          this.classDialogTitle = "修改班级名称"
          this.classDialogPlaceholder = this.classList[index].classname
          this.classCurrent = this.classList[index]
      },

      /**
       * 创建班级/修改班级名称操作
       */
      setClass(name) {
        this.loading = true
        let params = {
            uid: this.getUserInfo ? this.getUserInfo.uid : '',
        }
        let http;
        if (this.classDialogType == 1) { // 创建班级/加入班级
            if(this.getUserInfo.role == 0) {
                // 加入
                params.type = 1
                params.uid = this.getUserInfo ? this.getUserInfo.uid : ''
                params.code = name
                http = classMsg(params)
            } else {
                // 创建
                params.type = 6
                params.sid = this.getUserInfo ? this.getUserInfo.school_id : ''
                params.cuid = 0
                params.name = name
                http = teacherMsg(params)
            }
        } else { // 修改班级名称
            params.type = 2
            params.cid = this.classCurrent.class_id
            params.cname = name
            http = teacherMsg(params)
        }
        http.then((res) => {
          console.log(res);
          this.$message({
            type: 'success',
            message: this.classDialogType == 1 ? this.getUserInfo.role == 0 ? '你的申请已发送，请等待老师的审批处理 ！' : '创建班级成功' : '班级名修改成功'
          })

          this.isClassDialog = false
          setTimeout(() => {
              this.$router.go(0)
          }, 1500)
        })
        .catch((err) => {
            console.log(err)
          this.loading = false;
        });
      },

      /**
       * 关闭弹窗
       */
      closeClassDialog() {
        this.isClassDialog = false
      },

      /**
       * 选择学生
       * @param {Number} index 选择学生的班级
       * @param {Number} index 选择学生的索引
       */
      selectStudent(index, studentIndex) {
          this.classList[index].students[studentIndex].isCheck = this.classList[index].students[studentIndex].isCheck ? false : true
      },

      /**
       * 删除学生
       * @param {NUmber} index 删除学生的班级
       */
      delectStudent(index) {
          let arr = []
          for (let i = 0; i < this.classList[index].students.length; i++) {
              if (this.classList[index].students[i].isCheck) {
                  arr.push(this.classList[index].students[i].uid)
              }
          }
          if (arr.length == 0) {
              this.$message({
                type: 'warning',
                message: '请选择需要删除的信息！'
              })
              return
          }
          console.log(arr)
          this.$confirm("是否确认删除选中学生?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
                this.loading = true
                let params = {
                    type: 7,
                    uid: this.getUserInfo ? this.getUserInfo.uid : '',
                    cid: this.classList[index].class_id,
                    stuList: arr.join('-')
                }
                teacherMsg(params).then((res) => {
                    console.log(res)
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    this.loading = false
                    this.getData()
                    /* setTimeout(() => {
                        this.$router.go(0)
                    }, 1000) */
                }).catch(() => {
                    this.loading = false
                })
          })
      },

      /**
       * 全选/取消全选
       * @param {Number} type 1 全选 2 取消全选
       */
      selectAllData(index, type) {
          for (let i = 0; i < this.classList[index].students.length; i++) {
              this.classList[index].students[i].isCheck = type == 1 ? true : false
          }
      },

      /**
       * 删除班级
       */
      delectClass(index) {
            this.$confirm("是否确认删除该班级?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                this.loading = true
                let params = {
                    type: 22,
                    uid: this.getUserInfo ? this.getUserInfo.uid : '',
                    cid: this.classList[index].class_id
                }
                teacherMsg(params).then((res) => {
                    console.log(res)
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    this.$router.go(0)
                }).catch(() => {
                    this.loading = false
                })
            }).catch(() => {
                // ...
            })
      },

      /**
       * 班级消息弹窗
       * @param {Boolean} type true 开启 false 关闭
       * @param {Number} index 班级索引
       * @param {Boolean} isReload 是否需要刷新数据
       */
      showClassMsg(type, index, isReload) {
          console.log(isReload)
          this.classCurrent = this.classList[index]
          this.isClassMsgDialog = type
          if (isReload && !type) {
              this.getData()
          }
      },

      /**
       * 重新加载数据
       */
      reloadClass() {
          this.getData()
      }
  }
}
</script>

<style lang="scss">
.class-manage {
    .class-manage-header {
        height: 100px;
        font-size: 20px;
    }

    .class-manage-list {
        min-height: 500px;
        margin-bottom: 30px;
    }
}
</style>