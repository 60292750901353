const reports = [
    {
        value: '违法、违规、违禁内容',
        id: 1
    }, {
        value: '垃圾广告，无意义刷屏',
        id: 2
    }, {
        value: '侵犯隐私或人身攻击',
        id: 3
    }, {
        value: '含有暴力色情',
        id: 4
    }, {
        value: '其它不良信息',
        id: 5
    }
]

export default {
    reports
}