<template>
  <div class="contact-us">
    <div class="top-info ub ub-be">
        <div class="top-info-left">
            <div class="detail-title">武汉智多蛙教育科技有限公司</div>
            <div class="contact-info iconfont icon-dianhua">
            <span>电话：18162618661</span>
            </div>
            <div class="contact-info iconfont icon-dingwei">
            <span>地址：武汉市江汉区国贸新都A座27楼</span>
            </div>
        </div>
        <div class="top-info-right">
            <qr-code></qr-code>
        </div>
    </div>
    <div class="map"><img src="../../assets/image/contactUs_map.png" alt="" /></div>
  </div>
</template>

<script >
import QrCode from '@/components/QrCode.vue'
export default {
  components: { QrCode },

}
</script>

<style lang="scss">
.contact-us {
    padding-left: 110px;
    padding-right: 110px;

    .top-info {
        .top-info-left {
            width: 550px;
            margin-bottom: 19px;

            .detail-title {
                font-size: 24px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 40px;
            }

            .contact-info {
                font-size: 18px;
                line-height: 30px;
                position: relative;
                padding-left: 42px;
            }

            .icon-dianhua {
                margin-bottom: 20px;
            }

            .contact-info::before {
                font-size: 30px;
                position: absolute;
                left: 0;
                top: 0px;
            }
        }

        .top-info-right {
            width: 140px;
            text-align: center;
        }


    }

    .map {
        margin-top: 35px;
    }
}
</style>