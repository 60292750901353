<template>
  <div class="teacher-rate-model" v-loading="loading">
      <el-form :model="formData" class="ub ub-ver ub-ac" label-width="85px">
          <el-form-item class="ub ub-ac" label="作品等级：">
              <the-rate class="ub ub-ac" :score="formData.score" :isDisabled="formData.isRateDisabled" @rateChange="rateChange"></the-rate>
          </el-form-item>
          <el-form-item label="作品评语：">
              <el-input
                  v-model="formData.content"
                  :rows="5"
                  type="textarea"
                  placeholder="请输入评语"
                  resize="none"
                  maxlength="300"
                  show-word-limit
                  :disabled="formData.isRateDisabled"
              />
          </el-form-item>
          <div class="ub ub-pc">
            <el-button class="default-button" @click="submitRate" :loading="buttonLoading" v-if="!formData.isRateDisabled && isTeacher">确认</el-button>
            <el-button class="default-button" @click="setDisabled" v-if="formData.isRateDisabled && isTeacher">修改</el-button>
            <el-button class="ub-f1" type="info" @click="cancelRate">取消</el-button>
          </div>
      </el-form>
  </div>
</template>

<script>
import { teacherMsg } from '../../../http/api'
import TheRate from '@/components/TheRate.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TheRate },
  data() {
    return {
      formData: {
        score: 5,
        isRateDisabled: false,
        content: ''
      },
      loading: false,
      buttonLoading: false
    }
  },

  computed: {
    ...mapGetters(['getUserInfo']),

    isTeacher() {
      return this.getUserInfo.uid == this.query.tid
    }
  },

  created() {
    console.log(this.$route.query)
    this.query = this.$route.query
    if (this.query.state == 1) {
      this.getRateInfo()
    }
  },

  methods: {
    rateChange(val) {
      this.formData.score = val
    },

    /**
     * 获取评价消息
     */
    getRateInfo() {
      this.loading = true
      let params = {
          type: 15,
          uid: this.query.tid,
          cid: this.query.cid,
          cpid: this.query.cpid,
          rid: this.query.rid,
          state: 1
      }
      teacherMsg(params).then((res) => {
          console.log(res)
          this.rateInfo = res.commentInfo
          this.formData.score = res.commentInfo.score
          this.formData.content = res.commentInfo.comment
          this.formData.isRateDisabled = true
          this.loading = false
      }).catch(() => {
          this.loading = false
      })
    },

    /**
     * 提交评分
     */
    submitRate() { 
      if (this.formData.score == 0) {
        this.$message({
          type: 'warning',
          message: '请先评分！'
        })
        return
      }

      if (!this.$util.isDefine(this.formData.content)) {
        this.$message({
          type: 'warning',
          message: '请输入评语！'
        })
        return
      }
      this.buttonLoading = true
      let params = {
          type: 16,
          uid: this.query.tid,
          stuid: this.query.uuid,
          cid: this.query.cid,
          cpid: this.query.cpid,
          rid: this.query.rid,
          score: this.formData.score,
          content: this.formData.content,
          state: this.query.state // 0 未点评/评价 1 已点评/编辑
      }
      teacherMsg(params).then(() => {
          this.$message({
              message: '评价成功',
              type: 'success',
              showClose: true
          })
          this.buttonLoading = false
          setTimeout(() => {
              this.$router.go(-1)
          }, 1500)
      }).catch(() => {
          this.buttonLoading = false
      })
    },

    setDisabled() {
      this.formData.isRateDisabled = false
    },

    cancelRate() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss">
.teacher-rate-model {
  background-color: #fff;
  margin-top: 30px;
  box-sizing: border-box;
  padding: 20px;
  min-height: 150px;

  .el-form {
    .el-form-item {
      width: 100%;
    }

    .el-button {
      width: 100px;
    }
  }
}
</style>