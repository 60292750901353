<template>
  <el-image :class="['user-image', size, radius, userImg ? 'cursor' : '']" :src="userImg" fit="cover" @click="userImgTap"></el-image>
</template>

<script>
export default {
    props: {
        userImg: {
            type: String,
            default: require('../assets/image/personal_default_banner2.png')
        },

        userId: {
            type: Number,
            default: ''
        },

        size: {  // small 小
            type: String,
            default: ''
        },

        radius: { // small-circle 小圆角
            type: String,
            default: ''
        }
    },

    methods: {
        userImgTap() {
            if (!this.$util.isDefine(this.userId)) {
                return
            }

            this.$router.push(`/personal-center?uid=${this.userId}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.user-image {
    width: 86px;
    height: 86px;
    border-radius: 50%;
}

.user-image.small {
    width: 56px;
    height: 56px;
}

.user-image.small-circle {
    border-radius: var(--item-border-radius);
}

.user-image.cursor {
    cursor: pointer;
}
</style>