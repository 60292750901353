<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    this.$router.replace('/shopping-index/shopping-center')
  },

  mounted() {

  },

  methods: {

  },

  watch: {
    $route(to, from) {
      //这里可以加你监听到路由改变时要触发的方法
      if (to.path == '/shopping-index') {
          this.$router.replace('/shopping-index/shopping-center')
      }
    }
  },
}
</script>

<style>

</style>