<template>
  <div class="down-resources">
      <the-header></the-header>
      <div class="content-body">
          <div v-if="!loadErr">
            <div class="nav" v-if="nav.list.length > 0">
              <ul :class="['warp ub', menu.list.length == 0 ? 'no-margin-left' : '']">
                <li :class="[index == nav.active ? 'act' : '']" v-for="(item, index) in nav.list" :key="index" @click="navTap(index)">{{item.name}}</li>
              </ul>
            </div>
            <div class="warp ub">
              <el-menu class="ub ub-ver menu" v-if="menu.list.length > 0">
                <el-menu-item :class="[index == menu.active ? 'act' : '']" :index="index + 1" v-for="(item, index) in menu.list" :key="index" @click="menuTap(index)">{{ item.name }}</el-menu-item>
              </el-menu>
              <div class="down-content ub-f1-item" v-loading="loading" :element-loading-text="loadingText">
                <div :class="['switch-view iconfont', viewType == 1 ? 'icon-dasuolvetuliebiao' : 'icon-liebiao']" @click="switchView"></div>
                <div class="down-model" v-if="downList">
                  <div :class="['down-list', viewType == 1 ? 'ub ub-wrap' : '']" v-if="downList.length > 0">
                    <!-- <file-cell-type v-for="(item, index) in downList" :key="index" :item="item" :viewType="viewType"></file-cell-type> -->
                    <el-tree :data="downList" 
                             :props="defaultProps" 
                             @node-click="handleNodeClick"
                             node-key="id"
                             :render-content="renderContent">
                    </el-tree>
                  </div>
                  <the-empty description="暂无资源" v-else></the-empty>
                </div>
              </div>
            </div>
          </div>
          <the-empty :description="description" v-else></the-empty>
      </div>
      <the-footer></the-footer>
  </div>
</template>

<script>
import { resourcesMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import { mapGetters } from 'vuex'
import TheFooter from '@/components/TheFooter.vue'
import TheEmpty from '@/components/TheEmpty.vue'
import FileCellType from './components/FileCellType.vue'
export default {
  components: { TheHeader, TheFooter, TheEmpty, FileCellType },
  data() {
     return {
      nav: {
        active: 0,
        list: []
      },

      menu: {
        active: 0,
        list: []
      },
      loadErr: false,
      loading: false,
      loadingText: 'Loading...',
      description: '暂无数据',
      downList: null,
      viewType: 1,
      // tree
      defaultProps: {
        children: 'list',
        label: 'name'
      }
    }
  },

  computed: {
    ...mapGetters(['getUserInfo']),
    fileType() {
      return (type) => {
        console.log(type)
        switch (Number(type)) {
            case 1:
                return "word"
            case 2:
                return "pdf"
            case 3:
                return "ppt"
            case 4:
                return "xls"
            case 5:
                return "exe"
            case 6:
                return "ps"
            case 7:
                return "pic"
        
            default:
                return 'word';
        }
      }
    }
  },

  created() {
    if (this.getUserInfo.role == 3) {
    // 之前是角色2 老师有权限，策划说改成3 校长
      this.getData()
    } else {
      // 其他角色暂无下载资源
      this.loadErr = true
      this.description = '暂无资源！'
    }
  },

  methods: {
    getData() {
      this.loading = true
      this.loadingText = 'Loading...'
      let params = {
        type: 1,
        resourceType: 2,
        userId: this.getUserInfo ? this.getUserInfo.uid : '',
        schoolId: this.getUserInfo ? this.getUserInfo.school_id : '',
        oneLevelId: this.menu.list.length == 0 ? 3 : this.menu.list[this.menu.active].oneLevelId,
        twoLevelId: this.nav.list.length == 0 ? 1 : this.nav.list[this.nav.active].twoLevelId
      }
      resourcesMsg(params).then((res) => {
        console.log(res)
        this.loading = false
        this.menu.list = res.data.list // 左侧菜单
        this.nav.list = res.data.list2 // 头部菜单
        this.downList = res.data.list3 // 下载菜单
        
      }).catch((err) => {
        console.log(err)
        this.loading = false
        this.loadErr = true
        this.description = '合作已到期，请联系管理员！'
      })
    },

    navTap(index) {
      this.nav.active = index
      this.getData()
    },

    menuTap(index) {
      this.menu.active = index
      this.getData()
    },

    switchView() {
      this.viewType = this.viewType == 1 ? 2 : 1
    },

    handleNodeClick(e) {
      console.log('rere', e)
      if (!e.fileName) {
        return
      }
      let url = e.fileName
      let arr = url.split('/')
      this.download(arr[arr.length - 1],url)
    },

    download(fileName, url) {
      console.log(fileName)
      console.log(url)
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      this.loading = true
      this.loadingText = '资源加载中...'
      x.onload = (e) => {
        let blob = x.response;
        console.log(blob)
        this.loading = false
        if ("msSaveOrOpenBlob" in navigator) {
          //IE导出
          window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          let a = document.createElement("a");
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          document.querySelector("body").appendChild(a);
          a.click();
          document.querySelector("body").removeChild(a);
        }
      };
      x.send();
    },

    renderContent(h, { node, data, store }) {
      console.log(node)
      console.log(data)
      console.log(store)
      let html1 = <span class="custom-tree-node title">
                    <span>{node.label}</span>
                  </span>
      let html2 = <span class="custom-tree-node" v-loading={data.loading}>
                    {data.type == 1 ? (<span class="icon word">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 2 ? (<span class="icon pdf">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 3 ? (<span class="icon ppt">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 4 ? (<span class="icon xls">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 5 ? (<span class="icon exe">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 6 ? (<span class="icon ps">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 7 || data.type == 8 ? (<span class="icon pic">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 9 ? (<span class="icon cdr">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 10 ? (<span class="icon mp4">{node.label}<em>{data.time}</em></span>) : 
                     data.type == 11 || data.type == 12 ? (<span class="icon rar">{node.label}<em>{data.time}</em></span>) : ''}
                  </span>
       
        return (
          node.data.list && node.data.list.length > 0 ? html1 : html2
          );
    }
  }
}
</script>

<style lang="scss">
.down-resources {
  .content-body {
    .pbl-empty {
      margin-top: 30px;
    }
    .nav {
      background-color: #fff;
      padding-top: 35px;
      ul {
        li {
          height: 50px;
          line-height: 50px;
          margin-right: 90px;
          position: relative;
          cursor: pointer;
        }

        li:first-child {
          margin-left: 236px;
        }

        li.act::before {
          content: '';
          width: 100%;
          height: 6px;
          background-color: var(--el-color-primary);
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }

      ul.no-margin-left {
        li:first-child {
          margin-left: 70px;
        }
      }
    }

    .menu {
      width: 182px;
      border-right: 0px;
      margin-top: 37px;
      .el-menu-item {
        height: 60px;
        display: flex;
        -webkit-justify-content: center;
        -moz-justify-content: center;
        -ms-justify-content: center;
        justify-content: center;
        font-weight: 500;
      }

      .el-menu-item.act {
        background-color: var(--el-color-primary);
        color: #000000;
      }
    }

    .down-content {
      width: 988px;
      min-height: 300px;
      margin-left: 20px;
      margin-top: 37px;
      position: relative;
      padding-top: 15px;

      .switch-view {
        position: absolute;
        right: 0;
        top: -30px;
        font-size: 26px;
        cursor: pointer;
        color: var(--el-color-three);
      }

      .down-model {
        .down-list {
          .el-tree {
            width: 100%;
            padding: 10px 0;
            .el-tree-node {
              .el-tree-node__content {
                width: 100%;
                height: 36px;
                box-sizing: border-box;
                .custom-tree-node {
                  display: block;
                  width: 100%;
                  .icon {
                    // width: 30px;
                    width: 100%;
                    height: 30px;
                    display: inline-block;
                    padding-left: 30px;
                    position: relative;
                    line-height: 30px;
                    margin: 5px 0;
                    box-sizing: border-box;
                    em {
                      float: right;
                      margin: 0 10px;
                    }
                  }

                  .icon:before {
                    content: '';
                    display: block;
                    width: 20px;
                    height: 30px;
                    position: absolute;
                    left: 0;
                    top: 0;
                  }

                  .icon.word:before {
                    background-image: url('../../assets/image/word.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.pdf:before {
                    background-image: url('../../assets/image/pdf.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.ppt:before {
                    background-image: url('../../assets/image/ppt.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.xls:before {
                    background-image: url('../../assets/image/xls.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.exe:before {
                    background-image: url('../../assets/image/exe.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.ps:before {
                    background-image: url('../../assets/image/ps.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.cdr:before {
                    background-image: url('../../assets/image/cdr.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.pic:before {
                    background-image: url('../../assets/image/pic.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }

                  .icon.mp4:before {
                    background-image: url('../../assets/image/mp4.png');
                    background-size: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                  }
                } 

                .custom-tree-node.title {
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>