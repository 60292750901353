<template>
  <el-form-item 
    v-for="(item, index) in queList" 
    :key="index"
    :label="(index + 1) + '. ' +item.title" 
    :class="['que1 ub ub-ver', isShowActErr(item) ? 'err' : '']">
        <div class="tips" v-if="item.tip">{{ item.tip }}</div>
        <component v-bind:is="'Que' + item.type"
                    :item="item"
                    :index="index"
                    :showErr="showErr"
                    :maxlength="item.maxlength ? item.maxlength : ''"
                    @setData="setData"></component>
        <el-alert
            :title="errTitle(item)"
            type="error"
            show-icon
            :closable="false"
            v-if="isShowActErr(item)"
        />
  </el-form-item>
</template>

<script>
import Que1 from './que1.vue';
import Que2 from './que2.vue';
import Que3 from './que3.vue';
import Que4 from './que4.vue';
export default {
  components: { Que1, Que2, Que3, Que4 },
    props: {
        queList: {
            type: Array,
            default: () => {
                return []
            }
        },
        showErr: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            errMsgTip: [{
                id: 1,
                title: '请输入内容'
            }, {
                id: 2,
                title: '请选择选项'
            }, {
                id: 3,
                title: '请上传文件'
            }, {
                id: 4,
                title: '请输入内容'
            }],
            numReg: /^1[345789]\d{9}$/,
            regex: /^([H|M]\d{8})$/i
        }
    },
    computed: {
        num() {
            return (index) => {
                return index + 1
            }
        },

        errTitle() {
            return (item) => {
                if (item.type == 1) {
                    if (!item.value) {
                        return '请输入内容'
                    }
                    if (item.mold == 'phone' && !this.numReg.test(item.value)) {
                        return '请输入正确手机号'
                    }
                    if (item.mold == 'card' && !this.$util.validateIdCard(item.value)) {
                        return '请输入正确身份证号码'
                    }

                    
                }
                let result = this.errMsgTip.filter((value)=>{
                    return value.id == item.type
                })
                return result[0].title
            }
        },

        isShowActErr() {
            return (item) => {
                 if(this.showErr) {
                    if (item.type == 1) {
                        if (item.mold == 'phone') {
                            if (item.isMust && (!item.value || !this.numReg.test(item.value))) {
                                return true
                            }
                            if (!item.isMust && item.value && !this.numReg.test(item.value)) {
                                return true
                            }
                        } if (item.mold == 'card') {
                            if (item.isMust && (!item.value || !this.$util.validateIdCard(item.value) && !this.$util.isValidHKMacauPassportNumber(item.value))) {
                                return true
                            }
                            if (!item.isMust && item.value && (!this.$util.validateIdCard(item.value) && !this.$util.isValidHKMacauPassportNumber(item.value))) {
                                return true
                            }
                        } else {
                            if (item.isMust && !item.value) {
                                return true
                            }
                        }
                    } else {
                        if (item.isMust && !item.value) {
                            return true
                        }
                    }
                 } else {
                    if (item.type == 1) {
                        if (item.mold == 'phone') {
                            if (item.value && !this.numReg.test(item.value)) {
                                return true
                            } 
                        }
                        if (item.mold == 'card') {
                            if (item.value && (!this.$util.validateIdCard(item.value) && !this.$util.isValidHKMacauPassportNumber(item.value))) {
                                return true
                            } 
                        }
                    }
                 }
                 return false
            }
        }
    },

    methods: {
        setData(index, value) {
            this.$emit('setData', index, value)
        }
    },
    watch: {
    }
}
</script>

<style>

</style>