<template>
  <div class="quick-nav" :style="'right:'+ leftMargin + 'px'">
      <div
        class="to-top btn iconfont icon-arrow-top"
        @click="toTop"
      ></div>
      <div class="create-scratch btn" @click="openProjectPage()">Scratch创作</div>
      <div class="customer-service btn">客服</div>
  </div>
</template>

<script>
import config from '../../../http/config'
import $ from 'jquery'
export default {
    created() {
        console.log(document.body.offsetHeight)
        this.leftMargin = (document.body.offsetWidth - 1190) / 2 - 77 - 10;
    },

    mounted() {
            var wHeight = $(window).height(); //屏幕可视区域高度
            var hHeight = $(".pbl-header").height(); //头部高度
            var bHeight = $(".pbl-banner").height(); //banner高度
            this.setTopHeight(wHeight, hHeight, bHeight)
            $(window).scroll(() => {
                if (this.$route.path == '/') {
                    this.setTopHeight(wHeight, hHeight, bHeight)
                }
            });
        
    },

    methods: {
        toTop() {
            $("body,html").animate({ scrollTop: 0 }, 300);
        },

        /**
         * 设置距离顶部高度
         */
        setTopHeight(wHeight, hHeight, bHeight) {
            var sHeight = $(".quick-nav").height(); //右侧菜单高度
            console.log(sHeight)
            var top = (wHeight - sHeight) / 2; //获取右侧菜单距离顶部定位高度
            var footerTop = $(".pbl-footer").offset().top; //获取右侧菜单距离顶部定位高度
            var wSHeight = $(document).scrollTop(); //页面滚动高度
            var scrolltop = 0;
            var topContentShowHeight = hHeight + bHeight - wSHeight; //banner + header 显示高度
            var scrollBottomHeight = footerTop - wSHeight - top - sHeight
            if (bHeight) {
                if (topContentShowHeight < top) {
                    scrolltop = top;
                    $(".to-top").css("display", "block");

                    if (scrollBottomHeight < 0) {
                        scrolltop = scrolltop + scrollBottomHeight - 30
                    }
                } else {
                    $(".to-top").css("display", "none");
                    scrolltop = topContentShowHeight + 20;
                }
            } else {
                if (wSHeight > 100) {
                    $(".to-top").css("display", "block");
                } else {
                    $(".to-top").css("display", "none");
                }
                scrolltop = top;
            }
            $(".quick-nav").css("top", scrolltop);
        },

        openProjectPage() {
            window.open(config.scratchUrl.create, '_blank');
        }
    }
}
</script>

<style lang="scss">
.quick-nav {
    width: 77px;
    height: 380px;
    position: fixed;
    right: 80px;
    z-index: 999;
    top: 100px;

    .btn {
        width: 77px;
        height: 122px;
        line-height: 200px;
        background: #ececec;
        text-align: center;
        border-radius: 10px;
        margin-bottom: 7px;
        cursor: pointer;
        color: #959595;
        font-size: 14px;
    }

    .btn:last-child {
        margin-bottom: 0;
    }

    .to-top {
        position: relative;
        line-height: 122px;
    }

    .to-top:before {
        display: inline-block;
        font-size: 34px;
        font-weight: bold;
    }

    .create-scratch {
        background: #ffd101;
        color: #000;
        position: relative;
        font-size: 12px;
    }

    .create-scratch:before {
        content: "";
        width: 60px;
        height: 67px;
        background: url(../../../assets/image/pbl_icon.png) -10px -10px no-repeat;
        position: absolute;
        left: 8.5px;
        top: 15px;
    }

    .create-scratch a {
        height: 122px;
        display: block;
        position: relative;
        z-index: 99;
    }

    .customer-service {
        position: relative;
    }

    .customer-service:before {
        content: "";
        width: 51px;
        height: 52px;
        background: url(../../../assets/image/pbl_icon.png) -10px -87px no-repeat;
        position: absolute;
        left: 13px;
        top: 22px;
    }

    .customer-service:hover:before {
        width: 70px;
        height: 70px;
        background: url(../../../assets/image/contact_us.png);
        background-size: 100% 100%;
        left: 4px;
        top: 6px;
    }
}


</style>