<template>
      <el-radio-group v-model="value" :class="item.list && item.list.length > 3 ? 'ub ub-ver ub-as' : ''" @change="radioChange">
        <el-radio :label="item.title" v-for="(item, index) in item.list" :key="index" />
      </el-radio-group>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            value: ''
        }
    },

    methods: {
        radioChange(value) {
            console.log(value)
            if(value == '无') {
                value = ''
            }
            this.$emit('setData', this.index, value)
        }
    },
    watch: {
        item: {
            handler(newVal) {
                console.log(newVal)
                if (newVal.value) {
                    this.value = newVal.value
                }
            },
            deep: true
            
        }
    }
}
</script>

<style>

</style>