<template>
  <div :class="['file-item-type ub ub-ac', 'file-item-type' + viewType,]">
      <a :class="['ub ub-ac', viewType == 1 ? 'ub-ver' : '']" :href="item.url">
        <el-image :src="require('../../../assets/image/'+ fileType(item.type) +'.png')"></el-image>
        <div class="file-title text-hidden" :title="item.name">{{item.name}}</div>
      </a>
  </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },

        viewType: {
            type: Number,
            default: 1
        }
    },

    computed: {
        fileType() {
            return (type) => {
                console.log(type)
                switch (Number(type)) {
                    case 1:
                        return "word"
                    case 2:
                        return "pdf"
                    case 3:
                        return "ppt"
                    case 4:
                        return "xls"
                    case 5:
                        return "exe"
                    case 6:
                        return "ps"
                
                    default:
                        return 'word';
                }
            }
        }
    }
}
</script>

<style lang="scss">
.file-item-type {
    height: 35px;
    border-bottom: 1px solid #ccc;
    .el-image {
        width: 20px;
        margin-right: 10px;
    }
    .file-title {
        font-size: 14px;
        color: #000000;
    }
}

.file-item-type1 {
    border: 0px;
    height: auto;
    width: 158px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .el-image { 
        width: 80px;
    }

    .file-title {
        width: 158px;
        line-height: 34px;
        margin-top: 5px;
        padding: 0 5px;
        text-align: center;
        font-size: 16px;
    }
}
</style>