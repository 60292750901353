<template>
  <div class="lesson-item">
    <el-image
      class="lesson-item-pic"
      :src="item.IMG"
      fit="cover"
      @click="courseDetail(item.PROJECT_ID, item.USER_ID)"
    />
    <div
      class="lesson-item-title ub ub-ac"
      @click="courseDetail(item.PROJECT_ID, item.USER_ID)"
    >
      <div class="text-hidden">{{ item.NAME }}</div>
    </div>
    <div class="lesson-item-user text-hidden">主讲人：{{ item.INSTRUCTION }}</div>
    <like-info class="ub-pe" :item="item"></like-info>
  </div>
</template>

<script>
import LikeInfo from "./LikeInfo.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    courseDetail(pid, uuid) {
      this.$router.push({
        path: "/course-detail",
        query: {
          pid: pid,
          uuid: uuid,
        },
      });
    },
  },
  components: { LikeInfo },
};
</script>

<style lang="scss">
.lesson-item {
  width: 285px;
  background-color: #fff;
  box-shadow: 0px 0px 8px #d5d5d5;
  overflow: hidden;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: var(--item-border-radius);
  .lesson-item-pic {
    width: 285px;
    height: 172px;
    cursor: pointer;
    background-color: var(--el-color-primary);
  }

  .lesson-item-title {
    height: 50px;
    padding: 16px 15px 8px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .lesson-item-user {
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 14px;
  }

  .like-info {
    padding: 0 15px;
  }
}
</style>