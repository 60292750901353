<template>
  <div class="pbl-alert">
      <el-alert :title="title"
                v-if="show"
                :closable="isClose"
                :type="type"
                @close="closeAlert"
                show-icon></el-alert>
  </div>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: '提示信息'
        },

        type: {
            type: String,
            default: 'success'
        },

        isClose: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        closeAlert() {
            this.$emit('closeAlert')
        }
    }
}
</script>

<style lang="scss">
.pbl-alert {
    width: 100%;
    .el-alert {
        background-color: #ffe4e2;
        border: 1px solid #fc4621;
        color: #fc4621;
        padding-top: 5px;
        padding-bottom: 5px;

        i.el-alert__icon {
            width: 23px;
            height: 23px;
            background-color: #fc4621;
            border-radius: 50%;
            position: relative;
            svg {
                display: none;
            }
        }

        i.el-alert__icon:before {
            content: '';
            width: 11px;
            height: 3px;
            position: absolute;
            top: 50%;
            margin-top: -1.5px;
            left: 50%;
            margin-left: -5.5px;
            background-color: #fff;
        }

        .el-alert__content {
            .el-alert__title {
                line-height: 22px;
            }

            i.el-alert__close-btn {
                top: 11px;
            }
        }
    }
}
</style>