<template>
  <div class="wares-item">
      <div class="wares-img">
        <el-image lazy v-if="(index != actPicHoverIndex) || playImages.length == 0" @contextmenu="handlePaste($event)" class="item-pic" :src="item.materialCover" fit="contain" @click="workDetail(item.rowId)"></el-image>
        <!-- <el-image v-if="isPlayImage && actPlayImage" class="item-pic" :src="actPlayImage" fit="contain" @mouseout="warePicLeave" @mouseleave="warePicLeave"></el-image> -->
        <swiper :options="swiperOption" v-if="index == actPicHoverIndex">
          <swiper-slide v-for="(item, index) in playImages" :key="index"><el-image :src="item" @contextmenu="handlePaste($event)"></el-image></swiper-slide>
        </swiper>
      </div>
      <div :class="['item-state', 'item-state'+item.whetherHave]" v-if="showWareButton(item.whetherHave)" @click="wareButtonTap(item.whetherHave, item.rowId)">{{wareButtonText(item.whetherHave)}}</div>
      <div class="item-title ub ub-ac" :title="item.materialName" @click="workDetail(item.rowId)"><div class="text-hidden-2">{{ item.materialName }}</div></div> 
      <div class="item-describe ub ub-ac"><div class="text-hidden-2">{{item.materialDescribe}}</div></div>
      <div class="item-coins ub ub-ac">
        <!-- <div class="item-user-coin ub-f1">{{item.exchangeValue}}</div> -->
        <the-coin :num="item.exchangeValue" class="item-user-coin ub-f1"></the-coin>
        <span>兑换：{{ item.exchangeSum }}</span>
      </div> 
  </div>
</template>

<script>
import util from '../utils/util'
import { mapGetters } from 'vuex'
import { shoppingMaterialMsg } from '../http/api'
import TheCoin from './TheCoin.vue'
let flag = true
let num = 0
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },

    index: {
      type: Number,
      default: null
    },
    
    actPicHoverIndex: {
      type: Number,
      default: null
    },

    isWhetherHave: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isPlayImage: false,
      playImages: [],
      swiperOption: {
        speed: 700,
        autoplay: true,
        loop : true,
        slidesPerView: 1,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        }
      }
      
    }
  },

  components: {TheCoin },

  computed: {
    ...mapGetters(['getUserInfo']),

    showWareButton() {
      return (state) => {
        return state == 0 || state == 1 || state == 3
      }
    },

    wareButtonText() {
        return (state) => {
            switch (Number(state)) {
                case 0:
                    return '兑换'
                case 1:
                    return '已拥有'
                case 3:
                    return '暂无库存'
            
                default:
                    return '';
            }
        }
    },

    playImages() {
      let images = this.item.materialUrl.split(',')
      let filterImages = images.filter((item) => {
        return item != ''
      })
      console.log(filterImages)
      return filterImages
    }
  },

  created() {
    console.log(util)
  },

  mounted() {
  },

  methods: {
    workDetail(rowId) {
      console.log(rowId)
      this.$router.push(`/shopping-index/shopping-detail?rowId=${rowId}`)
    },

    likeTapSuccess() {
      
    },

    wareButtonTap(state, rowId) {
      if (state == 1) {
        return
      }
      this.createdOrder(rowId)
    },

    /**
     * 生成订单
     */
    createdOrder(rowId) {
        if (!this.$util.isDefine(this.getUserInfo)) {
            this.$store.commit('setLoginAlertState', true)
            return
        }
        let params = {
            type: 6,
            userId: this.getUserInfo.uid,
            rowId: rowId
        }
        shoppingMaterialMsg(params).then((res) => {
          console.log(res)
          this.$confirm("立即支付?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
              closeOnClickModal: false
          }).then(() => {
              console.log('确认')
              console.log(res.data.orderId)
              this.exchange(res.data.orderId, rowId)
          }).catch(() => {
              console.log('取消')
          })
      }).catch((err) => {
          console.log(err)
          if (err.data.state == 302) { // 订单已存在
              this.$router.push('/shopping-index/wares-record')
          }
      })
    },

    /**
     * 兑换
     */
    exchange(orderId, rowId) {
        let params = {
            type: 5,
            orderType: 2,
            orderId: orderId,
            userId: this.getUserInfo.uid
        }
        shoppingMaterialMsg(params).then((res) => {
          console.log(res)
          this.$message({
              type: 'success',
              message: '兑换成功'
          })
          this.$emit('exchangeSuccess', rowId)
          setTimeout(() => {
              // this.$router.push('/shopping-index/wares-record')
          }, 2000)
      }).catch(() => {
      })
    },

    handlePaste (event) {
      // 禁用鼠标右键
      event.preventDefault()
      return false
    }
  }

}
</script>

<style lang="scss">
.wares-item {
  width: 224px;
  font-size: 14px;
  box-shadow: 0px 0px 8px #d5d5d5;
  border-radius: var(--item-border-radius);
  overflow: hidden;
  position: relative;

  .wares-img {
    height: 171px;
    overflow: hidden;

    .item-pic {
      width: 100%;
      height: 171px;
      background-color: #ccc;
      display: block;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .swiper-container {
      .swiper-slide {
        width: 100%;
        background-color: #fff;
        img {
          width: 100%;
        }
      }

      .swiper-slide-active {
        img {
          
        }
        // display: block;
      }

      .swiper-slide-duplicate {
        background-color: transparent;
      }
    }
  }

  .item-state {
    position: absolute;
    left: 0;
    top: 144px;
    width: 100%;
    height: 27px;
    text-align: center;
    line-height: 27px;
    z-index: 99;
    color: #fff;
    cursor: pointer;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  .item-state0 {
    background-color: rgba($color: #e55900, $alpha: 0.7);
  }

  .item-title {
    height: 50px;
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .item-describe {
    height: 34px;
    padding: 0 15px;
    color: #bfbfbf;
    font-size: 12px;
  }

  .item-coins {
    height: 53px;
    padding: 0 15px;
    box-sizing: border-box;
    border-top: 1px solid #f0f0f0;
    color: #bfbfbf;

    .item-user-coin  {
      color: #000000;
      margin-left: -8px;
    }

    .item-user-coin::before {
      transform: scale(0.6);
      margin-right: -3px;
    }
  }
}
</style>