<template>
  <div class="legal-declaration">
        <div class="title">法律声明</div>
        <div class="detail-body">
        <p class="indent">1、适用法律</p>
        <p class="indent">您同意所有与您访问或使用本网站有关的事务适用中华人民共和国法律。您同意有关双方不可调和的争讼由公司所在地人民法院辖权。</p>
        <p class="indent">2、版权和商标</p>
        <p class="indent">没有特别授权或合作协议的情况下，本网站包含之所有内容：图片、素材、声音、标志、广告、商标、商号、域名、软件、程序、版面设计以及为注册用户提供的任何或所有信息等之所有权归属本网站。对本网站上所有内容之复制、归纳或整理编纂以及再造、重构、修改、发布、演示或播出等行为属于侵犯本网站知识产权的不法行为。</p>
        <p class="indent">3、免责声明</p>
        <p class="indent">3.1除本网站注明的服务条款外：智多蛙创客对网站上所有内容（包括但不限于本网站上的信息、内容、材料或产品）的合法性、准确性、真实性、实用性和安全性，不提供任何形式的保证，亦不承担任何法律责任。</p>
        <p class="indent">3.2用户在智多蛙创客发表的内容仅表明其个人的立场和观点，并不代表智多蛙创客的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。智多蛙创客不承担任何法律及连带责任。</p>
        <p class="indent">3.3凡在本平台上发布的作品，其所有权均属武汉智多蛙教育科技有限公司所有。任何媒体、网站或个人未经本公司授权不得转载、链接、转贴或以其他方式使用平台作品。已经本公司授权的媒体、网站或个人，在下载使用时必须注明"来源：智多蛙"，使用时不得超越授权范围，违者本公司将依法追究相关法律责任。</p>
        <p class="indent">3.4本公司在制作上述作品时，采用了部分来源网络的内容，该部分内容并未标明出处导致原作者不详，因而本公司未能获取相应的授权。本公司非常重视知识产权问题，尊重原作者的智力成果，如果本司的上述作品使用了您的版权内容，请您及时与本公司联系，本公司将立即与您协商解决版权相关事宜。</p>
        
        <p class="indent">3.5无论在任何原因下（包括但不限于疏忽原因），对您或任何人使用本网站上的信息或由本网站链接的信息，或其他与本网站链接的网站信息所导致的损失或损害（包括直接、间接、特别或后果性的损失或损害，例如收入或利润之损失，电脑系统之损坏或数据丢失等后果），责任均由使用者自行承担（包括但不限于疏忽责任）。</p>
        <p class="indent">3.6使用者对本网站的使用即表明同意承担浏览本网站的全部风险，对使用者在本网站存取资料所导致的任何直接、相关的、后果性的、间接的或金钱上的损失不承担任何责任。</p>
        <p class="indent">本声明所有修改权、更新权、解释权均归武汉智多蛙教育科技有限公司所有。</p>
        <p class="indent tx-r">武汉智多蛙教育科技有限公司</p>
        <p class="indent tx-r">2019年12月</p>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.legal-declaration {
    .detail-title {

    }
}
</style>