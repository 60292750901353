<template>
  <div class="work-item">
      <el-image lazy class="work-item-pic" :src="item.IMG" fit="cover" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)">
        <template #error>
          <div class="image-slot">
            <el-image :src="require('../assets/image/work_default_pic.png')" fit="cover" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)" />
          </div>
        </template>
      </el-image>
      <div class="work-item-title ub ub-ac" :title="item.NAME" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)"><div class="text-hidden-2">{{ item.NAME }}</div></div> 
      <like-info :item="item"></like-info> 
      <the-user :image="item.HEADIMG==undefined ? item.USER_HEADIMG:item.HEADIMG" :name="nickName(item.NICKNAME)" @click="userTap(item.USER_ID)"></the-user>
      <slot></slot>
  </div>
</template>

<script>
import LikeInfo from './LikeInfo.vue'
import TheUser from './TheUser.vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  components: { LikeInfo, TheUser },

  computed: {
    nickName() {
      return function(item) {
        return item ? item : '-'
      }
    }
  },

  created() {
    
  },

  methods: {
    workDetail(pid, uuid, releaseState) {
      this.$router.push(`/work-detail?pid=${pid}&uuid=${uuid}&releaseState=${releaseState}`)
    },

    userTap(uid) {
      this.$router.push('/personal-center?uid=' + uid)
    }
  },

  watch: {
    item: {
    	immediate: true,
    	handler(val) {
      	}
    }
  }

}
</script>

<style lang="scss">
.work-item {
  width: 224px;
  font-size: 14px;
  box-shadow: 0px 0px 8px #d5d5d5;
  border-radius: var(--item-border-radius);
  overflow: hidden;

  .work-item-pic {
    width: 100%;
    height: 171px;
    cursor: pointer;
  }

  .work-item-title {
    height: 50px;
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .like-info {
    padding: 0 15px;
  }
}
</style>