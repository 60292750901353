<template>
  <div class="class-center">
    <the-header></the-header>
    <div class="content-body">
      <the-breadcrumb
        class="warp"
        :breadcrumbList="breadcrumbList"
      ></the-breadcrumb>
      <div class="banner ub ub-ac" v-if="classInfo">
        <div class="class-message ub warp">
          <user-img class="class-pic" :userImg="classInfo.class_img" :userId="classInfo.teacherId"></user-img>
          <!-- <el-image class="class-pic" :src="classInfo.class_img" fit="cover"></el-image> -->
          <div class="class-info ub ub-ver ub-pc">
            <div class="class-name">{{classInfo.class_name}}</div>
            <div class="class-school">
              <span>{{classInfo.school_name}}</span><span>{{classInfo.teacher_name}}</span>
            </div>
            <div class="class-data ub ub-ac">
              <span>学生：{{classInfo.studentNum}}人</span>
              <span>作品：{{classInfo.workNum}}个</span>
              <span class="ub ub-ac">项目：{{classInfo.projectNum}}个
                <el-button class="default-button created-project-button" v-if="getUserInfo.role == 2"><router-link :to="'/project-created?cid=' + cid">创建项目</router-link></el-button>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="class-content warp">
        <the-seartch class="class-search" placeholder="请输入关键词" @inputChange="inputChange" @clearInput="clearInput"></the-seartch>
        <el-tabs
          v-model="active"
          v-loading="loading"
          class="class-tabs default-tabs"
          @tab-change="tabHandleClick"
        >
          <el-tab-pane :label="item.label" :name="item.index" v-for="(item, index) in tabLists" :key="index">
              <tab-class-news v-if="item.index == 1 && item.classData" :classData="item.classData"></tab-class-news>
              <tab-class-project v-if="item.index == 2 && item.classData" :classData="item.classData"></tab-class-project>
              <tab-class-work v-if="item.index == 3 && item.classData" :classData="item.classData"></tab-class-work>
              <tab-my-work v-if="item.index == 4 && item.classData" :classData="item.classData"></tab-my-work>
              <tab-teacher-comment v-if="item.index == 5 && item.classData" :classData="item.classData"></tab-teacher-comment>
          </el-tab-pane>
          <the-pagination :total="total" :pageSize="tabList[active - 1].num" @paginationChange="paginationChange"></the-pagination>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { classMsg } from '../../http/api'
import TheHeader from "@/components/TheHeader.vue";
import TheSeartch from "@/components/TheSeartch.vue";
import TheBreadcrumb from "@/components/TheBreadcrumb.vue";
import { mapGetters } from "vuex";
import TabClassNews from './components/TabClassNews.vue';
import TabClassProject from './components/TabClassProject.vue';
import TabClassWork from './components/TabClassWork.vue';
import TabMyWork from './components/TabMyWork.vue';
import TabTeacherComment from './components/TabTeacherComment.vue';
import ThePagination from '../../components/ThePagination.vue'
import UserImg from '@/components/UserImg.vue';
export default {
  components: { TheHeader, TheSeartch, TheBreadcrumb, TabClassNews, TabClassProject, TabClassWork, TabMyWork, ThePagination, TabTeacherComment, UserImg },

  data() {
    return {
      breadcrumbList: [
        {
          name: "班级管理",
          path: "/class-manage",
        },
        {
          name: "班级中心",
          path: "",
        },
      ],
      loading: false,
      classInfo: null,
      newsData: null,
      projectData: null,
      classWorkData: null,
      myWorkData: null,
      tabList: [{
          label: '班级动态',
          name: 'first',
          index: 1,
          num: 10,
          classData: null,
      }, {
          label: '班级项目',
          name: 'second',
          index: 2,
          num: 4,
          classData: null
      }, {
          label: '班级作品',
          name: 'third',
          index: 3,
          num: 10,
          classData: null
      }, {
          label: '我的项目作品',
          name: 'fourth',
          index: 4,
          num: 10,
          classData: null,
          isStudent: true
      }, { // 只有学生才显示
          label: '教师点评',
          name: 'fifth',
          index: 5,
          num: 4,
          classData: null,
          isStudent: true
      }],
      active: 1,
      page: 1,
      total: 0,
      searchVal: ''
    };
  },

  computed: {
    ...mapGetters(["getUserInfo"]),

    isStudent() {
      return this.getUserInfo.role == 0 ? true : false
    },
    
    tabLists() {
      return this.tabList.filter((item) => {
        return this.isStudent ? item : !item.isStudent
      })
    }
  },

  /* beforeRouteEnter (to, from, next) {
    next(vm => {
      console.log(from, 'from')
      let active = sessionStorage.getItem('ctab')
      console.log(active)
      if ((from.fullPath.indexOf('/project-created') != -1 || from.fullPath.indexOf('/project-detail') != -1) && active) {
          vm.active = Number(active)
      }
    })
  }, */

  created() {
    this.cid = this.$route.query.cid
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true
      let params = {
          type: 18,
          role: this.getUserInfo ? this.getUserInfo.role : '',
          uid: this.getUserInfo ? this.getUserInfo.uid : '',
          keyword: this.searchVal,
          cid: this.cid,
          page: this.page,
          num: this.tabList[this.active - 1].num,
          classType: this.active
      }
      classMsg(params)
        .then((res) => {
          console.log(res);
          this.classInfo = res.data[0].classInfo
          this.tabList[this.active - 1].classData = res.data[1]
          this.total = res.data[1].classNum
          this.loading = false
          console.log(this.tabList)
        })
        .catch(() => {
          this.loading = false;
        });
    },

    tabHandleClick(e) {
        // sessionStorage.setItem('ctab', e)
        this.page = 1
        this.classData = null
        this.searchVal = ''
        this.getData()

    },

    paginationChange(page) {
        console.log(page)
        this.page = page
        this.getData()
    },

    /**
     * 搜索
     * @param {String} value 搜索内容
     */
    inputChange(value) {
        this.searchVal = value
        this.getData()
    },

    /**
     * 清空搜索
     */
    clearInput() {
      this.searchVal = ''
      this.getData()
    },
  },
};
</script>

<style lang="scss">
.class-center {
  .banner {
    width: 100%;
    height: 273px;
    background-color: #fff;

    .class-message {
      .class-pic {
        width: 185px;
        height: 185px;
        border-radius: 50%;
        background-color: #ccc;
        margin-right: 60px;
      }

      .class-info {
        .class-name {
          font-size: 24px;
        }

        .class-school {
          line-height: 50px;
          font-size: 12px;
          color: #a0a0a0;

          span {
            padding: 0 14px;
            position: relative;
          }

          span::before {
            content: "";
            width: 1px;
            height: 12px;
            background-color: #a0a0a0;
            position: absolute;
            right: 0;
            top: 2px;
          }

          span:first-child {
            padding-left: 0;
          }

          span:last-child {
            margin-right: 0;
          }

          span:last-child:before {
            display: none;
          }
        }

        .class-data {
          line-height: 36px;
          font-size: 16px;

          span {
            display: block;
            margin-right: 44px;

            .created-project-button {
              font-size: 12px;
              min-height: 28px;
              height: 28px;
              margin-left: 8px;
              margin-top: -2px;
            }
          }

          span:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .class-content {
    position: relative;
    margin-top: 20px;

    .class-search {
      position: absolute;
      right: 0;
      top: 46px;
      z-index: 9;
    }

    .class-tabs {
      .el-tabs__header {
        padding: 49px 0 20px;
      }

      .el-tab-pane {
      }
    }
  }
}
</style>