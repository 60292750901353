<template>
  <div class="message-textarea">
    <el-input
        v-model="messageValue"
        :rows="3"
        :disabled="isCloseComment"
        type="textarea"
        :placeholder="isCloseComment ? `${isAuthorText}已关闭评论` : messagePlaceholderText"
        show-word-limit
        maxlength="300"
        resize="none"
        @focus="textareaFocus"
    />
    <div class="message-info ub ub-ac ub-be">
        <emoji-model @imageTap="imageTap" :isCloseComment="isCloseComment" :isAuthorText="isAuthorText"></emoji-model>
        <el-button :class="[ isCloseComment ? '' : 'default-button']" :type="isCloseComment ? 'info' : ''" @click="sendMessage" :disabled="isCloseComment">{{messageBtnText}}评论</el-button>
    </div>
  </div>
</template>

<script>
import EmojiModel from './EmojiModel.vue'
export default {
  components: { EmojiModel },
  props: {
    isCloseComment: {
      type: Boolean,
      default: true
    },

    messageType: {
      type: Number,
      default: 1 // 1 发表评论 2 回复评论
    },

    replyUserName: { // 回复的人
      type: String,
      default: ''
    },
 
    replyUserId: {  // 回复的人的id
      type: Number,
      default: 0
    },

    replyUserId2: {
      type: Number,
      default: 0
    },

    isAuthorText: {
      type: String,
      default: '作者'
    }
  },

  data() {
    return {
      messageValue: ''
    }
  },

  mounted() {
  },

  computed: {
    messageBtnText() {
      return this.messageType == 1 ? '发表' : '回复'
    },

    messagePlaceholderText() {
      return this.messageType == 1 ? '请发表你的想法，最多允许输入300字' : `@${this.replyUserName}`
    }
  },

  created() {
    console.log(this.replyUserName)
  },

  methods: {
    sendMessage() {
      this.$emit('sendMessage', this.messageValue, this.replyUserId, this.replyUserId2, this.messageType)
    },

    textareaFocus() {
      this.$emit('textareaFocus')
    },

    /**
     * 选中的表情
     */
    imageTap(value) {
      this.messageValue = this.messageValue + '♆' + value + '♆'
    },

    /**
     * 清除留言框内容
     */
    clearValue() {
      this.messageValue = ''
    }
  }
}
</script>

<style lang="scss">
.message-textarea {
  overflow: initial !important;
  textarea {
      padding: 12px 18px;
      font-size: 14px;
  }

  .message-info {
      height: 54px;
  }
}
</style>