<template>
  <div :class="['activity-enroll', isMobile, resultClass]" :style="'height:'+ windowHeight">
    <div class="content" v-if="!isResult">
        <div class="" v-if="quesitionData">
            <div class="top-title"><h1>{{ quesitionData.title }}</h1></div>
            <div class="QR-code iconfont icon-erweima">
                <div class="code-content">
                    <div id="code"></div>
                    <div class="text">手机扫描二维码报名</div>
                    <div class="iconfont icon-down ub ub-ac ub-pc" @click="downloadQrCode">二维码下载</div>
                </div>
            </div> 
            <div class="activity-image">
                <el-image
                    :src="quesitionData.imgUrl"
                    :zoom-rate="1.2"
                    :preview-src-list="image"
                    :initial-index="4"
                    fit="cover"
                />
            </div>
            
            <el-form>
                <que-model :queList="quesitionData.configList" :showErr="showErr" @setData="setData"></que-model>
            </el-form>
            <div class="ub ub-ac ub-pc">
                <el-button class="submit" type="primary" @click="submit">提交报名信息</el-button>   
            </div>
        </div>
        <el-empty :description="errMsg" v-if="isErr"></el-empty>
    </div>
    <div class="result-model ub ub-ac" v-else>
        <el-image
                :src="require('@/assets/image/result_bg.jpg')"
                fit="cover"
            />
    </div>
  </div>
</template>

<script>
import { activityMsg } from '@/http/api'

import QRCode from 'qrcodejs2'
import QueModel from './components/enroll/queModel.vue'
export default {
  components: {QueModel  },
    data() {
        return {
            isResult: false,
            windowHeight: '100%',
            image:[require('../../assets/image/enroll_banner.png')],
            showErr: false,
            quesitionData: null,
            quesitonList: [{
                key: 'joinMatch',
                type: 2, // 单选
                title: '请选择要参加的赛事：',
                list: [{
                    title: '空中机器人越障迷宫赛'
                }, {
                    title: '“太空探索”编程创意挑战活动'
                }, {
                    title: '未来工程师普及赛'
                }, {
                    title: 'ATC探索者科技挑战赛'
                }, {
                    title: 'AI Will - 乐探星球挑战赛'
                }, {
                    title: '开源鸿蒙创客挑战赛'
                }, {
                    title: '智能未来AI挑战赛'
                }, {
                    title: '轨道机器人挑战赛'
                }, {
                    title: '机甲大师空地协同挑战赛'
                }],
                isMust: true
            }, {
                key: 'joinGrade',
                type: 2, // 单选
                title: '请选择参赛学段：',
                list: [{
                    title: '学龄前'
                }, {
                    title: '小学组（1～3年级）'
                }, {
                    title: '小学组（4～6年级）'
                }, {
                    title: '初中组'
                }, {
                    title: '高中组(含中职)'
                }],
                isMust: true
            }, {
                key: 'groupName',
                type: 1, // 填空
                title: '战队名称：',
                isMust: true,
                tip: '同一战队成员填写同一队名',
                maxlength: 20
            }, {
                key: 'joinName',
                type: 1, // 填空
                title: '参赛者姓名：',
                isMust: true,
                mold: 'name'
            }, {
                key: 'joinSex',
                type: 2, // 单选
                title: '参赛者性别：',
                list: [{
                    title: '男'
                }, {
                    title: '女'
                }],
                isMust: true
            }, {
                key: 'tel',
                type: 1, // 填空
                inputType: 2,
                title: '监护人手机号码：',
                isMust: true,
                mold: 'phone',
                tip: ''
            }, {
                key: 'sfId',
                type: 1, // 填空
                title: '参赛者身份证号：',
                isMust: true,
                mold: 'card'
            }, {
                key: 'img',
                type: 3, // 上海南
                title: '请上传一寸证件照一张：',
                isMust: true
            }, {
                key: 'city',
                type: 4, // 下拉
                title: '请选择所在城市：',
                isMust: true,
                mold: 'city'
            }, {
                key: 'unit',
                type: 1, // 填空
                title: '参赛者所属单位：',
                isMust: true,
                tip: '填写所属学校或机构'
            }, {
                key: 'teacherName',
                type: 1, // 填空
                title: '指导老师姓名：',
                isMust: true
            }, {
                key: 'teacherTel',
                type: 1, // 填空
                inputType: 2,
                title: '指导老师电话：',
                isMust: true,
                mold: 'phone'
            }, {
                key: 'teamLeaderName',
                type: 1, // 填空
                title: '现场领队姓名：'
            }, {
                key: 'teamLeaderTel',
                type: 1, // 填空
                inputType: 2,
                title: '现场领队电话：',
                mold: 'phone'
            }, {
                key: 'clothingSize',
                type: 2, // 单选
                title: '请选择衣服尺码：',
                list: [{
                    title: 'XS 150~155cm'
                }, {
                    title: 'S 160~165cm'
                }, {
                    title: 'M 165~170cm'
                }, {
                    title: 'L 170~175cm'
                }, {
                    title: 'XL 175~180cm'
                }, {
                    title: 'XLL 180~185cm'
                }, {
                    title: 'XLLL 185~190cm'
                }],
                isMust: true
            }],
            numReg: /^1[345789]\d{9}$/,
            isErr: false,
            errMsg: ''
        }
    },

    created() {
        let id = this.$util.getUrlKey('id')
        console.log(id)
        if (!this.$util.isDefine(id)) {
            /* this.$message({
                type: 'warning',
                message: '参数异常'
            })
            return */
            id = 8 //默认青科协赛事
        }
        this.id = id
        this.getData()
    },

    computed: {
        isMobile() {
            return this.$util.isMobile() ? 'phone' : ''
        },

        resultClass() {
            return this.isResult ? 'result' : ''
        }
    },

    mounted() {
        this.windowHeight = window.innerHeight + 'px'
    },

    methods: {
        scrollH() {
            console.log('aaaa')
        },

        /**
         * 获取缓存数据
         */
        getStorageData() {
            let queData = localStorage.getItem(`queData${this.id}`)
            if (queData) {
                this.$messageBox.confirm(
                    '是否继续上次报名?',
                    '提示',
                        {
                            customClass: 'reload-page-message',
                            confirmButtonText: '继续',
                            cancelButtonText: '取消',
                            type: 'warning',
                        }
                    )
                    .then(() => {
                        for (let index = 0; index < this.quesitionData.configList.length; index++) {
                            let key = this.quesitionData.configList[index].key
                            let value = JSON.parse(queData)[key]
                            this.quesitionData.configList[index].value = value
                        }
                    })
                    .catch(() => {
                        localStorage.removeItem(`queData${this.id}`)
                    })
            }
        },

        /**
         * 获取配置数据
         */
        getData() {
            activityMsg({
                type: 15,
                matchId: this.id
            }).then((res) => {
            console.log(res)
                document.title = res.data.title
                this.quesitionData = res.data
                this.windowHeight = window.innerHeight + 'px'
                this.$nextTick(() => {
                    this.createQrcode()
                    this.getStorageData()
                })
            }).catch((err) => {
                console.log(err)
                this.isErr = true
                this.errMsg = err.data.msg
            })
        },

        /**
         * 生成二维码
         */
        createQrcode() {
            new QRCode('code', {
                text: window.location.href, // 二维码地址
                colorDark: "#000",
                colorLight: "#fff",
            });
        },

      downloadQrCode() {
        const codeElement = document.getElementById('code');
        const canvasElement = codeElement.querySelector('canvas');
        const dataURL = canvasElement.toDataURL();
        const link = document.createElement('a');
        link.href = dataURL;
        link.download = 'QrCode.png'; // 下载的文件名
        document.body.appendChild(link); // 在 DOM 中添加链接
        link.click();
      },

      setData(index, value) {
            let queData = localStorage.getItem(`queData${this.id}`)
            if (queData) {
                queData = JSON.parse(queData)
            } else {
                queData = {}
            }
            this.quesitionData.configList[index].value = value
            queData[this.quesitionData.configList[index].key] = value

            // 参赛者2的数据，填了一个其他都必填
            const keysToCheck = ['joinName2', 'joinSex2', 'sfId2', 'img2', 'clothingSize2'];
            if (keysToCheck.includes(this.quesitionData.configList[index].key)) {
                if (value) {
                    for (const obj of this.quesitionData.configList) {  
                        // 只设置那些未被检查的键或value原本为空的对象的value为'必填'  
                        if (keysToCheck.includes(obj.key)) {  
                            obj.isMust = true 
                        }
                    }  
                } else {
                    let hasNonEmptyValueInSpecifiedKeys = false;
                    for (const obj of this.quesitionData.configList) { 
                        if (keysToCheck.includes(obj.key) && obj.value) {
                            console.log(obj.key)
                            hasNonEmptyValueInSpecifiedKeys = true;  
                            break;
                        }
                    }

                    if (!hasNonEmptyValueInSpecifiedKeys) {
                        for (const obj of this.quesitionData.configList) {  
                            // 只设置那些未被检查的键或value原本为空的对象的value为'必填'  
                            if (keysToCheck.includes(obj.key)) {  
                                obj.isMust = false 
                            }
                        } 
                    }
                    
                }
            }

            localStorage.setItem(`queData${this.id}`, JSON.stringify(queData))
            console.log(this.quesitionData)
        },

      submit() {
        let queData = localStorage.getItem(`queData${this.id}`)
        console.log(queData)
        if (queData) {
            queData = JSON.parse(queData)
            // 检查数据必填项是否填写，或填写是否有误
            for (let i = 0; i < this.quesitionData.configList.length; i++) {
                if (this.quesitionData.configList[i].isMust && !this.quesitionData.configList[i].value) {
                    this.showErr = true
                    console.log(1)
                    break
                }

                if (this.quesitionData.configList[i].mold == 'phone' && this.quesitionData.configList[i].value && !this.numReg.test(this.quesitionData.configList[i].value)) {
                    this.showErr = true
                    console.log(2)
                    break
                }

                if (this.quesitionData.configList[i].mold == 'card' && this.quesitionData.configList[i].value && !this.$util.validateIdCard(this.quesitionData.configList[i].value) && !this.$util.isValidHKMacauPassportNumber(this.quesitionData.configList[i].value)) {
                    this.showErr = true
                    console.log(3)
                    break
                }
                this.showErr = false
            }
            console.log(this.showErr)
            console.log(queData)
            this.submitData()
        } else {
            this.showErr = true
        }
      },

      submitData() {
        let data = {}
        console.log(this.showErr)

        if (this.showErr) {
            return
        }  else {
            console.log('aaa')
            for (let index = 0; index < this.quesitionData.configList.length; index++) {
                data[this.quesitionData.configList[index].key] = this.quesitionData.configList[index].value ? this.quesitionData.configList[index].value : ''
                
            }
            console.log(data)
        }
        data.type = 16
        data.matchId = this.id
        activityMsg(data).then((res) => {
            console.log(res)
            this.$message({
                type: 'success',
                message: '提交成功'
            })
            setTimeout(() => {
                localStorage.removeItem(`queData${this.id}`)
                this.isResult = true
            }, 2000)
        })
      }
    }
     
}
</script>

<style lang="scss">
.activity-enroll {
    height: 100%;
    overflow-x: hidden;
    overflow: scroll;
    padding-top: 80px;
    box-sizing: border-box;
    background: url('../../assets/image/enroll_bg.jpg');
    background-size:cover;
    background-repeat: no-repeat;
    .content {
        max-width: 800px;
        margin: 0 auto;
        padding: 0 30px 30px;
        box-sizing: border-box;
        background-color: #ffffff;
        position: relative;
        .top-title {
            background: #ffffff;
            padding: 50px 130px 20px;
            color: #0095ff;
            text-align: center;
            h1 {
                font-size: 20px;
            }
        }
        .QR-code {
            position: absolute;
            right: 15px;
            top: 15px;
            font-size: 36px;
            background-color: #f1f1f1;
            border-radius: 3px;
            cursor: pointer;
            .code-content {
                position: absolute;
                top: 39px;
                right: 0;
                background-color: #f7f7f7;
                width: 190px;
                box-sizing: border-box;
                padding: 12px;
                display: none;
                z-index: 10;
                #code {
                    background-color: #fff;
                    padding: 20px;
                    img {
                        width: 100%;
                    }
                }
                .text {
                    font-size: 14px;
                    text-align: center;
                    color: #4e4e4e;
                    margin-top: 10px;
                    font-weight: bold;
                }
                .icon-down {
                    text-align: center;
                    font-size: 12px;
                    color: #0095ff;
                    font-weight: bold;
                    margin-top: 5px;
                }
                .icon-down:before {
                    font-size: 16px;
                    margin-right: 3px;
                }
            }
        }
        .QR-code:hover {
            .code-content {
                display: block;
            }
        }
        .activity-image {
            box-sizing: border-box;
            padding: 10px 0 26px;
            margin: 0 20px 16px;
            border-bottom: 1px dashed #ccc;
        }
        .el-form {
            .el-form-item {
                box-sizing: border-box;
                padding: 20px 19px;
                .el-form-item__label {
                    text-align: left;
                    font-weight: bold;
                    color: #000000;
                    font-size: 18px;
                    margin-bottom: 8px;
                    font-size: 17px;
                }
                .el-form-item__content {
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    flex-direction: column;
                    -webkit-box-align: flex-start;
                    -moz-box-align: flex-start;
                    -ms-flex-align: flex-start;
                    -webkit-align-items: flex-start;
                    align-items: flex-start;
                    .tips {
                        font-size: 12px;
                        color: #666;
                        line-height: 18px;
                        margin-bottom: 10px;
                    }
                    .el-alert {
                        margin-top: 10px;
                        line-height: 18px;
                        border-radius: 0;
                        .el-alert__icon {
                            width: 18px;
                            font-size: 18px;
                        }
                        .el-alert__content {
                            .el-alert__title {
                                font-size: 14px;
                                font-weight: bold;
                            }
                        }
                    }
                    .el-input {

                        .el-input__inner {
                            padding: 11px 10px;
                            height: auto;
                            line-height: normal;
                            border-radius: 0px;
                        }
                    }
                    .el-input.iconfont {
                        .el-input__inner {
                            padding-left: 30px !important;
                        }
                    }
                    .el-input.iconfont::before {
                        position: absolute;
                        left: 5px;
                        z-index: 99;
                        top: 4px;
                        font-size: 18px;
                        color: #8c8c8c;
                        font-weight: bold;
                    }

                    .el-radio-group {
                        .el-radio {
                            height: auto;
                            line-height: 24px;
                            margin: 8px 8px 8px 0;
                            .el-radio__input {
                                .el-radio__inner {
                                    width: 18px;
                                    height: 18px;
                                }
                                .el-radio__inner::after {
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                            .el-radio__label {
                                font-size: 16px;
                                white-space: break-spaces;
                            }
                        }
                    }
                    .el-cascader {
                        width: 100%;

                    }

                    .el-cascader {
                        .el-input__inner {
                            padding-left: 30px !important;
                        }
                    }

                    .el-cascader::before {
                        position: absolute;
                        left: 5px;
                        z-index: 99;
                        top: 4px;
                        font-size: 18px;
                        color: #8c8c8c;
                        font-weight: bold;

                    }
                }
            }
            .el-form-item.err {
                border: 1px dashed rgb(255, 64, 64);
            }
            
        }
        .submit {
            width: 180px;
            height: 43px;
            line-height: 43px;
            font-size: 16px;
            margin-top: 20px;
            padding: 0;
        }
    }
}

@media screen and (max-width: 750px) {
    .reload-page-message {
        width: 80% !important;
    }
}

@media screen and (max-width: 570px) {
    .el-popper {
        .el-cascader-panel {
            .el-cascader-menu {
                min-width: 100px;
                .el-cascader-node {
                    padding: 0 7px 0 5px;
                    .el-cascader-node__label {
                        padding: 0 3px;
                    }
                    .el-cascader-node__postfix {
                        right: 2px;
                    }
                }
            }
        }
    }
}

.activity-enroll.phone {
    .content {
        width: 92%;
        padding: 0 3px 30px;
        .top-title {
            background: #ffffff;
            padding: 50px 20px 20px;
            h1 {
                font-size: 16px;
            }
        }
    }
}


.result {
        background: #fff;
        .result-model {
            width: 400px;
            margin: 0 auto;
        }
    }
</style>