<template>
  <router-view />
</template>

<script>
export default {
  components: { },

  created() {
    if (this.$route.path == "/down-index") {
      this.$router.replace("/down-index/down-product");
    }
  },

  watch: {
    $route(to) {
      //这里可以加你监听到路由改变时要触发的方法
      console.log(to.path);
      if (to.path == "/down-index") {
        this.$router.replace("/down-index/down-product");
      }
    },
  },
}
</script>

<style>

</style>