/**
 * 验证是否为空
 * @param {*} value 
 */
const isDefine = (value) => {
    if (value == null || value == "" || value == "undefined" || value == undefined || value == "null" || value == "(null)" || value == 'NULL' || typeof(value) == 'undefined') {
      return false;
    } else {
      value = value + "";
      value = value.replace(/\s/g, "");
      if (value == "") {
        return false;
      }
      return true;
    }
}

/**
 * 时间戳转换
 * @param {*} times 时间戳数据
 * @param {*} type  返回格式 1 带时分 2 不带时分 3显示年月日格式
 * @returns 
 */
const formateDateTimesToZh = (times, type) => {
  if (!times) {
    return '-'
  }
  let date = new Date(times) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let year = date.getFullYear()
  let mounth = date.getMonth() + 1
  mounth = mounth > 9 ? mounth : '0' + mounth
  let day = date.getDate()
  day = day > 9 ? day : '0' + day
  let timeStr = '' 
  if (type == 1) {
    let hours = date.getHours()
    hours = hours > 9 ? hours : '0' + hours
    let mimutes = date.getMinutes()
    mimutes = mimutes > 9 ? mimutes : '0' + mimutes
    timeStr = `${year}-${mounth}-${day} ${hours}:${mimutes}`
  } 
  else if (type==2){
    timeStr = `${year}-${mounth}-${day}`
  }
  else if(type==3){
    timeStr = `${year}年${mounth}月${day}日`
  }
  return timeStr
}
   
/**
 * 删除字符串左右空格
 */
const trim = (str) => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}

/* *
     *  获取链接参数
     */
const getUrlKey = (name) => {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.href
      ) || [, ''])[1].replace(/\+/g, '%20')
    ) || null
  );
}


const isMobile = () => {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

// 节流
const throttleFn = (fn, t) => {
  let last;
  let timer;
  let interval = t || 2500;
  console.log('vvvvvvvvv')
  return function () {
      let args = arguments;
      let now = +new Date();
      if (last && now - last < interval) {
          clearTimeout(timer);
          timer = setTimeout(() => {
              last = now;
              fn.apply(this, args);
          }, interval);
      } else {
          last = now;
          fn.apply(this, args);
      }
  }
};

// 防抖
const debounceFn = (fn, t) => {
  let delay = t || 500;
  let timer;
  console.log(fn)
  console.log(typeof fn)
  return function () {
      let args = arguments;
      if(timer){
          clearTimeout(timer);
      }
      timer = setTimeout(() => {
          timer = null;
          fn.apply(this, args);
      }, delay);
  }
}

/* 身份证校验 */
const validateIdCard = (idCard) => {
  const reg = /^(\d{6})(19|20)(\d{2})(0[1-9]|1[0-2])([0-2][1-9]|10|20|30|31)(\d{3})(\d|[Xx])$/;
  if (!reg.test(idCard)) {
    // 如果身份证号码不符合规则，则返回 false
    return false;
  }
  // 检查身份证校验码
  const checkCode = idCard.charAt(17).toUpperCase();
  let sum = 0;
  for (let i = 0; i < 17; i++) {
    sum += parseInt(idCard.charAt(i)) * (Math.pow(2, 17 - i) % 11);
  }
  const checkCodeList = "10X98765432";
  return checkCode === checkCodeList.charAt(sum % 11);
}

/* 港澳通行证校验 */
const isValidHKMacauPassportNumber = (number) => {
  const regex = /^([C|H|M]\d{8})$/i;
  return regex.test(number);
}

const checkHKMacaoPassport = (str) => {
  if (!/^[HMhm]{1}([0-9]{10}|[0-9]{8})$/.test(str)) {
    return false;
  }
  var strArray = str.split('');
  if (strArray[0].toUpperCase() === 'H') {
    // 澳门通行证
    strArray.splice(2, 0, '1', '0');
  } else {
    // 港澳通行证
    strArray.splice(2, 0, '0', '0');
  }
  var checkCode = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  var checkSum = 0;
  for (var i = 0; i < 17; i++) {
    checkSum += parseInt(strArray[i]) * factor[i];
  }
  var mod = checkSum % 11;
  var checkBit = checkCode[mod];
  return checkBit === strArray[17].toUpperCase();
}

export default {
    isDefine,
    formateDateTimesToZh,
    trim,
    getUrlKey,
    isMobile,
    throttleFn,
    debounceFn,
    validateIdCard,
    isValidHKMacauPassportNumber,
    checkHKMacaoPassport
}