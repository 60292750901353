<template>
  <div class="atlas-model">
      <div class="images">
        <div class="no-image ub ub-ac ub-pc" v-if="imgArr.length == 0">{{isUpload ? '暂未上传图片' : '暂无图片'}}</div>
        <el-upload
            :class="['upload-model ub ub-ac ub-pc', isUpload ? '' : 'disabled']"
            :action="url + '/'"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-error="handleAvatarError"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
            :file-list="images"
            :on-exceed="handleExceed"
            :limit="imgNum"
        >
            <span class="iconfont ub ub-ac icon-yunshangchuan"></span>
          </el-upload>
      </div>
      <div class="title ub ub-be">
          <span class="iconfont ub ub-ac icon-mokuai_tucemulu">图册（{{images.length}}张）</span>
      </div>
      <images-dialog :isImagesDialog="isImagesDialog" :images="images" :isUpload="isUpload" @closeImagesDialog="closeImagesDialog" @deleteImage="deleteImage"></images-dialog>
  </div>
</template>

<script>
import config from '../../../http/config'
import ImagesDialog from './ImagesDialog.vue'
export default {
    components: { ImagesDialog },
    props: {
        images: {
            type: Array,
            default: () => {
                return []
            }
        },

        isUpload: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            imgArr: [],
            imgNum: 10,
            isImagesDialog: false
        }
    },

    created() {
        this.url = config.url
        console.log(this.images)
        /* this.fileList = this.images.map((item) => {
            return {
                name: '123',
                url: item
            }
        }) */
        // this.imgArr = this.fileList
        // console.log(this.fileList)
        // console.log(this.imgArr)
    },

    methods: {
        //添加图片成功回调
        handleAvatarSuccess(res, file) {
            console.log(res)
            console.log(file)
            /* file.raw.url = file.url
            this.imgArr.push(file.raw)
            this.getImages(file.raw, 'add') */
        },

        //  获取头像失败回调
        handleAvatarError() {
            this.$message({
                showClose: true,
                message: '获取图片失败!',
                type: 'error'
            });
        },
        
        // 上传图片限制
        beforeAvatarUpload(file) {
            console.log(file)
            this.userImageLoading = true;
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.warning("上传头像图片只能是 JPG或者PNG 格式!");
                this.userImageLoading = false;
            }

            console.log(isLt2M)
            if (!isLt2M) {
                this.$message.warning("上传头像图片大小不能超过 2MB!");
                this.userImageLoading = false;
            }
            return (isJPG || isPNG) && isLt2M;
        },

        handleRemove(e) {
            // console.log(this.imgArr)
            /* let index = this.imgArr.findIndex(item => {
                return item.url == e.url
            })
            console.log(index)
            console.log(e.url)
            console.log(this.imgArr)
            this.imgArr.splice(index, 1) */
            // this.getImages(e, 'del')
        },

        getImages(file, type) {
            console.log(file)
            // this.$emit('getImages', file, type)
        },

        /**
         * 上传图片限制
         */
        handleExceed() {
            this.$message.warning(`最多允许上传${this.imgNum}张图片!`);
        },

        /**
         * 打开查看图片弹窗
         */
        handlePictureCardPreview() {
            this.isImagesDialog = true
        },

        /**
         * 关闭查看图片弹窗
         */
        closeImagesDialog() {
            this.isImagesDialog = false
        },

        /**
         * 删除图片
         */
        deleteImage(index) {
            this.$emit('deleteImage', index)
        }
    }
}
</script>

<style lang="scss">
.atlas-model {
    width: 286px;
    height: 260px;
    box-sizing: border-box;
    padding: 15px 15px 0;
    border: 1px solid #d2d2d2;
    background-color: #fff;
    
    .images {
        width: 100%;
        height: 194px;
        position: relative;

        .no-image {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            color: #bfbfbf;
            font-size: 14px;
        }

        .upload-model {
            width: 100%;
            height: 194px;
            z-index: 100;

            .el-upload-list {
                width: 100%;
                height: 194px;

                .el-upload-list__item {
                    width: 100%;
                    height: 194px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin: 0;
                }

                .el-upload--picture-card {
                    position: absolute;
                    right: 0;
                    bottom: -40px;
                    width: 30px;
                    height: 30px;
                    z-index: 99;
                }
            }
        }

        .upload-model.disabled {
            .el-upload-list {
                .el-upload-list__item { 
                    .el-upload-list__item-actions {
                        .el-upload-list__item-delete {
                            display: none;
                        }
                    }
                }
                .el-upload--picture-card {
                    display: none;
                }
            }
        }
    }

    .title {
        height: 48px;
        span {
            color: #6e6e6e;
            font-size: 14px;
            font-weight: 500;
        }

        span::before {
            font-size: 34px;
            color: #bfbfbf;
            margin-right: 8px;
        }

        span.icon-yunshangchuan::before {
            font-size: 28px;
        }
    }
}
</style>