<template>
  <div class="notice-detail" v-if="noticeInfo">
    <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/about-us/official-notice' }">公告</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
  </el-breadcrumb>
    <div class="title">{{noticeInfo.title}}</div>
    <div class="detail-info ub">
        <span class="author">{{noticeInfo.name}}</span>
        <span class="date ub-f1">{{noticeInfo.date}}</span>
        <span class="eye ub ub-ac ub-pc iconfont icon-eye">{{noticeInfo.pv}}</span>
    </div>
    <div class="detail-body" v-html="noticeInfo.content"></div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            noticeInfo: ''
        }
    },

    created() {
        let noticeInfo = sessionStorage.getItem('noticeInfo');
        console.log(noticeInfo)
        if (noticeInfo) {
            this.noticeInfo = JSON.parse(noticeInfo)
        } else {
            this.$route.go(-1)
        }
    }
}
</script>

<style lang="scss">
.notice-detail {
    padding: 0 25px;
    .el-breadcrumb {
        margin-bottom: 20px;
    }
    .detail-info{
        height: 50px;
        line-height: 50px;
        color: #a0a0a0;
        font-size: 14px;
        text-align: left;
        border-bottom: 1px solid #eeeeee;

        .date{
            margin-left: 20px;
        }

        span{
            display: block;
        }

        .eye::before{
            margin-right: 3px;
            font-size: 22px;
            float: left;
        }
    }

    .detail-body{
        text-align: left;
        padding: 10px 0;
        color: #000;

        p{
            padding: 0;
        }
    }
}
</style>