<template>  
  <div class="comment-message" v-if="messageInfo" id="comment-message">
      <div class="send-message">
            <div class="title">发表评论</div>
            <div class="message-box ub">
                <div class="user-info ub ub-ver" @click="lookUser">
                    <!-- <el-image class="user-img" :src="userInfoType(2)" fit="cover" /> -->
                    <user-img class="user-img" :src="userInfoType(2)" :userId="getUserInfo ? Number(getUserInfo.uid) : ''"></user-img>
                    <div class="user-name ub ub-pc ub-ac text-hidden" :title="userInfoType(1)">{{userInfoType(1)}}</div>
                </div>
                <common-message-textarea class="ub-f1" 
                                        :isCloseComment="isCloseComment" 
                                        :replyUserId="authorId"
                                        :replyUserId2="0"
                                        :isAuthorText="isAuthorText"
                                        @sendMessage="sendMessage"
                                        @textareaFocus="textareaFocus"
                                        ref="messageTextarea"></common-message-textarea>
            </div>

            <div class="message-set ub ub-ac">
                <div class="title">全部评论（{{messageInfo.commentNum}}）</div>
                <div v-if="(messageInfo.commentNum != 0 || justLookAuthor) && type == 1" :class="['look-author ub ub-ac iconfont', justLookAuthor ? 'icon-duigou act' : '']" @click="lookAuthorTap">只看作者！</div>
                <div class="ub ub-pe ub-ac ub-f1">
                    <span class="tip" v-if="isCloseComment && !isAuthor">{{isAuthorText}}关闭了评论！</span>
                    <div :class="['comment-switch iconfont', isCloseComment ? 'icon-duigou act' : '']" v-if="isAuthor" @click="switchCommentTap">关闭评论</div>
                    <div class="sort ub ub-ac ub-be" v-if="messageInfo.commentList.length > 0">
                        <span :class="index == sortAct ? 'act' : ''" @click="index == sortAct ? '' : setMsgSort(index)" v-for="(item, index) in sortList" :key="index">{{item.text}}</span>
                    </div>
                </div>
            </div>
            <div class="message-list" v-if="messageInfo.commentList.length > 0">
                <div class="list-item ub have-sub-list" v-for="(item, index) in messageInfo.commentList" :key="index">
                    <div class="user-info ub ub-ver">
                        <user-img :userImg="item.USER_HEADIMG" :userId="Number(item.USER_ID)"></user-img>
                        <div class="user-name text-hidden" :title="item.USERNAME">{{item.USERNAME}}</div>
                    </div>
                    <div class="message-info ub-f1">
                        <div class="item-head">
                            <div class="item-info ub">
                                <div class="item-date ub ub-ac ub-f1">{{timeFormat(item.CREATE_TIME)}}</div>
                                <div class="ub ub-ac report" @click="reportMsg(item)">举报</div>
                                <div class="item-num ub">
                                    <span :class="['iconfont', item.IS_LIKE == 1 ? 'act icon-like-act' : 'icon-like']" @click="likeMsg(item.ROW_ID, item.IS_LIKE, index)">{{item.LIKE_TIMES}}</span>
                                    <span class="iconfont icon-liuyan">{{item.subCommentNum}}</span>
                                </div>
                            </div>
                            <div class="item-text" v-html="contentChange(item.CONTENT)"></div>
                        </div>
                        <div class="handle ub ub-pe">
                            <span :class="['reply', isReply(replyText(index + 1)) ? 'act' : '']" @click="replyMsg(index)">{{replyText(index + 1)}}</span>
                            <span class="del" v-if="getUserInfo && getUserInfo.uid == item.USER_ID" @click="delectMsg(item.ROW_ID)">删除</span>
                        </div>
                        <common-message-textarea v-if="openMsgTextareaArr.length == 1 && openMsgTextareaArr[0] == index" 
                                                :messageType="2" 
                                                :replyUserName="item.USERNAME"
                                                :isCloseComment="isCloseComment" 
                                                :replyUserId="item.USER_ID"
                                                :replyUserId2="item.ROW_ID"
                                                @sendMessage="sendMessage"
                                                ref="messageTextarea"></common-message-textarea>
                        <div class="sub-list" v-if="item.subCommentList.length > 0">
                            <div class="list-item sub-list-item ub" v-for="(subItem, subIndex) in item.subCommentList" :key="subIndex">
                                <div class="user-info ub ub-ver">
                                    <user-img :userImg="subItem.USER_HEADIMG" :userId="Number(item.USER_ID)" size="small"></user-img>
                                    <div class="user-name ub-ac text-hidden" :title="subItem.USERNAME">{{subItem.USERNAME}}</div>
                                </div>
                                <div class="message-info ub-f1">
                                    <div class="item-head">
                                        <div class="item-info ub">
                                            <div class="item-date ub ub-ac ub-f1">{{timeFormat(subItem.CREATE_TIME)}}</div>
                                            <div class="ub ub-ac report" @click="reportMsg(item)">举报</div>
                                            <div class="item-num ub">
                                                <span :class="['iconfont', subItem.IS_LIKE == 1 ? 'act icon-like-act' : 'icon-like']" @click="likeMsg(subItem.ROW_ID, subItem.IS_LIKE, index, subIndex + 1)">{{subItem.LIKE_TIMES}}</span>
                                            </div>
                                        </div>
                                        <div class="item-text" v-html="contentChange(subItem.CONTENT, item, subItem)"></div>
                                    </div>
                                    <div class="handle ub ub-pe"><span :class="['reply', isReply(replyText(index + 1, subIndex + 1)) ? 'act' : '']" @click="replyMsg(index, subIndex)">{{replyText(index + 1, subIndex + 1)}}</span><span class="del" v-if="getUserInfo && getUserInfo.uid == subItem.USER_ID" @click="delectMsg(subItem.ROW_ID)">删除</span></div>
                                    <common-message-textarea v-if="openMsgTextareaArr.length == 2 && openMsgTextareaArr[0] == index && openMsgTextareaArr[1] == subIndex" 
                                                :messageType="2" 
                                                :replyUserName="subItem.USERNAME"
                                                :isCloseComment="isCloseComment" 
                                                :replyUserId="subItem.USER_ID"
                                                :replyUserId2="item.ROW_ID"
                                                @sendMessage="sendMessage"
                                                ref="messageTextarea"></common-message-textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <the-empty description="暂无留言" v-else></the-empty>
      </div>
      <report-dialog :isReportDialog="isReportDialog" @closeReportDialog="closeReportDialog" :reportData="reportData"></report-dialog>
  </div>
</template>

<script>
import emojiArr from '../../../utils/enjoy'
import { pblWork, activityMsg } from '../../../http/api'
import util from '@/utils/util'
import TheEmpty from '@/components/TheEmpty.vue'
import { mapGetters } from 'vuex'
import ReportDialog from './ReportDialog.vue'
import CommonMessageTextarea from './CommonMessageTextarea.vue'
import UserImg from '@/components/UserImg.vue'
let http = null
let getDaraPrm = {}
export default {
    components: { TheEmpty, ReportDialog, CommonMessageTextarea, UserImg },
    props: {
        type: { // 留言模块类型 1 作品留言 2 活动留言
            type: Number,
            default: 1 
        },

        authorId: { // 作者ID
            type: Number,
            default: 0
        },

        projectId: { // 作品ID
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            messageInfo: null,
            messageValue: '',
            justLookAuthor: false,
            isReportDialog: false,
            reportData: {},
            sortAct: 0,
            sortList: [{
                text: '正序',
                type: 'desc'
            }, {
                text: '倒序',
                type: 'reverse'
            }],
            openMsgTextareaArr: [],
            http: null
        }
    },

    computed: {
        ...mapGetters(['getUserInfo']),

        userInfoType() {
            return function(type) {
                switch(type) {
                    case 1: 
                        return this.getUserInfo ? this.getUserInfo.name : '请登录';
                    case 2: 
                        return this.getUserInfo ? this.getUserInfo.user_img : 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
                    default:
                    return "";
                }
            }
        },

        timeFormat() {
            return (time) => {
                return this.$util.formateDateTimesToZh(time, 1)
            }
        },

        isAuthor() {
            return this.getUserInfo && this.getUserInfo.uid == this.authorId ? true : false
        },

        isCloseComment() {
            return this.messageInfo.isComment == 1 ? true : false // 1 关闭评论状态 0 开启评论状态
        },

        isAuthorText() {
            return this.isAuthor ? '您' : '作者'
        },

        /**
         * 回复文字
         */
        replyText() {
            return (index, subIndex) => {
                let text = ''
                if (!subIndex) {
                    if (index - 1 == this.openMsgTextareaArr[0] && this.openMsgTextareaArr.length == 1) {
                        text = '收起'
                    } else {
                        text = '回复'
                    }
                } else {
                    if (index - 1 == this.openMsgTextareaArr[0] && subIndex - 1 == this.openMsgTextareaArr[1]) {
                        text = '收起'
                    } else {
                        text = '回复'
                    }
                }
                return text
            }
        },

        /**
         * 回复信息
         */
        replyUser() {
            return (item, subItem) => {
                if (subItem.USERNAME2 == item.USERNAME) {
                    return ''
                } else {
                    return `<span>@${subItem.USERNAME2}</span>：`
                }
            }
        },

        /**
         * 是否回复
         */
        isReply() {
            return (text) => {
                return text == '收起' ? 'act' : ''
            }
        },

        /**
         * 
         */
        contentChange() {
            return (content, item, subItem) => {
                let contentArr =  content.split('♆')
                let images = emojiArr[0].images.concat(emojiArr[1].images)
                let str = ''
                if (subItem) {
                    str = this.replyUser(item, subItem) + str
                }
                if (contentArr.length > 1) {
                    for(var i in contentArr) {
                        let msg = contentArr[i]
                        for (let j = 0; j < images.length; j++) {
                            if (images[j].title == msg) {
                                msg = `<img src="${images[j].img}">`
                                break
                            }

                            if (j == images.length - 1) {
                                break
                            }
                        }
                        str = str + msg
                    }
                    return str
                } else {
                    return str + contentArr[0]
                }
            }
        }

    },

    created() {
        if (this.type == 1) {
            http = pblWork
        } else {
            http = activityMsg
        }
        this.getData()
    },

    methods: {
        getData() {
            let params = {
                type: 11,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                pid: this.projectId,
                sort: this.sortList[this.sortAct].type,
                view: this.justLookAuthor ? this.authorId : 'null'
            }
            http(params).then((res) => {
                // ...
                console.log(res)
                let data = {}
                if (this.type == 1) {
                    data = res
                } else {
                    data = res.data
                }
                this.messageInfo = data
            }).catch(() => {

            })
        },
        /**
         * 查看自己信息
         */
        lookUser() {
            if(!this.getUserInfo) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
        },

        /**
         * 发表评论
         * @param {String} value 留言信息
         * @param {Number} replyUserId 回复ID
         */
        sendMessage(value, replyUserId, replyUserId2, messageType) {
            if(!this.getUserInfo) {
                this.$store.commit('setLoginAlertState', true)
                return
            }

            if(!util.isDefine(value)) {
                this.$message({
                    type: 'warning',
                    message: '请输入留言信息',
                    showClose: true
                })
                return
            }

            this.sendMessageHttp(value, replyUserId, replyUserId2, messageType)
        },

        /**
         * 发表评论接口
         * @param {Number} type 接口号
         * @param {Number} uid  用户Id
         * @param {Number} userId2 一级的时候显示作者Id（二级的时候被评论人的id）
         * @param {Number} pid  作品id
         * @param {String} content 评论消息
         * @param {Number} twoId 一级的时候显示 0 二级显示一级评论id
         */
        sendMessageHttp(value, replyUserId, replyUserId2, messageType) {
            let params = {
                type: 30,
                uid: this.getUserInfo.uid,
                userId2: replyUserId,
                pid: this.projectId,
                content: value,
                twoId: replyUserId2
            }

            http(params).then((res) => {
                console.log(res)
                this.$message({
                    message: "评论成功！",
                    type: "success",
                    showClose: true
                });
                console.log(messageType)
                if (messageType == 1) {
                    this.$refs.messageTextarea.clearValue()
                } else {
                    this.$refs.messageTextarea[0].clearValue()
                }
                
                this.getData()
            })
        },

        /**
         * 只看作者
         */
        lookAuthorTap() {
            this.justLookAuthor = !this.justLookAuthor
            this.getData()
        },

        /**
         * 排序
         */
        setMsgSort(index) {
            this.sortAct = index
            this.getData()
        },

        /**
         * 评论开关
         */
        switchCommentTap() {
            this.switchCommentHttp()
        },

        /**
         * 评论开关接口
         * @param {Number} type 接口号
         * @param {Number} uid  用户id
         * @param {Number} state 评论开关 0 开启 1 关闭
         * @param {Number} pid 作品id
         */
        switchCommentHttp() {
            let params = {
                type: 31,
                uid: this.getUserInfo.uid,
                state: this.isCloseComment ? 0 : 1,
                pid: this.projectId
            }

            http(params).then((res) => {
                console.log(res)
                this.$message({
                    message: `${this.isCloseComment ? '开启' : '关闭'}评论成功！`,
                    type: "success",
                    showClose: true
                });
                this.messageInfo.isComment == this.isCloseComment ? 0 : 1
                this.getData()
            })
        },

        /**
         * 打开举报弹窗
         */
        reportMsg(item) {
            if(!this.getUserInfo) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
            this.isReportDialog = true
            console.log(item)
            console.log(this.contentChange(item.CONTENT))
            item.CONTENT = this.contentChange(item.CONTENT)
            this.reportData = item
        },

        /**
         * 关闭举报弹窗
         */
        closeReportDialog() {
            this.isReportDialog = false
        },

        /**
         * 删除评论
         * @param {String} msgId 评论id
         */
        delectMsg(msgId) {
            this.$confirm("是否删除该评论?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
            .then(() => {
                let params = {
                    type: 13,
                    uid: this.getUserInfo.uid,
                    pid: this.projectId,
                    coid: msgId
                }
                pblWork(params).then((res) => {
                    console.log(res)
                    this.$message({
                        message: "删除成功！",
                        type: "success",
                        showClose: true
                    });
                    this.getData()
                })
            })
        },

        /**
         * 回复留言
         */
        replyMsg() {
            if (JSON.stringify(this.openMsgTextareaArr) == JSON.stringify(arguments)) {
                this.openMsgTextareaArr = []
                return 
            }
            this.openMsgTextareaArr = arguments
        },

        /**
         * 点赞留言
         * @param {Number} rowId 评论id
         * @param {boolean} isLike 是否点赞
         * @param {index} 评论索引
         * @param {subIndex} 二级评论索引
         * @params
         * @param {Number} type 接口号
         * @param {Number} uid 用户id
         * @param {Number} coid 评论id
         * @param {Number} state 点赞状态 
         * @param {Number} pid 作品id
         */
        likeMsg(rowId, isLike, index, subIndex) {
            if (!this.getUserInfo) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
            let params = {
                type: 10,
                uid: this.getUserInfo.uid,
                coid: rowId,
                state: isLike == 1 ? -1 : 0,
                pid: this.projectId
            }
            pblWork(params).then((res) => {
                console.log(res)
                if (!subIndex) {
                    this.messageInfo.commentList[index].IS_LIKE = this.messageInfo.commentList[index].IS_LIKE == 0 ? 1 : 0
                    this.messageInfo.commentList[index].LIKE_TIMES = this.messageInfo.commentList[index].IS_LIKE == 1 ? this.messageInfo.commentList[index].LIKE_TIMES + 1 : this.messageInfo.commentList[index].LIKE_TIMES - 1
                } else {
                    this.messageInfo.commentList[index].subCommentList[subIndex - 1].IS_LIKE = this.messageInfo.commentList[index].subCommentList[subIndex - 1].IS_LIKE == 0 ? 1 : 0
                    this.messageInfo.commentList[index].subCommentList[subIndex - 1].LIKE_TIMES = this.messageInfo.commentList[index].subCommentList[subIndex - 1].IS_LIKE == 1 ? this.messageInfo.commentList[index].subCommentList[subIndex - 1].LIKE_TIMES + 1 : this.messageInfo.commentList[index].subCommentList[subIndex - 1].LIKE_TIMES - 1
                }
            })
        },

        /**
         * 输入框聚焦
         */
        textareaFocus() {
            this.openMsgTextareaArr = []
        },

        /**
         * 滚动到留言
         */
        gotoMessage(id) {
            document.querySelector(id).scrollIntoView(true);
        }
    }
}
</script>

<style lang="scss">
.comment-message {
    .ub-f1 {
        overflow: initial;
    }

    .send-message {
        padding: 7px 0 18px ;
        background-color: #fff;
        .title {
            line-height: 56px;
            background-color: #fff;
            padding: 0 25px;
            font-size: 14px;
            font-weight: 500;
        }

        .message-box {
            padding: 0 25px;
            .user-info {
                margin-right: 18px;
                cursor: pointer;

                .user-img {
                    width: 86px;
                    height: 86px;
                    background-color: #ccc;
                    border-radius: 50%;
                }

                .user-name {
                    height: 54px;
                    width: 86px;
                    line-height: 54px;
                    text-align: center;
                }
            }
        }

        .message-set {
            background-color: #f4f4f4;
            font-size: 14px;

            .title {
                background-color: unset;
            }   

            .look-author {
                color: #000000;
                font-size: 14px;
                cursor: pointer;
                position: relative;
                padding-left: 25px;
            }

            .look-author.act {
                color: var(--el-color-second);
            }

            .look-author::before {
                position: absolute;
                z-index: 2;
                left: 1px;
            }

            .look-author::after {
                content: '';
                display: inline-block;
                width: 14px;
                height: 14px;
                border: 1px solid #dddddd;
                border-radius: 50%;
                padding: 1px;
                color: var(--el-color-four);
                margin-right: 7px;
                font-size: 14px;
                position: absolute;
                left: 0;
                z-index: 1;
                background-color: #fff;
            }

            .tip {
                font-size: 12px;
                color: var(--el-color-four);
                font-weight: 500;
                margin-right: 5px;
            }

            .comment-switch {
                font-size: 14px;
                margin-right: 13px;
                cursor: pointer;
                position: relative;
                padding-left: 25px;
            }

            .comment-switch.act {
                color: var(--el-color-second);
            }

            .comment-switch::before {
                position: absolute;
                z-index: 2;
                top: 2px;
                left: 1px;
            }

            .comment-switch::after {
                content: '';
                display: inline-block;
                width: 14px;
                height: 14px;
                border: 1px solid #dddddd;
                border-radius: 50%;
                padding: 1px;
                color: var(--el-color-four);
                margin-right: 7px;
                font-size: 14px;
                position: absolute;
                left: 0;
                z-index: 1;
                top: 1px;
                background-color: #ffffff;
            }

            .sort {
                width: 128px;
                height: 35px;
                border: 1px solid #dddddd;
                border-radius: 3px;
                box-sizing: border-box;
                padding: 0 20px;
                position: relative;
                background-color: #f9f9f9;

                span {
                    display: inline-block;
                    font-weight: 500;
                    cursor: pointer;
                }

                span.act {
                    color: var(--el-color-second);
                }
            }

            .sort::before {
                content: '';
                width: 1px;
                height: 18px;
                background-color: #dddddd;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-top: -9px;
            }
        }

        .message-list {
            padding: 20px 25px;
            .list-item {
                margin-bottom: 28px;
                .user-info {
                    margin-right: 38px;

                    .user-img {
                        width: 86px;
                        height: 86px;
                        background-color: #ccc;
                        border-radius: 50%;
                    }

                    .user-name {
                        height: 54px;
                        width: 86px;
                        line-height: 54px;
                        text-align: center;
                    }
                }

                .message-info {
                    padding-bottom: 10px;
                    border-bottom: 1px solid #dcdcdc;
                    font-size: 14px;
                    width: 686px;
                    .item-info {
                        color: #999999;
                        span {
                            font-size: 12px;
                            display: block;
                            line-height: 24px;
                            margin: 0 12px;
                            font-weight: 500;
                            position: relative;
                        }

                        span:last-child {
                            margin-right: 0;
                        }

                        span:last-child:after {
                            display: none;
                            
                        }

                        span:before {
                            font-size: 18px;
                            float: left;
                            margin-right: 5px;
                            font-weight: 600;
                        }

                        span:after {
                            content: '';
                            width: 1px;
                            height: 14px;
                            background-color: #d3d3d3;
                            position: absolute;
                            right: -12px;
                            top: 5px;
                        }

                        .report {
                            color: var(--el-color-primary);
                            cursor: pointer;
                            font-weight: 500;
                            display: none;
                        }

                        .icon-like {
                            cursor: pointer;
                        }

                        .icon-like-act {
                            color: var(--el-color-four);
                        }
                    }

                    .item-text {
                        font-size: 14px;
                        color: #6e6e6e;
                        line-height: 28px;
                        margin-top: 15px;
                        overflow-wrap: break-word; /* 在单词内部换行 */
                        img {
                            max-width: 50px;
                            margin: 0 5px;
                        }

                        span {
                            color: var(--el-color-primary);
                        }
                    }

                    .handle {
                        font-size: 14px;
                        color: #6e6e6e;
                        line-height: 30px;
                        margin-bottom: 10px;

                        span {
                            margin-left: 10px;
                            font-weight: 500;
                            cursor: pointer;
                        }

                        span.reply {
                            color: #6e6e6e;
                        }

                        span.reply.act {
                            color: var(--el-color-primary);
                        }

                        span.del {
                            color: var(--el-color-four);
                        }
                    }

                    .item-head:hover {
                        .item-info {
                            .report {
                                display: flex;
                            }
                        }
                    }
                }

                .sub-list {
                    background-color: #f9f9f9;
                    padding: 20px 28px;

                    .user-info {
                        margin-right: 20px;
                        .user-img {
                            width: 56px;
                            height: 56px;
                        }

                        .user-name {
                            width: 56px;
                            height: 36px;
                            line-height: 36px;
                        }
                    }

                    .message-info {
                        border: 0px;
                    }
                }
            }

            .list-item.have-sub-list {
                .message-info {
                    border: 0px;
                }
            }

            .list-item:last-child {
                margin-bottom: 0px;
            }
        }
    }
}
</style>