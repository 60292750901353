<template>
  <div class="pbl-empty ub ub-pc">
      <el-empty :description="description" :image-size="size"></el-empty>
  </div>
</template>

<script>
export default {
    props: {
        description: {
            type: String,
            default: '暂无数据'
        },

        size: {
            type: Number,
            default: 160
        }
    }
}
</script>

<style lang="scss">
.pbl-empty {
    width: 100%;
    height: 100%;
    .el-empty {
        padding: 20px 0;
    }
}
</style>