<template>
  <el-dialog
    :model-value="isImagesDialog"
    custom-class="default-dialog images-dialog"
    title="图片"
    :before-close="handleClose"
  >
    <el-carousel indicator-position="outside" interval="100000">
        <el-carousel-item v-for="(item, index) in images" :key="item">
            <div class="image ub ub-ac ub-pc">
                <el-image :src="item.url" fit="cover"></el-image>
                <span class="iconfont icon-delect" v-if="isUpload" @click="deleteMessage(index)"></span>
            </div>
        </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
export default {
    props: {
        isImagesDialog: {
            type: Boolean,
            default: false
        },

        images: {
            type: Array,
            default: () => {
                return []
            }
        },

        isUpload: {
            type: Boolean,
            default: true
        }
    },

    created() {
        console.log(this.images)
    },

    methods: {
        handleClose() {
            this.$emit('closeImagesDialog')
        },

        deleteMessage(index) {
            this.$emit('deleteImage', index)
        }
    },

    watch: {
        isImagesDialog(newVal) {
            if(newVal) {
                console.log(this.images)
            }
        }
    }
}
</script>

<style lang="scss">
.images-dialog {
    .el-dialog__body {

        .el-carousel {
            .el-carousel__container {
                .el-carousel__item {
                    width: 100%;
                    height: 100%;

                    .image {
                        width: 100%;
                        height: 100%;
                        position: relative;

                        .icon-delect {
                            font-size: 24px;
                            position: absolute;
                            right: 0;
                            top: 0;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>