<template>
  <div class="wares-record">
    <the-header></the-header>
    <div class="content-body warp">
      <the-breadcrumb :breadcrumbList="breadcrumbList"></the-breadcrumb>
      <div class="exchange-record-list" v-loading="loading">
        <div v-if="recordList">
          <div v-if="recordList.length > 0">
            <exchange-cell
              v-for="(item, index) in recordList"
              :key="index"
              :item="item"
            ></exchange-cell>
          </div>
          <el-empty description="暂无数据" v-else />
          <the-pagination
            :total="total"
            :pageSize="num"
            @paginationChange="paginationChange"
          ></the-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { shoppingMaterialMsg } from "../../http/api";
import TheHeader from "@/components/TheHeader.vue";
import TheBreadcrumb from "@/components/TheBreadcrumb.vue";
import ExchangeCell from "./components/ExchangeCell.vue";
import ThePagination from "@/components/ThePagination.vue";
import { mapGetters } from "vuex";
export default {
  components: { TheHeader, TheBreadcrumb, ExchangeCell, ThePagination },

  data() {
    return {
      loading: false,
      breadcrumbList: [
        {
          name: "资源库",
          path: "/shopping-index",
        },
        {
          name: "兑换记录",
        },
      ],
      recordList: null,
      total: 0,
      page: 1,
      num: 10,
      timer: ''
    };
  },

  created() {
    this.getData();
  },

  computed: {
    ...mapGetters(["getUserInfo"]),
  },

  methods: {
    getData() {
      this.loading = true;
      let params = {
        type: 3,
        userId: this.getUserInfo ? this.getUserInfo.uid : "",
        pageNo: this.page,
        pageSize: this.num,
      };
      shoppingMaterialMsg(params)
        .then((res) => {
          console.log(res);
          this.recordList = res.data.page.items;
          this.total = res.data.page.totalCount;
          this.loading = false;
          this.playTimes()
        })
        .catch(() => {
          this.loading = false;
        });
    },

    playTimes() {
        this.timer = setInterval(()=>{
          for (let i = 0; i < this.recordList.length; i++) {
            let s = parseInt(this.recordList[i].orderTime)
            if (this.recordList[i].state == 1) {
              if(s == 0) {
                this.recordList[i].state = -1
              } else {
                this.recordList[i].orderTime = s
                this.recordList[i].orderTime -= 1
                this.recordList[i].time = this.timeDifference(this.recordList[i].orderTime)
              }
              
            }
          }
        }, 1000)
    },

    timeDifference(time) {
      if (time > 0) {
        var dd = parseInt(time / 60 / 60 / 24);
        dd = dd == 0 ? '' : dd + '天'
        var hh = parseInt((time / 60 / 60) % 24);
        hh = hh == 0 ? '' : hh + '小时'
        var mm = parseInt((time / 60) % 60);
        mm = mm == 0 ? '' : mm + '分'
        var ss = parseInt(time % 60);
        ss = ss == 0 ? '' : ss + '秒'
        return dd + hh + mm + ss;
      }
    },

    /**
     * 分页
     */
    paginationChange(page) {
      this.page = page;
      this.getData();
    },

    /**
     * 查看订单
     */
    lookOrder(item) {
      sessionStorage.setItem("waresOrder", JSON.stringify(item));
      this.$router.push("/shopping-index/order-detail");
    },

    /**
     * 取消订单
     * @param {type} 1 删除 2 兑换 3 取消
     */
    orderTap(type, orderId) {
        this.$confirm(`是否${type == 1 ? '删除订单' : type == 2 ? '立即兑换' : '取消订单'}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          this.cancelOrderHttp(type, orderId)
        }).catch(() => {
          // ...
        });
    },

    /**
     * 取消/删除/兑换订单接口
     * @param {Number} type 1 删除 2 兑换 3 取消
     * @param {Numver} orderId 订单id
     */
    cancelOrderHttp(type, orderId) {
        let params = {
            type: 5,
            userId: this.getUserInfo ? this.getUserInfo.uid : "",
            orderType: type,
            orderId: orderId
        };
        shoppingMaterialMsg(params)
            .then((res) => {
                console.log(res);
                let msg = ''
                if (type == 1) {
                  msg = '订单已删除'
                } else if (type == 2) {
                  msg = '购买成功'
                } else {
                  msg = '订单已取消'
                }
                this.$message({
                  type: 'warning',
                  message: msg
                })
                this.getData()
            })
    },
  },

  unmounted() {
    console.log('组建销毁')
    clearInterval(this.timer);
  }
};
</script>

<style lang="scss">
.wares-record {
  .content-body {
    .exchange-record-list {
      min-height: 500px;
      .exchange-item {
        margin-bottom: 30px;
      }
    }
  }
}
</style>