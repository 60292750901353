<template>
  <el-dialog
      :model-value="isContributionDialog"
      :close-on-click-modal="false"
      width="845px"
      :title="title"
      custom-class="default-dialog contribution-dialog"
      :before-close="handleClose">
      <div v-loading="loading && !loadErr">
        <el-form :model="formData" label-width="100px" v-if="activityList.length > 0">
            <el-form-item label="投稿活动：">
                <el-radio-group v-model="formData.activityId" @change="activityChange">
                    <el-radio :label="item.activityId" size="large" border v-for="(item, index) in activityList" :key="index">{{ item.title }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="赛道：" v-if="trackList.length > 0">
                <el-radio-group v-model="formData.trackId" @change="trackChange">
                    <el-radio :label="item.trackId" size="large" border v-for="(item, index) in trackList" :key="index">{{ item.trackName }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="赛道组：" v-if="groupList.length > 0">
                <el-radio-group v-model="formData.groupId">
                    <el-radio :label="item.groupId" size="large" border v-for="(item, index) in groupList" :key="index">{{ item.groupName }}</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <the-empty v-if="!this.activityId && activityList.length == 0" description="暂无可投稿活动"></the-empty>

        <div class="ub ub-f1" v-if="list && !projectId">
            <div class="ub ub-f1  ub-wrap" v-if="list.length > 0">
                <activity-work v-for="(item, index) in list" 
                                :key="index" 
                                :class="[(index + 1)%4 == 0 ? 'last' : '', index == active ? 'act': '']" 
                                :item="item"
                                @click="selectWork(index, item)"></activity-work>
                <the-pagination :total="total"
                    :pageSize="num"
                    @paginationChange="paginationChange"></the-pagination>
            </div>
            <the-empty v-else description="暂无作品"></the-empty>
        </div>
      </div>
      <the-empty v-if="loadErr" description="加载失败，请刷新重试！"></the-empty>
      <template #footer>
            <span class="dialog-footer">
                <el-button class="default-button" @click="contributionChange" :loading="buttonLoading">确认投稿</el-button>
            </span>
        </template>
  </el-dialog>
</template>

<script>
import { activityMsg, pblWork } from '../../../http/api'
import TheEmpty from '@/components/TheEmpty.vue'
import { mapGetters } from 'vuex'
import ActivityWork from './ActivityWork.vue'
import ThePagination from '@/components/ThePagination'
export default {
  components: { TheEmpty, ActivityWork, ThePagination },
    props: {
        isContributionDialog: {
            type: Boolean,
            default: false
        },

        activityId: {
            type: String,
            default: ''
        },
        
        projectId: {
            type: Number,
            default: null
        },

        workName: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            active: -1,
            loading: false,
            loadErr: false,
            list: null,
            page: 1,
            num: 8,
            total: 0,
            buttonLoading: false,
            activityList: [],
            formData: {
                activityId: '',
                trackId: '',
                groupId: ''
            }
        }
    },

    computed: {
        ...mapGetters(['getUserInfo']),
        /**
         * dialog标题
         */
        title() {
            if (this.activityId) {
                return '请选择投稿作品'
            }

            if (this.projectId) {
                return `请选择投稿活动（当前投稿作品名称：${this.workName}）`
            }

            return '提示'
        },
         /**
         * 赛道列表
         */
         trackList() {
            console.log(this.activityList)
            if (!this.activityList.length) {
                return []
            }
            let index = this.activityList.findIndex((item) => {
                return item.activityId == this.formData.activityId
            })
            console.log(index)
            if (index != -1) {
                return this.activityList[index].trackList
            } 
            return []
        },
        /**
         * 组列表
         */
        groupList() {
            if (!this.trackList.length) {
                return []
            }
            let index = this.trackList.findIndex((item) => {
                return item.trackId == this.formData.trackId
            })
            console.log(index)
            if (index != -1) {
                return this.trackList[index].activityGroupInfo
            } 
            return []
        }
    },

    methods: {
        handleClose() {
            this.$emit('closeContributionDialog', true)
        },

        init() {
            this.page = 1
            this.active = -1
            this.loading = false
            this.loadErr = false
            this.list = null
            this.formData= {
                activityId: this.activityId ? this.activityId : '',
                trackId: '',
                groupId: ''
            }
        },

        /**
         * 
         */
         activityChange() {
            console.log(this.formData)
            this.formData.trackId = ''
            this.formData.groupId = ''
         },

        /**
         * 选择赛道
         */
        trackChange() {
            this.formData.groupId = ''
        },

        getActivityWork() {
            this.loading = true
            activityMsg({
                type: 2,
                userId: this.getUserInfo.uid,
                activityId: this.activityId,
                projectId: this.projectId,
                pageNo: this.page,
                pageSize: this.num
            }).then((res) => {
                console.log(res)
                this.loading = false
                this.total = res.data.sum
                this.list = res.data.list
                this.activityList = res.data.activityList ? res.data.activityList : []
            }).catch((err) => {
                console.log(err)
                this.loading = false
                this.loadErr = true
            })
        },

        /**
         * 选择作品
         */
        selectWork(index, item) {
            if (item.releaseState == 0) {
                this.$message({
                    message: '该作品未发布，请发布后在进行投稿！',
                    type: 'warning'
                })
                return
            }
            this.active = index
        },

        /**
         * 投稿
         */
        contributionChange() {
            console.log(this)
            console.log(this.trackList)
            if (this.activityList.length > 0 && this.formData.activityId == '') {
                this.$message({
                    message: '请选择投稿活动',
                    type: 'warning'
                })
                return
            }

            if (this.trackList.length > 0 && this.formData.trackId == '') {
                this.$message({
                    message: '请选择赛道',
                    type: 'warning'
                })
                return
            }
            if (this.groupList.length > 0 && this.formData.groupId == '') {
                this.$message({
                    message: '请选择赛道组',
                    type: 'warning'
                })
                return
            }
            console.log(this.formData)
            if (this.active < 0 && !this.projectId) {
                this.$message({
                    message: '请选择需要投稿的作品',
                    type: 'warning'
                })
                return
            }

            if (this.trackList.length == 0) {
                this.$message({
                    message: '您未报名参与此活动，无投稿权限！',
                    type: 'warning'
                })
                return
            }

            this.buttonLoading = true
            activityMsg({
                type: 5,
                userId: this.getUserInfo.uid,
                activityId: this.formData.activityId,
                projectId: this.projectId ? this.projectId : this.list[this.active].PROJECT_ID,
                trackId: this.formData.trackId,
                groupId: this.formData.groupId
            }).then((res) => {
                console.log(res)
                this.$message({
                    message: '投稿成功',
                    type: 'success'
                })
                this.buttonLoading = false
                setTimeout(() => {
                    this.$emit('closeContributionDialog', true)
                }, 1500)
            }).catch((err) => {
                console.log(err)
                this.buttonLoading = false
            })
        },

        /**
         * 分页
         * @param {Number} page 分页数
         */
        paginationChange(page) {
            console.log(page);
            this.active = -1
            this.page = page;
            this.getActivityWork();
        },
    },

    watch: {
        isContributionDialog(newVal) {
            if (newVal) {
                this.init()
                this.getActivityWork()
            }
        }
    }
}
</script>

<style lang="scss">
.contribution-dialog {
    width: 845px;
    .el-dialog__body {
        .el-form {
            .el-form-item {
                .el-form-item__label {
                    line-height: 40px;
                    text-align: right;
                    color: #000000;
                    font-size: 16px;
                }
                .el-form-item__content {
                    .el-radio {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .activity-work {
            margin: 20px 12px 10px 5px;
        }

        .activity-work.last {
            margin-right: 0;
        }

         .activity-work.act {
            box-sizing: border-box;
            border: 4px solid #ffd100;
         }

         .pagination-model {
            padding: 20px 0 0;
            background-color: #ffffff;
         }
    }
    .el-dialog__footer {
        text-align: center;
        .dialog-footer {
            button {
                width: 169px;
                height: 48px;
                font-size: 16px;
            }
        }
    }
}
</style>