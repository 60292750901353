<template>
    <div class="pbl-message iconfont icon-message ub ub-ac" v-if="path != ''"><router-link to="/message-center" ><span :class="[messageNumClass]" v-if="num != 0">{{ messageNum }}</span></router-link></div>
    <div class="pbl-message iconfont icon-message ub ub-ac" v-else @click="messageTap"><span :class="[messageNumClass]" v-if="num != 0">{{ messageNum }}</span></div>
</template>

<script>
export default {
    props: {
        num: {
            type: Number,
            default: 0
        },
        
        path: {
            type: String,
            default: ''
        }
    },

    computed: {
        messageNum() {
            return this.num.length > 2 ? `99+` : this.num
        },

        messageNumClass() {
            return this.num.length > 2 ? `span3` : `span${this.num.length}`
        },
    },

    methods: {
        messageTap() {
            this.$emit('messageTap', '')
        }
    }
}
</script>

<style lang="scss">
.pbl-message {
    font-size: 30px;
    float: left;
    margin: 0 15px 0 10px;
    position: relative;
    cursor: pointer;
    
    a {
        display: inline-block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
    }

    span {
        display: inline-block;
        width: 18px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        background-color: #ec443d;
        position: absolute;
        right: -7px;
        top: 50%;
        margin-top: -20px;
        font-size: 12px;
        color: #fff;
        border-radius: 50%;
    }

    .span2 {
        width: 22px;
        height: 22px;
        line-height: 22px;
        right: -10px;
    }

    .span3 {
        width: 26px;
        height: 26px;
        line-height: 26px;
        right: -15px;
        top: 18px;
    }
}

.pbl-message:hover {
    animation: actMessage 0.5s;
    -webkit-animation: actMessage 0.5s;
}

.pbl-message::before {
    font-size: 30px;
}

@keyframes actMessage {
    10% {
        transform: scale(0.5);
    }
    50% {
        transform: scale(1.2);
    }
    80% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}
</style>