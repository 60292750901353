<template>
  <div class="shop-center">
      <the-header></the-header>
      <div class="content-body">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick" :before-leave="handleBeforeClick">
          <el-tab-pane label="资源库物品" name="1">
            <shopping-mall v-if="activeName == '1'"></shopping-mall>
          </el-tab-pane>
          <el-tab-pane label="我的物品" name="2">
            <shopping-cart v-if="activeName == '2'"></shopping-cart>
          </el-tab-pane>
        </el-tabs>
        <router-view></router-view>
      </div>
      <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import ShoppingMall from './components/ShoppingMall.vue'
import ShoppingCart from './components/ShoppingCart.vue'
import { mapGetters } from 'vuex'
import TheFooter from '@/components/TheFooter.vue'
export default {
  components: { TheHeader, ShoppingMall, ShoppingCart, TheFooter },

  data() {
    return {
      activeName: '1'
    }
  },

  created() {
    console.log(this.$route)
  },

  computed: {
    ...mapGetters(['getUserInfo'])
  },

  methods: {
    handleClick(e) {
      console.log(e)
      console.log(this.activeName)
    },

    handleBeforeClick(activeName) {
      if(activeName == 2 && !this.$util.isDefine(this.getUserInfo)) {
        this.$store.commit('setLoginAlertState', true)
        return false
      }
    }
  }
}
</script>

<style lang="scss">
.shop-center {
  .content-body {
    .el-tabs {
      background-color: #fff;
      .el-tabs__header {
        width: 1190px;
        margin: 0 auto;
        padding-top: 20px;

        .el-tabs__nav-wrap {
          .el-tabs__item {
            height: 52px;
            line-height: 52px;
            font-size: 20px;
          }

          .el-tabs__active-bar {
            height: 4px;
            bottom: 1px;
          }
        }

        .el-tabs__nav-wrap::after {
          height: 1px;
        }
      }
    }
  }
}
</style>