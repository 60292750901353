<template>
    <el-upload
        v-model:file-list="fileList"
        list-type="picture-card"
        :limit="1"
        class="avatar-uploader ub-f1 ub"
        :action="url + '/'"

        :on-error="handleAvatarError"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :before-remove="beforeAvatarRemove">
        <!-- <el-button size="small" type="primary">上传本地头像</el-button> -->
        <div class="upload-content ub ub-ver ub-ac ub-pc">
            <div class="iconfont icon-yunshangchuan"></div>
            <div>选择文件<br/>（不超过2M）</div>
        </div>
        </el-upload>
</template>

<script>
import config from '../../../../http/config'
import {activityMsgPost} from '../../../../http/api'
export default {
    props: {
        index: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            fileList: [],
            imgUrl: ''
        }
    },

    created() {
        this.url = config.url
    },

    methods: {
        handleAvatarError() {

        },
        handleAvatarSuccess(res, file) {
            console.log(res)
            console.log(file)
            let userImage = URL.createObjectURL(file.raw);
            console.log(userImage)
            this.uploadImage(file.raw)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
                this.userImageLoading = false;
            }

            console.log(isLt2M)
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
                this.userImageLoading = false;
            }
            return (isJPG || isPNG) && isLt2M;
        },

        uploadImage(raw) {
            console.log(raw)
            var params = new FormData();
            params.append("file", raw);
            params.append("type", "13");
            console.log(params)
            activityMsgPost(params).then((res) => {
                console.log(res)
                this.imgUrl=res.data
                this.$emit('setData', this.index, res.data)
            })
        },

        beforeAvatarRemove() {
            this.$emit('setData', this.index, '')
        }
    },
    watch: {
        item: {
            handler(newVal) {
                console.log(newVal)
                if (newVal.value) {

                    this.fileList = [{
                        url: newVal.value
                    }]
                    this.imgUrl = newVal.value
                    console.log(this.fileList)
                }
            },
            deep: true
            
        }
    }
}
</script>

<style lang="scss">
.upload-content {
    width: 100px;
    height: 100px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    line-height: initial;
    text-align: center;
    font-size: 12px;
    color: #8c8c8c;
    .iconfont {
        color: var(--el-color-primary);
        font-size: 24px;
        margin-bottom: 5px;
    }
}
</style>