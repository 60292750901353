<template>
  <div class="common-problem">
      <p class="p-title">1、正常的创作流程是？</p>
      <p class="p-con">点击创作按钮—》在创作页面拖拽积木块创作—》保存—》发布作品---》个人中心查看作品。</p>
      <p class="p-title">2、推荐浏览器有哪些？</p>
      <p class="p-con">360浏览器、qq浏览器、Google浏览器（需要打开FLASH加载项）</p>
      <p class="p-title">3、收藏的作品、学习视频、素材都在哪里？</p>
      <p class="p-con">收藏的作品/学习视频在个人中心；兑换的素材在素材库-我的物品里。</p>
      <p class="p-title">4、作品支持手机端扫码分享？</p>
      <p class="p-con">发布的作品，在作品详情页，用手机扫码可以手机端操作查看、分享。</p>
      <p class="p-title">5、代码开源？</p>
      <p class="p-con">代码开源是指其他非本人用户可以查看作品代码，并授权允许其他用户自由改编！</p>
      <qr-code></qr-code>
  </div>
</template>

<script>
import QrCode from '@/components/QrCode.vue'
export default {
  components: { QrCode },

}
</script>

<style lang="scss">
.common-problem {
  p.p-title {
    margin: 0;
    font-weight: bold;
  }

  p.p-con {
    margin-bottom: 10px;
    padding-left: 26px;
  }

  .wx-qr-code {
    margin-top: 30px;
  }
}
</style>