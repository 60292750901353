<template>
  <div class="work-rate-list ub ub-wrap">
      <work-item v-for="(item, index) in workList" :key="index" :item="item">
          <el-button class="default-button" @click="gotoRate(item)" v-if="getUserInfo.uid == item.USER_ID2">{{rateText(item.commState)}}</el-button>
      </work-item>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import WorkItem from './WorkItem.vue'
export default {
    props: {
        workList: {
            type: Object,
            default: ()=> {
                return {}
            }
        }
    },

    components: { WorkItem },

    created() {
        console.log(this.workList)
    },

    computed: {
        ...mapGetters(['getUserInfo']),

        rateText() {
            return (state) => { // 0 未评分 1 已评分
                return state == 0 ? '去评分' : '查看评分' 
            }
            
        }
    },

    methods: {
        gotoRate(item) {
            this.$router.push(`/work-detail?pid=${item.PROJECT_ID}&uuid=${item.USER_ID}&cid=${item.CLASS_NUM}&cpid=${item.ITEM_ID}&tid=${item.USER_ID2}&rid=${item.ROW_ID}&state=${item.commState}&isScore=true`)
        }
    }
}
</script>

<style lang="scss">
.work-rate-list {
    .work-item {
        margin-right: 18px;
        margin-bottom: 18px;
        position: relative;

        .el-button {
            position: absolute;
            bottom: 60px;
            right: 10px;
            min-height: 26px;
            height: 26px;
            line-height: 26px;
        }
    }
}
</style>