<template>
  <div :class="['activity-work', item.releaseState == 0 ? 'gray' : '']">
    <el-image class="work-item-pic" :src="item.IMG" fit="cover">
        <template #error>
          <div class="image-slot">
            <el-image :src="require('@/assets/image/work_default_pic.png')" fit="cover" />
          </div>
        </template>
    </el-image>
    <div class="work-item-title ub ub-ac" :title="item.NAME"><div class="text-hidden-2">{{ item.NAME }}</div></div> 
    <div class="work-item-time">发布时间:{{timeFomart(item.DATE)}}</div>
  </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    computed: {
        timeFomart() {
            return (time) => {
                return time.substr(0, 16)
            }
        },
    }
}
</script>

<style lang="scss">
.activity-work {
    width: 186px;
    font-size: 14px;
    box-shadow: 0px 0px 8px #d5d5d5;
    border-radius: var(--item-border-radius);
    overflow: hidden;

    .work-item-pic {
        width: 100%;
        height: 124px;
        cursor: pointer;
        overflow: hidden;
    }

    .work-item-title {
        height: 36px;
        padding: 0 12px;
        box-sizing: border-box;
        cursor: pointer;
        font-weight: bold;
    }

    .work-item-time {
        padding: 0 12px 10px;
        font-size: 12px;
        color: #bfbfbf;
        font-weight: bold;
    }
}

.activity-work.unreleased {

}
</style>