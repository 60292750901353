<template>
  <div class="legal-declaration">
        <the-header></the-header>
        <div class="content-body warp">
            <div class="detail-title">智多蛙创客-创新学习平台用户协议</div>
            <div class="detail-info ub ub-be">
                <div class="logo ub ub-ac"><el-image :src="require('../../assets/image/default_user_logo.png')"></el-image>智多蛙创客</div>
                <div class="date">更新版生效日期：2019年10月28日</div>
            </div>
            <div class="detail-body">
                    <p class="indent title">1、特别提示</p>
                    <p class="indent">1.1欢迎使用智多蛙创客-创新学习平台（以下简称“智多蛙创客”或“我们”），为了保障您的权益，方便您更好的使用智多蛙创客提供的产品和服务，请您在使用之前，认真地阅读并理解本协议的所有内容。</p>
                    <p class="indent">1.2您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力，确保有能力对您使用智多蛙公司提供服务的一切行为独立承担责任。若您不具备前述主体资格或您是未满十八周岁的未成年人，请在您的监护人的陪同下阅读本服务条款，并在取得他们对您使用服务的行为，以及对本服务条款的同意之后，使用本服务；智多蛙公司在依据法律规定或本协议约定要求您承担责任时，有权向您的监护人追偿。</p>
                    <p class="indent">1.3用户阅读并同意本协议条款，完成注册程序后，才能成为智多蛙创客的正式注册用户，使用智多蛙创客的各项服务。您的注册、登录和使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。如果用户不同意本协议，或对本协议中的条款存在任何疑问，请立即停止智多蛙创客注册，并可以选择不使用智多蛙创客提供的服务。</p>
                    <p class="indent">1.4本协议的规定可能随时更改，修改本协议时，智多蛙创客将在相关页面公告修改的内容，而不会另行对用户进行个别通知。敬请用户及时查阅了解修改内容。如用户不同意本协议修改内容，有权利停止使用智多蛙创客提供的服务；如用户继续使用智多蛙创客，则视为同意接受修改后的协议。</p>
                    <p class="indent title">2、定义</p>
                    <p class="indent">智多蛙创客--创新学习平台是针对于中国少儿编程课程、创新作品、互动交流及进行其他活动的一站式项目式学习平台。</p>
                    <p class="indent title">3、账号注册及使用规则</p>
                    <p class="indent">3.1您在注册和使用账号时应当遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在注册资料中出现任何违法违规或侵权信息。对提交的账号名称、头像和简介等注册信息进行审核，对含有违法和不良信息的，不予注册，对发布的违法信息，有权进行删除处理，多次出现违法行为的账号，我们有权删除用户账号。</p>
                    <p class="indent">3.2您有义务维持并更新注册的用户资料，确保其为真实、最新、有效及完整的资料。如因您未及时更新基本资料，导致智多蛙创客服务无法提供或提供时发生任何错误，我们将不承担任何责任，所有后果应由您承担。</p>
                    <p class="indent">3.3若您提供任何错误、虚假、过时或不完整的资料，或者智多蛙创客依其独立判断怀疑资料为错误、虚假、过时或不完整的，智多蛙创客有权暂停或终止您在本网站的注册账号，并拒绝您使用本网站服务的部分或全部功能。在此情况下，智多蛙创客不承担任何责任，您同意承担因此所产生的直接或间接的任何支出或损失。</p>
                    <p class="indent">3.4您拥有在本网站的用户名及登录密码，并有权使用自己的用户名及密码随时登录本网站，您不得以任何形式擅自转让或授权他人使用自己的用户名及密码。如果您将用户名或密码丢失或被盗，应及时重新登记并重新设置密码。您造成的账号失密等不良后果，应自行承担责任。智多蛙创客通过用户的注册账号及密码来识别用户的指令。除非另有证明，储存在智多蛙服务器上的数据是您使用智多蛙创客提供的服务的唯一有效证据，用户确认，使用账户和密码登录后在本网站的一切行为均代表用户本人。用户注册操作所产生的电子信息记录均为用户行为的有效凭证，并由用户本人承担由此产生的全部责任。</p>
                    <p class="indent">3.5您必须提供真实、最新、有效及完整的资料，并且授予智多蛙创客基于提供网站服务的目的，对您提供的资料及数据信息拥有全球通用的、永久的、免费的使用权利。</p>
                    <p class="indent title">4、用户的权利</p>
                    <p class="indent">4.1用户可以根据本协议以及智多蛙创客更新和公布的其他规则使用智多蛙创客提供的服务。</p>
                    <p class="indent">4.2用户有权在使用智多蛙创客提供的服务的期间监督智多蛙创客及智多蛙创客的工作人员是否按照智多蛙创客所公布的标准向用户提供服务，也可以随时向智多蛙创客提出与智多蛙创客的服务有关意见和建议。</p>
                    <p class="indent">4.3如果用户不同意本协议或对智多蛙创客后来更新的协议有异议，或对智多蛙创客所提供服务不满意，用户可以选择停止使用智多蛙创客提供的服务。如果用户选择停止使用智多蛙创客的服务，则智多蛙创客即不再对用户承担任何义务和责任。</p>
                    <p class="indent title">5、用户的义务</p>
                    <p class="indent">5.1用户承诺，其通过本网站发布的信息均真实有效，其向智多蛙创客提交的任何资料均真实、有效、完整、准确。如因违背上述承诺，造成智多蛙创客或本网站其他用户损失的，用户将承担相应责任。</p>
                    <p class="indent">5.2用户承诺，不对本网站上的任何数据作商业性利用，包括但不限于在未经智多蛙创客事先书面同意的情况下，以复制、传播等任何方式使用本站上展示的资料。</p>
                    <p class="indent">5.3用户在接受智多蛙创客服务时必须遵守中国法律、法规、规章以及政府规范性文件，不得做出违法违规的行为，具体如下：</p>
                    <p class="indent">5.3.1发表、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容。</p>
                    <p class="indent">5.3.2制造虚假身份、发布虚假信息等误导、欺骗他人，或违背智多蛙创客页面公布之活动规则进行虚假交易。</p>
                    <p class="indent">5.3.3进行危害计算机网络安全的行为。包括但不限于在您所发布的信息中含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序。</p>
                    <p class="indent">5.3.4发布国家禁止发布的信息，发布其它涉嫌违法或违反本协议及各类规则的信息。</p>
                    <p class="indent title">6、用户平台使用守则</p>
                    <p class="indent">用户在使用智多蛙创客平台时，必须知悉并遵守相关使用守则，具体如下：</p>
                    <p class="indent">6.1用户不得发表违反国家法律法规的言论、视频及图片等信息。违规者所发布内容一经举报或发现将立即删除，用户账号将被永久封禁。包含但不限于如下原则：</p>
                    <p class="indent">6.1.1违反宪法确定的基本原则；</p>
                    <p class="indent">6.1.2危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一；</p>
                    <p class="indent">6.1.3损害国家荣誉和利益；</p>
                    <p class="indent">6.1.4煽动民族仇恨、民族歧视，破坏民族团结；</p>
                    <p class="indent">6.1.5破坏国家宗教政策，宣扬邪教和封建迷信；</p>
                    <p class="indent">6.1.6散布谣言，扰乱社会秩序，破坏社会稳定；</p>
                    <p class="indent">6.1.7散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪；</p>
                    <p class="indent">6.1.8侮辱或者诽谤他人，侵害他人合法权益；</p>
                    <p class="indent">6.1.9煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</p>
                    <p class="indent">6.1.10含有法律、法规、规章、地方规范性文件、国家政策、政府通知、公序良俗等禁止的内容；</p>
                    <p class="indent">6.2用户不得发表不道德、破坏和谐友好氛围的言论、视频及图片等，违规者所发布内容一经举报或发现将立即删除，多次警告依然发布违规内容者，用户账号将被封禁15~180天，情节特别恶劣者永久封禁。具体表现为：</p>
                    <p class="indent">6.2.1含有任何性或性暗示的；</p>
                    <p class="indent">6.2.2含有辱骂、恐吓、威胁内容的；</p>
                    <p class="indent">6.2.3发表和使用侮辱性言辞对他人进行人身攻击；</p>
                    <p class="indent">6.2.4恶意对其他用户的个人信息进行人肉搜索，非法传播他人个人信息；</p>
                    <p class="indent">6.2.5针对他人的民族、种族、宗教、性取向、性别、年龄、地域、生理特征等身份或者归类的攻击；</p>
                    <p class="indent">6.2.6以不文明的语言激怒他人，故意挑起争端；</p>
                    <p class="indent">6.2.7对他人的作品进行无礼的贬低、嘲笑，轻视他人及其劳动成果。</p>
                    <p class="indent">6.2.8抄袭他人作品，不尊重他人劳动成果。</p>
                    <p class="indent">6.3用户不得发布垃圾广告信息，违规者所发布内容一经举报或发现将立即删除，多次警告依然发布违规内容者，用户账号将被封禁7~30天，情节特别恶劣者永久封禁。具体表现如下：</p>
                    <p class="indent">6.3.1以直接或欺骗的方式发布各种电商网站的购物链接或跳转链接；</p>
                    <p class="indent">6.3.2以图片或二维码的形式来发布广告信息；</p>
                    <p class="indent">6.3.3发布含有推销广告内容的软文等。</p>
                    <p class="indent">6.4用户不得发布恶意干扰他人，影响其他用户体验的视频、图片及文字等内容。违规者所发布内容一经举报或发现将立即删除，多次警告依然发布违规内容者，用户账号将被封禁1~30天，情节特别恶劣者永久封禁。具体表现如下：</p>
                    <p class="indent">6.4.1同一人频发相似主题内容；</p>
                    <p class="indent">6.4.2在帖内刷长篇文字、长图；</p>
                    <p class="indent">6.4.3在帖内刷同一内容或相似内容超过三次的；</p>
                    <p class="indent">6.4.4在不同帖内刷相同或相似内容的；</p>
                    <p class="indent">6.4.5在帖内发表无关他人讨论内容的主题帖、文字、链接或者图片；</p>
                    <p class="indent">6.4.6在没有得到版主许可进行挖坟（回复上次回复时间在30天以上的贴子，但精品帖、官方帖不算在内）；</p>
                    <p class="indent">6.4.7骚扰他人，以评论、@他人、私信等方式对他人反复发送重复或者相似的诉求。</p>
                    <p class="indent title">7、服务终止</p>
                    <p class="indent">出现以下情况时，我方可以随时终止被协议：</p>
                    <p class="indent">7.1您违反本协议约定，我方依据违约条款终止本协议的。如您再一次直接或间接或以他人名义注册为用户的，一经发现，我方有权直接单方面终止向您提供服务；</p>
                    <p class="indent">7.2我方通过您提供的信息与您联系时，发现您在注册时填写的联系方式已不存在或无法接通，我方以其他联系方式通知您更改，而您在三个工作日内仍未能提供新的联系方式，我方有权终止向您提供服务；</p>
                    <p class="indent">7.3您转让本人账户、盗用他人账户、发布违禁内容和信息、骗取他人财物、采取不正当手段谋利等行为，我方依据平台规则对您的账户予以查封的；</p>
                    <p class="indent">7.4除上述情形外，因您多次违反我方平台规则相关规定且情节严重，我方依据平台规则对您的账户予以查封的；</p>
                    <p class="indent">7.5您注册数据中内容是虚假或不存在的，我方有权随时终止向您提供服务；如我方有合理理由怀疑您提供的资料错误、不实、过时或不完整的，有权发出询问及/或要求改正的通知，并有权直接做出删除相应资料的处理，直至中止、终止对您提供部分或全部服务。我方对此不承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。</p>
                    <p class="indent">7.6您的账户被我方依据本协议进行注销等清理的；</p>
                    <p class="indent">7.7您在我方平台有侵犯他人合法权益或其他严重违法违约行为的；</p>
                    <p class="indent">7.8用户协议更新时，您明示不愿接受新的用户协议的；</p>
                    <p class="indent">7.9不可抗力导致本协议不能履行的（“不可抗力”是指双方不可预见、或可以预见但不能避免并或克服的客观情况，该事件妨碍、影响或延误任何一方根据合同履行其全部或部分义务。该事件包括但不限于政府行为、自然灾害、战争、网络堵塞或中断、黑客袭击或任何其他类似事件）；</p>
                    <p class="indent title">8其它根据相关法律法规我方应当终止服务或我方认为需终止服务的情况。</p>
                    <p class="indent">8.1用户理解并同意，即便在本协议终止及用户的服务被终止后，我方仍有权：</p>
                    <p class="indent">（a）继续保存您的用户信息；</p>
                    <p class="indent">(b)继续向用户主张在其使用本网站平台服务期间因违反法律法规、本协议及平台规则而应承担的责任。</p>
                    <p class="indent">8、所有权及知识产权</p>
                    <p class="indent">8.1智多蛙创客鼓励并支持创作作品、分享作品、互相学习，但任何用户以及其他第三方的知识产权都应得到尊重。您应当确保您拥有必要的授权或权限，以便您在智多蛙创客创作、复制、发布、传播作品。为免争议，本条所称“作品”包括任何形式的作品、制品、代码、评论、帖子或用户在智多蛙创客发布的其他内容。</p>
                    <p class="indent">8.2用户在智多蛙创客上传或发布的内容，用户应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益，用户同意授予智多蛙创客所有上述内容在全球范围内的免费、不可撤销的、永久的、可再许可或转让的独家使用权许可，据该许可智多蛙创客将有权以展示、推广及其他不为我法律所禁止的方式使用前述内容。</p>
                    <p class="indent">8.3非经智多蛙创客授权同意及法律规定，任何其他组织或个人都不得使用属于智多蛙创客的信息内容。本网站所有的产品、技术及程序等信息内容均由智多蛙创客享有知识产权或被授权使用；未经智多蛙创客授权许可，任何其他组织或个人不得擅自使用（包括但不限于以非法的方式打印、复制、传播、展示、下载、出售、出版等）。否则，智多蛙创客将依法追究其法律责任。</p>
                    <p class="indent">8.4智多蛙创客不对所提供的内容（包括基础素材）以及其他用户作品的适用性、合法性、不侵权或满足用户特定需求及目的进行任何明示或者默示的担保。用户之间或用户与其他权利人之间因使用相关内容或作品而产生或可能产生的任何纠纷和/或损失，由用户自行协商解决并承担相应的责任，与智多蛙创客无关。</p>
                    <p class="indent title">9、违约责任</p>
                    <p class="indent">9.1当用户的行为涉嫌违反法律法规或违反本协议和规则的，智多蛙创客有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息，或直接停止提供服务。如使智多蛙创客遭受任何损失的（包括但不限于受到第三方的索赔、受到行政管理部门的处罚等），用户还应当赔偿或补偿智多蛙创客遭受的损失及（或）发生的费用，包括诉讼费、律师费、保全费等。</p>
                    <p class="indent">9.2由于违反本协议，或违反其在本网站上签订的协议或文件，或由于用户使用本网站服务违反了任何法律或侵害第三方权利而导致任何第三方向智多蛙创客提出任何补偿申请或要求（包括律师费用），用户应对智多蛙创客给予全额补偿并使之不受损害。</p>
                    <p class="indent">9.3如因智多蛙创客违反有关法律、法规或本协议项下的任何条款而给用户造成损失，智多蛙创客同意承担由此造成的损害赔偿责任。</p>
                    <p class="indent title">10、隐私条款</p>
                    <p class="indent">10.1账号及信息的安全：</p>
                    <p class="indent">10.1.1智多蛙创客将竭尽所能保护所有用户信息安全，不会向任何无关第三方提供、出售、分享和注册用户的个人账号及信息。</p>
                    <p class="indent">10.1.2用户应保管好的账号与密码，不得向其他任何人泄露用户的账号和密码，亦不可使用其他任何人的账号和密码。因黑客、病毒或用户的保管疏忽等非智多蛙创客原因导致账号遭他人非法使用的，智多蛙创客不承担任何责任。冒用他人账号的，智多蛙创客及其合法授权主体保留追究实际使用人责任的权利。智多蛙创客将严格采取相应技术措施保护用户的账号安全，包括但不限于账号对用户密码加密的方式。同时，智多蛙创客将运用相关安全技术确保其掌握的信息不丢失、不被滥用和变造。但请注意，尽管智多蛙创客将竭尽全力保护用户的账号及信息安全，但在互联网情况下不存在绝对安全的保障措施。</p>
                    <p class="indent">10.2信息资料的来源：</p>
                    <p class="indent">10.2.1智多蛙创客收集用户的任何资料旨在保障用户顺利、有效、安全地进行交易等行为。</p>
                    <p class="indent">10.2.2除用户向智多蛙创客自愿提供的资料外，用户同意智多蛙创客以下列方式收集并核对用户的信息：</p>
                    <p class="indent">（a）通过公开及私人资料来源收集用户的额外资料。</p>
                    <p class="indent">（b）智多蛙创客根据用户在本网站上的行为自动追踪关于用户的相关资料。智多蛙创客将合理利用该相关资料进行本网站用户的人数统计及行为的内部研究，以更好地了解您以及本网站的其他用户，从而提供更好、更完善的服务。</p>
                    <p class="indent">（c）若其他用户或第三方向智多蛙创客发出关于用户在本网站上活动或登录事项的信息，智多蛙创客可以将这些资料收集、整理在用户的专门档案中。</p>
                    <p class="indent">10.3信息披露和使用：</p>
                    <p class="indent">在如下情况发生时，用户同意智多蛙创客披露或使用用户的个人信息：</p>
                    <p class="indent">10.3.1用户同意智多蛙创客利用用户的联系方式与用户联络，并向用户传递与用户相关的信息，包括但不限于行政管理方面的通知、产品提供、有关用户使用智多蛙创客的服务、通讯以及针对性的广告条；</p>
                    <p class="indent">10.3.2为了分析本网站的使用率、改善本网站的内容和产品推广形式，并使本网站的内容、设计和服务更能符合用户的要求，用户同意智多蛙创客使用用户的个人信息。智多蛙创客对个人信息的合理使用能使用户在使用本网站服务时得到更为顺利、有效、安全的体验；</p>
                    <p class="indent">10.3.3智多蛙创客有义务根据有关法律要求向司法机关和政府部门提供用户的个人信息。</p>
                    <p class="indent">10.3.4为了保护用户的合法权益或为防止严重侵害他人合法权益或为了公共利益的需要，智多蛙创客将善意地披露用户的个人信息。</p>
                    <p class="indent">10.3.5用户无权要求智多蛙创客提供其他用户的个人信息，除非司法机关或政府部门根据法律法规并依照法定程序要求智多蛙创客提供。</p>
                    <p class="indent">10.4保密义务：</p>
                    <p class="indent">智多蛙创客及本网站所有用户应当履行保密义务。对在文件签署过程中所获取的对方的商业秘密以及相关权益（包括但不限于客户资料、策划方案、产品知识产权、协议合同、技术文档、账号密码），非经相关权利人许可，不得向第三方披露。关于被授权使用的有关资料、商业秘密等信息，只能在授权指定的范围使用，不得用于其他目的。</p>
                    <p class="indent title">11、其他约定</p>
                    <p class="indent">11.1通知：本协议条款及任何其他的协议、告示或其他关于您使用本服务账号及服务的通知，您同意本网站使用电子方式或通过向您在注册时预留的通讯地址邮寄文件资料的方式通知您。电子方式包括但不限于以电子邮件方式、或于本网站或者合作网站上公布、或无线通讯装置通知等方式。</p>
                    <p class="indent">11.2本协议适用中华人民共和国的法律（不含冲突法）。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关内容将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。</p>
                    <p class="indent">11.3如果您对本协议内容有任何疑问，请发送邮件至我们的客服邮箱：2930608013@qq.com，或来电咨询：027-85555748。</p>
                    <p class="indent">本声明所有修改权、更新权、解释权均归武汉智多蛙教育科技有限公司所有。</p>
            </div>
        </div>
        <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
export default {
  components: { TheHeader, TheFooter },

}
</script>

<style lang="scss">
.legal-declaration {
    .content-body {
        padding-bottom: 40px;
        .detail-title {
            font-size: 24px;
            text-align: center;
            margin-top: 40px;
        }
        .detail-info {
            margin: 40px 0;

            .logo {
                font-weight: 700;
                font-size: 18px;
                color: #2c3e50;
                .el-image {
                    width: 40px;
                    margin-right: 10px;
                }
            }

            .date {
                font-size: 12px;
                color: #a0a0a0;
            }

        }
        .detail-body {
            font-size: 14px;
            line-height: 28px;
            p {
                margin-bottom: 10px;
            }
            .title {
                font-weight: 700;
                margin-top: 35px;
                font-size: 16px;
            }
        }
    }
    
}
</style>