<template>
  <div class="message-center">
      <the-header></the-header>
      <div class="content-body warp">
          <div class="message-list" v-loading="loading">
              <div class="message-manage ub" v-if="messageList">
                <div class="title ub-f1">全部消息（{{total}}条）</div>
                <div class="manage ub ub-ac">
                    <span :class="['manage-switch iconfont icon-manage manage-span', manageText(isManage, 2)]" @click="manage">{{manageText(isManage, 1)}}</span>
                    <span class="manage-delect manage-span" v-if="isManage" @click="delectMessage">删除</span>
                    <el-checkbox v-if="isManage" class="manage-select" v-model="selectAll" label="全选" size="large" @change="selectAllChange" />
                </div>
              </div>
              <div v-if="messageList">
                  <div v-if="messageList.length > 0">
                    <msg-cell :isManage="isManage" :item="item" :index="index" v-for="(item, index) in messageList" :key="index" @checkItem="checkItem"></msg-cell>
                </div>
                <el-empty description="暂无数据" v-else />
              </div>
          </div>
          <the-pagination :total="total" :pageSize="15" @paginationChange="paginationChange"></the-pagination>
      </div>
      <the-footer></the-footer>
  </div>
</template>

<script>
import { messagePageMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import ThePagination from '@/components/ThePagination.vue'
import MsgCell from './components/MsgCell.vue'
import { mapGetters } from 'vuex'
import TheFooter from '@/components/TheFooter.vue'
export default {
  components: { TheHeader, ThePagination, MsgCell, TheFooter },
  data() {
      return {
          loading: false,
          isManage: false,
          messageList: null,
          selectAll: false,
          total: 1,
          page: 1
      }
  },

  created() {
      this.getData()
  },

  computed: {
      ...mapGetters(['getUserInfo']),

      manageText() {
          return function (isManage, type) {
            console.log(type)
            return isManage ? type == 1 ? '取消管理' : 'act' : type == 1 ? '管理' : ''
          }
      }
  },
  methods: {
      /**
       * 管理
       */
      manage() {
          if (this.messageList.length == 0) {
              this.$message({
                type: 'warning',
                message: '暂无数据！'
              })
              return
          }
          this.isManage = !this.isManage
          if (!this.isManage) {
              this.selectAllData(2)
              this.selectAll = false
          }
      },

      /**
       * 获取数据
       */
      getData() {
          this.loading = true
          let params = {
              type: 1,
              userId: this.getUserInfo.uid,
              pageNum: this.page
          }
          messagePageMsg(params).then((res) => {
              console.log(res)
              this.messageList = res.data
              this.total = res.messagesCount
              // 重置消息数量为0
              let userInfo = this.getUserInfo
              userInfo.MessageNum = 0
              this.$store.commit('setUserInfo', userInfo)
              this.loading = false
          }).catch(() => {
              this.loading = false
          })
      },

      /**
       * 选择数据
       */
      checkItem(params) {
          if (!this.isManage) return
          this.messageList[params.index].isCheck = params.val
          for (let i = 0; i < this.messageList.length; i++) {
              if (!this.messageList[i].isCheck) {
                  this.selectAll = false
                  break;
              }

              if (i == this.messageList.length - 1) {
                  this.selectAll = true
              }
          }
      },

      /**
       * 全选按钮
       */
      selectAllChange() {
          if (this.selectAll) {
              this.selectAllData(1)
          } else {
              this.selectAllData(2)
          }
      },

      /**
       * 全选/取消全选
       * @param {Number} type 1 全选 2 取消全选
       */
      selectAllData(type) {
          for (let i = 0; i < this.messageList.length; i++) {
              this.messageList[i].isCheck = type == 1 ? true : false
          }
      },

      /**
       * 删除留言
       */
      delectMessage() {
          let arr = []
          for (let i = 0; i < this.messageList.length; i++) {
              if (this.messageList[i].isCheck) {
                  arr.push(this.messageList[i].rowId)
              }
          }
          if (arr.length == 0) {
              this.$message({
                type: 'warning',
                message: '请选择需要删除的信息！'
              })
              return
          }
          this.$confirm("是否确认删除选中信息?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
                this.loading = true
                let params = {
                    type: 2,
                    rowIds: arr.join('-')
                }
                messagePageMsg(params).then((res) => {
                    console.log(res)
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    this.$router.go(0)
                }).catch(() => {
                    this.loading = false
                })
          })
      },

      /**
       * 切换分页
       * @param {Number} page 分页数
       */
      paginationChange(page) {
          this.page = page
          this.getData()
      }
  }
}
</script>

<style lang="scss">
.message-center {
    .content-body {
        .message-list {
            min-height: 300px;
            margin-top: 35px;
            padding: 0 35px 40px;
            width: 100%;
            box-sizing: border-box;
            background-color: #fff;

            .message-manage {
                height: 70px;
                line-height: 70px;

                .title {
                    font-size: 16px;
                }

                .manage {
                    cursor: pointer;
                    color: #a0a0a0;

                    span.manage-span {
                        padding:0 10px;
                        display: inline-block;
                        position: relative;
                    }

                    span.manage-span::after {
                        content: "";
                        width: 1px;
                        height: 14px;
                        background-color: #d3d3d3;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -7px;
                    }

                    span.manage-switch::before {
                        margin-right: 5px;
                        font-size: 18px;
                        float: left;
                    }

                    span.act {
                        color: var(--el-color-primary);
                    }

                    span.manage-span:last-child:after {
                        display: none;
                    }

                    span.manage-delect {
                        color: red;
                    }

                    .manage-select {
                        margin-left: 10px;

                        .el-checkbox__input {
                            .el-checkbox__inner {
                                width: 16px;
                                height: 16px;
                            }

                            .el-checkbox__inner::after {
                                height: 9px;
                                left: 5px;
                                top: 0px;
                                width: 4px;
                            }
                        }

                        .el-checkbox__label {
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
</style>