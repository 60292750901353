<template>
  <div class="create-work">
      <the-header></the-header>
      <div class="content-body warp">
          <the-breadcrumb :breadcrumbList="breadcrumbList" v-if="breadcrumbList[0]"></the-breadcrumb>
          <el-form :model="formData" label-width="140px">
                <el-form-item label="作品名称：">
                    <el-input v-model="formData.title" placeholder="请输入标题" clearable maxlength="30" />
                </el-form-item>
                <el-form-item label="作品简介：">
                    <el-input
                        v-model="formData.content"
                        :rows="5"
                        type="textarea"
                        placeholder="请输入内容"
                        resize="none"
                        maxlength="300"
                        show-word-limit
                    />
                </el-form-item>
                <el-form-item label="作品所属类别：">
                    <el-radio-group v-model="formData.type" @change="typeChange">
                        <el-radio :label="item.id" size="large" border v-for="(item, index) in types" :key="index">{{ item.name }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="投稿活动：">
                    <el-radio-group :model-value="formData.activityId" v-if="activityList.length > 0">
                        <el-radio @click.native.prevent="activityChange(item.activityId)" :label="item.activityId" size="large" border v-for="(item, index) in activityList" :key="index" >{{ item.title }}</el-radio>
                    </el-radio-group>
                    <div v-else style="color:#bfbfbf">暂无可投稿活动</div>
                </el-form-item>
                <el-form-item label="" v-if="trackList.length > 0">
                    <el-radio-group v-model="formData.trackId" @change="trackChange">
                        <el-radio :label="item.trackId" size="large" border v-for="(item, index) in trackList" :key="index">{{ item.trackName }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="" v-if="groupList.length > 0">
                    <el-radio-group v-model="formData.groupId">
                        <el-radio :label="item.groupId" size="large" border v-for="(item, index) in groupList" :key="index">{{ item.groupName }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="ub" label="标签：" v-if="showLabels">
                    <div class="ub ub-ver">
                        <div class="ub default">
                            <el-button class="label-button" v-for="(item, index) in labelsSelect" :key="index" @click="selectLabel(item)">{{item.mainType}}</el-button>
                        </div>
                        <div class="act-label ub ub-ac">
                            <div>选中的标签：</div>
                            <el-button class="default-button label-button" v-for="(item, index) in formData.actLabels" :key="index">{{item.mainType}}<span class="iconfont icon-close ub ub-ac" @click="delectLabel(index, item)"></span></el-button>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="上传作品：">
                    <el-upload
                        :class="[imgNum == files.length ? 'hide' : '']"
                        :action="url + '/'"
                        list-type="picture-card"
                        :on-progress="handleProgress"
                        :on-preview="handlePictureCardPreview"
                        :on-error="handleAvatarError"
                        :on-success="handleAvatarSuccess"
                        :on-remove="handleRemove"
                        :before-upload="beforeAvatarUpload"
                        :on-exceed="handleExceed"
                        :limit="imgNum"
                        :disabled="isUploadDisabled"
                    >
                        <span class="iconfont ub ub-ac icon-yunshangchuan"></span>
                    </el-upload>
                </el-form-item>
                <div class="footer-button ub ub-pc">
                    <el-button class="default-button" @click="releaseWork(1)" :loading="loading">发布</el-button>
                    <el-button class="" type="info" @click="releaseWork(2)">预览</el-button>
                </div>
          </el-form>
      </div>
      <the-footer></the-footer>
      <preview-dialog :isPreviewDialog="isPreviewDialog" 
                      :previewFiles="previewFiles"
                      :formData="formData"
                      @closeRreviewDialog="closeRreviewDialog">
                    </preview-dialog>
      <el-dialog custom-class="dialog-enlarge" 
                 :model-value="dialogEnlargeVisible" 
                 :before-close="handleEnlargeClose" 
                 title="资源"
                 :close-on-click-modal="false">
                    <video :src="dialogFileUrl" v-if="dialogFileType == 'mp4'" autoplay controls></video>
                    <el-image width="100%" :src="dialogFileUrl" alt="" v-else></el-image>
      </el-dialog>
  </div>
</template>

<script>
import { releaseWorkMsgPost, releaseWorkMsg, activityMsg } from '../../http/api'
import config from '../../http/config'
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import TheBreadcrumb from '@/components/TheBreadcrumb.vue'
import PreviewDialog from './components/PreviewDialog.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TheHeader, TheFooter, TheBreadcrumb, PreviewDialog },
  data() {
      return {
          breadcrumbList: [],
          formData: {
              title: '',
              content: '', // 为空时默认 “这是一个有梦想的作品”
              type: '',
              activityId: '',
              trackId: '',
              groupId: '',
              actLabels: [],
              labels: []
          },
          types: [{
            id: 3,
            name: '机器人编程创作'
          }, {
            id: 4,
            name: 'STEAM创作'
          }, {
            id: 5,
            name: '绘本馆创作'
          }, {
            id: 6,
            name: '科普日视频'
          }],
          activityList: [],
          files: [],
          imgNum: 3,
          loading: false,
          isUploadDisabled: false,
          isPreviewDialog: false,
          previewFiles: [],
          dialogEnlargeVisible: false,
          dialogFileUrl: '',
          dialogFileType: ''
        }
  },

  computed: {
      ...mapGetters(['getUserInfo']),
      pageTitle() {
          return (type) => {
              switch (Number(type)) {
                  case 3:
                      return '机器人编程创作';
                  case 4:
                      return 'STEAM创作';
                  case 5:
                      return '绘本馆创作';
                  case 6:
                      return '科普日视频';
              
                  default:
                      return '';
              }
          }
      },

      labelsSelect() {
          return this.formData.labels.filter((item) => {
              return !item.hide
          })
      },

      /**
       * 赛道列表
       */
      trackList() {
        console.log(this.formData.activityId)
        let index = this.activityList.findIndex((item) => {
            return item.activityId == this.formData.activityId
        })
        console.log(index)
        if (index != -1) {
            return this.activityList[index].trackList
        } 
        return []
      },

      /**
       * 组列表
       */
       groupList() {
        let index = this.trackList.findIndex((item) => {
            return item.trackId == this.formData.trackId
        })
        console.log(index)
        if (index != -1) {
            return this.trackList[index].activityGroupInfo
        } 
        return []
       },

       /**
        * 是否显示标签
        */
        showLabels() {
            return this.formData.type != 5 && this.formData.type != 6
        }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
        vm.breadcrumbList[0] = {
          name: vm.$util.isDefine(from.meta.title) ? from.meta.title : '首页',
          path: from.fullPath
        }
    })
  },

  created() {
      console.log('aaa')
      this.url = config.url
      this.type = this.$route.query.type
      this.breadcrumbList[1] = {
        //   name: this.pageTitle(this.type),
          name: '其它类型创作',
          path: ''
      }
      this.getLabels()
      this.getActivity()
  },

  methods: {
    /**
     * 获取标签
     */
      getLabels() {
          let params = {
              type: 2,
              workType: 3
          }
          releaseWorkMsg(params).then((res) => {
                res.data.map(item => {
                    return item.hide = false
                })
                this.formData.labels = res.data
            })
            .catch((err) => {
                console.log(err)
            });
      },

      /**
       * 创建标签
       */
      createLabel() {
          if (this.formData.label == '') {
              this.$message({
                type: 'warning',
                message: '请输入标签名称！'
              })
              return
          }

          let index = this.formData.labels.findIndex(item => item == this.formData.label)
          console.log(index)
          if (index != -1) {
              this.$message({
                type: 'warning',
                message: '该标签已存在'
              })
              return
          }
          if (this.formData.labels.length == 3) {
              this.$message({
                type: 'warning',
                message: '最多允许输入3个标签'
              })
              return
          }
          this.formData.labels.push(this.formData.label)
          this.formData.label = ''
      },

       /**
         * 获取活动数据
         */
         getActivity() {
            let params = {
                type: 14,
                userId: this.getUserInfo.uid,
                workType: 2
            }
            activityMsg(params).then((res) => {
                    console.log(res)
                    this.activityList = res.data
                })
                .catch((err) => {
                    console.log(err)
                });
        },

        activityClick() {
            console.log('AAA')
        },

        /**
         * 选择活动
         */
         activityChange(id) {
            console.log(id)
            this.formData.activityId = this.formData.activityId ? '' : id
            this.formData.trackId = ''
            this.formData.groupId = ''
            console.log(this.formData)
         },

         /**
          * 选择赛道
          */
          trackChange() {
            this.formData.groupId = ''
          },

          /**
           * 切换所属类别
           */
           typeChange() {
            console.log(this.showLabels)
            if (!this.showLabels) {
                this.formData.actLabels = []
                for (let index = 0; index < this.formData.labels.length; index++) {
                    this.formData.labels[index].hide = false
                    
                }
                console.log(this.formData.labels)
            }
           },

      /**
       * 选择标签
       */
      selectLabel(data) {
          if (this.formData.actLabels.length == 3) {
              this.$message({
                type: 'warning',
                message: '最多允许输入3个标签'
              })
              return
          }
          let actIndex = this.formData.labels.findIndex(item => {
              return item.mainType ==  data.mainType
          })
          this.formData.labels[actIndex].hide = true
          this.formData.actLabels.push(data)
      },

      /**
       * 删除标签
       */
      delectLabel(index, data) {
          let actIndex = this.formData.labels.findIndex(item => {
              return item.mainType ==  data.mainType
          })
          this.formData.labels[actIndex].hide = false
          this.formData.actLabels.splice(index, 1)
      },

      /**
       * 发布/预览作品
       */
      releaseWork(type) {
        console.log(this.formData)
          if(!this.$util.isDefine(this.formData.title)) {
              this.$message({
                type: 'warning',
                message: '请输入作品标题！'
              })
              return
          }

          if(this.formData.type == '') {
              this.$message({
                type: 'warning',
                message: '请选择所属类别！'
              })
              return
          }

          if(this.trackList.length > 0 && this.formData.activityId!= '' && this.formData.trackId == '') {
              this.$message({
                type: 'warning',
                message: '请选择活动赛道！'
              })
              return
          }

          if(this.groupList.length > 0 && this.formData.activityId!= '' && this.formData.groupId == '') {
              this.$message({
                type: 'warning',
                message: '请选择赛道组！'
              })
              return
          }

          if(this.formData.actLabels.length == 0 && this.showLabels) {
              this.$message({
                type: 'warning',
                message: '请选择标签！'
              })
              return
          }

          if (this.files.length == 0) {
              this.$message({
                type: 'warning',
                message: '请上传作品！'
              })
              return 
          }
        if (type == 1) {
            this.releaseWorkHttp()
        } else {
            this.previewTap()
        }
      },

      releaseWorkHttp() {
        this.loading = true
        var params = new FormData();
        params.append("type", "1");
        params.append("workType", this.formData.type);
        params.append("userId", this.getUserInfo.uid);
        params.append("name", this.formData.title);
        params.append("activityId", this.formData.activityId);
        params.append("trackId", this.formData.trackId);
        params.append("groupId", this.formData.groupId);
        params.append("workSynopsis", this.formData.content ? this.formData.content : '这是一个有梦想的作品！');
        let tagArr = []
        for (let index = 0; index < this.formData.actLabels.length; index++) {
            tagArr.push(this.formData.actLabels[index].mainType)
        }
        params.append("tag", tagArr.join('_'));
        console.log(this.files)
        console.log(params.values)
        if (this.files.length > 0) {
            for(var i in this.files) {
                if (this.files[i].raw.type == 'video/mp4') {
                    params.append("video", this.files[i].raw);
                } else {
                    params.append("img", this.files[i].raw);
                }
            }
        }
        releaseWorkMsgPost(params).then((res) => {
                console.log(res);
                this.loading = false;
                this.$message({
                    message: "发布成功",
                    type: "success",
                    showClose: true
                });
                setTimeout(() => {
                    // this.$router.push(`/work-detail?pid=${res.data.projectId}&uuid=${this.getUserInfo.uid}`)
                    this.$router.push(`/personal-center`)
                }, 1500)
            })
            .catch((err) => {
                console.log(err)
                this.loading = false;
            });
      },

      handleProgress() {
          this.isUploadDisabled = true
      },

        // 获取资源成功
      handleAvatarSuccess(res, file) {
            console.log(res)
            console.log(file)
            this.files.push(file)
            this.isUploadDisabled = false
            console.log(this.files)
        },

      //  获取资源失败
        handleAvatarError() {
            this.$message({
                showClose: true,
                message: '获取资源失败!',
                type: 'error'
            });
          this.isUploadDisabled = false
        },

        // 删除图片资源
        handleRemove(e) {
            let index = this.files.findIndex(item => {
                return item.uid == e.uid
            })
            this.files.splice(index, 1)
        },
        
        // 上传图片限制
        beforeAvatarUpload(file) {
            this.userImageLoading = true;
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isMP4 = file.type === "video/mp4";
            const isLt2M = file.size / 1024 / 1024 < 2;
            const isLt10M = file.size / 1024 / 1024 < 10;

            if (!isJPG && !isPNG && !isMP4) {
                this.$message.warning("上传资源只能是 JPG或者PNG或者MP4 格式!");
                this.userImageLoading = false;
            }

            if ((isJPG || isPNG) && !isLt2M) {
                this.$message.warning("上传图片大小不能超过 2MB!");
                this.userImageLoading = false;
            }

            if (isMP4 && !isLt10M) {
                this.$message.warning("上传视频大小不能超过 10MB!");
                this.userImageLoading = false;
            }

            const index = this.files.findIndex(item => {
                return item.raw.type == 'video/mp4'
            })
            const isOneVideo = index === -1

            if (!isOneVideo && isMP4) {
                this.$message.warning("视频资源只能上传一个!");
                this.userImageLoading = false;
            }

            return ((isJPG || isPNG) && isLt2M) || (isMP4 && isLt10M) && (isOneVideo);
        },

        handleExceed() {
            // ...
        },

        handlePictureCardPreview(e) {
            // ...
            console.log(e.url)
            console.log(e)
            let fileNameArr = e.name.split('.')
            let fileType = fileNameArr[fileNameArr.length - 1]
            this.dialogFileUrl = e.url
            this.dialogFileType = fileType
            this.dialogEnlargeVisible = true

        },

        handleImageClose() {
            this.dialogEnlargeVisible = false
        },

        previewTap() {
            console.log(this.files)
            let images = this.files.filter((item) => {
                return item.name.indexOf('jpg') != -1 || item.name.indexOf('png') != -1
            })
            let previewImages = []
            for (let i = 0; i < images.length; i++) {
                previewImages.push({type: 2, url: images[i].url})
            }

            let video = this.files.filter((item) => {
                return item.name.indexOf('mp4') != -1
            })
            let previewVideo = []
            if (video.length > 0) {
                previewVideo = [{
                    type: 1,
                    url: video[0].url
                }]
            }
            this.previewFiles = previewVideo.concat(previewImages)
            console.log(this.previewFiles)

            this.isPreviewDialog = true
        },

        closeRreviewDialog() {
            this.isPreviewDialog = false
        },

        findvideocover (url, file) {
            const video = document.getElementById("upvideo") // 获取视频对象
            // const video = document.createElement("video") // 也可以自己创建video
            video.src = url // url地址 url跟 视频流是一样的
            var canvas = document.getElementById("mycanvas") // 获取 canvas 对象
            const ctx = canvas.getContext("2d") // 绘制2d
            video.crossOrigin = "anonymous" // 解决跨域问题，也就是提示污染资源无法转换视频
            video.currentTime = 1 // 第一帧
            video.oncanplay = () => {
                canvas.width = video.clientWidth // 获取视频宽度
                canvas.height = video.clientHeight //获取视频高度
                // 利用canvas对象方法绘图
                ctx.drawImage(video, 0, 0, video.clientWidth, video.clientHeight)
                // 转换成base64形式
                this.jieimgurl = canvas.toDataURL("image/png") // 截取后的视频封面
                file.url = this.jieimgurl
                console.log(this.jieimgurl)
            }
        }
  },

  watch: {
      $route: {
          handler() {
            this.$router.go(0)
          },
          deep: true
      }
  }
}
</script>

<style lang="scss">
.create-work {
    .el-form {
        .el-form-item {
            .el-form-item__label {
                width: 92px;
                line-height: 40px;
                text-align: left;
                color: #000000;
                font-size: 16px;
            }
            .el-form-item__content {
                .el-radio-group {
                    .el-radio {
                        background-color: #ffffff;
                    }
                }
                .el-input {
                    line-height: 40px;
                    input {
                        height: 40px;
                        line-height: 40px;
                    }
                }

                .label-button {
                    margin-top: 10px;
                    position: relative;

                    span.icon-close {
                        display: flex;
                        width:15px;
                        height: 15px;
                        position: absolute;
                        background-color: #ccc;
                        right: -5px;
                        top: -5px;
                        border-radius: 50%;
                        font-size: 12px;
                    }
                }

                .el-upload {
                    .icon-yunshangchuan {
                        font-size: 28px;
                    }
                }

                .hide {
                    .el-upload {
                        display: none;
                    }
                }

                .act-label {
                    margin-top: 15px;
                    .el-button {
                        margin-top: 0;
                    }
                }
            }
        }

        .footer-button {
            .el-button {
                width: 288px;
                height: 40px;
                font-size: 16px;
            }
        }
    }
}

.dialog-enlarge {
    video {
        width: 100%;
    }

    .el-image {
        width: 100%;
    }
}
</style>