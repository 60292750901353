<template>
  <div class="activity-detail" :style="{height: bodyHeight + 'px'}">
    <el-scrollbar :style="{height: bodyHeight + 'px'}">
        <the-header></the-header>
        <div :class="['content-body', headerInfo.class]" v-if="headerInfo">
            <component v-bind:is="activityModel(headerInfo.types)" 
                :headerInfo="headerInfo"
                :activityIntro="activityIntro"
                :params="params"
                @createdWork="createdWork"
                @openPage="openPage"
                @contributionChange="contributionChange">
            
            </component>
        </div>
        <contribution-dialog :isContributionDialog="isContributionDialog" @closeContributionDialog="closeContributionDialog" :activityId="params.activityId"></contribution-dialog>
        <el-dialog
            v-model="isCreatedDialog"
            title="选择作品创作类型"
            width="30%"
            :before-close="handleClose"
        >
            <!-- 1 scratch作品  2 其他创作类型  3 scratch跟其他创作类型 -->
            <el-button @click="createdWorkTap(1)" v-if="headerInfo.activityWorkType == 1 || headerInfo.activityWorkType == 3">scratch作品创作</el-button>
            <el-button @click="createdWorkTap(2)" v-if="headerInfo.activityWorkType == 2 || headerInfo.activityWorkType == 3">其它类型作品创作</el-button>
        </el-dialog>
    </el-scrollbar>
  </div>
</template>

<script>

import config from '../../http/config'
import QRCode from 'qrcodejs2'
import { activityMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import { mapGetters } from 'vuex'
import ContributionDialog from './components/ContributionDialog.vue'
import ActivityModelOne from './components/detailTemplate/ActivityModelOne.vue'
import ActivityModelTwo from './components/detailTemplate/ActivityModelTwo.vue'
export default {
    components: { TheHeader, ContributionDialog, ActivityModelOne, ActivityModelTwo },
    data() {
        return {
            headerInfo: null,
            activityIntro: null,
            isContributionDialog: false,
            bodyHeight: 300,
            flag: true,
            showCanvas: false,
            isCreatedDialog: false
        }
    },

    created() {
        console.log(config)
        this.params = this.$route.query
        this.getActivityInfo()
        this.bodyHeight = document.body.clientHeight

    },

    mounted() {

    },

    computed: {
        ...mapGetters(['getUserInfo']),
        isLInk() {
            return function(link) {
                return link.indexOf('http') != -1
            }
        },
        activityModel() {
            return function(type) {
                switch (Number(type)) {
                    case 1:
                        return 'ActivityModelOne'
                    case 2:
                        return 'ActivityModelTwo'
                
                    default:
                        return '';
                }
            }
        }
    },

    methods: {

        /**
         * 获取该活动banner图及活动简介等信息
         */
        getActivityInfo() {
            this.loading = true
            activityMsg({
                type: 6,
                activityId: this.params.activityId,
                userId: this.getUserInfo ? this.getUserInfo.uid : '',
                state: this.params.state
            }).then((res) => {
                console.log(res)
                this.loading = false
                this.headerInfo = res.data
                this.activityIntro = res.data.detailsUrl
                console.log('bbb')
                this.$nextTick(() => {
                    console.log('aaa')
                    document.getElementById('qrcodeContainer').innerHTML = ''
                    this.createQrcode('qrcodeContainer')
                    if (res.data.activityEncourage) {
                        this.getCanvas()
                    }
                })
            }).catch((err) => {
                console.log(err)
                this.loading = false
                this.loadErr = true
            })
        },

        /**
         * 分页
         * @param {Number} page 分页数
         */
        paginationChange(page) {
            console.log(page);
            this.page = page;
            this.getData();
        },

        /**
         * 投稿
         */
        contributionChange() {
            if(!this.getUserInfo) {
                this.$store.commit('setLoginAlertState', true)
                return
            }

            /* if (this.headerInfo.whetherContribute == 1) { // 0未投稿 1已投稿
                return
            } */
            this.isContributionDialog = true
        },

        /**
         * 关闭投稿弹窗
         */
        closeContributionDialog(isRefresh) {
            this.isContributionDialog = false
            if(isRefresh) {
                // this.handleClick(this.activeName)
                this.getActivityInfo()
            }
        },

        openPage(link) {
            if (this.isLInk(link) && this.flag) {
                console.log('aaaaaa')
                window.open(link, "_blank");
                this.flag = false
            }

            setTimeout(() => {
                this.flag = true
            }, 2000)
        },

        createdWork(type) {
            this.isCreatedDialog = true
        },

        createdWorkTap(type) {
            if (type == 1) {
                window.open(config.scratchUrl.create, "_blank");
            } else {
                if(!this.getUserInfo) {
                    this.$store.commit('setLoginAlertState', true)
                    return
                }
                this.$router.push('/create-index')
            }
        },

        /**
         * 生成二维码
         */
        createQrcode(id) {
            this.relayAddress = `${config.scratchUrl.activityPhone}&id=${this.params.activityId}`
            console.log(this.relayAddress)
            let phoneCode = new QRCode(id, {
                    text: this.relayAddress, // 二维码地址
                    colorDark: "#000",
                    colorLight: "#fff",
            });
            console.log(phoneCode)

        },

        /**
         * 证书canvas
         */
        getCanvas() {
            // 获取canvas
            var canvas = document.getElementById("certificateCanvas");
            // 获取canvas上下文
            var ctx = canvas.getContext("2d");

            var img = new Image();
            // img.src = require('../../assets/image/spring_certificate.png');
            img.crossOrigin = 'anonymous';
            img.src = this.headerInfo.activityEncourage.certificateUrl;
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                if (this.headerInfo.activityEncourage.rank > 3) {
                    ctx.font = "26px Arial";
                    // 绘制姓名
                    ctx.fillStyle = "#565656";
                    const name = this.headerInfo.activityEncourage.userName;
                    const textWidth = ctx.measureText(name).width;
                    ctx.fillText(name, 112, 354);
                    // 绘制下滑线
                    const lineHeight = 2; // 下划线的高度
                    ctx.fillRect(112, 363, textWidth, lineHeight);
                    // 绘制"同学"文字
                    ctx.fillText("同学：", 112 + 10 + textWidth, 354);
                    // 绘制编号
                    ctx.fillStyle = "#ffffff";
                    ctx.font = "20px Arial";
                    ctx.fillText(this.headerInfo.activityEncourage.number, 902, 103);
                }
                this.showCanvas = true
            };
        },

        /**
         * 证书下载
         */
         downloadCertificateTap() {
            var canvas = document.getElementById("certificateCanvas");
            console.log(canvas)
            var dataURL = canvas.toDataURL("image/png");
            var link = document.createElement("a");
            link.href = dataURL;
            link.download = `${ this.headerInfo.activityEncourage.activityName }_${this.headerInfo.activityEncourage.certificateName}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
         }
    }
}
</script>

<style lang="scss">
.activity-detail {
    overflow-y: scroll;
    .activity-header {
        height: 451px;
        position: relative;
        background-color: #ffffff;
        z-index: 9;
        .activity-header-pic {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;

            img {
                min-width: 1192px;
            }
        }
        .activity-header-info {
            position: relative;
            z-index: 2;
            border: 1px solid transparent;
            color: #fff;

            .activity-header-title {
                font-size: 36px;
                font-weight: 400;
                margin-top: 86px;
            }

            .activity-header-num {
                width: 100px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: rgba($color: #000000, $alpha: 0.3);
                border-radius: 5px;
                margin: 23px 0;
            }

            .activity-handle {
                margin-top: 75px;
                .el-button {
                    width: 155px;
                    height: 51px;
                    font-size: 18px;
                    font-weight: 400;
                    margin-right: 32px;
                    .small-size {
                        font-size: 12px;
                    }
                }

                .el-button.created {
                    background-color: #00e7c9;
                    border-color: #00e7c9;
                }

                .el-button.created:hover {
                    background-color: #02c8ae;
                    border-color: #02c8ae;
                }

                .share {
                    font-size: 18px;
                    height: 51px;
                    cursor: pointer;
                    .share-ewm {
                        display: none;
                        width: 168px;
                        background-color: #fff;
                        position: absolute;
                        left: 0;
                        top: 51px;
                        color: #000000;
                        text-align: center;
                        z-index: 99;
                        padding: 0 10px 10px;
                        box-sizing: border-box;
                        .text {
                            line-height: 40px;
                            font-size: 14px;
                        }

                        img {
                            width: 100%;
                        }
                    }
                }

                .share::before {
                    font-size: 28px;
                    float: left;
                    margin-right: 6px;
                }

                .share:hover {
                    .share-ewm {
                        display: block;
                    }
                }

                .download-certificate {
                    font-size: 18px;
                    margin-left: 20px;
                    cursor: pointer;
                }

                .download-certificate::before {
                    font-size: 26px;
                    font-weight: bold;
                    margin-right: 5px;
                }

                #certificateCanvas {
                    position: absolute;
                    z-index: -1;
                    opacity: 0;
                    left: -99999px;
                }
            }
            .activity-download {
                margin-top: 20px;
                a {
                    display: block;
                    line-height: 34px;
                    font-size: 18px;
                    text-decoration: underline;
                    color: #000000;
                }
                a:before {
                    font-size: 24px;
                    margin-right: 5px;
                    font-weight: bold;
                }
            }
        }
    }
    .activity-tabs {
        padding-bottom: 15px;
        .el-tabs__header {
            background-color: #ffd100;
            margin-bottom: 43px;
            .el-tabs__nav-wrap {
                .el-tabs__nav-scroll {
                    .el-tabs__nav {
                        width: 1191px;
                        margin: 0 auto;
                        float: unset;
                        .el-tabs__active-bar {
                            display: none;
                        }
                        .el-tabs__item {
                            width: 25%;
                            height: 65px;
                            line-height: 65px;
                            text-align: center;
                            font-weight: bold;
                            font-size: 20px;
                            color: #fff;
                        }
                        .el-tabs__item.is-active {
                            background-color: #e55900;
                        }
                    }
                }
            }

            .el-tabs__nav-wrap::after {
                display: none;
            }
        }

        .el-tabs__content {
            width: 1191px;
            margin: 0 auto;
            padding: 0 38px;
            box-sizing: border-box;
            background-color: #fff;
            .el-tab-pane {
                .el-loading-mask {
                }

                .pagination-model {
                    background-color: unset;
                }
            }

            .activity-intro {
                // overflow: scroll;
                width: 1000px;
                margin: 0 auto;
                padding: 15px 0 0;
                .el-image {
                    min-height: 30px;
                    display: block;
                }
                .el-image.link {
                    cursor: pointer;
                }
            }

            .all-work {
                .work-list {
                    width: 100%;
                    min-height: 200px;
                    .work-item {
                        width: 206px;
                    }

                    .work-item.last {
                        margin-right: 0;
                    }
                }
            }

            .participant {
                .user-list {
                    .user-cell.last {
                        margin-right: 0;
                    }
                }
            }
        }
    }



    /* 春天踏青主题 */
    .spring-theme {
        .activity-header {
            .activity-header-title {
                color: #1a7e68;
            }

            .activity-header-summary {
                color: #000000;
            }

            .activity-handle {
                .el-button.created {
                    background-color: #008057;
                    border-color: #008057;
                    span {
                        color: #fff;
                    }
                }

                .el-button.created:hover {
                    background-color: #019968;
                    border-color: #019968;
                }
            }
        }

        .activity-tabs {
            background-color: #89e578;
            .el-tabs__header {
                background-color: #22a04f;
                .el-tabs__nav-wrap {
                    .el-tabs__nav-scroll {
                        .el-tabs__nav {
                            .el-tabs__item.is-active {
                                background-color: #59c263;
                            }
                        }
                    }
                }
                
            }
            
        }

        .activity-tabs.detail {
            .el-tabs__content {
                background-color:unset;
            }
        }
    }

     /* 缤纷校园活动 */
     .colorful-campus {
        .activity-header {
            .activity-header-title {
                color: #1e4892;
            }

            .activity-header-summary {
                color: #ffffff;
                width: 50%;
            }

            .activity-handle {
                margin-top: 55px;
                .el-button.created {
                    background-color: #015f9d;
                    border-color: #015f9d;
                    span {
                        color: #fff;
                    }
                }

                .el-button.created:hover {
                    background-color: #005187;
                    border-color: #005187;
                }
            }
        }

        .activity-tabs {
            background-color: #017cca;
            .el-tabs__header {
                background-color: #015f9d;
                .el-tabs__nav-wrap {
                    .el-tabs__nav-scroll {
                        .el-tabs__nav {
                            .el-tabs__item.is-active {
                                background-color: #4caadd;
                            }
                        }
                    }
                }
                
            }
            
        }

        .activity-tabs.detail {
            .el-tabs__content {
                background-color:unset;
            }
        }
     }
}
</style>