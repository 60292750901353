<template>
  <div class="about-company">
      <div class="title">智多蛙公司简介</div>
      <div class="body">
        <p class="indent">武汉智多蛙教育科技有限公司成立于2019年，是武汉市英才世家教育科技有限公司的全资子公司，是集专业少儿编程、动植物研学、社会实践等综合素养教育为一体的新型教育体系。</p>
        <p class="indent">公司形成了以创意编程启蒙、人工智能编程、机器人创新工程教育、科技美育教育等课程为核心的课程体系，并紧跟国家政策，结合基于对中小学生农业劳动课的研究，大力开展动植物研学旅行课程设计、开发，并提供研究性学习课程等特色研学产品的研发和执行服务。致力于打造出适合3到18岁中国孩子的综合科学素养教育解决方案。</p>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.about-company {

    .body {

    }
}
</style>