<template>
  <div :class="['class-news', getUserInfo.role == 2 ? 'teacher' : '']">
    <div class="" v-if="list.length > 0">
        <div class="news-item ub" v-for="(item, index) in list" :key="index">
            <div class="news-item-time">{{createdTime(item.createTime)}}</div>
            <div class="news-item-title ub-f1" v-html="newTitle(item)"></div>
            <div :class="['news-item-top', item.topStatus == 1 ? 'top' : '']" @click="newToTop(item)" v-if="item.topStatus == 1 || getUserInfo.role == 2">置顶</div>
        </div>
    </div>
    <the-empty v-else></the-empty>
  </div>
</template>

<script>
import { teacherMsg } from '../../../http/api'
import TheEmpty from '@/components/TheEmpty.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TheEmpty },
    props: {
        classData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },

    data() {
        return {
            list: []
        }
    },

    created() {
        console.log(this.list)
        this.initData()
    },

    computed: {
        ...mapGetters(['getUserInfo']),
        createdTime() {
            return (time) => {
                return this.$util.formateDateTimesToZh(time, 1)
            }
        },

        newTitle() {
            return (item) => {
                let contentArr = item.content.split('♆')
                let strHtml = ''
                switch (item.type) {
                    case 1: // xx创建班级xx
                    case 10: // xx将班级更名为：xx
                        strHtml = `<span class="user-name"><a href="/#/personal-center?uid=${item.userId2}">${contentArr[0]}</a></span>${contentArr[1]}<span class="class-name">${contentArr[2]}</span>`
                        break;
                    case 2: // xx加入了班级
                        strHtml = `<span class="user-name"><a href="/#/personal-center?uid=${item.userId}">${contentArr[0]}</a></span>${contentArr[1]}`
                        break;
                    case 3: // xx提交了作品xx
                    case 9: // xx点评项目作品xx
                        strHtml = `<span class="user-name"><a href="/#/personal-center?uid=${item.type == 3 ? item.userId : item.userId2}">${contentArr[0]}</a></span>${contentArr[1]}<span class="work-name"><a href="/#/work-detail?pid=${item.projectId}&uuid=${item.userId}">${contentArr[2]}</a></span>`
                        break;
                    case 4: // xx学生被移除班级
                        strHtml = `<span class="user-name"><a href="/#/personal-center?uid=${item.userId}">${contentArr[0]}</a></span>${contentArr[1]}`
                        break;
                    case 0: // xx发布项目任务xx
                    case 5: // xx编辑项目任务xx
                    case 6: // xx删除项目任务xx
                        strHtml = `<span class="user-name"><a href="/#/personal-center?uid=${item.userId2}">${contentArr[0]}</a></span>${contentArr[1]}<span class="project-name project-name${item.type}">${contentArr[2]}</span>`
                        break;
                    case 7: // 项目xx已开始
                        strHtml = `项目<span class="project-name"><a href="/#/project-detail?cid=${item.classNum}&cpid=${item.projectId}">${contentArr[0]}</a></span>${contentArr[1]}`
                        break;
                    case 8: // xx项目已结束
                        strHtml = `项目<span class="project-name"><a href="/#/project-detail?cid=${item.classNum}&cpid=${item.projectId}">${contentArr[0]}</a></span>${contentArr[1]}${contentArr[2]}`
                        break;
                    default:
                        break;
                }
                return strHtml
            }
        }
    },

    methods: {
        initData() {
            console.log(this.getUserInfo.role)
            console.log(this.classData)
            if (this.getUserInfo.role == 0) {
                this.list = this.classData.message
            } else if (this.getUserInfo.role == 2) {
                this.list = this.classData.classList
            }
        },

        newToTop(item) {
            if (this.getUserInfo.role != 2 || item.topStatus == 1) {
                return
            }
            let params = {
                type: 8,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                cid: item.classNum,
                rid: item.rowId,
            }
            teacherMsg(params)
                .then((res) => {
                console.log(res);
                this.$message({
                    type: 'success',
                    message: '置顶成功'
                })
                setTimeout(() => {
                    this.$router.go(0)
                }, 1500)
            })
            .catch(() => {
            });
        }
    },

    watch: {
        classData() {
            this.initData()
        }
    }
}
</script>

<style lang="scss">
.class-news {
    .news-item {
        margin-bottom: 15px;
        .news-item-title {
            margin-left: 10px;
            span {
                font-weight: bold;
                margin: 0 3px;
                cursor: pointer;
                a {
                    color: #000000;
                }
            }
            span:hover {
                text-decoration: underline;
            }
            span.user-name {
                // color: var(--el-color-second);
            }

            span.class-name {
                color:var(--el-color-four-hover)
            }

            span.project-name {
                a {
                    color: var(--el-color-second);
                }
            }

            span.work-name {
                a {
                    color: var(--el-color-primary);
                }
            }
        }

        .news-item-top {
            color: var(--el-color-primary);
            font-weight: bold;
        }
    }
}

.class-news.teacher {
    .news-item {
        .news-item-top {
            display: none;
            cursor: pointer;
        }

        .news-item-top.top {
            display: block;
            cursor: default;
        }
    }
    .news-item:hover {
        .news-item-top {
           display: block;
        }
    }
}
</style>