<template>
  <div class="work-item-small ub" >
      <el-image class="work-item-pic" :src="item.IMG" fit="cover" @click="courseDetail(item.PROJECT_ID,item.USER_ID)"></el-image>
      <div class="work-item-info ub ub-ver">
          <div class="work-title ub ub-ac" :title="item.NAME" @click="courseDetail(item.PROJECT_ID,item.USER_ID)"><div class="text-hidden-2 ">{{item.NAME}}</div></div>
          <like-info :item="item"></like-info>
      </div>
  </div>
</template>

<script>
import LikeInfo from './LikeInfo.vue'
export default {
  components: { LikeInfo },
  props: {
      item: {
          type: Object,
          default: () => {
              return {}
          }
      }
  },

  created() {
      console.log(this.item)
  },
  methods:{
      courseDetail(pid, uuid){
        this.$router.push({
          path:"/course-detail",
          query: {
            pid: pid,
            uuid: uuid,
          }
        })
        
    }
  }

}
</script>

<style lang="scss">
.work-item-small {
    width: 254;
    .work-item-pic {
        width: 124px;
        height: 93px;
        background-color: #ccc;
    }

    .work-item-info {
        width: 130px;
        .work-title {
            height: 55px;
            padding: 0 15px;
        }

        .like-info {
            padding-left: 15px;
        }
    }
}

</style>