<template>
  <div class="about-us">
    <the-header></the-header>
    <div class="content-body warp ub ub-be">
      <left-menu></left-menu>
      <left-menu-content>
        <router-view />
      </left-menu-content>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import LeftMenu from "@/components/LeftMenu.vue";
import LeftMenuContent from "@/components/LeftMenuContent.vue";
import TheFooter from '@/components/TheFooter.vue';
export default {
  components: { TheHeader, LeftMenu, LeftMenuContent, TheFooter },
  created() {
    if (this.$route.path == "/about-us") {
      this.$router.replace("/about-us/about-company");
    }
  },

  watch: {
    $route(to) {
      //这里可以加你监听到路由改变时要触发的方法
      console.log(to.path);
      if (to.path == "/about-us") {
        this.$router.replace("/about-us/about-company");
      }
    },
  },
};
</script>

<style lang="scss">
.about-us {
  .content-body {
    margin-top: 40px;
  }
}
</style>