<template>
  <el-dialog 
    :model-value="isRuleDialog"
    custom-class="default-dialog rule-dialog"
    title="任务规则"
    :before-close="handleClose">
        <p class="title">赶快开启积累金币之旅吧</p>
        <p>完成对应任务，就能获得金币奖励，用金币奖励去商城兑换你需要的素材吧！</p>
        <p class="sub-title">每日任务</p>
        <p>每天开放，每天登录后签到、点赞收藏即可获得金币</p>
        <p class="sub-title">每周任务</p>
        <p>每周创作的第一个作品，即可获得对应的金币奖励</p>
        <p class="sub-title">长期任务</p>
        <p>长期坚持创作以及和其他作者互动，不仅能获得金币奖励，还能提示自己的创作水平。</p>
        <p class="sub-title">素材兑换</p>
        <p>积累够一定的金币后，在商城兑换需要的素材，兑换成功后会扣除相应的金币，素材会不定期更新，敬请期待！</p>
  </el-dialog>
</template>

<script>
export default {
    props: {
        isRuleDialog: {
            type: Boolean,
            defailt: false
        }
    },

    methods: {
        handleClose() {
            this.$emit('closeRuleDialog')
        }
    }
}
</script>

<style lang="scss">
.rule-dialog {
    .el-dialog__body {
        padding-top: 10px;
        padding-bottom: 20px;
    }
    p {
        margin-bottom: 15px;
    }
    .title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
    }

    .sub-title {
        font-weight: bold;
        font-size: 16px;
    }
}
</style>