<template>
  <el-dialog
      :model-value="isBigImageDialog"
      :close-on-click-modal="false"
      width="600px"
      title="查看大图"
      custom-class="default-dialog big-image-dialog"
      :before-close="handleClose">
        <el-image :src="src" v-if="!isCloseMouseRight" class="1"></el-image>
        <el-image :src="src" @contextmenu="handlePaste($event)" v-else class="2"></el-image>
   </el-dialog>
</template>

<script>
export default {
    props: {
        isBigImageDialog: {
            type: Boolean,
            default: false
        },

        src: {
            type: String,
            default: ''
        },

        isCloseMouseRight: { // 是否禁止右键下载
            type: Boolean,
            default: false
        }
    },

    methods: {
        handleClose() {
            this.$emit('closeBigImageDialog')
        },

        handlePaste (event) {
            console.log('aaa')
            // 禁用鼠标右键
                event.preventDefault()
                return false
            
        }
    },

    watch: {
        isBigImageDialog(newVal) {
            console.log(newVal)
        },

        isCloseMouseRight(newVal) {
            console.log(newVal)
        }
    }
}
</script>

<style lang="scss">
.big-image-dialog {
    .el-image {
        width: 100%;
        text-align: center;
    }
}
</style>