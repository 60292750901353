<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    if (this.$route.path == "/course-index") {
      this.$router.replace("/course-index/course-public");
    }
    if(this.$route.path=="/course-index/course-center"){
      window.open('http://codefrog.zdwkj.com/', '_blank');
    }
  },

  mounted() {

  },

  methods: {

  },

  watch: {
    $route(to, from) {
      //这里可以加你监听到路由改变时要触发的方法
      if (to.path == '/course-index') {
          this.$router.replace('/course-index/course-public')
      }
    }
  },
}
</script>

<style>

</style>