<template>
  <div class="match-item" @click.stop="itemclick(matchData.rowId)">
    <div class="match-img"><el-image class="a-img" :src="matchData.url" fit="cover" /></div>
    <div class="match-txt">
      <div :class="matchData.state!=1?'match-zhuangtai0':'match-zhuangtai1'">{{state}}</div>
      <div class="match-h1">{{matchData.title}}</div>
      <div class="match-describe">
        {{matchData.describe}}
      </div>
      <div class="match-time">比赛时间:{{matchData.startTime}}-{{matchData.endTime}}</div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'

export default {
  created() {
    if(this.matchData.state==1)
    {
      this.state='进行中'
    }
    else if(this.matchData.state==0){
      this.state='未开始'
    }
    else if(this.matchData.state==-1){
      this.state='已结束'
    }
  },
  props: {
    matchData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      state:''
    };
  },
  methods:{
    itemclick(rowId){
      if(this.matchData.state==-1)
      {
        this.$router.push({
          path:"/end-detail",
          query: {
            rowId:rowId,
            state:this.matchData.state
          }
        })
      }
      else{
        ElMessage.error('比赛未结束！');
        return
      }
      
      
    }
  }
};
</script>
<style lang="scss">
.match-item {
  width: 95%;
  height: 240px;
  border-radius: var(--item-border-radius);
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px -2px 0px 0px #e5e5e5, -2px 0px 0px 0px #e5e5e5,
    2px 0px 0px 0px #e5e5e5, 0px 2px 0px 0px #e5e5e5;
  margin-top: 20px;
  cursor: pointer;
  .match-img {
    width: 300px;
    height: 150px;
    margin-top: 45px;
    margin-left: 30px;
    border-radius: 10px;
    .a-img{
      width: 300px;
      height: 150px;
      justify-content: center;
    }
  }
  .match-txt {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 45px;
    margin-left: 30px;
    height: 150px;
    .match-h1 {
      width: 100%;
      height: 33%;
      font-size: 30px;
      font-weight: bold;
    }
    .match-describe {
      width: 100%;
      font-size: 8px;
      height: 53%;
    }
    .match-time {
      width: 100%;
      height: 13%;
    }
  }
  .match-zhuangtai1 {
    position: absolute;
    width: 150px;
    height: 35px;
    background-color: #ffd101;
    border-radius: 35px 0 0 35px;
    text-align: center;
    line-height: 35px;
    margin-left: 622px;
    margin-top: -30px;
  }
  .match-zhuangtai0 {
    position: absolute;
    width: 150px;
    height: 35px;
    background-color: #D2D2D2;
    border-radius: 35px 0 0 35px;
    text-align: center;
    line-height: 35px;
    margin-left: 622px;
    margin-top: -30px;
  }
}
</style>