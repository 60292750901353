<template>
  <iframe id="work-iframe" :src="url" frameborder="0" v-if="url"></iframe>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss">
#work-iframe {
    width: 100%;
    height: 100%;
}
</style>