<template>
  <div class="down-product">
    <the-header></the-header>
    <div class="content-body ub" v-loading="loading">
      <div :class="['down-product-module ub-f1', downList && downList.length == 0 ? 'ub ub-pc' : '']" v-if="downList">
        <div v-if="downList.length > 0">
          <div
            class="down-product-item"
            v-for="(item, index) in downList"
            :key="index"
          >
            <div class="warp ub ub-ac ub-be">
              <el-image :src="item.pictureUrl" fit="cover" />
              <div class="down-product-info">
                <div class="product-title">{{ item.title }}</div>
                <div
                  class="product-summary"
                  v-html="productSummary(item.describe)"
                ></div>
                <div class="product-down-list">
                  <div
                    class="product-down-item ub"
                    v-for="(subItem, subIndex) in item.resourcesList"
                    :key="subIndex"
                  >
                    <el-button class="default-button"
                      ><a class=" iconfont icon-down" :href="subItem.fileName" target="_blank" rel="noopener noreferrer">立即下载<span class="tip">{{ subItem.name }}</span></a></el-button
                    >
                    <span class="version ub ub-ae">当前版本：{{ subItem.versionName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-empty description="暂无数据" v-else />
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { resourcesMsg } from '../../http/api'
import TheHeader from "@/components/TheHeader.vue";
import { mapGetters } from 'vuex';
import TheFooter from '@/components/TheFooter.vue';
export default {
  components: { TheHeader, TheFooter },
  data() {
    return {
      loading: false,
      downList: null
    };
  },

  computed: {
    ...mapGetters(['getUserInfo']),

    productSummary() {
      return function (summary) {
        if (summary) {
          console.log(summary) 
          let summaryArr = summary.split("&");
          let summaryStr = "";
          for (let i = 0; i < summaryArr.length; i++) {
            summaryStr = summaryStr + "<p>" + summaryArr[i] + "<p>";
          }
          return summaryStr;
        } else {
          return ''
        }
        
      };
    },
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.loading = true
      let params = {
        type: 1,
        resourceType: 1,
        userId: this.getUserInfo ? this.getUserInfo.uid : '',
        schoolId: '',
        oneLevelId: 0,
        twoLevelId: 0
      }
      resourcesMsg(params).then((res) => {
        console.log(res)
        this.loading = false
        this.downList = res.data.list
      }).catch((err) => {
        console.log(err)
      this.loading = false
      })
    }
  }
};
</script>

<style lang="scss">
.down-product {
  .content-body {
    .down-product-module {
        background-color: #ffffff;
      .down-product-item {
        border-bottom: 1px solid #e5e5e5;
        padding: 100px 0;
        .el-image {
          width: 615px;
          height: 360px;
        }

        .down-product-info {
            width: 496px;
            .product-title {
                font-size: 36px;
                margin-bottom: 20px;
            }

            .product-summary {
                p {
                    line-height: 32px;
                }
            }

            .product-down-list {
                margin-top: 30px;
                .product-down-item {
                    margin-bottom: 17px;
                    .el-button {
                        width: 316px;
                        height: 76px;
                        border-radius: 0px;
                        font-size: 22px;
                        font-weight: 400;
                        // padding: 8px 15px;

                        span {
                            width: 100%;
                            height: 100%;
                        }

                        span.tip {
                            font-size: 16px;
                            margin-left: 32px;
                            font-weight: 500;
                        }

                        a {
                          position: relative;
                          font-size: 22px;
                          padding: 8px 15px 0 25px;
                        }

                        a:before {
                          margin-right: 12px;
                          font-size: 28px;
                          font-weight: 500;
                          position: absolute;
                          left: -15px;
                        }
                    }

                    span.version {
                        font-size: 14px;
                        color: #666666;
                        font-weight: 500;
                        margin-left: 15px;
                    }
                }
            }
        }
      }
    }
  }
}
</style>