<template>
  <el-dialog
      :model-value="isReportDialog"
      :close-on-click-modal="false"
      width="600px"
      title="举报"
      custom-class="default-dialog report-dialog"
      :before-close="handleClose">
        <el-form :model="formData" label-width="100px">
            <el-form-item class="report-message" label="举报内容：" v-if="reportData">
                <div class="report-info ub ub-ac"><span>{{reportData.USERNAME}}</span>的评论：<span class="msg" v-html="reportData.CONTENT"></span></div>
            </el-form-item>
            <el-form-item label="举报理由：">
                <el-select v-model="formData.reason" popper-class="aaa" class="m-2" style="z-index: 9999 !importent" placeholder="请选择" size="large">
                    <el-option
                    style="z-index: 9999 !importent"
                    v-for="item in reasonOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="举报说明：">
                <el-input
                    v-model="formData.content"
                    :rows="5"
                    type="textarea"
                    placeholder="请输入项目内容"
                    resize="none"
                    maxlength="300"
                    show-word-limit
                />
            </el-form-item>
            <el-form-item label="">
                <el-checkbox class="manage-select" v-model="formData.isAnonymous" label="匿名举报" @change="anonymousChange" />
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button class="default-button" @click="reportTap" :loading="buttonLoading">举报</el-button>
            </span>
        </template>
      </el-dialog>
</template>

<script>
import commonData from '../../../utils/commonData'
import { mapGetters } from 'vuex'
import { pblWork } from '../../../http/api'
export default {
    props: {
        isReportDialog: {
            type: Boolean,
            default: false
        },

        reportData: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            reasonOptions: [{
                value: '违法、违规、违禁内容',
                lable: '1'
            }, {
                value: '垃圾广告，无意义刷屏',
                lable: '2'
            }, {
                value: '侵犯隐私或人身攻击',
                lable: '3'
            }, {
                value: '含有暴力色情',
                lable: '4'
            }, {
                value: '其它不良信息',
                lable: '5'
            }],
            formData: {
                reason: '',
                content: '',
                isAnonymous: false
            },
            buttonLoading: false
        }
    },

    computed: {
        ...mapGetters(['getUserInfo'])
    },

    methods: {
        handleClose() {
            this.$emit('closeReportDialog')
        },

        reportTap() {
            // 举报信息已经发送给官方管理员,举报进度将在15个工作日内发送到您的个人消息中,请注意查收!
            console.log(this.formData.reason)
            if (this.formData.reason == '') {
                this.$message({
                    message: '请选择举报理由',
                    type: 'warning',
                    showClose: true
                })
                return
            }

            this.buttonLoading = true
            let index = this.reasonOptions.findIndex(item => item.value == this.formData.reason)
            let params = {
                type: 24,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                commentId: this.reportData.ROW_ID,
                ctag: this.reasonOptions[index].id,
                content: this.formData.content,
                state: this.formData.isAnonymous ? 1 : 0
            }
            pblWork(params).then(() => {
                this.$message({
                    message: '举报信息已经发送给官方管理员，举报进度将在15个工作日内发送到您的个人消息中，请注意查收！',
                    type: 'success',
                    showClose: true
                })
                this.buttonLoading = false
                setTimeout(() => {
                    this.handleClose()
                }, 2500)
            }).catch(() => {
                this.buttonLoading = false
            })
        }
    },

    watch: {
        isReportDialog(newVal) {
            if (newVal) {
                this.formData = {
                    reason: '',
                    content: '',
                    isAnonymous: false
                }
                this.reasonOptions = commonData.reports
                console.log(this.reportData)
                console.log(commonData)
            }
        }
    }
}
</script>

<style lang="scss">
.report-dialog {
    .el-form {
        .el-form-item {
            .el-form-item__label {
                font-weight: bold;
            }

            .el-form-item__content {
                .el-select {
                    width: 220px;
                }

                .el-checkbox {
                    .el-checkbox__input {
                        .el-checkbox__inner {
                            width: 16px;
                            height: 16px;
                        }

                        .el-checkbox__inner::after {
                            height: 9px;
                            left: 5px;
                            top: 0px;
                            width: 4px;
                        }
                    }
                }

                .report-info {
                    span {
                        color: var(--el-color-second);
                        margin-right: 3px;
                    }

                    .msg {
                        font-weight: bold;

                        img {
                            width: 40px;
                        }
                    }
                }
            }
        }

        .report-message {
            .el-form-item__label {
                display: flex;
                -webkit-box-align: center;
                -moz-box-align: center;
                -ms-flex-align: center;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: flex-end;
                -moz-justify-content: flex-end;
                -ms-justify-content: flex-end;
                justify-content: flex-end;
            }
        }
    }

    .el-dialog__footer {
        text-align: center;

        .el-button {
            font-size: 16px;
            letter-spacing: 2px;
        }
    }
}

.aaa {
    z-index: 9999 !important;
}
</style>