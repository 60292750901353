<template>
  <div class="like-info ub" v-if="item">
      <span class="iconfont icon-eye">{{ item.PV }}</span>
      <like-icon :isLike="item.IS_LIKE" :likeNum="item.LIKE_TIMES"></like-icon>
      <!-- <span :class="['iconfont like', item.IS_LIKE == 1 ? 'act icon-like-act' : 'icon-like']" @click="likeWork()">{{ item.LIKE_TIMES }}</span> -->
  </div>
</template>

<script>
import { pblWork } from '../http/api'
import LikeIcon from './LikeIcon.vue'
import { mapGetters } from 'vuex'
export default {
  components: { LikeIcon },
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    computed: {
        ...mapGetters([
            'getUserInfo'
        ]),
    },

    inject: ['likeTapSuccess'],

    methods: {
        /**
         * 点赞作品
         */
        likeWork() {
            console.log(this.getUserInfo)
            if (!this.$util.isDefine(this.getUserInfo)) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
            console.log(this.item)
            let params = {
                type: 4,
                uid: this.getUserInfo.uid,
                pid: this.item.PROJECT_ID,
                state: this.item.IS_LIKE == 1 ? -1 : 0
            }
            console.log(this.item.PROJECT_ID)
            pblWork(params).then((res) => {
                console.log(res)
                this.likeTapSuccess(this.item.type, this.item.oders, this.item)
            })
        }
    },
}
</script>

<style lang="scss">
.like-info {
    height: 34px;
    color: #bfbfbf;
    box-sizing: border-box;

    span {
      font-size: 12px;
      display: block;
      line-height: 24px;
      margin-right: 12px;
      font-weight: 500;
    }

    span:before {
      font-size: 22px;
      float: left;
      margin-right: 5px;
      font-weight: 600;
    }

    span:last-child {
        margin: 0;
    }
  }
</style>