<template>
  <div class="events-activity">
    <the-header></the-header>
    <div class="content-body warp" v-loading="loading">
        <div v-if="activityInfo" >
            <div class="screen-sub-list warp">
                <ul class="ub ub-wrap">
                    <li
                        @click="screenTap(index)"
                        :class="[index == active ? 'act' : '']"
                        v-for="(item, index) in activityInfo.activityTypeList"
                        :key="index"
                    >
                        {{ item.name }}
                    </li>
                </ul>
            </div>
            <div class="activity-model ub ub-wrap">
                <activity-cell v-for="(item, index) in activityInfo.activityDetailsList" 
                                :key="index" 
                                :class="[(index + 1)%4 == 0 ? 'last' : '']"
                                :item="item"></activity-cell>
            </div>
            <the-pagination :total="total"
                :pageSize="num"
                @paginationChange="paginationChange"></the-pagination>
        </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { activityMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import ActivityCell from './components/ActivityCell.vue'
import ThePagination from '@/components/ThePagination'
export default {
    name: 'EventsActivity',
  components: { TheHeader, TheFooter, ActivityCell, ThePagination },
  data() {
    return {
        loading: false,
        activityInfo: null,
        tabs: [{
            typeName: '全部'
        }, {
            typeName: '节日类'
        }],
        active: 0,
        total: 0,
        page: 1,
        num: 20
    }
  },
  created() {
    this.getData()
  },

  methods: {
    screenTap(index) {
        this.active = index
        this.getData()
    },

    getData() {
        this.loading = true
        activityMsg({
            type: 1,
            activityTypeId: this.activityInfo ? this.activityInfo.activityTypeList[this.active].rowId : 0,
            pageNo: this.page,
            pageSize: this.num
        }).then((res) => {
            console.log(res)
            this.loading = false
            this.activityInfo = res.data
            this.total=res.data.sum
        }).catch((err) => {
            console.log(err)
            this.loading = false
            this.loadErr = true
        })
    },

    /**
     * 分页
     * @param {Number} page 分页数
     */
    paginationChange(page) {
      console.log(page);
      this.page = page;
      this.getData();
    },
  }
}
</script>

<style lang="scss">
.events-activity {
    .content-body {
        .screen-sub-list {
            margin: 44px auto;
            ul {
                li {
                width: 113px;
                height: 33px;
                line-height: 33px;
                background-color: #fff;
                text-align: center;
                border-radius: 30px;
                box-sizing: border-box;
                padding: 0 5px;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                }

                li.act {
                background-color: var(--el-color-primary);
                }
            }
        }

        .activity-model {
            .activity-cell {
                margin-right: 16px;
                margin-bottom: 16px;
            }

            .activity-cell.last {
                margin-right: 0;
            }
        }
    }
}
</style>