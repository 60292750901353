<template>
  <div class="work-tab-list">
    <el-tabs v-model="active" class="demo-tabs" @tab-change="handleClick">
      <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabList" :key="index" v-loading="loading">
        <div class="list-model" v-if="item.list">
          <div v-if="item.list.length > 0">
            <work-item-small v-for="(subItem, subIndex) in item.list" :key="subIndex" :item="subItem"></work-item-small>
            <div class="more"><router-link :to="item.more">更多>></router-link></div>
          </div>
          <the-empty :size="50" v-else></the-empty>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { pblWork } from '../../../http/api'
import WorkItemSmall from '@/components/WorkItemSmall.vue'
import { mapGetters } from 'vuex'
import TheEmpty from '@/components/TheEmpty.vue'
export default {
  components: { WorkItemSmall, TheEmpty },
  props: {
    projectId: {
      type: Number,
      default: 0
    },

    uuid: {
      type: Number,
      default: 0
    },

    workType: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      loading: false,
      active: 0,
      tabList: [{
        name: 0,
        label: '推荐作品',
        list: null,
        more: '/work-center'
      }, {
        name: 1,
        label: '作者作品',
        list: null,
        more: `/personal-center`
      }]
    }
  },

  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    }
  },

  created() {
    if (!this.$util.isDefine(this.tabList[0].list)) {
      this.getData()
    }

    this.tabList[1].more = this.tabList[1].more + `?uid=${this.uuid}`
  },

  computed: {
    ...mapGetters(['getUserInfo'])
  },

  methods: {
    handleClick(index) {
      this.active = index
      this.getData()
    },

    getData() {
      this.loading = true
      let params = {
        uid: this.getUserInfo ? this.getUserInfo.uid : '',
      }
      if (this.active == 0) {
        params.type = 32
        params.pid = this.projectId
      } else {
        params.type = 36
        params.uuid = this.uuid
        params.workType = this.workType
      }

      pblWork(params).then((res) => {
          console.log(res)
          this.tabList[this.active].list = res.pblwork_info
          console.log(this.tabList)
          this.loading = false
      }).catch((err) => {
          console.log(err)
          this.loading = false
      })

    },

    /**
    * 点赞成功，修改状态
    */
    likeTapSuccess() {
        console.log(arguments)
        let index = this.tabList[this.active].list.findIndex((item) => item.PROJECT_ID == arguments[2].PROJECT_ID)
        this.tabList[this.active].list[index].IS_LIKE = this.tabList[this.active].list[index].IS_LIKE == 0 ? 1 : 0
        this.tabList[this.active].list[index].LIKE_TIMES = this.tabList[this.active].list[index].IS_LIKE == 0 ? this.tabList[this.active].list[index].LIKE_TIMES - 1 : this.tabList[this.active].list[index].LIKE_TIMES + 1
    }
  }
}
</script>

<style lang="scss">
.work-tab-list {
  .el-tabs {
    .el-tabs__header {
      background-color: #f4f4f4;
      margin-bottom: 0;

      .el-tabs__nav-wrap {
        .el-tabs__nav {
          width: 100%;

          .el-tabs__active-bar {
            display: none;
          }

          .el-tabs__item {
            width: 50%;
            padding: 0;
            text-align: center;
            background-color: #dcdcdc;
            font-size: 16px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          .el-tabs__item.is-active {
            background-color: var(--el-color-primary);
            color: #000000;
          }

          .el-tabs__item:hover {
            color: #000000;
          }
        }
      }

      .el-tabs__nav-wrap::after {
        display: none;
      }
    }
    
    .el-tabs__content {
      padding: 0 28px;

      .el-tab-pane {
        min-height: 200px;

        .el-loading-mask {
          top: 10px;
        }
      }

      .list-model {
        .more {
          text-align: right;
          margin-top: 20px;
          a {
            color: #6e6e6e;
            font-weight: bold;
          }

          a:hover {
            text-decoration: underline;
            color: var(--el-color-primary);
          }
        }
      }
    }
  }
}
</style>