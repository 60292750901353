<template>
  <div :class="['module-title', 'module-title'+ icon]">{{title}}<router-link class="iconfont icon-arrow-right" :to="path">更多</router-link></div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },

        icon: {
          type: String,
          default: '1'
        },

        path: {
          type: String,
          default: ''
        }
    }
}
</script>

<style lang="scss">
.module-title {
    line-height: 118px;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    padding-left: 38px;
    position: relative;

    a {
      line-height: 118px;
      position: absolute;
      right: 0;
      font-size: 16px;
      color: var(--el-color-three) !important;
    }

    a:hover {
      color: var(--el-color-second);
    }

    a:before {
      float: right;
      margin-left: 20px;
      font-size: 18px;
    }
}

.module-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
}

.module-title1::before {
  width: 25px;
  height: 33px;
  margin-top: -16px;
  background-image: url('../assets/image/pbl_icon.png');
  background-position: -10px -160px;
}

.module-title2::before {
  width: 24px;
  height: 28px;
  margin-top: -14px;
  background-image: url('../assets/image/pbl_icon.png');
  background-position: -10px -197px;
}

.module-title3::before {
  width: 27px;
  height: 28px;
  margin-top: -16px;
  background-image: url('../assets/image/pbl_icon.png');
  background-position: -10px -231px;
}
</style>