const emojiArr = [
    {
      name: "智多蛙",
      class: "zdw",
      images: [
        {
          img: require("../assets/image/emoji/Bye.gif"),
          title: "[bye]",
        },
        {
          img: require("../assets/image/emoji/Hi.gif"),
          title: "[Hi]",
        },
        {
          img: require("../assets/image/emoji/enen.gif"),
          title: "[嗯嗯]",
        },
        {
          img: require("../assets/image/emoji/jiayou.gif"),
          title: "[加油]",
        },
        {
          img: require("../assets/image/emoji/aini.gif"),
          title: "[爱你]",
        },
        {
          img: require("../assets/image/emoji/bangbangda.gif"),
          title: "[棒棒哒]",
        },
        {
          img: require("../assets/image/emoji/fahuo.gif"),
          title: "[发火]",
        },
        {
          img: require("../assets/image/emoji/hahaha.gif"),
          title: "[哈哈哈]",
        },
        {
          img: require("../assets/image/emoji/jiaozuoye.gif"),
          title: "[交作业]",
        },
        {
          img: require("../assets/image/emoji/ku.gif"),
          title: "[哭]",
        },
        {
          img: require("../assets/image/emoji/shangke.gif"),
          title: "[上课啦]",
        },
        {
          img: require("../assets/image/emoji/wanan.gif"),
          title: "[晚安]",
        },
        {
          img: require("../assets/image/emoji/wozoule.gif"),
          title: "[我走了]",
        },
        {
          img: require("../assets/image/emoji/zan.gif"),
          title: "[赞]",
        },
        {
          img: require("../assets/image/emoji/zao.gif"),
          title: "[早]",
        },
        {
          img: require("../assets/image/emoji/wolaile.gif"),
          title: "[我来了]",
        },
      ],
    },
    {
      name: "emoji",
      class: "emoji",
      images: [
        {
          img: require("../assets/image/emoji/smilea_thumb.gif"),
          title: "[呵呵]",
        },
        {
          img: require("../assets/image/emoji/tootha_thumb.gif"),
          title: "[嘻嘻]",
        },
        {
          img: require("../assets/image/emoji/laugh.gif"),
          title: "[哈哈]",
        },
        {
          img: require("../assets/image/emoji/tza_thumb.gif"),
          title: "[可爱]",
        },
        {
          img: require("../assets/image/emoji/kl_thumb.gif"),
          title: "[可怜]",
        },
        {
          img: require("../assets/image/emoji/kbsa_thumb.gif"),
          title: "[挖鼻屎]",
        },
        {
          img: require("../assets/image/emoji/cj_thumb.gif"),
          title: "[吃惊]",
        },
        {
          img: require("../assets/image/emoji/shamea_thumb.gif"),
          title: "[害羞]",
        },
        {
          img: require("../assets/image/emoji/zy_thumb.gif"),
          title: "[挤眼]",
        },
        {
          img: require("../assets/image/emoji/bz_thumb.gif"),
          title: "[闭嘴]",
        },
        {
          img: require("../assets/image/emoji/bs2_thumb.gif"),
          title: "[鄙视]",
        },
        {
          img: require("../assets/image/emoji/sada_thumb.gif"),
          title: "[泪]",
        },
        {
          img: require("../assets/image/emoji/heia_thumb.gif"),
          title: "[偷笑]",
        },
        {
          img: require("../assets/image/emoji/qq_thumb.gif"),
          title: "[亲亲]",
        },
        {
          img: require("../assets/image/emoji/sb_thumb.gif"),
          title: "[生病]",
        },
        {
          img: require("../assets/image/emoji/mb_thumb.gif"),
          title: "[太开心]",
        },
        {
          img: require("../assets/image/emoji/ldln_thumb.gif"),
          title: "[懒得理你]",
        },
        {
          img: require("../assets/image/emoji/yhh_thumb.gif"),
          title: "[右哼哼]",
        },
        {
          img: require("../assets/image/emoji/zhh_thumb.gif"),
          title: "[左哼哼]",
        },
        {
          img: require("../assets/image/emoji/x_thumb.gif"),
          title: "[嘘]",
        },
        {
          img: require("../assets/image/emoji/cry.gif"),
          title: "[衰]",
        },
        {
          img: require("../assets/image/emoji/wq_thumb.gif"),
          title: "[委屈]",
        },
        {
          img: require("../assets/image/emoji/t_thumb.gif"),
          title: "[吐]",
        },
        {
          img: require("../assets/image/emoji/k_thumb.gif"),
          title: "[打哈气]",
        },
        {
          img: require("../assets/image/emoji/bba_thumb.gif"),
          title: "[抱抱]",
        },
        {
          img: require("../assets/image/emoji/angrya_thumb.gif"),
          title: "[怒]",
        },
        {
          img: require("../assets/image/emoji/yw_thumb.gif"),
          title: "[疑问]",
        },
        {
          img: require("../assets/image/emoji/cza_thumb.gif"),
          title: "[馋嘴]",
        },
        {
          img: require("../assets/image/emoji/88_thumb.gif"),
          title: "[拜拜]",
        },
        {
          img: require("../assets/image/emoji/sk_thumb.gif"),
          title: "[思考]",
        },
        {
          img: require("../assets/image/emoji/sweata_thumb.gif"),
          title: "[汗]",
        },
        {
          img: require("../assets/image/emoji/sleepya_thumb.gif"),
          title: "[困]",
        },
        {
          img: require("../assets/image/emoji/sleepa_thumb.gif"),
          title: "[睡觉]",
        },
        {
          img: require("../assets/image/emoji/money_thumb.gif"),
          title: "[钱]",
        },
        {
          img: require("../assets/image/emoji/sw_thumb.gif"),
          title: "[失望]",
        },
        {
          img: require("../assets/image/emoji/cool_thumb.gif"),
          title: "[酷]",
        },
        {
          img: require("../assets/image/emoji/hsa_thumb.gif"),
          title: "[花心]",
        },
        {
          img: require("../assets/image/emoji/hatea_thumb.gif"),
          title: "[哼]",
        },
        {
          img: require("../assets/image/emoji/gza_thumb.gif"),
          title: "[鼓掌]",
        },
        {
          img: require("../assets/image/emoji/dizzya_thumb.gif"),
          title: "[晕]",
        },
        {
          img: require("../assets/image/emoji/bs_thumb.gif"),
          title: "[悲伤]",
        },
        {
          img: require("../assets/image/emoji/crazya_thumb.gif"),
          title: "[抓狂]",
        },
        {
          img: require("../assets/image/emoji/h_thumb.gif"),
          title: "[黑线]",
        },
        {
          img: require("../assets/image/emoji/yx_thumb.gif"),
          title: "[阴险]",
        },
        {
          img: require("../assets/image/emoji/nm_thumb.gif"),
          title: "[怒骂]",
        },
        {
          img: require("../assets/image/emoji/hearta_thumb.gif"),
          title: "[心]",
        },
        {
          img: require("../assets/image/emoji/unheart.gif"),
          title: "[伤心]",
        },
        {
          img: require("../assets/image/emoji/pig.gif"),
          title: "[猪头]",
        },
        {
          img: require("../assets/image/emoji/ok_thumb.gif"),
          title: "[ok]",
        },
        {
          img: require("../assets/image/emoji/ye_thumb.gif"),
          title: "[耶]",
        },
        {
          img: require("../assets/image/emoji/good_thumb.gif"),
          title: "[good]",
        },
        {
          img: require("../assets/image/emoji/no_thumb.gif"),
          title: "[不要]",
        },
        {
          img: require("../assets/image/emoji/come_thumb.gif"),
          title: "[来]",
        },
        {
          img: require("../assets/image/emoji/sad_thumb.gif"),
          title: "[弱]",
        },
        {
          img: require("../assets/image/emoji/lazu_thumb.gif"),
          title: "[蜡烛]",
        },
        {
          img: require("../assets/image/emoji/clock_thumb.gif"),
          title: "[钟]",
        },
        {
          img: require("../assets/image/emoji/cake.gif"),
          title: "[蛋糕]",
        },
        {
          img: require("../assets/image/emoji/m_thumb.gif"),
          title: "[话筒]",
        },
        {
          img: require("../assets/image/emoji/weijin_thumb.gif"),
          title: "[围脖]",
        },
        {
          img: require("../assets/image/emoji/lxhzhuanfa_thumb.gif"),
          title: "[转发]",
        },
        {
          img: require("../assets/image/emoji/lxhluguo_thumb.gif"),
          title: "[路过这儿]",
        },
        {
          img: require("../assets/image/emoji/bofubianlian_thumb.gif"),
          title: "[bofu变脸]",
        },
        {
          img: require("../assets/image/emoji/gbzkun_thumb.gif"),
          title: "[gbz困]",
        },
        {
          img: require("../assets/image/emoji/boboshengmenqi_thumb.gif"),
          title: "[生闷气]",
        },
        {
          img: require("../assets/image/emoji/chn_buyaoya_thumb.gif"),
          title: "[不要啊]",
        },
        {
          img: require("../assets/image/emoji/daxiongleibenxiong_thumb.gif"),
          title: "[dx泪奔]",
        },
        {
          img: require("../assets/image/emoji/cat_yunqizhong_thumb.gif"),
          title: "[运气中]",
        },
        {
          img: require("../assets/image/emoji/youqian_thumb.gif"),
          title: "[有钱]",
        },
      ],
    }
]

export default emojiArr