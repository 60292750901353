import store from "@/store";
import router from "@/router";
import axios from 'axios'    //引入axios
// import QS from 'qs'    //引入qs，用来序列化post类型的数据，否则后端无法接收到数据
import { ElMessage } from 'element-plus'

const errMsg = (state, msg) => {
    switch (Number(state)) {
        case 101:
            return '参数异常';
        case 105:
            return '作品不存在！';
        case 107:
            return '暂无权限';
        case 110:
            return '金币余额不足，完成任务可累积金币哦！';
        case 111:
            return '不能发表该评论';
        case 112:
            return '您已举报过该评论';
        case 116:
            return '投稿期未结束，作品暂不对外公示';
        case 201:
            return '账户不存在';
        case 202:
            return '密码错误';
        case 205:
            return '该手机号已注册';
        case 208:
            setTimeout(() => {
                localStorage.clear()
                sessionStorage.clear()
                store.dispatch('setUserInfo', '')
                router.replace("/");
                router.go(0);

                // 关闭弹窗
                store.dispatch('setLoginAlertShow', false)
                store.dispatch('setUserInfoAlertShow', false)
            }, 2000)
            return '登录已失效，请重新登录！';
        case 209:
            return '该昵称已存在！';
        case 210:
            return '教师已评价过该作品！';
        case 212:
            return '该微信已绑定了其他账号！';
        case 213:
            return '当前密码与原密码一致！';
        case 302:
            return '订单已存在，该订单不允许重复购买！';
        case 401:
            return '短信发送失败！';
        case 402:
            return '验证码错误！';
        case 403:
            return '短信发送次数已满10次！';
        case 404:
            return '验证码已失效！';
        case 405:
            return '发送短信操作频繁，请稍后在试！';
        case 406:
            return '验证码类型错误！';
        case 1001:
            return '该邀请码不存在！';
        case 1002:
            return '学校人数已满！';
        case 1005:
            return '该项目已被删除！';
        case 1004:
            return '该学生已在班级中，无需重复添加！';
        case 1009:
            return '该班级已存在！';
        case 1010:
            return '学校合作已到期，请联系客服！';
        case 1012:
            return '该项目已经开始，不能修改或删除！';
        case 1014:
            return '该班级中还有学生，不能删除！';
        default:
            return msg;
    }
}

// 设置post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.withCredentials = false;//在跨域请求时，不会携带用户凭证；返回的 response 里也会忽略 cookie

//创建axios实例，请求超时时间为300秒，因为项目中有多个域名，所以对应的也要创建多个axios实例
const instance = axios.create({
  timeout: 25000,
});

// 拦截器
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        if (store.getters.getUserInfo) {
            const token = store.getters.getUserInfo.token;
            const uid = store.getters.getUserInfo.id;
            token && (config.headers.token = token);
            uid && (config.headers.uid = uid);
        }
        return config;
    },
    error => Promise.error(error)
)
// 响应拦截器

instance.interceptors.response.use(
    // 请求成功
    res => {
        console.log(res)
        if (res) {
            if (res.status === 200) {
                if (res.data.status === '200' || res.data.state === '200') {
                    return Promise.resolve(res)
                } else {
                    ElMessage.error(errMsg(res.data.state, res.data.msg));
                    res.errText = errMsg(res.data.state, res.data.msg)
                    return Promise.reject(res)
                }
            } else {
                ElMessage.error('加载失败！');
                return Promise.reject(res)
            }
        }
        
    },
    // 请求失败
    error => {
        console.log(error)
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            // errorHandle(response.status, response.data.message);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            //store.commit('changeNetwork', false);
            ElMessage.error('网络连接错误！');
            return Promise.reject({
                data: {
                    msg: '网络连接错误！'
                }
            });
        }
    }
);

// export default instance

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
 export function get(url, params){
    return new Promise((resolve, reject) =>{        
        instance.get(url, {            
            params: params        
        })        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {       
            reject(err)        
        })    
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
 export function post(url, params) { 
    return new Promise((resolve, reject) => {         
        instance.post(url, params)        
        .then(res => {            
            resolve(res.data);        
        })        
        .catch(err => {            
            reject(err.data)        
        })    
    });
}
 

// 1.去掉了之前get和post方法的封装，通过创建一个axios实例然后export default方法导出，这样使用起来更灵活一些。
// 2.去掉了通过环境变量控制baseUrl的值。考虑到接口会有多个不同域名的情况，所以准备通过js变量来控制接口域名。这点具体在api里会介绍。
// 3.增加了请求超时，即断网状态的处理。说下思路，当断网时，通过更新vuex中network的状态来控制断网提示组件的显示隐藏。断网提示一般会有重新加载数据的操作，这步会在后面对应的地方介绍。
// 4.公用函数进行抽出，简化代码，尽量保证单一职责原则。