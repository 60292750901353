<template>
  <div class="work-item-small ub">
      <el-image class="work-item-pic" :src="item.IMG" fit="cover" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)">
        <template #error>
          <div class="image-slot">
            <el-image :src="require('../assets/image/work_default_pic.png')" fit="cover" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)" />
          </div>
        </template>
      </el-image>
      <div class="work-item-info ub ub-ver">
          <div class="work-title ub ub-ac" :title="item.NAME" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)"><div class="text-hidden-2 ">{{item.NAME}}</div></div>
          <like-info :item="item"></like-info>
      </div>
  </div>
</template>

<script>
import LikeInfo from './LikeInfo.vue'
export default {
  components: { LikeInfo },
  props: {
      item: {
          type: Object,
          default: () => {
              return {}
          }
      },
  },

  data() {
      return {
        flag: true
      }
  },

  created() {
      console.log(this.item)
  },

  methods: {
    workDetail(pid, uuid, releaseState) {
        this.$router.push(`/work-detail?pid=${pid}&uuid=${uuid}&releaseState=${releaseState}`)
    }
  },

}
</script>

<style lang="scss">
.work-item-small {
    width: 254;
    .work-item-pic {
        width: 124px;
        height: 93px;
        background-color: #ccc;
        cursor: pointer;
    }

    .work-item-info {
        width: 130px;
        .work-title {
            height: 55px;
            padding: 0 15px;
            cursor: pointer;
        }

        .like-info {
            padding-left: 15px;
        }
    }
}

</style>