<template>
  <el-dialog
    :model-value="isClassMsgDialog"
    custom-class="default-dialog class-msg-dialog"
    title="班级消息"
    :before-close="handleClose"
  >
    <div class="class-message-list" v-loading="loading">
        <div v-if="classMessageList">
            <div class="ub ub-ver" v-if="classMessageList.length > 0">
                <div class="class-message-item ub ub-f1 ub-ac" v-for="(item, index) in classMessageList" :key="index">
                    <div class="time">{{createdTime(item.CREATE_TIME)}}</div>
                    <div class="name" v-html="messageText(item)"></div>
                    <div class="status ub-f1 ub ub-pe" v-if="item.CLASS_STATUS != 4">
                        <div class="handel" v-if="item.CLASS_STATUS == 0">
                            <el-button class="default-button" :loading="item.agreeLoading" @click="handelMsg(1, item, index)">同意</el-button>
                            <el-button class="default-button four" :loading="item.refuseLoading" @click="handelMsg(2, item, index)">拒绝</el-button>
                        </div>
                        <div :class="['text', messageStatusClass(item.CLASS_STATUS)]">{{messageStatus(item.CLASS_STATUS)}}</div>
                    </div>
                </div>
                <the-pagination :pageSize="num" :total="total" @paginationChange="paginationChange"></the-pagination>
            </div>
            <the-empty :description="暂无数据" :size="120" v-else></the-empty>
        </div>
    </div>
  </el-dialog>
</template>

<script>
import ThePagination from '@/components/ThePagination.vue'
import { mapGetters } from 'vuex'
import { teacherMsg } from '../../../http/api'
import TheEmpty from '@/components/TheEmpty.vue'
export default {
  components: { ThePagination, TheEmpty },
    props: {
        isClassMsgDialog: {
            type: Boolean,
            default: false
        },

        classInfo: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            isSetData: false, // 是否修改过数据
            classMessageList: null,
            total: 0,
            page: 1,
            num: 10,
            // 同意拒绝
            agreeLoading: false,
            refuseLoading: false
        }
    },

    created() {
        console.log('aaa')
        console.log(this.$util.formateDateTimesToZh)
    },

    computed: {
        ...mapGetters(['getUserInfo']),

        createdTime() {
            return (time) => {
                return this.$util.formateDateTimesToZh(time, 1)
            }
        },

        messageText() {
            return (item) => {
                switch (item.CLASS_STATUS) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        return `<span><a href="/#/personal-center?uid=${item.USER_ID}">${item.NAME}</a></span>申请加入班级`
                    case 4:
                        return `<span><a href="/#/personal-center?uid=${item.USER_ID}">${item.NAME}</a></span>被移出班级`
                    default:
                        break;
                }
            }
        },

        messageStatus() {
            return (status)=> {
                return status == 1 ? '已同意' : status == 2 ? '已拒绝' : ''
            }
        },

        messageStatusClass() {
            return (status)=> {
                return status == 1 ? 'agree' : status == 2 ? 'refuse' : ''
            }
        }
    },

    methods: {
        handleClose() {
            this.$emit('showClassMsg', false, this.isSetData)
        },

        getData() {
            this.loading = true
            let params = {
                type: 3,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                token: this.getUserInfo ? this.getUserInfo.token : '',
                cid: this.classInfo.class_id,
                page: this.page,
                num: this.num
            }
            teacherMsg(params).then((res) => {
                console.log(res);
                this.loading = false
                this.total = res.num
                this.classMessageList = res.list
            })
            .catch(() => {
                console.log('aaaa')
                this.loading = false;
            });
        },

        /**
         * 申请加入班级处理
         * @param {Number} type 1 同意 2 拒绝
         */
        handelMsg(type, item, index) {
            if (type == 1) {
                this.classMessageList[index].agreeLoading = true
            } else {
                this.classMessageList[index].refuseLoading = true
            }
            let params = {
                type: 4,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                cid: this.classInfo.class_id,
                stuid: item.USER_ID,
                schoolId: this.getUserInfo ? this.getUserInfo.school_id : '',
                state: type
            }
            teacherMsg(params).then((res) => {
                console.log(res);
                this.classMessageList[index].agreeLoading = false
                this.classMessageList[index].refuseLoading = false
                this.classMessageList[index].CLASS_STATUS = type
                this.isSetData = true
                this.$emit('reloadClass')
            })
            .catch(() => {
                this.classMessageList[index].agreeLoading = false
                this.classMessageList[index].refuseLoading = false
            });
        },

        paginationChange(page) {
            console.log(page)
            // this.classMessageList = null
            this.page = page
            this.getData()
        }
    },

    watch: {
        isClassMsgDialog(newVal) {
            console.log(newVal)
            if (newVal) {
                this.classMessageList = null
                this.total = 0
                this.page = 1
                this.getData()
            }
        }
    }
}
</script>

<style lang="scss">
.class-msg-dialog {
    .class-message-list {
        min-height: 200px;

        .class-message-item {
            font-size: 16px;
            margin-bottom: 10px;
            .name {
                margin: 0 5px 0 8px;
                span {
                    color: var(--el-color-second);
                    text-decoration: underline;
                    a {
                        color: var(--el-color-second);
                        text-decoration: underline;
                    }
                    
                }
            }

            .status {
                .agree {
                    color: green;
                }

                .refuse {
                    color: red;
                }
            }
        }

        .pagination-model {
            padding: 20px 0;
            background-color: unset;
        }
    }
}
</style>