<template>
  <div class="project-detail">
      <the-header></the-header>
      <div class="content"  v-if="projectData">
          <div class="project-detail-header">
            <the-breadcrumb class="warp" :breadcrumbList="breadcrumbList"></the-breadcrumb>
            <div class="warp ub ub-be">
                <div class="ub-f1-item project-info">
                    <div class="project-p project-title"><span>项目标题：</span>{{projectData.projectInfo.TAG}}</div>
                    <div class="project-p project-content ub"><span>项目内容：</span><p class="ub-f1-item">{{projectData.projectInfo.TEXT_CONTENT}}</p></div>
                    <div class="project-p project-date"><span>开始时间：</span>{{formTime(projectData.projectInfo.BEGIN_TIME)}}</div>
                    <div class="project-p project-date"><span>结束时间：</span>{{formTime(projectData.projectInfo.END_TIME)}}</div>
                    <div class="project-p project-code ub">
                        <span>项目程序：</span>
                        <work-name v-if="projectData.projectInfo.PROJECT_NAME" :name="projectData.projectInfo.PROJECT_NAME" :id="projectData.projectInfo.PROJECT_ID"></work-name>
                        <p v-else>暂无</p>
                    </div>
                    <div class="project-p project-date update"><span>更新时间：</span>{{formTime(projectData.projectInfo.UPDATE_TIME)}}</div>
                </div>
                <atlas-model :images="projectData.picture" :isUpload="false"></atlas-model>
            </div>
          </div>
          <el-tabs
                    v-model="active"
                    v-loading="loading"
                    class="class-tabs default-tabs warp"
                    @tab-click="tabHandleClick"
                    v-if="projectData.projectInfo.state != 0"
                    >
                    <el-tab-pane :label="item.label + '（' + item.total + '）'" :name="item.index" v-for="(item, index) in tabLists" :key="index">
                        <detail-tab-work v-if="(item.index == 1 || item.index == 2 || item.index == 3) && item.classData" :classData="item.classData"></detail-tab-work>
                        <detail-tab-user v-if="item.index == 4 && item.classData" :classData="item.classData"></detail-tab-user>
                    </el-tab-pane>
                    <the-pagination :total="total" :pageSize="tabList[active - 1].num" @paginationChange="paginationChange"></the-pagination>
          </el-tabs>
          <div v-else class="not-started">项目暂未开始</div>
      </div>
  </div>
</template>

<script>
import { teacherMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import TheBreadcrumb from '@/components/TheBreadcrumb.vue'
import { mapGetters } from 'vuex'
import AtlasModel from './components/AtlasModel.vue'
import ThePagination from '@/components/ThePagination.vue'
import DetailTabWork from './components/DetailTabWork.vue'
import DetailTabUser from './components/DetailTabUser.vue'
import WorkName from '../../components/WorkName.vue'
export default {
  components: { TheHeader, TheBreadcrumb, AtlasModel, ThePagination ,DetailTabWork, DetailTabUser, WorkName},
  data() {
      return {
          breadcrumbList: [
            {
                name: "班级管理",
                path: "/class-manage",
            },
            {
                name: "班级中心",
                path: "",
            }, {
                name: "项目详情",
                path: "",
            }],
            projectData: null,
            active: 1,
            loading: false,
            tabList: [{
                index: 1,
                type: 11,
                label: '全部项目作品',
                num: 10,
                classData: null,
                total: 0,
                isStudent: true
            }, {
                index: 2,
                type: 12,
                label: '已点评',
                num: 10,
                classData: null,
                total: 0
            }, {
                index: 3,
                type: 13,
                label: '未点评',
                num: 10,
                classData: null,
                total: 0
            }, {
                index: 4,
                type: 14,
                label: '未提交项目',
                num: 10,
                classData: null,
                total: 0
            }],
            page: 1

      }
  },

  created() {
      let query = this.$route.query
      this.cid= query.cid
      this.cpid= query.cpid
      this.getData()
      this.getTabData()
      this.breadcrumbList[1].fullPath = `class-center?cid=${this.cid}`
  },

  computed: {
      ...mapGetters(['getUserInfo']),

      formTime() {
          return (time) => {
              return this.$util.formateDateTimesToZh(time, 1)
          }
      },

      isTeacher() {
          return this.getUserInfo.role == 2 ? true : false
      },

      isStudent() {
        return this.getUserInfo.role == 0 ? true : false
      },
        
      tabLists() {
        return this.tabList.filter((item) => {
            return this.isTeacher ? item : item.isStudent
        })
      }
  },

  methods: {
      getData() {
        let params = {
            type: 9,
            uid: this.getUserInfo ? this.getUserInfo.uid : '',
            cid: this.cid,
            cpid: this.cpid
        }
        teacherMsg(params)
            .then((res) => {
                console.log(res);
                this.loading = false
                res.picture = res.picture.map((item) => {
                    return {
                        name: '123',
                        url: item
                    }
                })
                this.projectData = res
                if (this.isTeacher) {
                    this.tabList[1].total = res.projectInfo.commNum
                    this.tabList[2].total = res.projectInfo.notCommNum
                    this.tabList[3].total = res.projectInfo.notSubNum
                }
                
                this.tabList[0].total = res.projectInfo.workNum
            })
            .catch(() => {
                this.loading = false;
            });
      },

      tabHandleClick() {
          console.log('aaa')
          this.getTabData()
      },

      getTabData() {
        let params = {
            type: this.tabList[this.active - 1].type,
            uid: this.getUserInfo ? this.getUserInfo.uid : '',
            cid: this.cid,
            cpid: this.cpid,
            page: this.page,
            num: this.tabList[this.active - 1].num
        }
        teacherMsg(params)
            .then((res) => {
                console.log(res);
                this.tabList[this.active - 1].classData = res
            })
            .catch(() => {
                this.loading = false;
            });
      }
  }
}
</script>

<style lang="scss">
.project-detail {
    .content {
        .project-detail-header {
            background-color: #fff;
            padding-bottom: 25px;
            .breadcrumb {
                .el-breadcrumb {
                    background-color: #fff;
                }
            }

            .project-info {
                margin-right: 100px;

                .project-p {
                    margin-bottom: 20px;
                    font-size: 14px;
                }

                span {
                    display: inline-block;
                    width: 75px;
                }
                .project-title {
                    // font-size: 24px;
                }

                .project-code {
                    p {
                        color: var(--el-color-five);
                    }
                }

                .project-date.update {
                    color: var(--el-color-five);
                }

            }
        }

        .class-tabs {
            margin-top: 69px;

            .el-tabs__header {
                padding-bottom: 20px;
            }
        }

        .not-started {
            text-align: center;
            margin-top: 150px;
            color: var(--el-color-five);
            font-weight: bold;
        }
    }
}
</style>