<template>
  <div class="home">
      <the-header></the-header>
      <div class="pbl-banner" id="banner">
        <el-carousel>
          <el-carousel-item v-for="(item, index) in banner" :key="item" :class="'banner' + (index + 1)">
            <div :class="['banner-con', 'banner-con' + item.id]" :style="'background-image:url(' + item.src + ')'"></div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content-body warp ub ub-ac ub-pc" v-loading="loading">
        <div v-if="homeData">
          <work-module title="推荐作品" icon='1' :data="homeData.data1" path="/work-center"></work-module>
          <!-- <work-module title="获奖作品" icon='1' :data="homeData.data6" path="/activity-index/activity-win"></work-module> -->
          <work-module title="科普日视频" icon='1' :data="homeData.data5" path="/work-center" v-if="homeData.data5.length > 0"></work-module>
          <work-module title="热门作品" icon='2' :data="homeData.data2" path="/work-center"></work-module>
          <!-- <work-module title="获奖作品" icon='3' :data="homeData.data3" v-if="homeData.data3.length > 0" path="/match-center"></work-module> -->
          <lesson-module title="公益课程" :data="homeData.data4" path="/course-index"></lesson-module>
        </div>
        <el-empty v-if="loadErr" description="加载失败，请刷新重试！"></el-empty>
      </div>
      <quick-nav></quick-nav>
      <the-footer></the-footer>
  </div>
</template>

<script>
import { loginMsg, pblPageMsg } from '../../http/api'
import TheFooter from '@/components/TheFooter.vue'
import TheHeader from '../../components/TheHeader.vue'
import WorkModule from '@/components/WorkModule.vue'
import { mapGetters } from 'vuex'
import QuickNav from './components/QuickNav.vue'
import LessonModule from '@/components/LessonModule.vue'
export default {
  components: { TheHeader, TheFooter, WorkModule, QuickNav, LessonModule },
  data() {
    return {
      banner: [{
        src: require('../../assets/image/banner1.jpg'),
        id: 2,
      },  {
        src: require('../../assets/image/banner2.jpg'),
        id: 3,
      }, {
        src: require('../../assets/image/banner3.jpg'),
        id: 1,
      }],
      homeData: null,
      loading: false,
      loadErr: false
    }
  },

  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    }
  },

  computed: {
    ...mapGetters([
      'getUserInfo'
    ]),

    isLogin() {
      return this.getUserInfo ? true : false
    }
  },

  created() {
    console.log()
    var code = this.$util.getUrlKey("code");
    var state = this.$util.getUrlKey("state");
    if (this.$util.isDefine(code) && this.$util.isDefine(state)) {
      console.log('aaa')
      this.getAccessToken(code);
    } else {
      this.getData()
    }
  },

  methods: {
    getData() {
      this.loading = true
      let params = {
        type: 1,
        user_id: this.getUserInfo ? this.getUserInfo.uid : ''
      }
      console.log(params)
      pblPageMsg(params).then((res) => {
        console.log(res)
        this.homeData = res
        this.loading = false
        this.flag = false
        if (this.getUserInfo) {
          this.getUserInfo.MessageNum = res.MessageNum
          this.$store.commit("setUserInfo", this.getUserInfo);
        }
      }).catch((err) => {
        console.log(err)
        this.loading = false
        this.loadErr = true
      })
    },

    getAccessToken(code) {
      let params = {
        type: 9,
        code: code,
        uid: this.getUserInfo ? this.getUserInfo.uid : '',
        token: this.getUserInfo ? this.getUserInfo.token : ''
      }
      loginMsg(params).then((res) => {
        console.log(res)
        this.$message({
          message: `${this.isLogin ? '绑定成功' : '登录成功！'}`,
          type: "success",
          showClose: true,
        });
        if (!this.isLogin) {
          this.$store.commit("setLoginAlertState", false);
        }

        this.$store.commit("setUserInfo", res.userInfo);
        
        setTimeout(() => {
          if (!this.$util.isDefine(res.userInfo.tel) || !this.$util.isDefine(res.userInfo.tel)) {
            window.open(`${window.location.origin}/#/account-info`, "_self");
          } else {
            window.open(`${window.location.origin}/`, "_self");
          }
        }, 1500)
      }).catch((err) => {
        console.log(err)
      })
    }, 

    /**
     * 点赞成功，修改状态
     */
    likeTapSuccess(type, index) {
      this.homeData[`data${type}`][index].IS_LIKE = this.homeData[`data${type}`][index].IS_LIKE == 0 ? 1 : 0
      this.homeData[`data${type}`][index].LIKE_TIMES = this.homeData[`data${type}`][index].IS_LIKE == 1 ? this.homeData[`data${type}`][index].LIKE_TIMES + 1 : this.homeData[`data${type}`][index].LIKE_TIMES - 1
    }
  }
}
</script>

<style lang="scss">
.home {
  .pbl-banner {
    .el-carousel__container {
      height: 452px;

      .el-carousel__arrow {
        background-color: rgba($color: #000000, $alpha: 0.6);
      }

      .el-carousel__item.banner1 {
        // background-image: url(../../assets/image/banner1_repeat_bg.png);
        // background-repeat: repeat-x;
        // background-size: auto 100%;
      }

      .banner-con {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>