import { get, post } from './request'
import config from './config'

export const loginMsg = p => get(config.loginMsg, p);
export const loginMsgPost = p => post(config.loginMsg, p);
export const pblPageMsg = p => get(config.pblPageMsg, p);
export const pblWork = p => get(config.pblWork, p);
export const curriculumMsg = p => get(config.curriculumMsg, p);
export const shoppingMaterialMsg = p => get(config.shoppingMaterialMsg, p);
export const noticeMsg = p => get(config.noticeMsg, p);
export const messagePageMsg = p => get(config.messagePageMsg, p);
export const matchMsg = p => get(config.matchMsg,p)
export const teacherMsg = p => get(config.teacherMsg,p)
export const teacherMsgPost = p => post(config.teacherMsg,p)
export const schoolMsg = p=> get(config.schoolMsg,p)
export const classMsg = p=> get(config.classMsg,p)
export const resourcesMsg = p=> get(config.resourcesMsg,p)
export const suggestMsg = p=> post(config.suggestMsg,p)
export const releaseWorkMsg = p=> get(config.releaseWorkMsg,p)
export const releaseWorkMsgPost = p => post(config.releaseWorkMsg,p)
export const activityMsg = p => get(config.activityMsg,p)
export const activityMsgPost = p => post(config.activityMsg,p)
