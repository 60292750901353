<template>
  <div class="rank">
    <div class="rank-pm">排名{{ranknum}}</div>
    <el-image class="rank-pic" :src="item.IMG" fit="cover" />
    <div class="rank-det">
      <div class="rank-title" @click.stop="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)">
        <div class="text-hidden">{{item.NAME}}</div>
      </div>
      <like-info class="browse" :item="item"></like-info>
      <div class="rank-user">
        <el-image class="rank-user-pic" :src="item.USER_HEADIMG" fit="cover" />
        <div class="rank-name">{{item.NICKNAME}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LikeInfo from "./LikeInfo.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ranknum:{
        type:String,
        default:''
    }
  },
  methods: {
    workDetail(pid, uuid, releaseState) {
      this.$router.push(`/work-detail?pid=${pid}&uuid=${uuid}&releaseState=${releaseState}`)
    }
  },
  components: { LikeInfo },
};
</script>

<style lang="scss">
.rank {
 margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  width: 380px;
  height: 210px;
    box-shadow: 0px 0px 8px #d5d5d5;

  background-color: #fff;
  border-radius: 10px;  
  
  .rank-pm {
    margin-left: 10px;
    width: 95%;
    height: 30px;
    line-height: 30px;
  }
  .rank-pic {
    margin-left: 10px;
    width: 172px;
    height: 172px;
    background-color: var(--el-color-primary);
  }
  .rank-det {
    width: 50%;
    height: 202px;
    display: flex;
    flex-direction: column;
    .rank-title {
      cursor: pointer;
      padding: 0 10px;
    }
    .browse{
     padding: 35px 10px;

    }
  }

  .rank-user {
    padding: 30px 15px;
    box-sizing: border-box;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    .rank-user-pic{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--el-color-primary);
    }
    .rank-name{
        padding: 15px 20px;
    }
  }

  .like-info {
    padding: 0 15px;
  }
}
</style>