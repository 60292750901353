<template>
  <div class="teacher-comment">
      <div class="ub ub-wrap" v-if="classData.classList.length > 0">
        <div :class="['comment-work-item ub']" v-for="(item, index) in classData.classList" :key="index">
            <el-image class="comment-work-image" :src="item.IMG"></el-image>
            <div class="comment-work-info ub ub-ver ub-f1-item">
                <div class="comment-work-title"  @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)">{{item.workName}}</div>
                <div class="comment-work-rate ub ub-ac"><span class="tip">评分：</span><the-rate :score="item.SCORE" :isDisabled="true"></the-rate></div>
                <div class="comment-work-text ub-f1"><span class="tip">评语：</span>{{item.CONTENT}}</div>
                <the-user :image="item.USER_HEADIMG" :name="item.userName"></the-user>
            </div>
        </div>
      </div>
      <the-empty v-else></the-empty>
  </div>
</template>

<script>
import TheEmpty from '@/components/TheEmpty.vue'
import TheRate from '@/components/TheRate.vue'
import TheUser from '@/components/TheUser.vue'
export default {
  components: { TheEmpty, TheRate, TheUser },
  props: {
      classData: {
        type: Object,
        default: () => {
            return {}
        }
      },
  },

  methods: {
    workDetail(pid, uuid, releaseState) {
        this.$router.push(`/work-detail?pid=${pid}&uuid=${uuid}&releaseState=${releaseState}`)
    },
  }
}
</script>

<style lang="scss">
.teacher-comment {
    .comment-work-item {
        width: 100%;
        height: 270px;
        box-shadow: 0px 0px 8px #d5d5d5;
        border-radius: var(--item-border-radius);
        background-color: #ffffff;
        margin-bottom: 24px;
        padding: 25px;
        box-sizing: border-box;
        font-size: 14px;

        .comment-work-image {
            width: 230px;
            margin-right: 15px;
        }

        .comment-work-info {
            .tip {
                color: var(--el-color-second);
                font-weight: bold;
            }
            
            .comment-work-title {
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }

            .comment-work-text {
                word-break: break-all;
            }

            .user-small-info {
                height: 45px;
            }
        }
    }
}
</style>