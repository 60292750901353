<template>
  <div class="shop-detail">
      <the-header></the-header>
      <div class="content-body warp">
        <the-breadcrumb :breadcrumbList="breadcrumbList"></the-breadcrumb>
        <div class="detail-content" v-loading="loading" >
            <div v-if="detailInfo">
                <div class="wares-model ub">
                    <el-image :src="detailInfo.materialCover" fit="contain" @click="lookBigImage(detailInfo.materialCover)" @contextmenu="handlePaste($event)"/>
                    <div class="wares-info ub-f1-item">
                        <div class="wares-title">{{detailInfo.materialName}}</div>
                        <div class="coin-num">{{detailInfo.exchangeValue}}</div>
                        <div class="wares-num">已兑换数量：{{detailInfo.sum}}</div>
                        <div class="wares-num">库存：{{detailInfo.exchangeSum}}</div>
                        <el-button :class="[ waresDisable(detailInfo.whetherHave) ? '' : 'default-button']" :disabled="waresDisable(detailInfo.whetherHave)" type="info" @click="createdOrder" :loading="buttonLoading">{{wareButtonText(detailInfo.whetherHave)}}</el-button>
                    </div>
                </div>
                <div class="wares-message">
                    <div class="wares-message-title">资源介绍</div>
                    <ul>
                        <li>类型：{{detailInfo.sortName}}</li>
                        <li>稀有等级：{{detailInfo.rare}}</li>
                        <li>{{detailInfo.materialDescribe}}</li>
                    </ul>
                </div>
                <div class="wares-message">
                    <div class="wares-message-title">注意事项</div>
                    <ul>
                        <li>1、虚拟物品兑换成功后不予退回。</li>
                        <li>2、兑换成功后可以在资源库-兑换记录里查看。</li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
      <big-image-dialog :isBigImageDialog="isBigImageDialog" :src="bigImageSrc" :isCloseMouseRight="true" @closeBigImageDialog="closeBigImageDialog"></big-image-dialog>
  </div>
</template>

<script>
import { shoppingMaterialMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import TheBreadcrumb from '@/components/TheBreadcrumb.vue'
import { mapGetters } from 'vuex'
import BigImageDialog from '@/components/BigImageDialog.vue'
export default {
  components: { TheHeader, TheBreadcrumb, BigImageDialog },

  data() {
      return {
          loading: false,
          symbol: '-',
          breadcrumbList: [{
              name: '资源库',
              path: '/shopping-index'
          }, {
              name: '资源详情',
              path: ''
          }],
          detailInfo: null,
          buttonLoading: false,
          isBigImageDialog: false,
          bigImageSrc: ''
      }
  },

  created() {
    this.query = this.$route.query
    this.getData()
  },

  computed: {
    ...mapGetters(['getUserInfo']),
    waresDisable() {
        return (state) => {
            switch (Number(state)) {
                case 0:
                    return false
                case 1:
                case 2:
                case 3:
                    return true
        
                default:
                    return false;
            }
        }
    },

    wareButtonText() {
        return (state) => {
            switch (Number(state)) {
                case 0:
                    return '立即兑换'
                case 1:
                    return '已拥有'
                case 2:
                    return '余额不足'
                case 3:
                    return '暂无库存'
            
                default:
                    return '';
            }
        }
    }
  },

  methods: {
      getData() {
          this.loading = true
          let params = {
              type: 4,
              userId: this.getUserInfo ? this.getUserInfo.uid : '',
              rowId: this.query.rowId
          }
          shoppingMaterialMsg(params).then((res) => {
            console.log(res)
            this.detailInfo = res.data
            this.loading = false
        }).catch(() => {
            this.loading = false
        })
      },

      /**
       * 生成订单
       */
      createdOrder() {
          if (!this.$util.isDefine(this.getUserInfo)) {
              this.$store.commit('setLoginAlertState', true)
              return
          }
          this.buttonLoading = true
          let params = {
              type: 6,
              userId: this.getUserInfo.uid,
              rowId: this.query.rowId
          }
          shoppingMaterialMsg(params).then((res) => {
            console.log(res)
            this.$confirm("立即支付?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false
            }).then(() => {
                console.log('确认')
                console.log(res.data.orderId)
                this.exchange(res.data.orderId)
            }).catch(() => {
                console.log('取消')
                this.$router.push('/shopping-index/wares-record')
            })
            this.buttonLoading = false
        }).catch((err) => {
            console.log(err)
            if (err.data.state == 302) { // 订单已存在
                this.$router.push('/shopping-index/wares-record')
            }
            this.buttonLoading = false
        })
      },

      /**
       * 兑换
       */
      exchange(orderId) {
          let params = {
              type: 5,
              orderType: 2,
              orderId: orderId,
              userId: this.getUserInfo.uid
          }
          shoppingMaterialMsg(params).then((res) => {
            console.log(res)
            this.$message({
                type: 'success',
                message: '兑换成功'
            })
            setTimeout(() => {
                this.$router.push('/shopping-index/wares-record')
            }, 2000)
        }).catch(() => {
        })
      },

      lookBigImage(src) {
        this.isBigImageDialog = true
        this.bigImageSrc = src
      },

      closeBigImageDialog() {
        this.isBigImageDialog = false
      },

      handlePaste (event) {
        // 禁用鼠标右键
        event.preventDefault()
        return false
      }
  }
}
</script>

<style lang="scss">
.shop-detail {
    .content-body {
        background-color: #fff;
    }

    .detail-content {
        min-height: 500px;
        box-sizing: border-box;
        padding: 30px;

        .wares-model {
            padding-bottom: 30px;

            .el-image {
                width: 268px;
                height: 203px;
                margin-right: 38px;
                cursor: pointer;
            }

            .wares-info {
                .wares-title {
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 30px;
                }

                .coin-num {
                    height: 38px;
                    line-height: 38px;
                    margin: 10px 0 20px;
                }

                .wares-num {
                    font-size: 12px;
                    color: #787878;
                    font-weight: 500;
                    margin-bottom: 10px;
                }

                .el-button {
                    margin-top: 5px;
                    width: 224px;
                    border-radius: 30px;

                    span {
                        width: auto;
                    }
                }
            }
        }

        .wares-message {
            padding: 20px 0;
            border-top: 1px solid #eeeeee;

            .wares-message-title {
                line-height: 36px;
                font-size: 18px;
                font-weight: bold;
            }

            ul {
                li {
                    font-size: 14px;
                    line-height: 26px;
                }
            }
        }
    }
}
</style>