<template>
  <div class="work-module">
      <module-title :title="title" :icon="icon" :path="path"></module-title>
      <div class="work-module-content ub">
        <work-item v-for="(item, index) in data.slice(0,5)" :key="index" :item="item"></work-item>
      </div>
  </div>
</template>

<script>
import ModuleTitle from './ModuleTitle.vue'
import WorkItem from './WorkItem.vue'
export default {
  props: {
    title: {
      type: String,
      default: ''
    },

    icon: {
      type: String,
      default: '1'
    },

    data: {
      type: Array,
      default: () => {
        return []
      }
    },

    path: {
      type: String,
      default: ''
    }
  },

  components: { WorkItem , ModuleTitle },

  data() {
    return {
      
    }
  },

  created() {

  },

  mounted() {
    console.log(this.path)
  }

}
</script>

<style lang="scss">
.work-module {
  width: 1190px;
  margin: 0 auto;
  .work-module-content {
    .work-item {
      margin-right: 17px;
    }

    .work-item:last-child {
      margin-right: 0;
    }
  }
}
</style>