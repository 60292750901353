<template>
  <!-- <keep-alive :include="['WorkCenter']">
    <router-view ></router-view>
  </keep-alive> -->
  <!-- <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  <!-- <router-view v-slot="{ Component }" v-if="$route.meta.keepAlive">
  <keep-alive>
    <component :is="Component" />
  </keep-alive>
</router-view>
  <router-view v-if="!$route.meta.keepAlive"></router-view> -->
  <!-- vue3.0配置 -->
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive"/>
  </router-view> 

  <!-- 全局弹窗 -->
  <login-alert :isLoginAlert="getIsLoginAlert"></login-alert>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginAlert from './components/LoginAlert.vue'

export default {
  name: 'App',

  components: { LoginAlert },

  computed: {
    ...mapGetters([
      "getIsLoginAlert"
    ])
  },

  mounted() {
    console.log(this.$route)
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
}
</style>
