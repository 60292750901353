export default {
    state: {
        isToIndex: false,
        userInfo: null,
        isLoginAlert: false
    },

    getters: {
        getIsToIndex(state) {
            return state.isToIndex
        },

        getIsLoginAlert(state) {
            return state.isLoginAlert
        },

        getUserInfo(state) {
            if(!state.userInfo){
                console.log(localStorage.getItem('autoLogin'))
                if (localStorage.getItem('autoLogin') === 'true') {
                    state.userInfo = JSON.parse(localStorage.getItem('userInfo'));
                } else {
                    state.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));

                }
            }
            return state.userInfo
        },
    },

    mutations: {
        setToIndex(state, type) {
            state.isToIndex = type
        },

        setLoginAlertState(state, type) {
            state.isLoginAlert = type
        },

        setUserInfo(state, userInfo) {
            if (localStorage.getItem('autoLogin') === 'true') {
                localStorage.setItem('userInfo', JSON.stringify(userInfo));
            } else {
                sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
            }
            state.userInfo = userInfo
        }
    }
}