<template>
  <div class="work-media-other">
    <el-carousel :autoplay="false" :loop="false" @change="carouselChange">
      <el-carousel-item v-for="item in images" :key="item">
        <div class="image ub ub-ac ub-pc">
          <video id="video" width="100%" height="100%" controls controlslist="nodownload" v-if="item.type == 1">
              <source :src="item.url" type="video/mp4">
              您的浏览器不支持Video标签。
          </video>
          <el-image :src="item.url" fit="contain" v-if="item.type == 2"></el-image>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {

    }
  },

  created() {
    console.log(this.images)
  },
  
  mounted() {
  },

  methods: {
    carouselChange() {
      let video = document.getElementById("video");
      if (video) {
        video.pause();
      }
    }
  },
}
</script>

<style lang="scss">
.work-media-other {
    height: 100%;
    .el-carousel {
        height: 100%;

        .el-carousel__container {
            height: 100%;

            video {
              width: 100%;
              height: 100%;
            }

            .image {
              height: 100%;

              .el-image {
                width: 100%;
                height: 100%;
              }
            }
        }
    }
        
}
</style>