<template>
  <div class="project-created">
      <the-header></the-header>
      <div class="content-body warp">
          <the-breadcrumb :breadcrumbList="breadcrumbList"></the-breadcrumb>
          <div class="created-content ub ub-be">
              <div class="form-body">
                  <el-form :model="formData" label-width="130px">
                    <el-form-item label="项目标题：">
                        <el-input v-model="formData.title" placeholder="请输入项目标题" clearable maxlength="30" />
                    </el-form-item>
                    <el-form-item label="项目内容：">
                        <el-input
                            v-model="formData.content"
                            :rows="5"
                            type="textarea"
                            placeholder="请输入项目内容"
                            resize="none"
                            maxlength="300"
                            show-word-limit
                        />
                    </el-form-item>
                    <el-form-item label="开始时间：">
                        <el-date-picker
                            v-model="formData.startTime"
                            type="datetime"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            :default-time="defaultTime"
                            value-format="YYYY-MM-DD HH:mm"
                        />
                    </el-form-item>
                    <el-form-item label="结束时间：">
                        <el-date-picker
                            v-model="formData.endTime"
                            type="datetime"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            :default-time="defaultTime"
                            value-format="YYYY-MM-DD HH:mm"
                        />
                    </el-form-item>
                    <el-form-item label="项目程序：">
                        <work-name class="project-name" :name="formData.project.NAME" :id="formData.project.PROJECT_ID" v-if="formData.project.NAME"></work-name>
                        <!-- <div class="project-name" v-if="formData.project.NAME">{{formData.project.NAME}}</div> -->
                        <el-button class="default-button" @click="selectWork">{{formData.project.NAME ? '修改' : '上传'}}</el-button>
                    </el-form-item>
                    <el-form-item class="ub submit" label="">
                        <el-button class="default-button ub-f1" @click="releaseProject" :loading="buttonLoading">{{isEdit ? '保存' : '发布'}}</el-button>
                        <el-button class="ub-f1 default-button four" @click="deleteProject()" v-if="isEdit">删除</el-button>
                        <el-button class="ub-f1" type="info" @click="cancel">取消</el-button>
                    </el-form-item>
                  </el-form>
              </div>
              
              <atlas-model v-if="isAtlas" :images="images" @getImages="getImages" @deleteImage="deleteImage"></atlas-model>
          </div>
          <select-work-dialog :isSelectWorkDialog="isSelectWorkDialog" @closeSelectDialog="closeSelectDialog" @workTap="workTap"></select-work-dialog>
      </div>
  </div>
</template>

<script>
import { teacherMsgPost, teacherMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import TheBreadcrumb from '@/components/TheBreadcrumb.vue'
import AtlasModel from './components/AtlasModel.vue'
import SelectWorkDialog from './components/SelectWorkDialog.vue'
import { mapGetters } from 'vuex'
import WorkName from '@/components/WorkName.vue'
export default {
  components: { TheHeader, TheBreadcrumb, AtlasModel, SelectWorkDialog, WorkName },
  data() {
      return {
         breadcrumbList: [
              {
                name: "班级管理",
                path: "/class-manage",
            },
            {
                name: "班级中心",
                path: "",
            }, {
                name: "创建项目",
                path: "",
            }
          ],
          formData: {
              title: '',
              content: '',
              startTime: '',
              endTime: '',
              project: ''
          },
          isAtlas: false,
          images: [],
          isSelectWorkDialog: false,
          buttonLoading: false
      }
  },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.breadcrumbList[1].fullPath = from.fullPath
        })
    },

  created() {
      console.log(this.getTime)
      this.cid = this.$route.query.cid
      this.cpid = this.$route.query.cpid
      console.log(this.cid)
      if (!this.$util.isDefine(this.cid)) {
          this.$router.go(-1)
          return
      }

      // 编辑作品 
      if (this.$util.isDefine(this.cpid)) {
        this.getData()
      } else {
        this.isAtlas = true
        let startTime = new Date().getTime() + 5 * 60 * 1000
        let endTime = startTime + 7 * 24 * 60 * 60 * 1000
        this.formData.startTime = this.formatDate(startTime)
        this.formData.endTime = this.formatDate(endTime)

      }

      
  },

  computed: {
      ...mapGetters(['getUserInfo']),

      isEdit() {
          return this.cpid ? true : false
      }
  },

  methods: {
      /**
       * 获取作品详情
       */
      getData() {
          let params = {
            type: 9,
            uid: this.getUserInfo ? this.getUserInfo.uid : '',
            cid: this.cid,
            cpid: this.cpid
        }
        teacherMsg(params)
            .then((res) => {
                console.log(res);
                // this.loading = false
                // this.projectData = res
                this.formData = {
                    title: res.projectInfo.TAG,
                    content: res.projectInfo.TEXT_CONTENT,
                    startTime: this.formatDate(res.projectInfo.BEGIN_TIME),
                    endTime: this.formatDate(res.projectInfo.END_TIME),
                    project: {
                        NAME: res.projectInfo.PROJECT_NAME,
                        PROJECT_ID: res.projectInfo.PROJECT_ID
                    }
                }
                console.log(res.picture)
                this.images = res.picture.map(item => {
                    return {
                        name: '123',
                        url: item
                    }
                })
                this.isAtlas = true
            })
            .catch(() => {
                this.loading = false;
            });
      },

      /**
       * 创建项目
       */
      releaseProject() {
          if(!this.$util.isDefine(this.formData.title)) {
              this.$message({
                type: 'warning',
                message: '请输入项目标题！'
              })
              return
          }
          if(!this.$util.isDefine(this.formData.content)) {
              this.$message({
                type: 'warning',
                message: '请输入项目内容！'
              })
              return
          }
          if(!this.$util.isDefine(this.formData.startTime)) {
              this.$message({
                type: 'warning',
                message: '请选择项目开始时间！'
              })
              return
          }
          if(!this.$util.isDefine(this.formData.endTime)) {
              this.$message({
                type: 'warning',
                message: '请选择项目结束时间！'
              })
              return
          }

          let isTime = this.getDiff(this.formData.startTime, this.formData.endTime)

          if (isTime) {
            this.releaseProjectHttp()
          }

      },

      /**
       * 发布项目
       */
      releaseProjectHttp() {
            this.buttonLoading = true
            var params = new FormData();

            params.append("uid", this.getUserInfo.uid);
            params.append("cid", this.cid);
            params.append("pid", this.formData.project ? this.formData.project.PROJECT_ID : 0);
            params.append("tag", this.formData.title);
            params.append("content", this.formData.content);
            params.append("startTime", this.formData.startTime);
            params.append("endTime", this.formData.endTime);
            let oldImages = []
            if (this.images.length == 0) {
                // params.append("files", "123");
            } else {
                console.log(this.images)
                for(var i in this.images){
                    if (this.images[i].raw) {
                        params.append("files", this.images[i].raw);
                    } else {
                        if (this.images[i].url) {
                            let arr = this.images[i].url.split('/')
                            let imgName = arr[arr.length - 1]
                            oldImages.push(imgName)
                        }
                    }
                }
            }

            if(this.isEdit) {
                params.append("type", "21");
                params.append("cpid", this.cpid);
                params.append("imgUrl", oldImages.join(','));
            } else {
                params.append("type", "18");
                params.append("sid", this.getUserInfo.school_id);   
            }
            
            // return
            teacherMsgPost(params).then((res) => {
                console.log(res);
                this.buttonLoading = false
                this.$message({
                    message: "保存成功",
                    type: "success",
                    showClose: true
                });
                setTimeout(() => {
                    this.$router.go(-1)
                }, 1500)
            })
            .catch(() => {
                this.buttonLoading = false;
            });
      },

      /**
       * 选择作品
       */
      selectWork() {
          this.isSelectWorkDialog = true
      },

      /**
       * 关闭选择作品弹窗
       */
      closeSelectDialog() {
          this.isSelectWorkDialog = false
      },

      /**
       * 时间判断
       * @param {String} startTime 开始时间
       * @param {String} endTime 结束时间
       */
      getDiff(startTime, endTime) {
        var a0 = new Date()
        var a1 = new Date(startTime)
        var a2 = new Date(endTime)

        if(a0 > a1) {
            this.$message({
                type: 'warning',
                message: '开始时间不能小于当前时间！'
            })
            return false
        }

        if (a1 > a2) {
            this.$message({
                type: 'warning',
                message: '开始时间不能大于结束时间！'
            })
            return false
        }

        var total = (a2.getTime() - a1.getTime()) / 1000; // 相差的秒数
        var timeDiff = parseInt(total / (60 * 60)); // 计算是否超过24小时
        if (timeDiff < 24) {
            this.$message({
                type: 'warning',
                message: '项目时间不能小于24小时！'
            })
            return false
        }

        return true
      },

      /**
       * 选择作品
       */
      workTap(item) {
          console.log(item)
          this.formData.project = item
      },

      /**
       * 获取图片数据
       */
      getImages(file, type) {
          
      },

      /**
       * 删除图片
       */
      deleteImage(index) {
          this.images.splice(index, 1)
      },

      /**
       * 删除作品
       */
      deleteProject() {
          this.$confirm(`确认删除项目：${this.formData.title}吗?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
                this.loading = true
                let params = {
                    type: 19,
                    uid: this.getUserInfo ? this.getUserInfo.uid : '',
                    cid: this.cid,
                    cpid: this.cpid,
                }
                teacherMsg(params).then((res) => {
                    console.log(res)
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1000)
                }).catch(() => {
                    this.loading = false
                })
          })
      },

      /**
       * 取消发布
       */
      cancel() {
          this.$router.go(-1)
      },

      /**
       * 格式化时间
       */
      formatDate(value) {
        // 时间戳转换日期格式方法
        if (value == null) {
            return "";
        } else {
            let date = new Date(value);
            let y = date.getFullYear(); // 年
            let MM = date.getMonth() + 1; // 月
            MM = MM < 10 ? "0" + MM : MM;
            let d = date.getDate(); // 日
            d = d < 10 ? "0" + d : d;
            let h = date.getHours(); // 时
            h = h < 10 ? "0" + h : h;
            let m = date.getMinutes(); // 分
            m = m < 10 ? "0" + m : m;
            let s = date.getSeconds(); // 秒
            s = s < 10 ? "0" + s : s;
            return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
        }
      },
  }
}
</script>

<style lang="scss">
.project-created {
    .content-body {
        .form-body{
            width: 604px;
            .el-form {
                .el-form-item {
                    .el-form-item__label {
                        line-height: 40px;
                    }
                    .el-form-item__content {
                        .el-input {
                            line-height: 40px;
                            input {
                                height: 40px;
                                line-height: 40px;
                            }
                        }

                        .el-date-editor {
                            width: 100%;
                            height: 40px;
                            line-height: 40px;
                        }

                        .project-name {
                            margin-right: 15px;
                        }
                    }
                }

                .submit {
                    .el-form-item__content {
                        .el-button {
                            height: 40px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .atlas {
            // width: ;
        }
    }
}
</style>