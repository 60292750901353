<template>
  <div class="personal-center">
      <the-header></the-header>
      <div class="personal-header" v-if="userInfo">
            <div class="second-banner"><el-image :src="userInfo.user_headimg" fit="cover"></el-image></div>
            <div class="user-info ub ub-ver ub-ac">
                <el-image class="user-img" :src="userInfo.user_headimg" fit="cover"></el-image>
                <div class="user-name">{{userInfo.user_name}}<span class="iconfont icon-edit" v-if="isSelf"><router-link to="/account-info">编辑</router-link></span></div>
                <div class="user-sex">{{getUserSex}} | {{userInfo.age}}岁{{isSelf ? ' | '+getUserRole(userInfo.role): ''}}</div>
                <div class="user-sex">ID：{{ userInfo.user_id }}</div>
                <div class="user-tag">标签：{{userInfo.tag}}</div>
                <div class="work-rate ub ub-ac">作品：<the-rate :score="userInfo.score" :isDisabled="true"></the-rate></div>
            </div>
      </div>
      <el-tabs v-model="tabActive" class="personal-tabs warp" @tab-click="handleClick">
        <div class="manage ub ub-ac" v-if="tabss[tabActive].isManage && isSelf">
            <span :class="['manage-switch iconfont icon-manage manage-span', manageText(isManage, 2)]" @click="manage">{{manageText(isManage, 1)}}</span>
            <span class="manage-delect manage-span" v-if="isManage" @click="delectWork">删除</span>
            <el-checkbox v-if="isManage" class="manage-select" v-model="selectAll" label="全选" size="large" @change="selectAllChange" />
        </div>
        <el-tab-pane :label="item.label + '（' + item.total +'）'" :name="index" v-for="(item, index) in tabss" :key="index">
            <div class="tab-content" v-loading="loading">
                <div class="tab-list" v-if="item.list">
                    <div class="ub ub-wrap" v-if="item.list.length > 0">
                        <tab-cell v-for="(subItem, subIndex) in item.list" 
                                  :key="subIndex" 
                                  :item="subItem" 
                                  :class="[(subIndex+1)%3 == 0 ? 'no-margin' : '']" 
                                  :isManage="isManage"
                                  :index="subIndex"
                                  :componentType="item.componentType"
                                  :isSelf="isSelf"
                                  @followAuthorSuccess="followAuthorSuccess"
                                  @checkItem="checkItem"></tab-cell>
                        <the-pagination :total="item.total" :current-page="page" :pageSize="num" @paginationChange="paginationChange"></the-pagination>
                    </div>
                    <el-empty description="暂无数据" v-else />
                </div>
            </div>
        </el-tab-pane>
      </el-tabs>
  </div>
</template>

<script>
import { pblWork } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import { mapGetters } from 'vuex'
import TheRate from '@/components/TheRate.vue'
import TabCell from './components/TabCell.vue'
import ThePagination from '../../components/ThePagination.vue'
export default {
  components: { TheHeader, TheRate, TabCell, ThePagination },
  data() {
      return {
        userInfo: null,
        rateColors: ['#ea4335', '#ea4335', '#ea4335'],
        tabActive: 0,
        tabs: [{
            index: 0,
            label: '我的作品',
            name: 'work',
            list: null,
            total: 0,
            type: 14,
            componentType: 1,
            isManage: true
        }, {
            index: 1,
            label: '作品收藏',
            name: 'collection',
            list: null,
            total: 0,
            type: 15,
            isSelf: true,
            componentType: 1,
            isManage: false
        }, {
            index: 2,
            label: '课程收藏',
            name: 'study',
            list: null,
            total: 0,
            type: 19,
            isSelf: true,
            componentType: 3,
            isManage: false
        }, {
            index: 3,
            label: '关注',
            name: 'follow',
            list: null,
            total: 0,
            type: 17,
            componentType: 2,
            isManage: false
        }, {
            index: 4,
            label: '粉丝',
            name: 'fans',
            list: null,
            total: 0,
            type: 18,
            componentType: 2,
            isManage: false
        }],
        page: 1,
        num: 9,
        loading: false,
        isManage: false,
        selectAll: false
      }
  },

  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
      refreshFn: this.refreshFn
    }
  },

  beforeCreate() {
      
  },

  created() {
      this.uuid = this.$route.query.uid
      if (!this.uuid && !this.getUserInfo) {
        this.$store.commit('setToIndex', true)
        this.$store.commit('setLoginAlertState', true)
        return
      }
      this.getData()
      if (!this.isSelf) {
          this.tabs[0].label = 'TA的作品'
      }
  },

  computed: {
      ...mapGetters(['getUserInfo']),

      /**
       * 是否是本人
       */
      isSelf() {
          return !this.$util.isDefine(this.uuid) || (this.getUserInfo &&  this.uuid == this.getUserInfo.uid)
      },

      getUserSex() {
          return this.userInfo.sex == 0 ? '男' : '女'
      },

      tabss() {
          return this.tabs.filter((item) => {
              return this.isSelf ? item : !item.isSelf
          })
      },

      getUserRole() {
          return function (role) {
              let roleStr = ''
              switch (role) {
                  case -1:
                      roleStr = '游客'
                      break
                  case 0:
                      roleStr = '学生'
                      break
                  case 2:
                      roleStr = '老师'
                      break
                  case 3:
                      roleStr = '校长'
                      break
                  case 4:
                      roleStr = '管理员'
                      break
                  case 5:
                      roleStr = '评审员'
                      break
                  default:
                      roleStr = ''
                      break
              }
              return roleStr
          }
      },

      manageText() {
          return function (isManage, type) {
            return isManage ? type == 1 ? '取消管理' : 'act' : type == 1 ? '管理' : ''
          }
      }
  },

  methods: {
    refreshFn() {
        this.getData()
    },
      /**
       * 获取数据
       */
      getData() {
        this.loading = true
        let params = {
            type: this.tabss[this.tabActive].type,
            uid: this.getUserInfo ? this.getUserInfo.uid : '',
            token: this.getUserInfo ? this.getUserInfo.token : '',
            uuid: this.uuid ? this.uuid : this.getUserInfo.uid,
            page: this.page,
            num: this.num
        }
        pblWork(params).then((res) => {
            console.log(res)
            this.loading = false
            this.tabss[this.tabActive].list = res.list
            if (res.userInfo) {
                if (this.isSelf) {
                    res.userInfo.role = this.getUserInfo.role
                } else {
                    res.userInfo.role = 9999
                }
                this.userInfo = res.userInfo
                this.getUserInfo.user_headimg = res.userInfo.user_headimg
                this.$store.commit('setUserInfo', this.getUserInfo)   
                for (let i = 0; i < this.tabss.length; i++) {
                    this.getTotal(i, this.tabss[i].index, res.userInfo)
                }
            } else {
                this.tabss[this.tabActive].total = res.num
            }
        }).catch((err) => {
            console.log(err)
            this.loading = false
        })
      },

      /**
       * 获取数据数量
       */
      getTotal(i, index, userInfo) {
          console.log(index, userInfo)
          let total = 0
          switch (index) {
              case 0:
                  total = userInfo.workNum
                  break;
              case 1:
                  total = userInfo.collectNum
                  break;
              case 2:
                  total = userInfo.videoNum
                  break;
              case 3:
                  total = userInfo.focusNum
                  break;
              case 4:
                  total = userInfo.fansNum
                  break;

              default:
                  break;
          }
          this.tabss[i].total = total
      },

      /**
       * tab切换
       */
      handleClick(e) {
          console.log(e)
          console.log(this.tabActive)
          this.page = 1
          this.getData()
          this.isManage = false
      },

      paginationChange(page) {
          console.log(page)
          this.page = page
          this.getData()
          this.selectAll = false
      },

      /**
       * 关注/取消关注成功
       */
      followAuthorSuccess(uid) {
          console.log(uid)
          console.log(this.tabs[this.tabActive].list)
          let index = this.tabss[this.tabActive].list.findIndex(item => item.ROW_ID == uid)
          console.log(index)
          this.tabss[this.tabActive].list[index].focusState = this.tabss[this.tabActive].list[index].focusState == 1 ? 0 : 1
      },

      /**
       * 点赞成功，修改状态
       */
      likeTapSuccess() {
          console.log(arguments)
          let index = this.tabss[this.tabActive].list.findIndex(item => item.PROJECT_ID == arguments[2].PROJECT_ID)
          this.tabss[this.tabActive].list[index].IS_LIKE = this.tabss[this.tabActive].list[index].IS_LIKE == 1 ? 0 : 1
          this.tabss[this.tabActive].list[index].LIKE_TIMES = this.tabss[this.tabActive].list[index].IS_LIKE == 1 ? this.tabss[this.tabActive].list[index].LIKE_TIMES + 1 : this.tabss[this.tabActive].list[index].LIKE_TIMES - 1

      },

      /**
       * 管理
       */
      manage() {
          if (this.tabs[this.tabActive].list.length == 0) {
              this.$message({
                type: 'warning',
                message: '暂无数据！'
              })
              return
          }
          this.isManage = !this.isManage
          if (!this.isManage) {
              this.selectAllData(2)
              this.selectAll = false
          }
      },

      /**
       * 全选按钮
       */
      selectAllChange() {
          if (this.selectAll) {
              this.selectAllData(1)
          } else {
              this.selectAllData(2)
          }
      },

      /**
       * 全选/取消全选
       * @param {Number} type 1 全选 2 取消全选
       */
      selectAllData(type) {
          for (let i = 0; i < this.tabs[this.tabActive].list.length; i++) {
            if (!this.tabs[this.tabActive].list[i].activity) {
              this.tabs[this.tabActive].list[i].isCheck = type == 1 ? true : false
            }
          }
      },

      /**
       * 选择数据
       */
      checkItem(params) {
          console.log(params)
          if (!this.isManage) return
          if (this.tabs[this.tabActive].list[params.index].activity) {
            this.$message({
                message: '已投稿作品不可删除',
                type: 'warning'
            })
            return
          }
          this.tabs[this.tabActive].list[params.index].isCheck = params.val
          for (let i = 0; i < this.tabs[this.tabActive].list.length; i++) {
              if (!this.tabs[this.tabActive].list[i].isCheck) {
                  this.selectAll = false
                  break;
              }

              if (i == this.tabs[this.tabActive].list.length - 1) {
                  this.selectAll = true
              }
          }

          console.log(this.selectAll)
      },

      /**
       * 删除作品
       */
      delectWork() {
          let arr = []
          for (let i = 0; i < this.tabs[this.tabActive].list.length; i++) {
              if (this.tabs[this.tabActive].list[i].isCheck) {
                  arr.push(this.tabs[this.tabActive].list[i].PROJECT_ID + '-1')
              }
          }
          if (arr.length == 0) {
              this.$message({
                type: 'warning',
                message: '请选择需要删除的作品！'
              })
              return
          }
          this.$confirm("是否确认删除选中作品?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
                this.loading = true
                let params = {
                    type: 20,
                    uid: this.getUserInfo.uid,
                    content: arr.join('_')
                }
                pblWork(params).then((res) => {
                    console.log(res)
                    this.$message({
                        type: 'success',
                        message: '删除成功'
                    })
                    this.$router.go(0)
                }).catch(() => {
                    this.loading = false
                })
          })
      },
  },
  watch: {
      $route: {
          handler() {
            this.$router.go(0)
          },
          deep: true
      }
  }
}
</script>

<style lang="scss">
.personal-center {
    .personal-header {
        position: relative;
        background-color: #ffffff;
        .second-banner {
            width: 100%;
            height: 357px;

            .el-image {
                width: 100%;
                height: 357px;
                img {
                    -webkit-filter: blur(10px);
                    filter: blur(10px);
                }
            }
        }

        .user-info {
            margin: -117px auto 0;
            .user-img {
                width: 184px;
                height: 184px;
                border-radius: 50%;
            }

            .user-name {
                font-size: 24px;
                margin-top: 25px;
                position: relative;

                span {
                    font-size: 12px;
                    color: #6e6e6e;
                    position: absolute;
                    right: -45px;
                    bottom: 3px;
                    cursor: pointer;

                    a {
                        color: #6e6e6e;
                    }
                }

                span:before {
                    margin-right: 2px;
                }
            }

            .user-sex {
                font-size: 12px;
                color: #999;
            }

            .user-tag {
                font-size: 12px;
                margin: 10px 0 15px;
                font-weight: 700;
            }

            .work-rate {
                font-size: 12px;
                color: #999;
                padding-bottom: 33px;
            }
        } 
    }

    .manage {
        cursor: pointer;
        color: #a0a0a0;
        position: absolute;
        right: 0;
        top: -99px;
        height: 84px;

        span.manage-span {
            padding:0 10px;
            display: inline-block;
            position: relative;
        }

        span.manage-span::after {
            content: "";
            width: 1px;
            height: 14px;
            background-color: #d3d3d3;
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -7px;
        }

        span.manage-switch::before {
            margin-right: 5px;
            font-size: 18px;
        }

        span.act {
            color: var(--el-color-primary);
        }

        span.manage-span:last-child:after {
            display: none;
        }

        span.manage-delect {
            color: red;
        }

        .manage-select {
            margin-left: 10px;

            .el-checkbox__input {
                .el-checkbox__inner {
                    width: 16px;
                    height: 16px;
                }

                .el-checkbox__inner::after {
                    height: 9px;
                    left: 5px;
                    top: 0px;
                    width: 4px;
                }
            }

            .el-checkbox__label {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

    .personal-tabs {
        .el-tabs__header {
            .el-tabs__nav-wrap {
                .el-tabs__nav-scroll {
                    .el-tabs__nav {
                        padding: 22px 0;
                        .el-tabs__active-bar {
                            display: none;
                        }
                        .el-tabs__item {
                            padding: 0 22px;
                            font-size: 16px;
                            color: #a0a0a0;
                        }

                        #tab-work {
                            padding-left: 0;
                        }

                        .is-active {
                            color: var(--el-color-second);
                        }
                    }
                }
            }

            .el-tabs__nav-wrap::after {
                display: none;
            }
        }

        .el-tabs__content {
            overflow: initial;
            .tab-content {
                min-height: 300px;

                .tab-list {
                    .tab-cell.manage-cell {
                        
                    }

                    .no-margin {
                        .tab-cell-item,
                        .tab-cell-item2 {
                            margin-right: 0px;
                        }
                    }

                    .pagination-model {
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>