<template>
  <div class="tab-user">
    <div class="ub ub-wrap" v-if="classData.list.length > 0">
        <div :class="['user-info ub ub-ver ub-ac', (index + 1) % 11 == 0 ? 'no-margin' : '']" v-for="(item, index) in classData.list" :key="index" >
            <user-img :userImg="item.userHeadimg" :userId="item.rowId" radius="small-circle"></user-img>
            <div class="user-name" @click="userNameTap(item.rowId)">{{item.name}}</div>
        </div>
    </div>
    <the-empty v-else></the-empty>
  </div>
</template>

<script>
import TheUser from '@/components/TheUser.vue'
import TheEmpty from '@/components/TheEmpty.vue'
import UserImg from '@/components/UserImg.vue'
export default {
  components: { TheUser, TheEmpty, UserImg },
  
  props: {
    classData: {
        type: Object,
        default: () => {
            return {}
        }
    },
  },

  data() {
      return {
          
      }
  },

  created() {
      console.log(this.classData)
  },

  methods: {
      userNameTap(uid) {
          this.$router.push(`/personal-center?uid=${uid}`)
      }
  }
}
</script>

<style lang="scss">
.tab-user {
    .user-info {
        margin-right: 21px; 
        .user-name {
            line-height: 38px;
            cursor: pointer;
        }
    }

    .user-info.no-margin {
        margin-right: 0;
    }
}
</style>