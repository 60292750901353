<template>
  <el-dialog
    :model-value="isLoginAlert"
    :close-on-click-modal="false"
    custom-class="default-dialog login-dialog"
    :before-close="handleClose"
  >
    <el-tabs
      v-model="activeName"
      :class="['login-tabs', loginType == 1 ? 'login-type' : 'other-type']"
      @tab-change="handleClick"
    >
      <el-tab-pane :label="loginTitle" name="accountLogin">
        <the-alert
          :show="errInfo.show"
          :title="errInfo.title"
          :type="errInfo.type"
          @closeAlert="closeAlert"
        ></the-alert>
        <!-- 登录 -->
        <div :class="['login-content', loginClass]">
          <el-input
            class="userPhone"
            v-model="userTel"
            placeholder="输入手机号"
            clearable
            autocomplete="new-tel"
            auto-complete="new-tel"
          ></el-input>
          <div class="code ub" v-if="loginType == 2 || loginType == 3">
            <el-input
              class="code-input"
              v-model="userCode"
              placeholder="验证码"
              clearable
              autocomplete="new-code"
              auto-complete="new-code"
            ></el-input>
            <el-button
              class="code-button default-button"
              type="primary"
              :disabled="codeDisabled"
              @click="sendCode(0)"
              v-text="codeDisabled ? sendCodeTime + 's' : '发送验证码'"
            ></el-button>
          </div>
          <el-input
            class="passWord"
            v-model="userPassWord"
            placeholder="输入密码"
            clearable
            type="password"
            autocomplete="new-password"
            auto-complete="new-password"
          ></el-input>
          <el-input
            class="passWord"
            v-model="passwordAgain"
            placeholder="再次输入密码"
            clearable
            type="password"
            v-show="loginType == 3"
            autocomplete="new-again-passwordss"
            auto-complete="new-again-passwordss"
          ></el-input>
        </div>

        <!-- 登录/注册按钮 -->
        <el-button
          class="login-button"
          type="primary"
          @click="loginButton"
          :loading="loginLoading"
          >{{
            loginType == 1
              ? "登 录"
              : loginType == 2
              ? "重新设置密码"
              : loginType == 3
              ? "注 册"
              : ""
          }}</el-button
        >
      </el-tab-pane>
      <el-tab-pane label="微信登录" name="wechartLogin">
        <div id="wechat-QR-code" class="ub ub-pc">
          <div class="loading" v-loading="true"></div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 切换登录/注册/忘记密码 -->
        <div class="login-info ub ub-pe">
          <el-checkbox @change="autoLoginCheck" class="ub-f1" v-model="autoLogin" v-if="loginType == 1"
            >下次自动登录</el-checkbox
          >
          <span class="register">
            <a
              v-if="loginType != 1"
              href="javascript:;"
              @click="setLoginType(1)"
              >登录</a
            >
            <a
              v-if="loginType != 2"
              href="javascript:;"
              @click="setLoginType(2)"
              >忘记密码</a
            >
            <a
              v-if="loginType != 3"
              href="javascript:;"
              @click="setLoginType(3)"
              >注册</a
            >
          </span>
        </div>
  </el-dialog>
</template>

<script>
import $ from "jquery";
import config from "../http/config";
import { mapGetters } from "vuex";
import { loginMsg } from "../http/api";
import TheAlert from "./TheAlert.vue";
export default {
  components: { TheAlert },
  props: {
    isLoginAlert: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeName: "accountLogin",
      errInfo: {
        title: "提示",
        show: false,
        type: "",
      },
      loginType: 1,
      autoLogin: true,
      userTel: "",
      userPassWord: "",
      passwordAgain: "",
      userCode: "",
      timer: "",
      codeDisabled: false,
      sendCodeTime: 300,
      loginLoading: false,
      // 正则验证
      numReg: /^1[345789]\d{9}$/,
      passWordReg: /^[a-zA-Z0-9]{6,15}$/,
    };
  },

  computed: {
    ...mapGetters(["getIsToIndex"]),

    loginTitle: function () {
      return this.loginType == 1
        ? "账号密码登录"
        : this.loginType == 2
        ? "找回密码"
        : "注册";
    },

    loginClass: function () {
      return this.loginType == 1
        ? "signIn"
        : this.loginType == 2
        ? "forgot-Password"
        : "register";
    },
  },

  methods: {
    /**
     * 弹窗关闭
     */
    handleClose() {
      sessionStorage.removeItem("loginType");
      this.$store.commit("setLoginAlertState", false);
      if (this.getIsToIndex) {
        this.$router.push("/");
        this.$store.commit("setToIndex", false);
      }
    },

    /**
     * tab切换
     */
    handleClick(e) {
      console.log(e);
      if (e == "wechartLogin") {
        if (
          $(
            "script[src='https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js']"
          ).length == 0
        ) {
          const wxLoginJs = document.createElement("script");
          wxLoginJs.type = "text/javascript";
          wxLoginJs.src =
            "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
          document.body.appendChild(wxLoginJs);
        }
        setTimeout(() => {
          //eslint disable next line
          new WxLogin({
            id: "wechat-QR-code",
            appid: "wx0bf3992f37d7a79a",
            scope: "snsapi_login",
            redirect_uri: config.base.host,
            state: Math.random(),
            style: "",
            href: "data:text/css;base64,QGNoYXJzZXQgIlVURi04IjsNCi5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOiAxNTBweDttYXJnaW4tdG9wOjBweDt9DQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQ0KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyNzBweDt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fSA=",
          });
        }, 2000);
      }
    },

    /**
     * 切换登录窗口
     * @param {Number} type 1 登录， 2 忘记密码， 3 注册
     */
    setLoginType(type) {
      this.loginType = type;
      this.userTel = "";
      this.userPassWord = "";
      this.passwordAgain = "";
      this.userCode = "";
      if (this.activeName == "wechartLogin") {
        this.activeName = 'accountLogin'
      }
    },

    loginButton() {
      if (!this.$util.isDefine(this.userTel)) {
        this.errInfo = {
          title: "请输入手机号！",
          show: true,
        };
        return false;
      }

      if (!this.numReg.test(this.userTel)) {
        this.errInfo = {
          title: "请输入正确手机号！",
          show: true,
        };
        return;
      }

      if (!this.$util.isDefine(this.userPassWord)) {
        this.errInfo = {
          title: "请输入密码",
          show: true,
        };
        return;
      }

      if (!this.passWordReg.test(this.userPassWord)) {
        this.errInfo = {
          title: "请输入正确密码！",
          show: true,
        };
        return;
      }

      if (!this.$util.isDefine(this.passwordAgain) && this.loginType == 3) {
        this.errInfo = {
          title: "请再次输入密码",
          show: true,
        };
        return;
      }

      if (this.userPassWord != this.passwordAgain && this.loginType == 3) {
        this.errInfo = {
          title: "两次密码不一致，请重新输入",
          show: true,
        };
        return;
      }

      if (!this.$util.isDefine(this.userCode) && this.loginType != 1) {
        this.errInfo = {
          title: "请输入验证码",
          show: true,
        };
        return;
      }

      if (this.loginType == 1) {
        this.login();
      } else if (this.loginType == 2) {
        this.forgotPassword(0);
      } else {
        this.register(3);
      }

      this.errInfo = {
        title: "",
        isshow: false,
      };
    },

    /**
     * 登录
     */
    login() {
      this.loginLoading = true;
      let params = {
        type: 1,
        tel: this.userTel,
        p: this.userPassWord,
      };
      console.log(params);
      loginMsg(params)
        .then((res) => {
          console.log(res);
          this.loginLoading = false;
          this.$message({
            message: "登录成功！",
            type: "success",
            showClose: true,
          });
          this.$store.commit("setLoginAlertState", false);
          localStorage.setItem("autoLogin", this.autoLogin);
          this.$store.commit("setUserInfo", res.userInfo);
          this.$router.go(0);
        })
        .catch(() => {
          this.loginLoading = false;
        });
    },

    /**
     * 发送验证码
     */
    sendCode() {
      if (!this.$util.isDefine(this.userTel)) {
        this.errInfo = {
          title: "请输入手机号！",
          show: true,
        };
        return false;
      }

      if (!this.numReg.test(this.userTel)) {
        this.errInfo = {
          title: "请输入正确手机号！",
          show: true,
        };
        return;
      }

      let params = {
        type: 2,
        role: this.getUserInfo ? this.getUserInfo.role : -1,
        tel: this.userTel,
        state: this.loginType == 2 ? 1 : this.loginType == 3 ? 0 : "", // 0注册 2更改手机号 1更改密码？
      };
      console.log(params);
      loginMsg(params).then((res) => {
        console.log(res);
        this.$message({
          message: "验证码发送成功！",
          type: "success",
          showClose: true,
        });

        this.codeDisabled = true;
        this.timeCountDown();
      });
    },

    /**
     * 倒计时
     */
    timeCountDown() {
      if (this.sendCodeTime === 0) {
        this.resetTimeCount();
      } else {
        this.sendCodeTime--;
        this.timer = setTimeout(() => {
          this.timeCountDown();
        }, 1000);
      }
    },

    /**
     * 重置倒计时
     */
    resetTimeCount() {
      this.sendCodeTime = 300;
      this.codeDisabled = false;
      clearInterval(this.timer);
    },

    /**
     * 是否自动登录
     */
    autoLoginCheck(value){
      localStorage.setItem("autoLogin", value);
    },

    /**
     * 忘记密码
     */
    forgotPassword() {
      this.loginLoading = true;
      let params = {
        type: 4,
        tel: this.userTel,
        code: this.userCode,
        p: this.userPassWord,
        state: 1,
      };
      loginMsg(params)
        .then((res) => {
          console.log(res);
          this.loginLoading = false;
          this.$message({
            message: "密码修改成功，请重新登录！",
            type: "success",
            showClose: true,
          });
          this.resetTimeCount();

          setTimeout(() => {
            this.setLoginType(1);
          }, 1500);
        })
        .catch(() => {
          this.loginLoading = false;
        });
    },

    /**
     * 注册
     */
    register() {
      let params = {
        type: 3,
        tel: this.userTel,
        code: this.userCode,
        p: this.userPassWord,
        state: 0,
      };
      loginMsg(params)
        .then((res) => {
          console.log(res);
          this.loginLoading = false;
          this.$message({
            message: "注册成功，请重新登录！",
            type: "success",
            showClose: true,
          });
          this.resetTimeCount();

          setTimeout(() => {
            this.setLoginType(1);
          }, 1500);
        })
        .catch(() => {
          this.loginLoading = false;
        });
    },

    /**
     * 关闭提示信息
     */
    closeAlert() {
      this.errInfo.show = false;
    },
  },

  watch: {
    isLoginAlert(newVal) {
      console.log(newVal);
      if (newVal) {
        let loginType = sessionStorage.getItem("loginType");
        this.setLoginType(loginType ? loginType : 1);
        this.activeName = "accountLogin";
        this.closeAlert();
      }
    },
  },
};
</script>

<style lang="scss">
.login-dialog {
  width: 400px;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__headerbtn {
    z-index: 9999;
  }

  .el-dialog__body {
    .el-tabs {
      .el-tabs__header {
        margin-bottom: 0;

        .el-tabs__nav-wrap {
          .el-tabs__active-bar {
            background-color: #000;
          }

          .el-tabs__item {
            height: 45px;
            font-size: 16px;
            font-weight: unset;
            color: var(--el-color-three);
          }

          .el-tabs__item.is-active {
            color: #000;
          }

          .el-tabs__active-bar {
            height: 1px;
          }
        }

        .el-tabs__nav-wrap::after {
          height: 1px;
        }
      }

      .el-tabs__content {
        min-height: 200px;
        position: relative;
        padding-top: 47px;
        box-sizing: border-box;

        .pbl-alert {
          position: absolute;
          top: 6px;
          left: 0;
        }

        .login-content {
          .el-input {
            margin-bottom: 20px;

            .el-input__inner {
              height: 40px;
              line-height: 40px;
              font-size: 14px;
              color: #000000;
              background-color: #eeeeee;
            }

            .el-input__inner:focus {
              background-color: #fff;
            }
          }

          .code {
            .el-button {
              background-color: unset;
              border: 0px;
              color: #000000;
              line-height: 40px;
              height: 40px;
              width: 142px;
              display: block;
              padding: 0;
            }
          }
        }

        .login-button {
          width: 100%;
          font-size: 16px;
          height: 38px;
          color: #121100;
          margin-top: 20px;
        }

        

        #wechat-QR-code {
          iframe {
            height: 230px !important;
          }

          .loading {
            height: 200px;
            width: 100%;
          }
        }
      }
    }

    .el-tabs.other-type {
      .el-tabs__nav {
        width: 100%;

        #tab-accountLogin {
          width: 100%;
          padding-right: 0;
          text-align: center;
        }

        #tab-wechartLogin {
          display: none;
        }
      }
    }
  }

  .login-info {
          line-height: 32px;
          margin-top: 10px;

          .el-checkbox {
            .el-checkbox__label {
              font-weight: 400;
              color: var(--el-color-five);
            }
          }

          .register {
            a {
              color: var(--el-color-five);
              padding: 0 13px;
              display: inline-block;
              position: relative;
            }

            a:first-child:before {
              content: "";
              width: 1px;
              height: 16px;
              display: inline-block;
              position: absolute;
              right: 0px;
              top: 50%;
              margin-top: -8px;
              background-color: var(--el-color-five);
            }

            a:last-child {
              padding-right: 0;
            }
          }
        }
}
</style>