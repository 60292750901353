<template>
  <footer class="pbl-footer">
      <div class="footer-content ub ub-ver">
        <div class="ub">
          <el-menu class="ub" v-if="routes.length > 0">
            <el-menu-item class="ub ub-ver" index="1" v-for="(item, index) in routesList" :key="index">
              <router-link v-bind:to="item.path" class="menu-title">{{ item.meta.title }}</router-link>
              <div class="sub-menu">
                  <el-menu-item class="ub" :index="(index + 1 + '') + (subIndex + 1 + '')" v-for="(subItem, subIndex) in item.children" :key="subIndex"><router-link v-bind:to="subItem.path" class="menu-title">{{ subItem.meta.title }}</router-link></el-menu-item>
              </div>
            </el-menu-item>
          </el-menu>
          <div class="message message-product">
            <div class="menu-title">产品</div>
              <div class="menu-sub-title"><a href="http://codefrog.zdwkj.com/" target="_blank">呱呱云</a></div>
              <div class="menu-sub-title"><a href="http://www.zdwkj.com/" target="_blank">模考中心</a></div>
              <div class="menu-sub-title"><a href="javascript:void(0);" >Python</a></div>
          </div>
          <div class="message message-download">
            <div class="menu-title">服务说明</div>
              <div class="menu-sub-title"><router-link to="/about-us/legal-declaration">法律声明</router-link></div>
              <div class="menu-sub-title"><router-link to="/user-agreement">用户协议</router-link></div>
              <div class="menu-sub-title"><router-link to="/juveniles-agreement">未成年人信息保护规则</router-link></div>
          </div>
          <div class="message message-tel">
            <div class="menu-title">服务热线</div>
            <div class="menu-sub-title">18162618661</div>
          </div>
          <div class="message message-contact">
            <div class="menu-title">联系我们</div>
            <div class="menu-sub-title"><img src="../assets/image/contact_us.png" alt="" srcset=""></div>
          </div>
          <div class="footer-logo"><img src="../assets/image/footer_logo.png" alt="" srcset=""></div>
        </div>
        <div class="message-address ub ub-f1">
          <div class="ub ub-f1">Copyright © 2016 All Right Reserved 武汉智多蛙教育科技有限公司</div>
          <div class="ub ub-ac">
            <img src="../assets/image/guohui.png" alt="" srcset="" /><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42010302002059" target="_blank">鄂公网安备 42010302002059号</a>
            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="noopener noreferrer">鄂ICP备19022715号</a>
            <img :src="require('../assets/image/gswj.png')" width="19" height="19" title="武汉网监电子标识" alt="武汉网监电子标识" border="0">
            <a href="http://wljg.scjgj.wuhan.gov.cn:80/whwjww/indexquery/indexqueryAction!dizview.dhtml?chr_id=55ff813bea859e6c7f7c7094e1789a42&amp;bus_ent_id=160000011096085425&amp;bus_ent_chr_id=6376cd1ff3304363b62ebdea4de498a1" target="_blank">工商网监</a>
          </div>
        </div>
    </div>
  </footer>
</template>

<script>
export default {
    data() {
      return {
        routes: []
      }
    },

    mounted() {
        this.routes = this.$router.options.routes
        let filterRoutes = JSON.parse(JSON.stringify(this.routes));
        filterRoutes = filterRoutes.filter((item) => {
          return item.meta.isFooterMenu
        })
        let routesList = []
        filterRoutes.forEach((item, index) => {
            routesList[index] = item
            routesList[index].children = item.children.filter((subItem) => {
            return subItem.meta.isFooterMenu
          })
        })
        this.routesList = routesList
    },

    computed: {
    }
}
</script>

<style lang="scss">
.pbl-footer {
    width: 100%;
    padding: 32px 0;
    color: #b5b6c5;
    background-color: #3d3e49;
    margin-top: 60px;
    
    .footer-content {
      width: 1190px !important;
      margin: 0 auto;
      height: 100%;

      .menu-title {
        font-size: 16px;
        color: #b5b6c5;
        margin-bottom: 15px;
      }

      .menu-sub-title {
        font-size: 14px;

        a {
          color: #b5b6c5;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .el-menu {
          background-color: unset;  
          border: 0px;

          .el-menu-item {
            height: auto;
            align-items: flex-start;
            width: 184px;
            line-height: 30px;
            padding-left: 53px !important;
            padding-right: 0px;
            box-sizing: border-box;
            
            a {
              line-height: 30px;
            }

            a:hover {
              text-decoration: underline;
            }

            .sub-menu {
              li {
                padding-left: 0px !important;
                color: #b5b6c5;
                a {
                  font-size: 14px;
                  margin-bottom: 0;
                }
              }
            }
          }

          .el-menu-item:hover {
            background-color: unset;
          }
      }

      .message {
        width: 195px;
        line-height: 30px;
        padding-left: 53px;
        box-sizing: border-box;
      }

      .message-contact {
        text-align: center;
          img {
            width: 92px;
          }
      }

      .footer-logo {
        margin-left: 80px;
        margin-top: 35px;

        img {
          width: 147px;
        }
      }

      .message-address {
        font-size: 14px;
        box-sizing: border-box;
        padding-left: 53px;
        margin-top: 66px;

        a {
          margin-right: 10px;
          color: #b5b6c5;
        }

        img {
          margin-right: 5px;
        }
      }
    }
}
</style>