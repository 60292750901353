<template>
        <el-input 
            :class="[icon(item.mold)] "
            v-model="value" 
            @input="change"
            type="text"
            :maxlength="maxLength"
            show-word-limit />
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: () => {
                return {}
            }
        },
        maxLength: {
            type: Number,
            default: -1
        }
    },
    data() {
        return {
            value: '',
            errTitle: '请输入内容',
            showActErr: false
        }
    },
    computed: {
        icon() {
            return function(mold) {
                switch (mold) {
                    case 'phone':
                        return 'iconfont icon-shouji';
                    case 'name':
                        return 'iconfont icon-touxiang';
                
                    default:
                        break;
                }
            }
        }
    }, 
    methods: {
        change(value) {
            console.log(value)
            this.$emit('setData', this.index, value)
        }
    },
    mounted() {
    },  
    watch: {
        item: {
            handler(newVal) {
                if (newVal.value) {
                    this.value = newVal.value
                }
            },
            deep: true
            
        }
    }
}
</script>

<style lang="scss">

</style>