<template>
  <div class="pbl-rate">
      <el-rate :model-value="score" :disabled="isDisabled" :colors="rateColors" disabled-void-color="#dcdcdc" @change="rateChange"></el-rate>
  </div>
</template>

<script>
export default {
    props: {
        score: {
            type: Number,
            default: 0
        },

        isDisabled: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            rateColors: ['#ea4335', '#ea4335', '#ea4335']
        }
    },

    methods: {
        rateChange(val) {
            this.$emit('rateChange', val)
        }
    }
}
</script>

<style lang="scss">
.pbl-rate {
    .el-rate__item {
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
    }
}
</style>