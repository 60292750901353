let type = 'prod'
let env = require(`../env/${type}.js`);
let base = env.default
let host = base.host

let url = host + base.project
let scratch = base.scratch
let phone = base.phone

/* 正式服 prod */
/* let url = host + '/api/ycsj_pbl_program'
let scratch = '/scratch3.0' 
let phone = '/phone' */

/* 测试 (局域网)dev */
/* let url = host + '/api/ycsj_pbl_program'
let scratch = '/scratch3.0' */

/* 测试服 pre */
/* let url = host + '/new_api_test/ycsj_pbl_program_test/api'
let scratch = '/test_new/scratch3_test'
let phone = '/test_new/phone_test' */

let config = {
    base,
    url,
    loginMsg: url + '/LoginMsg',
    pblPageMsg: url + '/PblPageMsg',
    pblWork: url + '/PblWork',
    curriculumMsg: url + '/CurriculumMsg',
    shoppingMaterialMsg: url + '/ShoppingMaterialMsg',
    noticeMsg: url + '/NoticeMsg',
    messagePageMsg: url + '/MessagePageMsg',
    matchMsg: url + '/MatchMsg',
    teacherMsg: url + '/TeacherMsg',
    schoolMsg: url + '/SchoolMsg',
    classMsg: url + '/ClassMsg',
    resourcesMsg: url + '/ResourcesMsg',
    suggestMsg: url + '/SuggestMsg',
    releaseWorkMsg: url + '/ReleaseWorkMsg',
    activityMsg: url + '/ActivityMsg',
    scratchUrl: {
        phone: host + phone + '/index.html?env=' + (type == 'pre' ? 0 : 1), // 手机访问作品界面 env 0 测试 1 正式
        activityPhone: host + '/phone/#/activity?env=' + (type == 'pre' ? 0 : 1), // 手机访问活动界面 env 0 测试 1 正式
        play: host + scratch +'/player.html', // scratch界面
        create: host + scratch
    }
}

export default config