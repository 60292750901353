<template>
  <span :class="['iconfont like', isLike == 1 ? 'act icon-like-act' : 'icon-like']" @click="likeWork()">{{ likeNum }}</span>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    isLike: {
      type: Number,
      default: 0
    },

    likeNum: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapGetters([
      'getUserInfo'
    ]),
  },

  methods: {
    /**
     * 点赞作品
     */
    likeWork() {
        this.$parent.likeWork()
    }
  }
}
</script>

<style lang="scss" scoped>
.like {
    font-size: 12px;
    display: block;
    line-height: 24px;
    margin-right: 12px;
    font-weight: 500;
    cursor: pointer;
}

.like.icon-like::before {
  font-size: 18px;
  margin-top: -1px;
}

.like.icon-like-act::before {
    color: var(--el-color-four);
    font-size: 18px;
}
</style>