<template>
  <div :class="['search ub', size]">
      <el-input v-model="input" :placeholder="placeholder" autocomplete="new-text" clearable @clear="clearInput" @keyup.enter="inputBtn" @input="inputChange" />
      <el-button class="iconfont icon-sousuo default-button" @click="inputBtn"></el-button>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Please input'
    },

    size: {
      type: String,
      default: 'default'
    }
  },
created() {
  this.input='';
}
,
  data() {
    return {
      input: ''
    }
  },

  methods: {
    inputBtn() {
      if (this.input == '') {
        this.$message({
          type: 'warning',
          message: '请输入搜索内容'
        })
        return
      }
      this.$emit('inputChange', this.input)
    },

    clearInput() {
      this.$emit('clearInput')
    },

    inputChange(e) {
      console.log(e)
      if (e == '') {
        this.clearInput()
      }
    }
  }
}
</script>

<style lang="scss">
.search {
  height: 40px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;


  .el-input {
    width: 270px;
    input {
      width: 270px;
      height: 40px;
      border-color: #e6e6e6;
      background-color: #e6e6e6;
      border-radius: 20px;
      box-sizing: border-box;
      padding-right: 95px !important  ;
    }

    input:focus {
      background-color: #ffffff;
    }

    .el-input__suffix {
      position: absolute;
      right: 75px;
    }
  }

  .el-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 63px;
    height: 40px;
    border-radius: 20px;
    font-size: 24px;
  }
}

.search.small {
  height: 35px;
  .el-input__inner {
    height: 35px;
  }

  .el-button {
    height: 35px;
  }
}
</style>