<template>
  <div class="my-work">
    <div class="" v-if="classData.classList.length > 0">
      <work-list :workList="classData.classList"></work-list>
    </div>
    <the-empty v-else></the-empty>
  </div>
</template>

<script>
import WorkList from '@/components/WorkList.vue'
import TheEmpty from '@/components/TheEmpty.vue'
export default {
  components: { WorkList, TheEmpty },

  props: {
    classData: {
        type: Object,
        default: () => {
            return {}
        }
    },
  },
}
</script>

<style>

</style>