<template>
  <div class="emoji-model ub ub-ac">
    <div class="iconfont icon-biaoqing"></div>
    <div :class="['emoji-content ub ub ub-ver', emojiArr[active].class, isCloseComment ? 'close' : '']">
      <div class="ub ub-f1 ub-ver" v-if="!isCloseComment">
        <div class="emoji-list ub-f1">
          <el-image
            :class="[(index + 1) % 13 == 0 ? 'no-margin' : '']"
            :src="item.img"
            fit="cover"
            :title="item.title"
            v-for="(item, index) in emojiArr[active].images"
            :key="index"
            @click="imageTap(index)"
          ></el-image>
        </div>
        <div class="emoji-tab-menu">
          <ul class="ub">
            <li
              :class="[index == active ? 'act' : '']"
              v-for="(item, index) in emojiArr"
              :key="index"
              @click="tabTap(index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
      
      <div v-else>{{isAuthorText}}已关闭评论！</div>
    </div>
  </div>
</template>

<script>
import emojiArr from '../../../utils/enjoy'
export default {
  props: {
    isCloseComment: {
      type: Boolean,
      default: false
    },

    isAuthorText: {
      type: String,
      default: '作者'
    }
  },
  data() {
    return {
      flag: true,
      active: 0,
    };
  },

  created() {
    this.emojiArr = emojiArr
    console.log(emojiArr)
  },

  methods: {
    tabTap(index) {
      this.active = index;
    },

    /**
     * 选择表情
     */
    imageTap(index) {
      if (this.flag) {
        console.log('aaa', index)
        // let str = `<el-image src="${this.emojiArr[this.active].images[index].img}"></el-image>`
        this.$emit('imageTap', this.emojiArr[this.active].images[index].title)
        this.flag = false
      }
      setTimeout(() => {
        this.flag = true
      }, 1000)
    }
  },
};
</script>

<style lang="scss">
.emoji-model {
  position: relative;
  height: 100%;

  .icon-biaoqing {
    font-size: 28px;
    font-weight: bold;
    color: #8f8f8f;
    cursor: pointer;
  }

  .emoji-content {
    position: absolute;
    left: 0px;
    top: 50px;
    z-index: 99;
    width: 380px;
    height: 250px;
    border-radius: var(--item-border-radius);
    background-color: #fff;
    border: 1px solid #ccc;
    box-sizing: border-box;
    padding: 10px;
    display: none;

    .emoji-list {
      .el-image {
        margin-right: 6px;
        cursor: pointer;
      }

      .el-image.no-margin {
        margin-right: 0px;
      }

      .el-image.zdw {
        width: 50px;
      }
    }

    .emoji-tab-menu {
      ul {
        li {
          line-height: 32px;
          padding: 0 10px;
          cursor: pointer;
        }

        li.act {
          background-color: #8f8f8f;
          color: #fff;
        }
      }
    }
  }

  .emoji-content.zdw {
    .el-image {
      width: 50px;
    }
  }

  .emoji-content::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid #ccc;
    position: absolute;
    top: -9px;
    left: 6px;
  }

  .emoji-content::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 8px solid #ffffff;
    position: absolute;
    top: -7px;
    left: 6px;
  }
}

.emoji-model:hover {
  .emoji-content {
    display: flex;
  }
  .emoji-content.close {
    height: 40px;
    width: 150px;
    font-size: 14px;
    color: rgb(123, 122, 122);
  }
  .icon-biaoqing {
    color: var(--el-color-primary);
  }
}
</style>