<template>
  <el-button :class="['author-follow-btn', this.focus == 1 ? '' : 'default-button']" :type="this.focus == 1 ? 'info' : ''" @click="followAuthor" :loading="followLoading">{{ followText }}</el-button>
</template>

<script>
import { pblWork } from '../http/api'
import { mapGetters } from 'vuex'
export default {
    props: {
        uuid: {
            type: String,
            default: ''
        },

        focus: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            followLoading: false
        }
    },

    inject: ['followAuthorSuccess'],

    created() {
        console.log(this.focus)
    },

    computed: {
        ...mapGetters([
            'getUserInfo'
        ]),

        followText() {
            return this.focus == 1 ? '取消关注' : '关注 +'
        }
    },

    methods: {
        followAuthor() {
            console.log(this.getUserInfo)
            if (!this.$util.isDefine(this.getUserInfo)) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
            this.followLoading = true
            let params = {
                type: 8,
                uid: this.getUserInfo.uid,
                uid2: this.uuid,
                state: this.focus == 1 ? -1 : 0
            }
            pblWork(params).then((res) => {
                console.log(res)
                this.followLoading = false
                this.$message.success(`${this.focus == 1 ? '已取消关注' : '已关注'}`)
                this.$parent.followAuthorSuccess(this.uuid)
            }).catch(() => {
                this.followLoading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.author-follow-btn {
    width: 104px;
}

.author-follow-btn.act {
    background-color: unset;
    border-color: #ccc;
}
</style>