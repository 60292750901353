<template>
  <div class="shopping-mall" v-loading.fullscreen.lock="loading">
    <div class="shop-screen-model">
      <div class="shop-info ub ub-ac warp">
        <the-coin v-if="getUserInfo" :num="userCoins"></the-coin>
        <div class="ub-f1">
          <router-link to="/shopping-index/wares-task"><el-button class="button">去做任务</el-button></router-link>
          <router-link to="/shopping-index/wares-record"><el-button class="button">兑换记录</el-button></router-link>
        </div>
        <the-seartch
          @inputChange="inputChange"
          @clearInput="clearInput"
          placeholder="请输入素材名称"
        ></the-seartch>
      </div>

      <div class="search-tab ub ub-pc ub-ver warp">
        <!-- 筛选 -->
        <div class="search-tab-con ub ub-ac">{{ screenTypes.name }}：
          <ul class="ub ub-f1-item ub-wrap">
            <li :class="[screenTypes.active == index ? 'act' : '']" v-for="(item, index) in screenTypes.list" :key="index" @click="screenTypesTap(index)">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <!-- 主题 -->
        <div class="search-tab-con ub ub-ac" v-if="themTypes.list.length > 0">{{ themTypes.name }}：
          <ul class="ub ub-f1-item ub-wrap">
            <li :class="[themTypes.active == index ? 'act' : '']" v-for="(item, index) in themTypes.list" :key="index" @click="themTypesTap(index)">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <!-- 分类 -->
        <div class="search-tab-con ub ub-ac" v-if="classificationTypes.list.length > 0">{{ classificationTypes.name }}：
          <ul class="ub ub-f1-item ub-wrap">
            <li :class="[classificationTypes.active == index ? 'act' : '']" v-for="(item, index) in classificationTypes.list" :key="index" @click="classificationTypesTap(index)">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <!-- 类型 -->
        <div class="search-tab-con ub ub-ac" v-if="categoryTypes.list.length > 0">{{ categoryTypes.name }}：
          <ul class="ub ub-f1-item ub-wrap">
            <li :class="[categoryTypes.active == index ? 'act' : '']" v-for="(item, index) in categoryTypes.list" :key="index" @click="categoryTypesTap(index)">
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="wares-type-model">
      <ul class="wares-type warp ub">
        <li
          :class="index == seartchTypes.active ? 'act' : ''"
          v-for="(item, index) in seartchTypes.list"
          :key="index"
          @click="typeTap(index)"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <list-module
      v-if="warsData"
      :warsData="warsData"
      :seartchTypes="seartchTypes"
      :total="total"
      :pageSize="num"
      :currentPage="page"
      @paginationChange="paginationChange"
      :isWhetherHave="false"
      @exchangeSuccess="exchangeSuccess"
    ></list-module>
  </div>
</template>

<script>
import { shoppingMaterialMsg } from "../../../http/api";
import TheSeartch from "@/components/TheSeartch.vue";
import { mapGetters } from "vuex";
import ListModule from "./ListModule.vue";
import TheCoin from "@/components/TheCoin.vue";
export default {
  components: { TheSeartch, ListModule, TheCoin },
  data() {
    return {
      searchVal: "",
      sortType: '',
      shelfOrExchange: '',
      seartchTabs: [
      ],
      screenTypes: {
        name: "筛选",
        active: 0,
        list: [{ name: "按分类", id: 1}, { name: "按主题", id: 2 }]
      },
      themTypes: {
        name: "主题",
        active: 0,
        list: []
      },
      classificationTypes: {
        name: "分类",
        active: 0,
        list: []
      },
      categoryTypes: {
        name: "类别",
        active: 0,
        list: []
      },
      seartchTypes: {
        active: 0,
        list: ["最新上架", "最多兑换"],
      },
      loading: false,
      warsData: null,
      userCoins: 0,
      total: 0,
      page: 1,
      num: 15,
    };
  },

  created() {
    this.getData();
    // console.log(this.seartchTabsFilter)
  },

  computed: {
    ...mapGetters(["getUserInfo"]),

    getTypeName() {
      return function (index, subItem) {
        return index == 0 ? subItem.themeName : subItem.sortName;
      };
    },

    /* seartchTabsFilter() {
      return this.seartchTabs.filter(item => {
          return item.type.indexOf(1) != -1
        })
    } */
  },

  methods: {
    /**
     * 筛选
     */
    screenTypesTap(index) {
      this.screenTypes.active = index
      this.themTypes.list = []
      this.themTypes.active = 0
      this.classificationTypes.list = []
      this.classificationTypes.active = 0
      this.categoryTypes.list = []
      this.categoryTypes.active = 0
      this.page = 1
      this.getData();
    },

    /**
     * 主题
     */
    themTypesTap(index) {
      this.themTypes.active = index
      this.page = 1
      this.getData()
    },

    /**
     * 分类
     */
    classificationTypesTap(index) {
      this.classificationTypes.active = index
      this.categoryTypes.list = this.classificationTypes.list[index].list
      this.categoryTypes.active = 0
      this.page = 1
      this.getData();

    },

    /**
     * 类型
     */
    categoryTypesTap(index) {
      this.categoryTypes.active = index
      this.getData();
    },

    /**
     * 筛选
     */
    searchTap(index, subIndex) {
      console.log(index, subIndex);
      this.seartchTabs[index].active = subIndex;
      this.getData();
    },

    /**
     * 筛选
     */
    typeTap(index) {
      this.seartchTypes.active = index;
      this.getData();
    },

    /**
     * 搜索
     */
    inputChange(val) {
      this.searchVal = val;
      this.getData();
    },

    /**
     * 清空搜索
     */
    clearInput() {
      this.searchVal = ''
      this.getData()
    },

    /**
     * 获取数据
     */
    getData() {
      this.loading = true;
      console.log(this.themTypes.list)
      console.log(this.themTypes.active)
      console.log()
      let params = {
        type: 1,
        userId: this.getUserInfo ? this.getUserInfo.uid : '',
        word: this.searchVal,
        materialOrUser: 1,
        screenType: this.screenTypes.list[this.screenTypes.active].id, // 1分类 2主题
        themeType: this.classificationTypes.list.length > 0 ? this.classificationTypes.list[this.classificationTypes.active].themeType : 0,
        sortType: this.categoryTypes.list.length > 0 ? this.categoryTypes.list[this.categoryTypes.active].sortType : '',
        shelfOrExchange: this.seartchTypes.active + 1,
        topicType: this.themTypes.list.length > 0 ? this.themTypes.list[this.themTypes.active].topicType : '',
        pageNo: this.page,
        pageSize: this.num,
      };
      console.log(params);
      shoppingMaterialMsg(params)
        .then((res) => {
          console.log(res);
          if (this.screenTypes.active == 0) {
            this.classificationTypes.list = res.data.shoppingSortsList
          } else {
            this.themTypes.list = res.data.shoppingSortsList
          }
          console.log(this.classificationTypes)
          this.total = res.data.page.totalCount;
          this.warsData = res.data.page.items;
          this.userCoins = res.data.userCoins
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     * 分页
     */
    paginationChange(val) {
      console.log(val);
      this.page = val;
      this.getData();
    },

    /**
     * 兑换成功
     */
    exchangeSuccess(rowId) {
      let index = this.warsData.findIndex((item) => {
        return item.rowId == rowId
      })
      this.warsData[index].whetherHave = 1
    }
  },
};
</script>

<style lang="scss">
.shopping-mall {
  .shop-screen-model {
    .shop-info {
      height: 40px;
      margin: 23px auto 49px;

      .gold-coins {
        margin-right: 18px;
      }

      .el-button.button {
        width: 111px;
        height: 33px;
        border-radius: 30px;
        color: #000000;
        font-size: 16px;
        font-weight: 400;
        margin-right: 10px;
      }
    }

    .search-tab {
      margin-bottom: 22px;

      .search-tab-con {
        min-height: 33px;
        margin-top: 14px;
        padding-bottom: 16px;

        ul {
          li {
            min-width: 114px;
            padding: 0 5px;
            height: 33px;
            line-height: 33px;
            margin-right: 10px;
            text-align: center;
            cursor: pointer;
          }

          li.act {
            background-color: #ffd100;
            border-radius: 30px;
          }
        }
      }

      .search-tab-con:first-child {
        border-bottom: 1px solid #eeeeee;
      }
    }
  }

  .wares-type-model {
    background-color: #f4f4f4;
    .wares-type {
      line-height: 78px;

      li {
        cursor: pointer;
        padding: 0 22px;
        position: relative;
        color: #787878;
      }

      li:first-child {
        padding-left: 0;
      }

      li:before {
        content: "";
        width: 1px;
        height: 14px;
        background-color: #6e6e6e;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
      }

      li:last-child::before {
        display: none;
      }

      li.act {
        color: #000000;
      }
    }
  }
}
</style>