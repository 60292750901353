<template>
  <div class="search-model ub">
      <the-seartch :placeholder="placeholder" @inputChange="inputChange" @clearInput="clearInput"></the-seartch>
      <ul class="search-type ub">
          <li @click="searchTypeTap(index, item.id)" :class="[item.id == sort ? 'act' : '']" v-for="(item, index) in searchTypes.list" :key="index">{{item.title}}</li>
      </ul>
  </div>
</template>

<script>
import TheSeartch from './TheSeartch.vue'
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Please input'
    },

    sort: {
      type: String,
      default: '0'
    },
    searchTypes:{
      type:Object,
      default: () => {
        return {
          list: [{
          title: '浏览量',
          id: '0'
        }, {
          title: '最热',
          id: 'heat'
        }, {
          title: '最新',
          id: 'now'
        }]
        };
      }
    }
  },

  components: { TheSeartch },

  data() {
    return {
      
    }
  },

  methods: {
    /**
     * 排序
     */
    searchTypeTap(index, id) {
      this.searchTypes.active = index
      console.log(id)
      this.$emit('searchTypeTap', id)
    },

    /**
     * 搜索
     */
    inputChange(val) {
      this.$emit('inputChange', val)
    },

    /**
     * 清空搜索框
     */
    clearInput() {
      this.$emit('clearInput')
    }
  }
}
</script>

<style lang="scss">
.search-model {
    .search-type {
      li {
        line-height: 40px;
        position: relative;
        padding: 0 10px;
        font-size: 14px;
        cursor: pointer;
      }

      li:after {
        content: "";
        width: 1px;
        height: 14px;
        background-color: #d3d3d3;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
      }

      li:last-child:after {
        display: none;
      }

      li.act {
        font-weight: 500;
        color: var(--el-color-primary);
      }
    }
}
</style>