<template>
  <div class="left-menu">
      <el-row class="tac">
        <el-col>
          <el-menu
            default-active="0"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
          >
            <el-menu-item :index="index + 1" v-for="(item, index) in menu" :key="index">
                <template #title><router-link :class="['space' + item.meta.title.length]" v-bind:to="item.path">{{ item.meta.title }}</router-link></template>
            </el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: []
    }
  },

  created() {
  },

  mounted() {
    console.log('aa')
    this.getSubMenu()
  },

  methods: {
    getSubMenu() {
      let routes = this.$router.options.routes
      console.log(routes)
      let path = this.$route.matched[0].path
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].path == path) {
          this.menu = routes[i].children.filter((item) => {
            return item.meta.isSecondMenu
          })
          console.log(this.menu)
        }
      }
      
      console.log(this.menu)
    }
  }
}
</script>

<style lang="scss">
.left-menu {
  width: 233px;
  margin-right: 40px;
  background-color: #fff;
  padding: 55px 0;

  .el-menu {
    border: 0px;
    .el-menu-item {
      height: 60px;
      padding: 0 !important;
      overflow: hidden;
        a {
          width: 100%;
          height: 60px;
          line-height: 60px;
          display: inline-block;
          width: 100%;
          color: #000000;
          font-size: 16px;
          text-align: center;
        }

        a.space2 {
            letter-spacing: 32px;
            text-indent: 32px;
        }

        a.router-link-active {
          background-color: var(--el-color-primary);
          color: #000000;
        }
    }
  }
}
</style>