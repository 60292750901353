<template>
  <div class="account-info">
      <the-header v-if="getUserInfo.name && getUserInfo.tel"></the-header>
      <el-alert class="warp" effect="dark" title="请先完善个人信息" center type="warning" v-else></el-alert>
      <div class="content-body warp">
          <div class="title">基本信息</div>
          <the-alert :show="errInfo.show" :title="errInfo.title" :type="errInfo.type" @closeAlert="closeAlert"></the-alert>
          <div class="user-info">
            <el-form :model="userForm" label-width="310px">
              <el-form-item label="昵称：">
                <el-input v-model="userForm.nickname" clearable />
              </el-form-item>
              <el-form-item label="真实姓名：">
                <el-input v-model="userForm.name" clearable />
              </el-form-item>
              <el-form-item label="性别：">
                <el-radio-group class="ub" v-model="userForm.sex">
                  <el-radio :label="item.label" v-for="(item, index) in gender" :key="index" >{{item.value}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item class="ub" label="年龄：">
                <el-select v-model="userForm.age" placeholder="请选择你的年龄">
                  <el-option :label="item.label" :value="item.label" v-for="(item, index) in ageOptions" :key="index" />
                </el-select>
                <span class="tip">岁</span>
              </el-form-item>
              <el-form-item label="标签：">
                <el-input v-model="userForm.tag" clearable />
              </el-form-item>
              <el-form-item label="学校：" v-if="getUserInfo.role == 2">
                <el-input v-if="userForm.school_name" :model-value="userForm.school_name" disabled />
                <div v-else>暂无</div>
              </el-form-item>
            </el-form>
            <el-button class="default-button" @click="saveUserInfo" :loading="loading">保存{{loading ? '中...' : ''}}</el-button>
            <div class="user-image" @click="showUserImageDialog">
              <el-image :src="userForm.user_img" fit="cover"></el-image>
              <div class="tip">修改头像</div>
            </div>
          </div>
          <div class="title">账号安全</div>
          <div class="account-security">
             <el-form :model="userForm" label-width="310px">
               <el-form-item class="phone-model" label="绑定手机号：">
                <div class="ub ub-ac" v-if="userForm.tel">
                  <el-input v-model="userForm.tel" disabled />
                  <div class="handle" @click="handleTap(1, '更换手机号')">更换手机号</div>
                  <div class="handle" @click="handleTap(2, '更换密码')">更换密码</div>
                </div>
                <div v-else class="handle" @click="handleTap(4, '绑定手机号')">绑定手机号</div>
               </el-form-item>
               <el-form-item label="绑定微信号：">
                 <div class="wechart-name" v-if="getUserInfo.isBing == 1">{{getUserInfo.WeChatName}}</div>
                 <div class="handle" @click="handleTap(3, '绑定微信')" v-if="getUserInfo.isBing == 0 || (getUserInfo.isBing == 1 && getUserInfo.tel)">{{isBindWxText}}</div>
               </el-form-item>
             </el-form>
          </div>
      </div>
      <handle-dialog :isHandleDialog="isHandleDialog" :dialogTitle="dialogTitle" :dialogType="dialogType" :currentTel="userForm.tel" @handleClose="handleClose"></handle-dialog>
      <user-image-dialog :isUserImageDialog="isUserImageDialog" @userImageDialogClose="userImageDialogClose"></user-image-dialog>
  </div>
</template>

<script>
import { loginMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import { mapGetters } from 'vuex'
import TheAlert from '@/components/TheAlert.vue'
import HandleDialog from './components/HandleDialog.vue'
import UserImageDialog from './components/UserImageDialog.vue'
export default {
  components: { TheHeader, TheAlert, HandleDialog, UserImageDialog },
  data() {
    return {
      errInfo: {
        title: '提示',
        show: false,
        type: ''
      },
      loading:false,
      userForm: {
        
      },
      gender: [{
        label: '0',
        value: '男'
      }, {
        label: '1',
        value: '女'
      }],
      ageOptions: [],
      isHandleDialog: false,
      dialogTitle: '',
      dialogType: 1,
      // 用户头像
      isUserImageDialog: false
    }
  },

  created() {
    this.userForm = JSON.parse(JSON.stringify(this.getUserInfo))
    this.ageOptions = this.getAgeOptions()
    console.log(this.userForm)
  },

  computed: {
    ...mapGetters(['getUserInfo']),

    /**
     * @param {isBing} 1 已绑定 0 未绑定
     */
    isBindWxText() {
      return this.getUserInfo.isBing == 1 ? '解绑微信' : '绑定微信'
    }

  },

  methods: {
    getAgeOptions () {
      var ageOptions = [];
      for (var i = 4; i < 51; i++) {
        var str = {
          value: "选项" + i,
          label: i
        };
        ageOptions.push(str);
      }
      return ageOptions;
    },

    /**
     * 保存用户信息
     */
    saveUserInfo() {
      if (!this.$util.isDefine(this.getUserInfo.tel)) {
        this.errInfo = {
          title: '请先绑定手机号',
          show: true
        }
        return
      }

      if (!this.$util.isDefine(this.userForm.nickname)) {
        this.errInfo = {
          title: '请输入用户昵称',
          show: true
        }
        return
      }

      if (this.userForm.nickname.length > 40) {
        this.errInfo = {
          title: '昵称最多允许40个字！',
          show: true
        }
        return
      }

      if (!this.$util.isDefine(this.userForm.name)) {
        this.errInfo = {
          title: '请输入真实姓名！',
          show: true
        }
        return
      }

      if (this.userForm.name.length > 15) {
        this.errInfo = {
          title: '真实姓名最多允许15个字！',
          show: true
        }
        return
      }

      // 特殊字符验证
      var regEn = /[`!@#$%^&*()_+<>?:"{},.;'[\]]/im,
          regCn = /[·！#￥（——）：；“”‘、，|《。》？、【】[\]]/im,
          num = /[0-9]/;

      if (regEn.test(this.userForm.name) ||regCn.test(this.userForm.name) ||num.test(this.userForm.name)) {
        this.errInfo = {
          show: true,
          title: "真实姓名禁止特殊字符和数字！"
        };
        return;
      }

      if (!this.$util.isDefine(this.userForm.sex)) {
        this.errInfo = {
          title: '请选择性别！',
          show: true
        }
        return
      }

      if (!this.$util.isDefine(this.userForm.age)) {
        this.errInfo = {
          title: '请选择年龄！',
          show: true
        }
        return
      }

      if (!this.$util.isDefine(this.userForm.tag)) {
        this.errInfo = {
          title: '请输入标签！',
          show: true
        }
        return
      }

      if (this.userForm.tag.length > 15) {
        this.errInfo = {
          title: '标签最多允许15个字！',
          show: true
        }
        return
      }

      this.errInfo.show = false
      this.loading= true
      let params = {
        type: 6,
        uid: this.getUserInfo.uid,
        nickname: this.userForm.nickname,
        name: this.userForm.name,
        sex: this.userForm.sex,
        tag: this.userForm.tag,
        age: this.userForm.age,
        token: this.getUserInfo.token
      }
      console.log(params)
      loginMsg(params).then((res) => {
        console.log(res)
        this.loading = false
        this.$message({
          message: "修改成功！",
          type: "success",
          showClose: true
        });

        this.$store.commit('setUserInfo', this.userForm)
      }).catch(() => {
        this.loading = false
      })
    },

    /**
     * 关闭提示信息
     */
    closeAlert() {
      this.errInfo.show = false
    },

    /**
     * 账号安全修改
     * @param {Number} type 1 更换手机号 2 更换密码 3 绑定微信
     * @param {String} title
     */
    handleTap(type, title) {
      if (type == 3 && this.getUserInfo.isBing == 1) {
        // 解绑微信
        this.$confirm("此操作将解除微信绑定, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          customClass:'default-message',
          closeOnClickModal: false
        })
        .then(() => {
          this.unbindWeChart()
        })
        return
      }
      this.isHandleDialog = true
      this.dialogTitle = title
      this.dialogType = Number(type)
    },

    /**
     * 解绑微信
     */
    unbindWeChart() {
      let params = {
        type: 11,
        uid: this.getUserInfo.uid,
        token: this.getUserInfo.token
      }
      loginMsg(params).then((res) => {
        console.log(res)
        this.$message({
          message: "解绑成功！",
          type: "success",
          showClose: true
        });
        setTimeout(() => {
          this.getUserInfo.isBing = 0
          this.$store.commit('setUserInfo', this.getUserInfo)
        }, 1500)
      }).catch(() => {

      })
    },

    /**
     * 关闭弹窗
     */
    handleClose() {
      this.isHandleDialog = false
    },

    /**
     * 打开头像弹窗
     */
    showUserImageDialog() {
      this.isUserImageDialog = true
    },

    /**
     * 关闭头像弹窗
     */
    userImageDialogClose() {
      this.isUserImageDialog = false
      this.userForm.user_img = this.getUserInfo.user_img
    }
  }
}
</script>

<style lang="scss">
.account-info {
  .content-body {
    box-sizing: border-box;
    background-color: #fff;
    padding: 50px 80px 60px;
    margin: 40px auto 50px;
    
    .title {
      font-size: 18px;
      color: #121100;
      text-align: left;
      font-weight: 700;
      margin-bottom: 25px;
    }

    .el-form {
      .el-form-item {
        label.el-form-item__label {
          width: 310px;
          line-height: 40px;
          color: #000000;
          font-size: 14px;
        }

        .el-form-item__content {
          width: 354px;
          flex: none;
          .el-input {
            input {
              height: 40px;
              line-height: 40px;
            }
          }

          .el-select {
            flex: 1;
          }

          span.tip {
            padding: 0 10px;
          }

          .handle {
            color: var(--el-color-second);
            cursor: pointer;
          }

          .handle:hover {
            text-decoration: underline;
          }

          .wechart-name {
            margin-right: 20px;
          }
        }
      }

      .phone-model {
        .el-form-item__content {
          width: auto;
          .el-input {
            width: 354px;
            margin-right: 10px;
          }

          .handle {
            margin-right: 30px;
          }
        }
      }
    }

    .user-info {
      width: 920px;
      margin-top: 25px;
      position: relative;
      button {
        width: 355px;
        height: 40px;
        margin-left: 310px;
        margin-top: 15px;
      }

      .user-image {
        position: absolute;
        right: 0;
        top: 0;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        .el-image {
          width: 100px;
          height: 100px;
          border-radius: var(--item-border-radius);
        }
      }
    }
  }
}
</style>