<template>
  <div class="exchange-item ub ub-ver">
      <div class="exchange-item-header ub ub-pc">
          <div class="exchange-item-time">创建时间：{{item.createTime}}</div>
          <div class="exchange-item-title">订单编号：{{item.orderId}}</div>
      </div>
      <div class="exchange-item-content ub ub-ac">
          <el-image :src="item.materialCover" fit="contain" />
          <div class="exchange-item-info">
              <div class="wares-title">{{item.materialName}}</div>
              <div class="wares-tip">类型：{{item.sortName}}</div>
              <div class="wares-tip">稀有等级：{{item.rare}}</div>
              <div class="wares-tip">{{item.materialDescribe}}</div>
          </div>
          <div class="exchange-item-num exchange-item-text">
              <span v-if="item.state == 0 || item.state == -1">X{{orderCommoditySum}}</span>
              <el-input-number v-else v-model="orderCommoditySum" :disabled="true" title="该资源不可重复购买" />
            </div>
          <div class="exchange-item-coin exchange-item-text">{{item.exchangeValue}}金币</div>
          <div :class="['exchange-item-state exchange-item-text ub ub-ver', stateClass]">{{stateText}}<span v-if="item.time">剩余：{{item.time}}</span></div>
          <div class="exchange-item-button ub ub-f1-item ub-pe">
              <el-button type="info" v-if="item.state == 1" @click="orderTap(3, item.orderId)">取消订单</el-button>
              <el-button class="default-button" @click="orderTap(2, item.orderId)" v-if="item.state == 1">立即兑换</el-button>
              <el-button class="default-button" v-if="item.state == 0 || item.state == -1" @click="lookOrder">查看订单</el-button>
              <el-button class="" type="danger" v-if="item.state == -1" @click="orderTap(1, item.orderId)">删除订单</el-button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },

    data() {
        return {
            orderCommoditySum: 0
        }
    },

    created() {
        this.orderCommoditySum = this.item.orderCommoditySum
    },

    computed: {
        stateText() {
            switch (this.item.state) {
                case -1:
                    return '已取消'
                case 1:
                    return '待兑换'
                case 0:
                    return '已完成'
                default:
                    return '';
            }
        },

        stateClass() {
            switch (this.item.state) {
                case -1:
                    return 'state2'
                case 1:
                    return 'state1'
                case 0:
                    return 'state'
                default:
                    return '';
            }
        }
    },

    methods: {
        /**
         * 查看订单
         */
        lookOrder() {
            this.$parent.lookOrder(this.item)
        },

        /**
         * 取消订单
         * @param {Number} type 1 删除订单 2 兑换订单 3 取消订单
         * @param {Number} orderId 订单id
         */
        orderTap(type, orderId) {
            this.$parent.orderTap(type, orderId)
        }
    }
}
</script>

<style lang="scss">
.exchange-item {
    border-radius: 8px;
    overflow: hidden;

    .exchange-item-header {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        font-weight: 500;
        background-color: var(--el-color-primary);

        .exchange-item-time {
            line-height: 40px;
            position: absolute;
            left: 15px;
        }
    }

    .exchange-item-content {
        min-height: 137px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 500;

        .el-image {
            width: 128px;
            height: 98px;
            margin-right: 16px;
        }

        .exchange-item-info {
            width: 248px;
            padding-right: 10px;
            box-sizing: border-box;
            font-size: 12px;
            color: #a0a0a0;

            .wares-title {
                font-size: 14px;
                margin-bottom: 10px;
                color: #000000;
            }
        }

        .exchange-item-text {
            text-align: center;
            box-sizing: border-box;
            padding: 0 5px;
        }

        .exchange-item-num {
            width: 110px;

            .el-input-number {
                width: 100%;
                .el-input {
                    .el-input__inner {
                        padding-left: 0px !important;
                    }
                }
            }
        }

        .exchange-item-coin {
            width: 141px;
        }

        .exchange-item-state {
            width: 185px;
            color: green;

            span {
                color: red;
            }
        }



        .exchange-item-state.state1 {
            color: var(--el-color-second);
        }

        .exchange-item-state.state2 {
            color: #a0a0a0;
        }

        .exchange-item-button{
            .el-button {
                width: 130px;
                font-size: 16px;
                border-radius: 30px;
                font-weight: 400;
            }

            .el-button.el-button--info {
                background-color: #e5e5e5;
                color: #000000;
                border-color: #e5e5e5;
            }
        }
    }
}
</style>