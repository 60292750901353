<template>
  <div class="wares-task">
    <the-header></the-header>
    <div class="content-body warp">
      <the-breadcrumb :breadcrumbList="breadcrumbList"></the-breadcrumb>
      <div class="ub ub-ac">
        <the-coin class="ub-f1" :num="coinNum"></the-coin>
        <span class="task-rule iconfont icon-guize" @click="ruleTap">任务规则</span>
      </div>
      <div class="task-model ub ub-ver" v-loading="loading">
        <div class="task-item" v-for="(item, index) in taskList" :key="index">
          <div class="task-item-title ub ub-ac">{{ item.title }}</div>
          <div class="task-item-list ub ub-wrap ub-be">
            <div
              class="task-sub-item ub ub-ac ub-be"
              v-for="(subItem, subIndex) in item.list"
              :key="subIndex"
            >
              <div class="ub ub-ver">
                <div class="task-sub-item-title">{{ subItem.taskName }}</div>
                <div class="task-sub-item-info">
                  <span>金币：{{ subItem.taskCoin }}</span
                  ><span
                    >完成：{{ subItem.userSum }}/{{
                      subItem.taskSum
                    }}</span
                  >
                </div>
              </div>
              <el-button
                :class="['enroll-button', stateClass(subItem), 'button'+item.type]"
                :disabled="stateDisabled(subItem)"
                :type="subItem.userTaskState == 1 ? 'info' : ''"
                @click="taskTap(item.type, subItem)"
                :loading="subItem.buttonLoading"
                >{{ stateText(subItem) }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <rule-dialog :isRuleDialog="isRuleDialog" @closeRuleDialog="closeRuleDialog"></rule-dialog>
  </div>
</template>

<script>
import config from '../../http/config'
import { shoppingMaterialMsg } from "../../http/api";
import TheHeader from "@/components/TheHeader.vue";
import TheBreadcrumb from "@/components/TheBreadcrumb.vue";
import TheCoin from "@/components/TheCoin.vue";
import { mapGetters } from "vuex";
import RuleDialog from './components/RuleDialog.vue';
export default {
  components: { TheHeader, TheBreadcrumb, TheCoin, RuleDialog },
  data() {
    return {
      breadcrumbList: [
        {
          name: "资源库",
          path: "/shopping-index",
        },
        {
          name: "做任务",
        },
      ],
      taskList: [
        {
          title: "每日任务",
          type: 1,
          list: [],
        },
        {
          title: "每周任务",
          type: 2,
          list: [],
        },
        {
          title: "长期任务",
          type: 3,
          list: [],
        },
      ],
      loading: false,
      coinNum: 0,
      isRuleDialog: false
    };
  },

  created() {
    this.getData();
  },

  computed: {
    ...mapGetters(["getUserInfo"]),

    stateText() {
      return function (item) {
        let title = "";
        console.log(item.taskName)
        switch (item.userTaskState) {
          case 0:
            if (item.taskName == "每日签到") {
              title = "签到";
            } else if (item.taskName == "每日点赞" || item.taskName == "每日收藏") {
              title = "去完成";
            } else if (item.taskName == "每周作品发布") {
              title = "去发布";
            } else {
              title = "未完成";
            }
            return title;
          case 1:
            if (item.taskName == "每日签到") {
              title = "已签到";
            } else if (item.taskName == "每日点赞" || item.taskName == "每日收藏") {
              title = "已完成";
            } else if (item.taskName == "每周作品发布") {
              title = "已发布";
            } else {
              title = "已完成";
            }
            return title;
          default:
            return "";
        }
      };
    },

    stateClass() {
      return function (item) {
        switch (item.userTaskState) {
          case 0:
            return "default-button";
          case 1:
            return "";
          default:
            return "";
        }
      };
    },

    stateDisabled() {
      return function (item) {
        switch (item.userTaskState) {
          case 0:
            return false;
          case 1:
            return true;
          default:
            return "";
        }
      };
    },
  },

  methods: {
    getData() {
      this.loading = true;
      let params = {
        type: 2,
        userId: this.getUserInfo ? this.getUserInfo.uid : "",
      };
      shoppingMaterialMsg(params)
        .then((res) => {
          console.log(res);
          this.coinNum = res.data.userCoins;
          this.taskList[0].list = res.data.everydayList;
          this.taskList[1].list = res.data.weeklyList;
          this.taskList[2].list = res.data.longTermList;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    /**
     * 完成任务
     * @param {Number} type 大模块类型 1 每日任务 2 每周任务 3 长期任务
     * @param {Obeject} item 小模块数据
     */
    taskTap(type, item) {
      if (type == 3) {
        return
      } else {
        if (item.taskName == '每日签到') {
          this.signIn(item)
        } else if (item.taskName == '每日点赞' || item.taskName == '每日收藏') {
          this.$router.push('/work-center')
        } else if (item.taskName == '每周作品发布') {
          // window.open(config.scratchUrl.create, '_blank')
          this.$router.push('/')
        }
      }
    },

    /**
     * 签到
     */
    signIn(item) {
      item.buttonLoading = true
      let params = {
        type: 7,
        userId: this.getUserInfo ? this.getUserInfo.uid : "",
        taskId: item.taskId,
        taskType: item.taskType
      };
      shoppingMaterialMsg(params)
        .then((res) => {
          console.log(res);
          item.userSum = 1
          item.userTaskState = 1
          item.buttonLoading = false;
          this.coinNum = this.coinNum + item.taskCoin
        })
        .catch(() => {
          item.buttonLoading = false;
        });
    },

    /**
     * 查看规则
     */
    ruleTap() {
      this.isRuleDialog = true
    },

    /**
     * 关闭规则弹窗
     */
    closeRuleDialog() {
      this.isRuleDialog = false
    }
  },
};
</script>

<style lang="scss">
.wares-task {
  .task-rule {
    color: #606266;
    cursor: pointer;
  }

  .task-rule::before {
    margin-right: 5px;
  }

  .task-rule:hover {
    color: var(--el-color-primary);
  }

  .task-model {
    min-height: 500px;
    margin-bottom: 40px;
    margin-top: 40px;

    .task-item {
      margin-bottom: 40px;
      .task-item-title {
        height: 40px;
        line-height: 40px;
        position: relative;
        font-size: 26px;
        margin: 0px 0 20px;
      }

      .task-item-title::before {
        content: "";
        display: inline-block;
        width: 15px;
        height: 37px;
        border-radius: 10px;
        background-color: #ffd100;
        margin-right: 25px;
      }

      .task-item-list {
        .task-sub-item {
          width: 586px;
          height: 170px;
          background-color: #fff;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 25px;
          margin-top: 20px;

          .task-sub-item-title {
            font-size: 18px;
            margin-bottom: 20px;
          }

          .task-sub-item-info {
            span {
              display: inline-block;
              width: 130px;
              padding-right: 10px;
            }

            span:last-child {
              color: #787878;
            }
          }

          .enroll-button {
            width: 111px;
            border-radius: 30px;
          }

          .enroll-button.button3 {
            cursor: default;
          }
        }
      }
    }
  }
}
</style>