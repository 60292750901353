<template>
  <div class="left-menu-content">
      <slot></slot>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.left-menu-content {
    width: 943px;
    box-sizing: border-box;
    background-color: #fff;
    padding: 55px 0;
    font-size: 14px;
    overflow: hidden;

    .title {
        font-size: 24px;
        text-align: center;
        line-height: 24px;
        margin-bottom: 40px;
    }

    p {
        padding: 0 26px;
        line-height: 28px;
        margin-bottom: 10px;
        color: #000;
    }

    p.indent {
        text-indent: 2em;
    }
}
</style>