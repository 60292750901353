<template>
    <div class="user-cell ub ub-ac">
        <el-image :src="item.USER_HEADIMG" class="user-img" fit="cover"></el-image>
        <div class="user-name ub-f1-item text-hidden" :title="item.NICKNAME">{{item.NICKNAME}}</div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: ()=> {
                return {}
            } 
        }
    }
}
</script>

<style lang="scss" scoped>
.user-cell {
    width: 180px;
    height: 60px;
    margin-bottom: 40px;
    margin-right: 28px;
    padding: 10px;
    .el-image {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 14px;
    }
}

.user-cell:hover {
    background-color: #f5f7f7;
    color: var(--el-color-primary-hover);
    cursor: pointer;
}
</style>