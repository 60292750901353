<template>
  <div class="join-in">
      <div class="join-title">合作加盟</div>
      <div class="join-sub-title">投资未来的人，才能拥有未来！</div>
      <!-- 我们的优势 -->
    <div class="join-item">
      <div class="join-item-title">我们的优势</div>
      <div class="join-sub-item-module ub ub-wrap">
        <div class="join-sub-item" v-for="(item, index) in advantage" :key="index">
          <div class="join-sub-item-title">{{item.title}}</div>
          <div class="join-sub-item-con">{{item.text}}</div>
        </div>
      </div>
    </div>

    <!-- 服务支持 -->
    <div class="join-item seaver">
      <div class="join-item-title">服务支持</div>
      <div class="join-item-subtitle">运营方案：从招生、活动到运营提供完整的整合方案。</div>
      <div class="join-sub-item-module ub">
        <div class="join-sub-item" v-for="(item, index) in seaver" :key="index">
          <div class="join-sub-item-title">{{item.title}}</div>
          <div class="join-sub-item-con">
            <p v-for="(subItem, subIndex) in item.text" :key="subIndex">{{subItem}}</p>
          </div>
        </div>
      </div>
    </div> 

    <!-- 加盟条件 -->
    <div class="join-item">
      <div class="join-item-title">加盟条件</div>
      <div class="join-sub-item-module clearfix">
        <div class="join-sub-item fl-l">
          <div class="join-sub-item-title">教学资质</div>
        </div>
        <div class="join-sub-item fl-l">
          <div class="join-sub-item-title">熟悉教研管理系统</div>
        </div>
        <div class="join-sub-item fl-l">
          <div class="join-sub-item-title">认同理念</div>
        </div>
        <div class="join-sub-item fl-l">
          <div class="join-sub-item-title">热爱教育</div>
        </div>
        <div class="join-sub-item fl-l">
          <div class="join-sub-item-title">经济条件</div>
        </div>
        <div class="join-sub-item fl-l">
          <div class="join-sub-item-title">有事业心责任心</div>
        </div>
      </div>
    </div> 
  </div>
</template>

<script>
export default {
    data() {
        return {
            advantage: [{
                title: '产品优势',
                text: '资深的课程研发专家设计，涵盖多学科内容，原创设计，掌握核心竞争力。'
            }, {
                title: '多年的培训经验，专业的精英研发团队',
                text: '专注于教育十几年，有多年的师训基础，高效的培训理念。'
            }, {
                title: '少儿编程一站式服务，一对一指导',
                text: '从课件、培训到运营，全方位服务，一对一进行校区指导运营。'
            }, {
                title: '品牌支持，辅助营销',
                text: '品牌课件，公众号，官方赛事支持！帮助校区打开知名度，辅助营销。'
            }],

            seaver: [{
                title: '招商引流',
                text: ['宣传方案', '招生方案']
            }, {
                title: '留存客户',
                text: ['完整课件资料', '优质的内容']
            }, {
                title: '促活用户',
                text: ['活动方案', '大赛活动']
            }, {
                title: '用户转化',
                text: ['作品宣传—大型门户网站']
            }, {
                title: '数据分析',
                text: ['市场数据分析', '行业发展动态']
            }]
        }
    }
}
</script>

<style lang="scss">
.join-in {
    .join-title {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
    }

    .join-sub-title {
        font-size: 18px;
        height: 60px;
        line-height: 60px;
        margin-bottom: 10px;
        text-align: center;
    }

    .join-item {
        .join-item-title {
            height: 54px;
            line-height: 54px;
            position: relative;
            font-size: 20px;
            padding-left: 33px;
            color: #282828;
            font-weight: bold;
            background: linear-gradient(to right,#f3f2f1 0,#ffffff 90%);
            background: -webkit-linear-gradient(to right,#f3f2f1 0,#ffffff 90%);
        }

        .join-item-title::before {
            content: '';
            width: 4px;
            height: 54px;
            background-color: #e55900;
            position: absolute;
            left: 0;
            top: 0;
        }

        .join-sub-item-module {
            width: 898px;
            margin-left: 33px;
            padding: 38px 0;

            .join-sub-item {
                width: 431px;
                margin-right: 18px;

                .join-sub-item-title {
                    line-height: 50px;
                    background-color: #e55900;
                    border-radius: 3px;
                    text-align: center;
                    color: #fff;
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 23px;
                }

                .join-sub-item-con {
                    height: 83px;
                    line-height: 30px;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                }
            }
        }
    }

    // 服务支持
    .join-item.seaver {
        .join-item-subtitle{
            height: 90px;
            line-height: 90px;
            padding: 0 33px;
            font-size: 16px;
            position: relative;
        }

        .join-item-subtitle::before{
            content: '';
            width: 7px;
            height: 7px;
            background-color: #e55900;
            border-radius: 50%;
            position: absolute;
            left: 17px;
            top: 41.5px;
        }

        .join-sub-item-module{
            width: 880px;
            padding-top: 0;
            margin: 0 auto;

            .join-sub-item{
                width: 176px;
                margin-right: 0;

                .join-sub-item-title{
                    height: 57px;
                    line-height: 57px;
                    background-color: initial;
                    background-image: url(../../assets/image/pbl_icon.png);
                    background-position: -80px -10px;
                }

                .join-sub-item-con {
                    p{
                        padding-left: 40px;
                        position: relative;
                    }

                    p::before{
                        content: '';
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #000;
                        position: absolute;
                        left: 24px;
                        top: 10px;
                    }
                }
            }

            .join-sub-item:last-child {
                .join-sub-item-title{
                    background-image: url(../../assets/image/pbl_icon.png);
                    background-position: -80px -67px;
                }
            }
        }
    }
}
</style>