<template>
  <div class="about-feedback ub ub-ver">
      <div class="feedback-input ub ub-be">
          <span class="title">意见建议</span>
          <el-input
            v-model="textarea"
            :rows="5"
            type="textarea"
            maxlength="300"
            show-word-limit
            resize="none"
            placeholder="请输入建议反馈内容！允许5--500字"
        />
      </div>
      <div class="feedback-input ub ub-ac">
          <span class="title iconfont icon-tianjiatupian">上传图片</span>
          <el-upload
            :action="url + '/'"
            list-type="picture-card"
            :on-error="handleAvatarError"
            :on-success="handleAvatarSuccess"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
            :file-list="fileList"
            ref="uploadPic"
        >
            <span class="iconfont icon-add"></span>
        </el-upload>
      </div>
      <el-button class="default-button send-feedback-btn" :loading="loading" @click="sendFeedBack">发送</el-button>
      <qr-code></qr-code>
  </div>
</template>

<script>
import config from '../../http/config'
import { suggestMsg } from '../../http/api'
import { mapGetters } from 'vuex'
import QrCode from '@/components/QrCode.vue'
export default {
  components: { QrCode },
    data() {
        return {
            textarea: '',
            fileList: [],
            imgArr: [],
            loading: false
        }
    },

    created() {
        this.url = config.url
        console.log(config)
    },

    computed: {
        ...mapGetters(['getUserInfo'])
    },

    methods: {
        handlePictureCardPreview() {

        },

        beforeAvatarUpload(file) {
            console.log('aaa')
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.warning("上传头像图片只能是 JPG或者PNG 格式!");
            }

            if (!isLt2M) {
                this.$message.warning("上传图片大小不能超过 2MB!");
            }
            return (isJPG || isPNG) && isLt2M;
        },

        //添加图片成功回调
        handleAvatarSuccess(res, file,fileList) {
            console.log(res)
            this.imgArr.push(file.raw)
        },

        //  获取头像失败回调
        handleAvatarError() {
            this.$message({
                showClose: true,
                message: '获取图片失败!',
                type: 'error'
            });
        },

        /**
         * 发送反馈
         */
        sendFeedBack() {
            if (!this.$util.isDefine(this.textarea)) {
                this.$message({
                    showClose: true,
                    message: '请输入反馈内容!',
                    type: 'warning'
                });
                return
            }

            if (this.textarea.length < 5) {
                this.$message({
                    showClose: true,
                    message: '反馈内容不能少于5个字!',
                    type: 'warning'
                });
                return
            }

            this.loading = true; 
            var params = new FormData();

            params.append("type", "1");
            params.append("content", this.textarea);
            params.append("uid", this.getUserInfo ? this.getUserInfo.uid : '000');
            if (this.imgArr.length == 0) {
                // params.append("files", "123");
            } else {
                for(var i in this.imgArr){
                    params.append("files", this.imgArr[i]);
                }
            }
            
            suggestMsg(params).then((res) => {
                console.log(res)
                this.$message({
                    message: "发送成功",
                    type: "success",
                    showClose: true
                });
                this.loading = false
                this.textarea = ''
                this.$refs.uploadPic.clearFiles();
            }).catch((err) => {
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss">
.about-feedback {
    padding: 0 65px;

    .feedback-input {
        margin-bottom: 30px;
        span.title {
            display: block;
            width: 100px;
            font-size: 18px;
            line-height: 34px;
            text-align: center;
        }

        .el-textarea {
            width: 693px;
            font-size: 16px;
        }

        span.icon-tianjiatupian {
            color: #a0a0a0;
            font-size: 14px;
        }

        span.icon-tianjiatupian:before {
            display: block;
            font-size: 26px;
        }

        .icon-add {
            font-size: 32px;
        }
    }

    .send-feedback-btn {
        width: 206px;
        height: 40px;
        font-size: 16px;
        margin: 0 auto;
    }
}
</style>