import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import App from './App.vue'

// 公共样式
import './assets/css/element-variables.scss'
import './assets/css/default.css'
import './assets/css/common.css'
import './assets/icon/iconfont.css'
// 公共js
import util from './utils/util'

import router from './router'
import store from './store'
// 视频组件
import VideoPlayer from 'vue-video-player/src'
window.videojs = VideoPlayer.videojs
require('video.js/dist/lang/zh-CN.js')
require('vue-video-player/src/custom-theme.css')
require('video.js/dist/video-js.css')

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';



const app = createApp(App)
app.config.globalProperties.$util = util
app.use(ElementPlus, {
    locale: zhCn
})
app.use(router)
app.use(store)
app.use(VideoPlayer)
app.use(VueAwesomeSwiper)
app.mount('#app')

export default app
