<template>
  <div class="work-list ub ub-wrap">
      <work-item v-for="(item, index) in workList" :key="index" :item="item"></work-item>
  </div>
</template>

<script>
import WorkItem from './WorkItem.vue'
export default {
    props: {
        workList: {
            type: Object,
            default: ()=> {
                return {}
            }
        }
    },

    components: { WorkItem },

    created() {
        console.log(this.workList)
    }
}
</script>

<style lang="scss">
.work-list {
    .work-item {
        margin-right: 13px;
        margin-left: 5px;
        margin-bottom: 13px;
        margin-top: 5px;
    }
}
</style>