<template>
  <el-dialog
    :model-value="isSelectWorkDialog"
    custom-class="default-dialog select-work-dialog"
    title="上传作品"
    :before-close="handleClose"
  >
    <div class="work-content" v-loading="loading">
        <the-seartch class="ub-pe" @inputChange="inputChange" @clearInput="clearInput" placeholder="请输入作品名称" size="small"></the-seartch>
        <div v-if="workList">
            <div class="ub ub-wrap" v-if="workList.length > 0">
                <div :class="['work-item', (index + 1) % 4 == 0 ? 'no-margin' : '']" v-for="(item, index) in workList" :key="index" @click="workTap(item)">
                    <el-image class="work-pic" :src="item.IMG"></el-image>
                    <div class="work-item-title ub ub-ac"><div class="text-hidden-2">{{item.NAME}}</div></div>
                </div>
            </div>
            <the-empty v-else></the-empty>
        </div>
    </div>
  </el-dialog>
</template>

<script>
import { pblWork } from '../../../http/api'
import TheEmpty from '@/components/TheEmpty.vue'
import { mapGetters } from 'vuex'
import TheSeartch from '@/components/TheSeartch.vue'
export default {
    components: { TheEmpty, TheSeartch },
    props: {
        isSelectWorkDialog: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            workList: null,
            searchVal: ''
        }
    },

    computed: {
        ...mapGetters(['getUserInfo'])
    },

    methods: {
        handleClose() {
            this.$emit('closeSelectDialog')
        },

        getData() {
            this.loading = true
            let params = {
                type: 27,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                keyword: this.searchVal
            }
            pblWork(params).then(res => {
                console.log(res)
                this.loading = false
                this.workList = res.list
            }).catch(err => {
                console.log(err)
                this.loading = false
            })
        },

        /**
         * 搜索
         */
        inputChange(val) {
            this.searchVal = val
            this.getData()
        },

        /**
         * 清空搜索
         */
        clearInput() {
            this.searchVal = ''
            this.getData()
        },

        /**
         * 点击作品
         */
        workTap(item) {
            console.log(item)
            this.$emit('workTap', item)
            this.handleClose()
        }
    },

    watch: {
        isSelectWorkDialog(newVal) {
            if (newVal) {
                this.getData()
            }
        }
    }
}
</script>

<style lang="scss">
.select-work-dialog {
    width: 820px;
    .work-content {
        min-height: 200px;

        .work-item {
            width: 180px;
            margin-right: 20px;
            margin-top: 30px;
            cursor: pointer;
            .work-item-title {
                background-color: #f4f4f4;
            }
        }

        .work-item.no-margin {
            margin-right: 0;
        }
    }
}
</style>