<template>
  <div class="my-work">
    <div class="" v-if="classData.list.length > 0">
      <work-list-rate :workList="classData.list"></work-list-rate>
    </div>
    <the-empty v-else></the-empty>
  </div>
</template>

<script>
import TheEmpty from '@/components/TheEmpty.vue'
import WorkListRate from '@/components/WorkListRate.vue'
export default {
  components: { TheEmpty, WorkListRate },

  props: {
    classData: {
        type: Object,
        default: () => {
            return {}
        }
    },
  },

  created() {
      console.log(this.classData)
  }
}
</script>

<style>

</style>