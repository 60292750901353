<template>
      <el-dialog
      :model-value="isHandleDialog"
      :close-on-click-modal="false"
      :title="dialogTitle"
      custom-class="default-dialog type1 handle-dialog"
      :before-close="handleClose">
        <the-alert :show="errInfo.show" :title="errInfo.title" @closeAlert="closeAlert"></the-alert>
        <!-- 修改手机号 -->
        <el-input class="userPhone"
                    :model-value="currentTel"
                    placeholder=""
                    disabled
                    clearable
                    v-if="dialogType != 3 && dialogType != 4"></el-input>
        <div class="modify-tel" v-if="dialogType == 1 || dialogType == 4">
            <el-input class="userPhone"
                    v-model="tel"
                    :placeholder="dialogType == 1 ? '输入新手机号' : '输入手机号'"
                    autocomplete="new-tel"
                    clearable></el-input>
        </div>
        <div class="modify-password"  v-if="dialogType == 2 || dialogType == 4">
            <el-input class="userPhone"
                    type="password"
                    v-model="password"
                    :placeholder="dialogType == 2 ? '请输入新密码' : '请输入密码'"
                    autocomplete="new-password"
                    clearable></el-input>
            <el-input class="userPhone"
                    type="password"
                    v-model="passwordAgain"
                    :placeholder="dialogType == 2 ? '请再次输入新密码' : '请再次输入密码'"
                    autocomplete="new-password"
                    clearable></el-input>
        </div>
        <div class="code ub" v-if="dialogType != 3">
                <el-input class="code-input"
                    v-model="code"
                    placeholder="验证码"
                    clearable
                    autocomplete="new-code"></el-input>
                <el-button class="code-button default-button"
                        type="primary"
                        :disabled='codeDisabled'
                        @click="sendCode()"
                        v-text="codeDisabled ? sendCodeTime + 's' : '发送验证码'"></el-button>
            </div>
        <el-button class="handle-button"
            type="primary"
            @click="handleTap"
            v-if="dialogType != 3"
            :loading="loading">{{ loading ? '提交中...' : '确定'}}</el-button>

            <div id="wechat-QR-code" class="ub ub-pc" v-if="dialogType == 3">
                <div class="loading" v-loading="true"></div>
            </div>
      </el-dialog>
</template>

<script>
import $ from "jquery";
import config from '../../../http/config'
import { loginMsg } from '../../../http/api'
import TheAlert from '@/components/TheAlert.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TheAlert },
    props: {
        currentTel: {
            type: String,
            default: ''
        },

        isHandleDialog: {
            type: Boolean,
            default: false
        },

        dialogTitle: {
            type: String,
            default: ''
        },

        dialogType: { // 1 更换手机号 2 更换密码 3 绑定微信
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            loading: false,
            errInfo: {
                show: false,
                title: ''
            },
            tel: '',
            password: '',
            passwordAgain: '',
            code: '',
            codeDisabled: false,
            sendCodeTime: 300,
            timer: "",
            // 正则验证
            numReg: /^1[345789]\d{9}$/,
            passWordReg: /^[a-zA-Z0-9]{6,15}$/
        }
    },

    computed: {
        ...mapGetters(['getUserInfo']),

        setSuccessTip() {
            switch (this.dialogType) {
                case 1:
                    return '手机号修改成功'
                case 2:
                    return '密码修改成功'
                case 4:
                    return '手机号绑定成功'
            
                default:
                    return '';
            }
        }
    },

    methods: {
        handleClose() {
            if(this.loading) return
            this.$emit('handleClose')
        },

        /**
         * 提交
         */
        handleTap() {
            if (!this.$util.isDefine(this.tel) && this.dialogType == 1) {
                this.errInfo = {
                    title: "请输入新手机号！",
                    show: true
                };
                return false;
            }

            if (!this.$util.isDefine(this.tel) && this.dialogType == 4) {
                this.errInfo = {
                    title: "请输入手机号！",
                    show: true
                };
                return false;
            }

            if (!this.numReg.test(this.tel) && this.dialogType == 1) {
                this.errInfo = {
                    title: "请输入正确手机号！",
                    show: true
                };
                return;
            }

            if(this.tel == this.currentTel && this.dialogType != 4) {
                this.errInfo = {
                    title: "新手机号与当前手机号重复！",
                    show: true
                };
                return
            }

            if (!this.$util.isDefine(this.password) && this.dialogType == 2) {
                this.errInfo = {
                    title: "请输入新密码！",
                    show: true
                };
                return;
            }

            if (!this.$util.isDefine(this.password) && this.dialogType == 4) {
                this.errInfo = {
                    title: "请输入密码！",
                    show: true
                };
                return;
            }

            if (!this.passWordReg.test(this.password) && (this.dialogType == 2 || this.dialogType == 4)) {
                this.errInfo = {
                    title: "密码输入错误，密码由6-15位数字组成！",
                    show: true
                };
                return;
            }

            if (!this.$util.isDefine(this.passwordAgain) && (this.dialogType == 2 || this.dialogType == 4)) {
                this.errInfo = {
                    title: "请再次输入新密码！",
                    show: true
                };
                return;
            }

            if (this.password !== this.passwordAgain && (this.dialogType == 2 || this.dialogType == 4)) {
                this.errInfo = {
                    title: "两次输入密码不一致！",
                    show: true
                };
                return;
            }

            if (!this.$util.isDefine(this.code)) {
                this.errInfo = {
                    title: "请输入验证码！",
                    show: true
                };
                return false;
            }

            this.errInfo.show = false
            this.loading = true

            let params

            if (this.dialogType == 1) { // 1 修改手机号
                params = {
                    type: 7,
                    uid: this.getUserInfo ? this.getUserInfo.uid : '',
                    newtel: this.tel,
                    token: this.getUserInfo ? this.getUserInfo.token : '',
                    code: this.code,
                    state: 2
                }
            } else if (this.dialogType == 2) { // 2 修改密码
                params = {
                    type: 4,
                    tel: this.currentTel,
                    code: this.code,
                    p: this.password,
                    state: 1
                }
            } else if (this.dialogType == 4) { // 2 绑定手机号
                params = {
                    type: 10,
                    uid: this.getUserInfo.uid,
                    tel: this.tel,
                    code: this.code,
                    p: this.password,
                    state: 3
                }
            }
            console.log(params)
            loginMsg(params).then((res) => {
                console.log(res)
                this.loading = false
                this.handleClose()
                this.$message({
                    // message: this.dialogType == 1 ? "手机号修改成功" : "密码修改成功",
                    message: this.setSuccessTip,
                    type: "success",
                    showClose: true
                });
                this.resetTimeCount()

                setTimeout(() => {
                    if (this.dialogType == 1) {
                        // ...
                        this.getUserInfo.tel = this.tel
                        this.$store.commit('setUserInfo', this.getUserInfo)
                    } else if (this.dialogType == 4) {
                        this.$store.commit('setUserInfo', res.userInfo)
                        this.$router.go(0)
                    } else {
                        sessionStorage.clear()
                        localStorage.clear()
                        this.$router.go(0)
                    }
                }, 1500)
            }).catch(() => {
                this.loading = false
            })
        },
        
        /**
         * 发送验证码
         */
        sendCode() {
            if (!this.$util.isDefine(this.tel) && this.dialogType == 1) {
                this.errInfo = {
                    title: "请输入新手机号！",
                    show: true
                };
                return false;
            }

            if (!this.$util.isDefine(this.tel) && this.dialogType == 4) {
                this.errInfo = {
                    title: "请输入手机号！",
                    show: true
                };
                return false;
            }

            if (!this.numReg.test(this.tel) && (this.dialogType == 1 || this.dialogType == 4)) {
                this.errInfo = {
                    title: "请输入正确手机号！",
                    show: true
                };
                return;
            }

            if (this.tel == this.currentTel && this.dialogType == 1) {
                this.errInfo = {
                    title: "新手机号与当前手机号重复！",
                    show: true
                };
                return
            }

            let params = {
                type: 2,
                role: this.getUserInfo.role,
                tel: this.dialogType == 1 || this.dialogType == 4 ? this.tel : this.currentTel, // dialogType 1 修改手机号 2 修改密码 3 绑定手机号
                state: this.dialogType == 1 ? 2 : this.dialogType == 2 ? 1 : 3,
            }
            console.log(params)
            loginMsg(params).then((res) => {
                console.log(res)
                this.$message({
                    message: "验证码发送成功！",
                    type: "success",
                    showClose: true
                });

                this.codeDisabled = true
                this.timeCountDown()
            })

        },

        /**
         * 倒计时
         */
        timeCountDown() {
            if (this.sendCodeTime === 0) {
                this.resetTimeCount()
            } else {
                this.sendCodeTime--;
                this.timer = setTimeout(() => {
                    this.timeCountDown();
                }, 1000);
            }
        },

        /**
         * 重置倒计时
         */
        resetTimeCount() {
            this.sendCodeTime = 300;
            this.codeDisabled = false;
            clearInterval(this.timer);
        },

        closeAlert() {
            this.errInfo.show = false
        }
    },

    watch: {
        isHandleDialog(newVal) {
            console.log(newVal)
            if (newVal) {
                if (this.dialogType == 3) {
                    if ($("script[src='https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js']").length == 0) {
                        const wxLoginJs = document.createElement("script");
                        wxLoginJs.type = "text/javascript";
                        wxLoginJs.src =
                            "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
                        document.body.appendChild(wxLoginJs);
                    }
                    console.log('ccc')
                    setTimeout(() => {
                        //eslint disable next line
                        new WxLogin({
                            id: "wechat-QR-code",
                            appid: "wx0bf3992f37d7a79a",
                            scope: "snsapi_login",
                            redirect_uri: config.base.host,
                            state: Math.random(),
                            style: "",
                            href: "data:text/css;base64,QGNoYXJzZXQgIlVURi04IjsNCi5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOiAxNTBweDttYXJnaW4tdG9wOjBweDt9DQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQ0KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyNzBweDt9DQouc3RhdHVzX2ljb24ge2Rpc3BsYXk6IG5vbmV9DQouaW1wb3dlckJveCAuc3RhdHVzIHt0ZXh0LWFsaWduOiBjZW50ZXI7fSA=",
                        });
                    }, 2000);
                    return
                }
                this.resetTimeCount()
                this.tel = ''
                this.password = '',
                this.passwordAgain = ''
                this.code = ''
            }
        }
    }
}
</script>

<style lang="scss">
.handle-dialog {
    width: 400px;

    .el-dialog__body{
        position: relative;
        padding-top: 32px !important;
        .pbl-alert {
            width: 360px;
            position: absolute;
            top: -7px;
            left: 20px;
        }

        .el-input {
            margin-bottom: 20px;

            .el-input__inner {
                height: 40px;
                line-height: 40px;
                font-size: 14px;
                background-color: #eeeeee;
            }

            .el-input__inner:focus {
                background-color: #fff;
            }
        }

        .code {
            .el-button {
              background-color: unset;
              border: 0px;
              color: #000000;
              line-height: 40px;
              height: 40px;
              width: 142px;
              display: block;
              padding: 0;
            }
        } 

        .handle-button {
            width: 100%;
            font-size: 16px;
            height: 38px;
            color: #121100;
            margin-top: 20px;
        }

        #wechat-QR-code {
          iframe {
            height: 230px !important;
          }

          .loading {
            height: 200px;
            width: 100%;
          }
        }
    }
}
</style>