<template>
  <div class="gold-coin ub ub-ac">{{num}}</div>
</template>

<script>
export default {
    props: {
        num: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.gold-coin {
    height: 40px;
    line-height: 40px;
    padding-right: 30px;
    font-size: 14px;
}

.gold-coin::before {
    width: 39px;
    height: 39px;
    display: inline-block;
    content: '';
    background-image: url('../assets/image/pbl_icon.png');
    background-position: -10px -296px;
    margin-right: 14px;
}
</style>