<template>
  <div class="class-item">
      <div class="class-item-header ub">
          <div class="class-name ub ub-ac ub-f1">{{item.classname}}<span class="student-num">（{{item.student_num}}人）</span><span v-if="getUserInfo.role == 2" class="iconfont icon-edit" @click="editClassName"></span><span v-if="getUserInfo.role == 2" class="iconfont icon-delect" @click="delectClass"></span></div>
          <el-button class="default-button" v-if="(getUserInfo.role == 2 && getUserInfo.cooperation == 0) || getUserInfo.role != 2"><router-link :to="'/class-center?cid=' + item.class_id">进入班级</router-link></el-button>
          <el-button class="default-button four" @click="copyInvitationCode" v-if="getUserInfo.role == 2 && getUserInfo.cooperation == 0">邀请码</el-button>
          <the-message class="class-message" :num="item.infoNum" @messageTap="messageTap" v-if="getUserInfo.role == 2"></the-message>
      </div>
      <div :class="['class-item-students ub ub-wrap', item.student_num == 0 ? 'ub-pc' : '']">
          <div class="student-model"  v-if="item.student_num != 0">
              <div class="student-manage ub ub-ac ub-pe" v-if="getUserInfo.role == 2 && getUserInfo.cooperation == 0">
                    <span :class="['manage-switch iconfont icon-manage manage-span', manageText(isManage, 2)]" @click="studentManage">{{manageText(isManage, 1)}}</span>
                    <span class="manage-delect manage-span" v-if="isManage" @click="delectStudent">删除</span>
                    <el-checkbox v-if="isManage" class="manage-select" v-model="selectAll" label="全选" size="large" @change="selectAllChange" />
              </div>
              <div class="ub ub-wrap">
                  <div :class="['student-item', subIndex + 1 % 8 == 0 ? 'no-margin' : '', ]" @click="studentTap(subItem.uid, subIndex)" v-for="(subItem, subIndex) in item.students" :key="subIndex">
                    <div :class="['student-con', isManage ? 'select' : '', subItem.isCheck && isManage ? 'check iconfont icon-duigou' : '']">
                        <el-image class="student-img" :src="subItem.user_img" fit="cover"></el-image>
                    </div>
                    <div class="student-name text-hidden ub-f1">{{subItem.name}}</div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import TheMessage from '@/components/TheMessage.vue'
import { mapGetters } from 'vuex'
export default {
  components: { TheMessage},
  props: {
      index: {
          type: Number,
          default: 0
      },

      item: {
          type: Object,
          default: () => {
              return {}
          }
      }
  },
  
  data() {
      return  {
          isManage: false,
          selectAll: false
      }
  },

  computed: {
      ...mapGetters(['getUserInfo']),
      manageText() {
          return function (isManage, type) {
            return isManage ? type == 1 ? '取消管理' : 'act' : type == 1 ? '学生管理' : ''
          }
      }
  },

  methods: {
      messageTap(params) {
          console.log(params)
          this.$emit('showClassMsg', true, this.index)
      },

      studentManage() {
          this.isManage = !this.isManage
          if (!this.isManage) {
              this.selectAllData(2)
              this.selectAll = false
          }
      },

      /**
       * 全选按钮
       */
      selectAllChange() {
          if (this.selectAll) {
              this.selectAllData(1)
          } else {
              this.selectAllData(2)
          }
      },

      /**
       * 全选/取消全选
       * @param {Number} type 1 全选 2 取消全选
       */
      selectAllData(type) {
        this.$emit('selectAllData', this.index, type)
        
      },

      /**
       * 点击学生
       * @param {String} uid 用户id
       * @param {Numver} studentIndex 学生索引
       */
      studentTap(uid, studentIndex) {
          if (!this.isManage) {
              this.$router.push('/personal-center?uid=' + uid)
              return
          }
          
          this.$emit('selectStudent', this.index, studentIndex)

          setTimeout(() => {
              for (let i = 0; i < this.item.students.length; i++) {
                if (!this.item.students[i].isCheck) {
                    this.selectAll = false
                    break;
                }

                if (i == this.item.students.length - 1) {
                    this.selectAll = true
                }
            } 
          })
      },

      /**
       * 删除学生
       */
      delectStudent() {
        this.$emit('delectStudent', this.index)

      },

      /**
       * 修改班级名称
       */
      editClassName() {
          this.$emit('editClassName', this.index)
      },

      /**
       * 删除班级
       */
      delectClass() {
          this.$emit('delectClass', this.index)
      },

      /**
       * 复制邀请码
       */
      copyInvitationCode() {
          var temp = document.createElement("textarea");
          temp.value = this.item.invitationCode;
          document.body.appendChild(temp);
          temp.select(); // 选择对象
          document.execCommand("Copy"); // 执行浏览器复制命令
          temp.style.display = "none";
          this.$message({
            type: 'success',
            message: '复制成功，去分享吧！'
          })
      }
  }

}
</script>

<style lang="scss">
.class-item {
    padding: 15px 20px;
    border: 2px solid var(--el-color-primary);
    border-radius: 10px;
    margin-bottom: 20px;
    .class-item-header {
        .class-name {
            span.student-num {
                font-weight: bold;
            }

            span.iconfont {
                display: block;
                font-size: 22px;
                margin-left: 10px;
                cursor: pointer;
                color: var(--el-color-second);
                font-weight: 500;
            }

            span.icon-edit {
                font-weight: 600;
                font-size: 20px;
                color: var(--el-color-three);
            }

            span.icon-delect {
                display: none;
            }
        }

        .class-message {
            margin-right: 0;
        }

        .class-message::before {
            font-size: 26px;
        }
    }

    .class-item-students {
        .student-model {
            width: 100%;

            .student-manage {
                margin-top: 20px;
                cursor: pointer;
                color: #a0a0a0;

                span.manage-span {
                        padding:0 10px;
                        display: inline-block;
                        position: relative;
                    }

                    span.manage-span::after {
                        content: "";
                        width: 1px;
                        height: 14px;
                        background-color: #d3d3d3;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        margin-top: -7px;
                    }

                    span.manage-switch {
                        padding-right: 0;
                    }

                    span.manage-switch.act {
                        padding-right: 10px;
                    }

                    span.manage-switch::before {
                        margin-right: 5px;
                        font-size: 18px;
                        float: left;
                        margin-top: 2px;
                    }

                    span.act {
                        color: var(--el-color-primary);
                    }

                    span.manage-span:last-child:after {
                        display: none;
                    }

                    span.manage-delect {
                        color: red;
                    }

                    .manage-select {
                        margin-left: 10px;
                        height: auto;

                        .el-checkbox__input {
                            .el-checkbox__inner {
                                width: 16px;
                                height: 16px;
                            }

                            .el-checkbox__inner::after {
                                height: 9px;
                                left: 5px;
                                top: 0px;
                                width: 4px;
                            }
                        }

                        .el-checkbox__label {
                            font-size: 16px;
                            font-weight: 400;
                        }
                    }
            }

            .student-item {
                width: 123px;
                height: 159px;
                margin-top: 20px;
                margin-right: 23px;
                cursor: pointer;
                position: relative;
                .student-con {
                    height: 123px;
                    .student-img {
                        width: 123px;
                        height: 123px;
                        border-radius: 10px;
                    }
                }

                .student-con.select::before {
                    position: absolute;
                    right: -1px;
                    top: -2px;
                    z-index: 9;
                    color: var(--el-color-second);
                }

                .student-con.select::after {
                    content: '';
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border: 2px solid #dddddd;
                    background-color: #fff;
                    border-radius: 50%;
                    padding: 1px;
                    color: var(--el-color-four);
                    font-size: 14px;
                    position: absolute;
                    right: -5px;
                    z-index: 1;
                    top: -5px;
                }

                .student-name {
                    line-height: 30px;
                    text-align: center;
                    margin-top: 5px;
                    color: var(--el-color-second);
                }
            }

            .student-item.no-margin {
                margin-right: 0;
            }

            .pagination-model {
                margin: 20px 0 0;
                padding: 20px 0;
            }
        }
    }
}

.class-item:hover {
    .class-item-header {
        .class-name {
            span.icon-delect {
                display: block;
            }
        }
    }
}
</style>