<template>
  <div class="course-center">
    <the-header></the-header>

    <div class="content-body" v-loading="loading">
      <div class="course-num">课程:共{{ total }}个</div>
      <div class="curriculum-search">
        <search-module
          placeholder="请输入课程名称"
          @searchTypeTap="searchTypeTap"
          @inputChange="inputChange"
          :sort="sort"
          :searchTypes="searchTypes"
          @clearInput="clearInput"
        ></search-module>
      </div>
      <div class="theme_class">
        <div
          v-for="(item, index) in titleArr"
          :key="index"
          :class="[
            'theme_item',
            titleStyle == index ? 'title_click' : 'title_noclick',
          ]"
          @click.stop="titleClick(index, item)"
        >
          {{ item.curriculumName }}
        </div>
      </div>

      <div class="works" v-if="isData == true">
        <lesson-item
          class="works_item"
          v-for="(item, index) in itemData"
          :key="index"
          :item="item"
        ></lesson-item>
      </div>
      <el-empty
        description="暂无数据"
        style="width: 1190px; display: flex; justify-content: center"
        v-else
      />

      <div class="Pageturning">
        <the-pagination
          :total="total"
          @paginationChange="paginationChange"
          :page-size="pagesize"
        ></the-pagination>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { pblWork } from "../../http/api";
import TheHeader from "@/components/TheHeader.vue";
import LessonItem from "@/components/LessonItem.vue";
import SearchModule from "@/components/SearchModule.vue";
import ThePagination from "@/components/ThePagination.vue";
import { mapGetters } from "vuex";
import TheFooter from "@/components/TheFooter.vue";

export default {
  data() {
    return {
      //排序分类
      typeArr: [
        { name: "浏览量", num: 0 },
        { name: "最热", num: 1 },
        { name: "最新", num: 2 },
      ],
      //控制选中样式   默认按浏览量 0
      itemStyle: 0,
      //主题分类
      //主题  全部为字符串 null
      titleArr: [{ curriculumName: "全部", rowId: 1, state: 0 }],
      //控制分类选中样式 默认第一个 0
      titleStyle: 0,
      navList: [],
      //总页数
      navNum: 10,
      navStyle: 0,
      move: 0,
      dataNavListRealWidth: 0,
      dataNavListViewWidth: 0,
      input: "",
      itemData: [],
      loading: false,
      loadErr: false,
      sort: "0",
      searchVal: "",
      total: 0,
      page: 1,
      pagesize: 8,
      isData: false,
      tag: "null",
      searchTypes: {
        list: [
          {
            title: "浏览量",
            id: "0",
          },
          {
            title: "最热",
            id: "heat",
          },
          {
            title: "最新",
            id: "now",
          },
        ],
      },
    };
  },
  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    };
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    //热度方法
    styleClick(num) {
      if (this.itemStyle != num) {
        this.itemStyle = num;
      }
    },
    //主题方法
    titleClick(num, item) {
      if (this.titleStyle != num) {
        this.titleStyle = num;
        this.searchVal = "";
        this.tag = item.curriculumName == "全部" ? "null" : item.curriculumName;
        this.getData();
      }
    },
    navclick(num) {
      if (this.navStyle != num) {
        this.navStyle = num;
      }
    },
    // 当菜单项向右的可移动距离大于单个菜单项的宽度时，move减去一个菜单项的宽度（即右移移动一个菜单项的宽度），
    // 当菜单项向右的可移动距离小于单个菜单项的宽度时，move等于0（即回到最开始没有移动的状态）
    navPrev() {
      if (this.move > 70) {
        this.move = this.move - 70;
      } else {
        this.move = 0;
      }
    },

    //获取数据
    getData() {
      this.loading = true;
      this.loadErr = false;
      let params = {
        type: 34,
        userId: this.getUserInfo ? this.getUserInfo.uid : "",
        //主题  空为全部
        tag: this.tag,
        //搜索关键字
        word: this.searchVal,
        //不传就是按浏览量 传heat是最热  now是最新
        sort: this.sort,
        page: this.page,
        num: this.pagesize,
      };
      pblWork(params)
        .then((res) => {
          this.loading = false;
          console.log("返回的数据是", res);
          this.itemData = res.data.pblwork_info;
          this.total = res.data.pblworkNums;
          if (this.titleArr[1] == null) {
            for (
              let index = 0;
              index < res.data.courseCenterTypeList.length;
              index++
            ) {
              this.titleArr.push(res.data.courseCenterTypeList[index]);
            }
          }

          this.isData = this.itemData.length != 0 ? true : false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.loadErr = true;
        });
    },
    /**
     * 分页
     * @param {Number} page 分页数
     */
    paginationChange(page) {
      console.log(page);
      this.page = page;
      this.getData();
    },
    likeTapSuccess(type, index) {
      if (type == undefined) {
        console.log(index);
        // console.log()
        this.itemData[index].IS_LIKE =
          this.itemData[index].IS_LIKE == 0 ? 1 : 0;
        this.itemData[index].LIKE_TIMES =
          this.itemData[index].IS_LIKE == 1
            ? this.itemData[index].LIKE_TIMES + 1
            : this.itemData[index].LIKE_TIMES - 1;
      }
    },
    /**
     * 搜索右侧筛选
     */
    searchTypeTap(val) {
      console.log(val);
      this.sort = val;
      this.getData();
    },
    /**
     * 搜索
     */
    inputChange(val) {
      console.log(val);
      this.searchVal = val;
      this.getData();
    },
    /**
     * 清空搜索
     */
    clearInput() {
      this.searchVal = "";
      this.getData();
    },
  },
  components: { TheHeader, LessonItem, SearchModule, ThePagination, TheFooter },
};
</script>

<style lang="scss">
.course-center {
  .content-body {
    width: 1190px;
    margin: 0 auto;
    min-height: 480px;
    display: flex;
    flex-wrap: wrap;
    .course-num {
      width: 25%;
      white-space: nowrap;
      margin-left: 18px;
      margin-top: 45px;
    }
    .curriculum-search {
      width: 70%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      white-space: nowrap;
      padding: 37px 0 20px;
    }
    .theme_class {
      width: 100%;
      height: 10%;
      display: flex;
      flex-wrap: nowrap;
      .theme_item {
        clear: both;
        overflow: auto;
        text-align: center;
        height: 33px;
        min-width: 113px;
        line-height: 33px;
        font-size: 14px;
        border-radius: 30px;
        margin-left: 15px;
        background-color: #ffffff;
        border: 1px solid #ffffff;
        cursor: pointer;
      }
      .title_click {
        background-color: var(--el-color-primary);
        border: 1px solid var(--el-color-primary);
      }
      .title_noclick {
        background-color: #ffffff;
        border: 1px solid #ffffff;
        color: #000000;
      }
    }
    .works {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      .no {
        width: 100%;
        height: 320px;
        text-align: center;
        line-height: 320px;
      }
    }
    .Pageturning {
      margin-top: 28px;
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
}
</style>