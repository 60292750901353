<template>
  <div class="shopping-cart" v-loading.fullscreen.lock="loading">
    <div class="search-model ub ub-pe">
      <the-seartch placeholder="请输入素材名称" @inputChange="inputChange" @clearInput="clearInput"></the-seartch>
    </div>
    <list-module v-if="warsData" :seartchTypes="seartchTypes" :warsData="warsData" :total="total" @paginationChange="paginationChange" :isWhetherHave="true"></list-module>
  </div>
</template>

<script>
import TheSeartch from '@/components/TheSeartch.vue'
import { mapGetters } from 'vuex'
import { shoppingMaterialMsg } from '../../../http/api'
import ListModule from './ListModule.vue'
export default {
  components: { ListModule, TheSeartch },
  porps: ['activeIndex'],

  data() {
    return {
      loading:false,
      warsData: null,
      total: 0,
      page: 1,
      num: 15,
    }
  },

  created() {
    this.getData()
  },

  computed: {
    ...mapGetters(['getUserInfo'])
  },

  methods: {
    /**
     * 获取数据
     */
    getData() {
      this.loading = true
      let params = {
        type: 1,
        materialOrUser: 2,
        userId: this.getUserInfo.uid,
        word: this.searchVal,
        sortType: 0,
        themeType: 0,
        shelfOrExchange: 1,
        pageNo: this.page,
        pageSize: this.num
      }
      console.log(params)
      shoppingMaterialMsg(params).then((res) => {
        console.log(res)
        this.warsData = res.data.page.items
        this.total = res.data.page.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    /**
     * 搜索
     */
    inputChange(val) {
      this.searchVal = val;
      this.getData();
    },

    /**
     * 清空搜索
     */
    clearInput() {
      this.searchVal = ''
      this.getData()
    },

    /**
     * 分页
     */
    paginationChange(val) {
      console.log(val)
      this.page = val
      this.getData()
    }
  },
  
}
</script>

<style lang="scss">
.shopping-cart {
  .search-model {
    background-color: #f4f4f4;
    padding: 23px;
  }
}
</style>