<template>
  <el-dialog
    :model-value="isClassDialog"
    custom-class="default-dialog class-dialog"
    :title="classDialogTitle"
    width="30%"
    :before-close="handleClose"
  >
    <el-input v-model="value" :placeholder="classDialogPlaceholder" clearable maxlength="20"></el-input>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" class="default-button" @click="submitButton"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        isClassDialog: {
            type: Boolean,
            default: false
        },

        classDialogTitle: {
            type: String,
            default: '提示'
        },

        classDialogType: {
            type: Number,
            default: 1 // 1 创建班级/加入班级 2 修改班级名称
        },

        classDialogPlaceholder: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            value: ''
        }
    },

    computed: {
        ...mapGetters(['getUserInfo'])
    },

    methods: {
        handleClose() {
            this.$emit('closeClassDialog')
        },

        submitButton() {
            if (!this.$util.isDefine(this.value)) {
                if(this.getUserInfo.role == 0) {
                    this.$message({
                        type: 'warning',
                        message: '请输入邀请码'
                    })
                } else {
                    this.$message({
                        type: 'warning',
                        message: '请输入班级名称'
                    })
                }
                
                return
            }
            
            if (this.value == this.classDialogPlaceholder && this.classDialogType == 2) {
                this.$message({
                    type: 'warning',
                    message: '新班级名称与当前班级名称重复！'
                })
                return
            }

            this.$emit('setClass', this.value)
        }
    },

    watch: {
        isClassDialog(newVal) {
            if(newVal) {
                this.value = ''
            }
        }
    }
}
</script>

<style lang="scss">
.class-dialog {
    .el-dialog__footer {
        text-align: center;
    }
}
</style>