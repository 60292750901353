<template>
  <div class="match-center">
    <the-header></the-header>
    <div class="pbl-banner" id="banner">
      <el-carousel>
        <el-carousel-item
          v-for="(item, index) in 2"
          :key="item"
          :class="'banner' + (index + 1)"
        >
          <div
            class="banner-con"
            :style="
              'background-image:url(' +
              require('../../assets/image/banner' + item + '.png') +
              ')'
            "
          ></div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="content-body" v-loading="loading" v-if="loading==false">
      <div class="introduction">
        <div class="introduction-css"></div>
        <div class="introduction-text">校区简介</div>
      </div>
      <div class="introduction-describe" v-if="schoolData.schoolProfile!=''">
        <div class="describe-text">
          {{ schoolData.schoolProfile }}
        </div>
      </div>

      <!-- 学生荣誉-->
      <!-- <div class="student" >
        <div class="student-css"></div>
        <div class="student-text">学员荣誉</div>
        <div class="student-gd" @click.stop="more()" v-if="isshow">
          {{ moretxt }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;>
        </div>
      </div> -->
      <!-- <div class="student-img" v-if="stuData[0]!=undefined">
        <div class="img-item" v-for="(item, index) in stuData" :key="index">
          <div class="stu-img">
          <el-image class="s-img" :src="item.userHeadimg" fit="cover" />
          </div>
          <div class="stu-txt">{{ item.honorName }}</div>
        </div>
      </div>
      <el-empty class="student-null" description="暂无数据" v-else /> -->
      
      <!--学生作品 -->
      <div class="student-works">
        <div class="student-css"></div>
        <div class="student-text">学员作品</div>
        <div class="works-num">
          共{{ schoolData.studentWorkList.totalCount }}个
        </div>
        <div class="works-right">
          <search-module
            placeholder="请输入作品名称"
            @searchTypeTap="searchTypeTap"
            @inputChange="inputChange"
            :sort="sort"
            @clearInput="clearInput"
          ></search-module>
        </div>
      </div>
      <div class="works" v-if="schoolData.studentWorkList.items.length>0">
        <work-item
          v-for="(item, index) in schoolData.studentWorkList.items"
          :key="index"
          class="works-item"
          :item="item"
          
        ></work-item>
      </div>
      <el-empty class="student-null" description="暂无数据" v-else />

      <div class="Pageturning">
        <the-pagination
          :total="total"
          @paginationChange="paginationChange"
          :page-size="pagesize"
        ></the-pagination>
      </div>
    </div>
     <el-empty v-if="loading" description="加载失败，请刷新重试！" style="height:360px"></el-empty>

    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import WorkItem from "@/components/WorkItem.vue";
import SearchModule from "@/components/SearchModule.vue";
import ThePagination from "@/components/ThePagination.vue";
import { schoolMsg } from "../../http/api";
import { mapGetters } from "vuex";
import TheFooter from '@/components/TheFooter.vue'

export default {
  data() {
    return {
      search_index: 0,
      schoolData: "",
      sort: "0",
      searchVal: "",
      page: 1,
      total: 0,
      pagesize: 8,
      ismore: 0,
      moretxt: "更多",
      stuData: [],
      isshow:0,
      loading:false
    };
  },
  created() {
    console.log('用户数据:',this.getUserInfo);
    this.getData();
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    };
  },
  methods: {
    heat(num) {
      if (this.search_index != num) {
        this.search_index = num;
      }
    },
    getData() {
      this.loading=true
      //schoolMsg
      //type=1&schoolId&userId&page=1&num=10&sort
      let params = {
        type: 1,
        schoolId:
          this.getUserInfo==null ? "" : this.getUserInfo.school_id==undefined ? "":this.getUserInfo.school_id,
        userId: this.getUserInfo==null ? "" : this.getUserInfo.uid,
        page: this.page,
        num: this.pagesize,
        word: this.searchVal,
        //不传就是按浏览量 传heat是最热  now是最新
        sort: this.sort,
      };
      schoolMsg(params)
        .then((res) => {
          this.loading=false
          console.log("返回的数据是", res);
          this.schoolData = res.data;
          this.total = res.data.studentWorkList.totalCount;
          this.isshow=res.data.studentHonorList.length >6 ? 1 : 0;
          this.stuData = [];
          if (this.ismore == 0) {
            for (let index = 0; index < 6; index++) {
              this.stuData.push(res.data.studentHonorList[index]);
            }
            
          } else {
            this.stuData = res.data.studentHonorList;
          }
          console.log("好好",this.stuData)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //更多
    more() {
      console.log("点击了"+this.moretxt);
      this.ismore = this.ismore == 0 ? 1 : 0;
      this.moretxt = this.ismore == 0 ? "更多" : "关闭";
      this.getData()
    },
    /**
     * 分页
     * @param {Number} page 分页数
     */
    paginationChange(page) {
      console.log(page);
      this.page = page;
      this.getData();
    },
    /**
     * 搜索右侧筛选
     */
    searchTypeTap(val) {
      console.log(val);
      this.sort = val;
      this.getData();
    },
    /**
     * 搜索
     */
    inputChange(val) {
      console.log(val);
      this.searchVal = val;
      this.getData();
    },
    /**
     * 清空搜索
     */
    clearInput() {
      this.searchVal = ''
      this.getData()
    },
    likeTapSuccess(type, index) {
      console.log(index);
      console.log(type);
      // console.log()
      this.schoolData.studentWorkList.items[index].IS_LIKE =
        this.schoolData.studentWorkList.items[index].IS_LIKE == 0 ? 1 : 0;
      this.schoolData.studentWorkList.items[index].LIKE_TIMES =
        this.schoolData.studentWorkList.items[index].IS_LIKE == 1
          ? this.schoolData.studentWorkList.items[index].LIKE_TIMES + 1
          : this.schoolData.studentWorkList.items[index].LIKE_TIMES - 1;
    },
  },
  components: { TheHeader, WorkItem, SearchModule, ThePagination,TheFooter },
};
</script>

<style lang='scss'>
.match-center {
  .pbl-banner {
    .el-carousel__container {
      height: 452px;

      .el-carousel__arrow {
        background-color: rgba($color: #000000, $alpha: 0.6);
      }

      .el-carousel__item.banner1 {
        background-image: url(../../assets/image/banner1_repeat_bg.png);
        background-repeat: repeat-x;
        background-size: auto 100%;
      }

      .banner-con {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }
  }
  .content-body {
    width: 1190px;
    margin: 0 auto;
    .introduction {
      width: 100%;
      height: 120px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .introduction-css {
        position: absolute;
        top: 35%;
        width: 20px;
        height: 40px;
        background-color: #ffd101;
        border-radius: 10px;
      }
      .introduction-text {
        position: absolute;
        top: 35%;
        font-size: 26px;
        height: 40px;
        line-height: 40px;
        font-weight: bolder;
        margin-left: 40px;
      }
    }
    .introduction-describe {
      height: 240px;
      border-radius: var(--item-border-radius);
      box-shadow: 0px -2px 0px 0px #e5e5e5, -2px 0px 0px 0px #e5e5e5,
        2px 0px 0px 0px #e5e5e5, 0px 2px 0px 0px #e5e5e5;
      display: flex;
      justify-items: center;
      justify-content: center;

      .describe-text {
        width: 960px;
        margin-top: 70px;
        font-size: 18px;
      }
    }
    .student {
      width: 100%;
      height: 120px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .student-css {
        position: absolute;
        top: 35%;
        width: 20px;
        height: 40px;
        background-color: #ffd101;
        border-radius: 10px;
      }
      .student-text {
        position: absolute;
        top: 35%;
        font-size: 26px;
        height: 40px;
        line-height: 40px;
        font-weight: bolder;
        margin-left: 40px;
      }
      .student-gd {
        width: 100px;
        position: absolute;
        top: 35%;
        font-size: 20px;
        height: 40px;
        line-height: 40px;
        font-weight: bolder;
        margin-left: 40px;
        margin-left: 1104px;
        cursor: pointer;
      }
    }
    .student-img {
      border-radius: var(--item-border-radius);
      box-shadow: 0px -2px 0px 0px #e5e5e5, -2px 0px 0px 0px #e5e5e5,
        2px 0px 0px 0px #e5e5e5, 0px 2px 0px 0px #e5e5e5;
      display: flex;
      flex-wrap: wrap;
      .img-item {
        width: 16.6%;
        height: 240px;
        align-items: center;
        .stu-img {
          margin-left: 32px;
          margin-top: 45px;
          width: 120px;
          height: 120px;
          background-color: #E6E6E6;
          border-radius: 50%;
          justify-content: center;
          .s-img{
          width: 120px;
          height: 120px;
          border-radius: 50%;
          justify-content: center;
          }
        }
        .stu-txt {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
    .student-null{
      border-radius: var(--item-border-radius);
      box-shadow: 0px -2px 0px 0px #e5e5e5, -2px 0px 0px 0px #e5e5e5,
        2px 0px 0px 0px #e5e5e5, 0px 2px 0px 0px #e5e5e5;
      height: 240px;
    }
    .student-works {
      width: 100%;
      height: 120px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      .student-css {
        position: absolute;
        top: 35%;
        width: 20px;
        height: 40px;
        background-color: #ffd101;
        border-radius: 10px;
      }
      .student-text {
        width: 120px;
        position: absolute;
        top: 35%;
        font-size: 26px;
        height: 40px;
        line-height: 40px;
        font-weight: bolder;
        margin-left: 40px;
      }
      .works-num {
        position: absolute;
        top: 50%;
        font-size: 14px;
        width: 80px;
        margin-left: 160px;
      }
      .works-right {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        padding: 42px 48px;
      }
    }
    .works {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .works-item {
        max-height: 320px;
        width: 23%;
        margin-left: 10px;
        margin-top: 20px;
      }
    }
    .Pageturning {
      margin-top: 28px;
      width: 100%;
      justify-content: center;
    }
  }
}
</style>