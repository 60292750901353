<template>
  <div class="lssson-module">
      <module-title :title="title" :path="path"></module-title>
      <div class="work-module-content ub">
          <lesson-item v-for="(item, index) in data.slice(0,4)" :key="index" :item="item"></lesson-item>
      </div>
  </div>
</template>

<script>
import LessonItem from './LessonItem.vue'
import ModuleTitle from './ModuleTitle.vue'
export default {
  props: {
      title: {
          type: String,
          default: ''
      },

      data: {
        type: Array,
        default: () => {
            return []
        }
      },

      path: {
        type: String,
        default: ''
      }
  },

  components: { LessonItem, ModuleTitle },

  created() {
      console.log(this.title)
  }

}
</script>

<style lang="scss">
.lssson-module {

}
</style>