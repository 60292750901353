<template>
  <div class="wx-qr-code">
      <img src="../assets/image/contact_us.png" alt="">
      <span>扫码关注我们</span>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.wx-qr-code {
    width: 150px;
    margin: 40px auto 0;
    text-align: center;
    img{
        width: 100%;
        float: left;
    }

    span{
        font-size: 14px;
        line-height: 16px;
        margin-top: 12px;
        display: inline-block;
        color: var(--el-color-five);
    }
}
</style>