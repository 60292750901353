<template>
  <div class="activity-tab-title">{{title}}
    <slot></slot>
  </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
.activity-tab-title {
    height: 80px;
    line-height: 80px;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 28px;
}
</style>