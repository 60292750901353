<template>
  <div class="official-notice">
      <div class="title">公告</div>
      <div class="notice-list" v-loading="loading">
          <div v-if="noticeList">
            <div v-if="noticeList.length > 0">
                <div class="notice-list-item ub" @click="noticeDetail(item)" v-for="(item,index) in noticeList" :key="index">
                    <span class="notice-title-type">【{{item.noticeType}}】</span>
                    <span class="notice-title ub-f1">{{item.title}}</span>
                    <span class="top" v-if="item.isTop ==1">置顶</span>
                </div>
            </div>
            <el-empty v-else :description="description" />
            <div class="pagination-model ub ub-pc" ><el-pagination class="default-pagination" background layout="prev, pager, next" :total="total" :pageSize="num" @size-change="paginationChange"/></div>
          </div>
      </div>
  </div>
</template>

<script>
import { noticeMsg } from '../../http/api'
export default {
    data() {
        return {
            loading: false,
            description: '暂无数据',
            noticeList: null,
            page: 1,
            num: 15,
            total: 0
        }
    },

    created() {
        this.getData()
    },

    methods: {
        getData() {
            this.loading = true
            let params = {
                type: 1,
                page: this.page,
                num: this.num
            }
            console.log(params)
            noticeMsg(params).then((res) => {
                console.log(res)
                this.loading = false
                this.noticeList = res.list
                this.total = res.num
            }).catch(() => {
                this.loading = false
                this.description = '加载失败，请刷新重试！'
            })
        },

        /**
         * 查看详情
         */
        noticeDetail(item) {
           this.lookNotice(item.rowId)
           sessionStorage.setItem('noticeInfo',JSON.stringify(item));
           this.$router.push({ name: "NoticeDetail"});
        },

        /**
         * 详情查看次数
         */
        lookNotice(id) {
            console.log(id)
        }
    }
}
</script>

<style lang='scss'>
.official-notice {
    .notice-list {
        padding: 0 25px;
        min-height: 300px;

        .notice-list-item {
            width: 883px;
            font-size: 14px;
            line-height: 36px;
            position: relative;
            cursor: pointer;
        }

        .notice-list-item:hover {
            color: var(--el-color-second);
        }

        .top {
            width: 53px;
            height: 36px;
            line-height: 36px;
            position: absolute;
            right: 0;
            top: 0;
            color: var(--el-color-second);
            font-weight: 500;
            text-align: right;
        }
    }

    .pagination-model {
        background-color: #fff;
    }
}
</style>