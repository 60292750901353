<template>
    <div class="template-two">
      <div class="activity-header">
          <el-image :src="headerInfo.detailsBanner" class="activity-header-pic" fit="cover"  v-if="headerInfo"></el-image>
          <div class="activity-header-info warp" v-if="headerInfo">
              <div class="activity-handle ub ub-pc">
                  <!-- 活动未进行中（未开始/已结束） -->
                  <el-button v-if="headerInfo.state != 0" type="info" disabled>{{activityState(headerInfo.state)}}</el-button>
                  <!-- 活动进行中 （进行中） -->
                  <el-button v-if="headerInfo.state == 0 && headerInfo.contState  == 0" class="default-button created" @click="contributionChange">{{headerInfo.whetherContribute == 1 ? '已投稿' : '我要投稿'}}<span class="small-size" v-if="headerInfo.whetherContribute == 1">（更换投稿）</span></el-button>
                  <!-- 活动进行中 （进行中）并且用户未投稿并且投稿已结束或者未开始 -->
                  <el-button v-if="headerInfo.state == 0 && headerInfo.contState != 0" class="default-button created" type="info" disabled>{{headerInfo.contState == -1 ? '投稿未开始' : '投稿已结束'}}</el-button>
                  <!-- 活动进行中 （进行中）并且用户未投稿并且投稿进行中 -->
                  <el-button v-if="headerInfo.state == 0 && headerInfo.contState  == 0" class="el-button default-button created" @click="createdWork">我要创作</el-button>
                  <div class="share iconfont icon-zhuanfa1 ub ub-ac" v-show="false">分享
                      <div class="share-ewm">
                          <div class="text">微信扫一扫分享</div>
                          <div id="qrcodeContainer"></div>
                      </div>
                  </div>
                  <div class="ub ub-ac ub-pc" v-if="showCanvas">
                      <div class="download-certificate iconfont icon-down ub ub-ac" @click="downloadCertificateTap">证书下载</div>
                  </div>
                  <canvas id="certificateCanvas" width="1121" height="793"></canvas>
              </div>
              <div class="activity-download ub ub-ver" v-if="headerInfo && headerInfo.resourceDownload">
                  <a class="iconfont icon-down" :href="item.url" v-for="(item, index) in headerInfo.resourceDownload" :key="index">{{item.name}}</a>
              </div>
          </div>
      </div>
      <el-tabs v-model="activeName" :class="['activity-tabs', activeName == 1 ? 'detail' : '']" @tab-change="handleClick">
          <el-tab-pane :label="item.name" :name="item.id" :class="item.name == '全部作品'? 'all-work' : ''" v-for="(item, index) in tabMenu" :key="index">
              <div class="activity-intro" v-if="item.id != 5">
                  <div class="ub ub-f1 ub-ver">
                      <el-image :lazy="true" :class="[isLInk(subItem.link) ? 'link' : '']" :src="subItem.img" v-for="(subItem, subIndex) in item.imgs" :key="subIndex" @click="openPage(subItem.link)" />
                  </div>
              </div>

              <div v-else class="bgc-white">
                <activity-tab-title title="全部作品"></activity-tab-title>
                <div class="work-list ub ub-wrap" v-loading="loading" v-if="!loadErr">
                    <div class="ub ub-f1" v-if="dataInfo">
                        <div class="ub ub-f1  ub-wrap" v-if="dataInfo.list.length > 0">
                            <work-item v-for="(item, index) in dataInfo.list" :key="index" :class="[(index + 1)%5 == 0 ? 'last' : '', item.NICKNAME]" :item="item"></work-item>
                            <the-pagination :total="total" :pageSize="num" @paginationChange="paginationChange"></the-pagination>
                        </div>
                        <the-empty v-else></the-empty>
                    </div>
                </div>
                <el-empty v-if="loadErr" description="加载失败，请刷新重试！"></el-empty>
              </div>
              
          </el-tab-pane>
          <!-- <el-dialog
            v-model="isCreatedDialog"
            title="选择作品装作类型"
            width="30%"
            :before-close="handleClose"
        >
            <el-button @click="createdWorkTap(1)">scratch作品创作</el-button>
            <el-button @click="createdWorkTap(2)">其它类型作品创作</el-button>
        </el-dialog> -->
      </el-tabs>
    </div>
  </template>
  
  <script>
  import { activityMsg } from '@/http/api'
  import TheEmpty from '@/components/TheEmpty.vue'
  import ThePagination from '@/components/ThePagination.vue'
  import UserCell from '../UserCell.vue'
  import CommentMessage from '@/pages/work/components/CommentMessage.vue'
  import ActivityTabTitle from '@/pages/activity/components/ActivityTabTitle.vue'
  import { mapGetters } from 'vuex'
import WorkItem from '@/components/WorkItem.vue'
  export default {
      components: {
          TheEmpty,
          ThePagination,
          UserCell,
          CommentMessage,
          ActivityTabTitle,
          WorkItem
      },
      props: {
          headerInfo: {
              type: Object,
              default: () => {
                  return {}
              }
          },
          activityIntro: {
              type: Array,
              default: () => {
                  return []
              }
          },
          params: {
              type: Object,
              default: () => {
                  return {}
              }
          }
  
      },
      computed: {
          ...mapGetters(['getUserInfo']),
          isLInk() {
              return function(link) {
                  return link.indexOf('http') != -1
              }
          },
          activityState() {
              return function(state) {
                  switch (Number(state)) {
                      case -2:
                          return '活动已结束';
                      case -1:
                          return '活动暂未开始';
                      case 0:
                          return '进行中';
                      default:
                          break;
                  }
              }
          }
      },
      provide() {
        return {
            likeTapSuccess: this.likeTapSuccess,
        }
      },
      data() {
          return {
              showCanvas: false,
              activeName: 1,
              type: 3,
              dataInfo: null, 
              workData: null,
              loading: false,
              loadErr: false,
              page: 1,
              num: 20,
              tabMenu: []
          }
      },
      created() {
        this.tabMenu = this.headerInfo.detailsUrl.filter((item) => {
            if (!this.getUserInfo || this.getUserInfo.role != 5) {
                return item.name != '全部作品'
            }
            return item
        })
        console.log(this.tabMenu)
        this.activeName = this.headerInfo.detailsUrl[0].id
      }, 
      methods: {
          init() {
              this.workData = null
              this.loading = false
              this.loadErr = false
              this.page = 1
          },
  
          handleClick(activeName) {
              console.log(activeName)
              this.init()
              if (activeName == 5) {
                  // 全部作品
                  this.type = 3
                  this.getData()
              }

                // 使用 $nextTick 来确保 DOM 更新完成后再执行计算高度的操作
                this.$nextTick(() => {
                // 获取容器元素，并触发重新计算高度
                const container = document.querySelector('.el-scrollbar__wrap');
                if (container) {
                    container.scrollTop += 1;
                    container.scrollTop -= 1;
                }
                });
          },
          /**
           * 获取该活动所有作品/参与者
           */
           getData() {
              this.loading = true
              let param = {
                  type: this.type,
                  activityId: this.params.activityId,
                  userId: this.getUserInfo ? this.getUserInfo.uid : '',
                  pageNo: this.page,
                  pageSize: this.num
              }
              console.log(this.type)
              if (this.type == 3) {
                  param.types = this.headerInfo.types
              }
              console.log(param)
              activityMsg(param).then((res) => {
                  console.log(res)
                  this.loading = false
                  // res.data.list = []
                  this.total = res.data.sum
                  this.dataInfo = res.data
                      
              }).catch((err) => {
                  console.log(err)
                  this.loading = false
                  this.loadErr = true
              })
          },
  
  
          /**
           * 查看学生信息
           */
           studentTap(uid) {
            this.$router.push('/personal-center?uid=' + uid)
          },
  
          /**
           * 创建作品
           */
           createdWork() {
                this.$emit('createdWork')
            },
          /**
           * 打开图片链接地址
           */
          openPage(link) {
              this.$emit('openPage', link)
          },
  
          /**
           * 投稿
           */
          contributionChange() {
              this.$emit('contributionChange')
          },

        /**
        * 点赞成功，修改状态
        */
        likeTapSuccess() {
            console.log(arguments)
            let index = this.dataInfo.list.findIndex((item) => item.PROJECT_ID == arguments[2].PROJECT_ID)
            this.dataInfo.list[index].IS_LIKE = this.dataInfo.list[index].IS_LIKE == 0 ? 1 : 0
            this.dataInfo.list[index].LIKE_TIMES = this.dataInfo.list[index].IS_LIKE == 0 ? this.dataInfo.list[index].LIKE_TIMES - 1 : this.dataInfo.list[index].LIKE_TIMES + 1
        },
      }
  }
  </script>
  
  <style lang="scss">
  .activity-detail {
    .template-two {
        .activity-header {
            height: 640px;
            background-color: #002154;
            .activity-header-info {
                .activity-handle {
                    margin-top: 287px;
                    .el-button, .el-button.created {
                        background-color: inherit;
                        border: inherit;
                        background-image: url('../../../../assets/image/button_bg.png');
                        background-size: 100%;
                        span {
                            color: #71fffd;
                        }
                    }
                    
                }
            }
            .activity-download {
                margin-left: 300px;
                a {
                    color: #ffffff !important;
                }
            }
        }
    }

    /* 太空探索 */
    .space-exploration {
        .activity-tabs {
            background-color: #002154;
            .el-tabs__header {
                background-color: #0c326e;
                .el-tabs__nav-wrap {
                    .el-tabs__nav-scroll {
                        .el-tabs__nav {
                            display: flex;
                            flex-direction: row;
                            position: relative;
                            -webkit-justify-content: space-between;
                            -moz-justify-content: space-between;
                            -ms-justify-content: space-between;
                            justify-content: space-between;
                            .el-tabs__item {
                                
                            }
                            .el-tabs__item.is-active {
                                background-color: #1a4993;
                            }
                        }
                    }
                }
            }
            .el-tabs__content {
                background-color: #002154;
                .activity-tab-title {
                    padding: 0 15px;
                }
            }
        }
    }

    // 24年人工智能科普活动展示
    .space-exploration2 {
        .activity-header {
            .activity-header-info {
                .activity-handle {
                    .el-button, .el-button.created {
                        background-image: url('../../../../assets/image/button_bg2.png');
                        span {
                            color: #0d4680;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
  }
  </style>