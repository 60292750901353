<template>
  <div class="work-name-project" @click="workCode">{{name}}</div>
</template>

<script>
import config from '../http/config'
export default {
    props: {
        name: {
            type: String,
            default: ''
        },

        id: {
            type: String,
            default: ''
        },

        isClick: {
            type: Boolean,
            default: true
        }
    },

    methods: {
        workCode() {
            if (!this.$util.isDefine(this.id) || !this.isClick) {
                return
            }
            window.open(`${config.scratchUrl.create}/#${this.id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
.work-name-project {
    color: var(--el-color-second);
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
}
</style>