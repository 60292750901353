// 引入vue-router对象
import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import vue from '../main'


// 1. 定义路由加载模式
const history = createWebHashHistory()

// 2. 定义一些路由
// 每个路由都需要映射到一个组件。
// 我们后面再讨论嵌套路由。
const routes = [
  {
    path: '/',
    component: require('@/pages/home/home-page').default,
    name: 'Home',
    meta: {
      isHeaderMenu: true, // 是否显示在顶部导航
      isFooterMenu: false, // 是否显示在底部导航
      title: '首页'
    }
  }, {
    path: '/course-index',
    component: require('@/pages/course/course-index').default,
    name: 'CourseIndex',
    meta: {
      isHeaderMenu: true,
      isFooterMenu: false,
      title: '课程中心'
    },
    children: [{
      path: '/course-index/course-public',
      component: require('@/pages/course/course-public').default,
      name: 'CoursePublic',
      meta: {
        isHeaderMenu: true,
        title: '公益课程'
      }
    },
    {
      path: '/course-index/course-center',
      component: require('@/pages/course/course-center').default,
      name: 'CourseCenter',
      meta: {
        isHeaderMenu: true,
        title: '呱呱系列'

      }
    }]
  }, {
    path: '/work-center',
    component: require('@/pages/work/work-center').default,
    name: 'WorkCenter',
    meta: {
      isHeaderMenu: true,
      isFooterMenu: false,
      title: '作品中心',
      keepAlive: true
    }
  }, {
    path: '/work-detail',
    component: require('@/pages/work/work-detail').default,
    name: 'WorkDetail',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '作品详情'
    }
  }, {
    path: '/match-center',
    component: require('@/pages/match/match-center').default,
    name: 'MatchCenter',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '赛事中心'
    }
  }, {
    path: '/walk-school',
    component: require('@/pages/school/walk-school').default,
    name: 'WalkSchool',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '走进校园'
    }
  }, {
    path: '/activity-index',
    component: require('@/pages/activity/activity-index').default,
    name: 'EventsActivity',
    meta: {
      isHeaderMenu: true,
      isFooterMenu: false,
      title: '活动'
    },
    children: [{
      path: '/activity-index/events-activity',
      component: require('@/pages/activity/events-activity').default,
      name: 'EventsActivity',
      meta: {
        isHeaderMenu: false,
        title: '活动'
      }
    }, {
      path: '/activity-index/activity-win',
      component: require('@/pages/activity/activity-win').default,
      name: 'ActivityWin',
      meta: {
        isHeaderMenu: false,
        title: '获奖作品'
      }
    }, {
      path: '/activity-index/activity-enroll',
      component: require('@/pages/activity/activity-enroll').default,
      name: 'activityEnroll',
      meta: {
        isHeaderMenu: false,
        title: '活动报名入口'
      }
    }]
  }, {
    path: '/activity-detail',
    component: require('@/pages/activity/activity-detail').default,
    name: 'ActivityDetail',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '活动详情'
    }
  }, {
    path: '/shopping-index',
    component: require('@/pages/shop/shopping-index').default,
    name: 'ShoppingIndex',
    meta: {
      isHeaderMenu: false,
      isHeaderRightMenu: true,
      isFooterMenu: false,
      title: '资源库',
      keepAlive: false

    },
    children: [{
      path: '/shopping-index/shopping-center',
      component: require('@/pages/shop/shopping-center').default,
      name: 'ShoppingCenter',
      meta: {
        isHeaderMenu: false,
        isFooterMenu: false,
        title: '资源库'
      }
    }, {
      path: '/shopping-index/wares-task',
      component: require('@/pages/shop/wares-task').default,
      name: 'WaresTask',
      meta: {
        title: '做任务',
        login: true
      }
    }, {
      path: '/shopping-index/wares-record',
      component: require('@/pages/shop/wares-record').default,
      name: 'WaresRecord',
      meta: {
        title: '兑换记录',
        login: true
      }
    }, {
      path: '/shopping-index/shopping-detail',
      component: require('@/pages/shop/shopping-detail').default,
      name: 'ShoppingDetail',
      meta: {
        title: '资源库详情',
      }
    }, {
      path: '/shopping-index/order-detail',
      component: require('@/pages/shop/order-detail').default,
      name: 'OrderDetail',
      meta: {
        title: '订单详情',
      }
    }]
  }, {
    path: '/about-us',
    component: require('@/pages/about/about-us').default,
    name: 'AboutUs',
    meta: {
      isHeaderMenu: false,
      isHeaderRightMenu: true,
      isFooterMenu: true,
      title: '关于我们'
    },
    children: [{
      path: '/about-us/about-company',
      component: require('@/pages/about/about-company').default,
      name: 'aboutCompany',
      meta: {
        title: '公司简介',
        isHeaderMenu: true,
        isFooterMenu: true,
        isSecondMenu: true,
      }
    }, {
      path: '/about-us/join-in',
      component: require('@/pages/about/join-in').default,
      name: 'JoinIn',
      meta: {
        title: '加盟咨询',
        isHeaderMenu: true,
        isFooterMenu: true,
        isSecondMenu: true,
      }
    }, {
      path: '/about-us/contact-us',
      component: require('@/pages/about/contact-us').default,
      name: 'ContactUs',
      meta: {
        title: '联系我们',
        isHeaderMenu: true,
        isFooterMenu: true,
        isSecondMenu: true,
      }
    }, {
      path: '/about-us/common-problem',
      component: require('@/pages/about/common-problem').default,
      name: 'CommonProblem',
      meta: {
        title: '常见问题',
        isHeaderMenu: true,
        isFooterMenu: false,
        isSecondMenu: true,
      }
    }, {
      path: '/about-us/official-notice',
      component: require('@/pages/about/official-notice').default,
      name: ' OfficialNotice',
      meta: {
        title: '公告',
        isHeaderMenu: false,
        isFooterMenu: false,
        isSecondMenu: true,
      }
    }, {
      path: '/about-us/legal-declaration',
      component: require('@/pages/about/legal-declaration').default,
      name: 'LegalDeclaration',
      meta: {
        title: '法律声明',
        isHeaderMenu: false,
        isFooterMenu: false,
        isSecondMenu: true,
      }
    }, {
      path: '/about-us/about-feedback',
      component: require('@/pages/about/about-feedback').default,
      name: 'AboutFeedback',
      meta: {
        title: '反馈',
        isHeaderMenu: false,
        isFooterMenu: false,
        isSecondMenu: true,
      }
    }, {
      path: '/about-us/notice-detail',
      component: require('@/pages/about/notice-detail').default,
      name: 'NoticeDetail',
      meta: {
        title: '公司详情',
        isHeaderMenu: false,
        isFooterMenu: false,
        isSecondMenu: false,
      }
    }]
  }, {
    path: '/user-agreement',
    component: require('@/pages/about/user-agreement').default,
    name: 'UserAgreement',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '用户协议'
    }
  }, {
    path: '/juveniles-agreement',
    component: require('@/pages/about/juveniles-agreement').default,
    name: 'JuvenilesAgreement',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '未成年人信息保护规则'
    }
  }, {
    path: '/down-index',
    component: require('@/pages/down/down-index').default,
    name: 'DownIndex',
    meta: {
      isHeaderMenu: false,
      isHeaderRightMenu: true,
      isFooterMenu: false,
      title: '下载'
    },
    children: [{
      path: '/down-index/down-product',
      component: require('@/pages/down/down-product').default,
      name: 'DownProduct',
      meta: {
        title: '应用下载',
        isHeaderMenu: true,
        isFooterMenu: true,
        isSecondMenu: true,
      }
    }, {
      path: '/down-index/down-resources',
      component: require('@/pages/down/down-resources').default,
      name: 'DownResources',
      meta: {
        title: '资源下载',
        isHeaderMenu: true,
        isFooterMenu: true,
        isSecondMenu: true,
        isTeacher: true,
        login: true
      }
    }]
  }, {
    path: '/class-center',
    component: require('@/pages/class/class-center').default,
    name: 'ClassCenter',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '我的班级',
      login: true
    }
  }, {
    path: '/class-manage',
    component: require('@/pages/class/class-manage').default,
    name: 'ClassManage',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '班级管理',
      login: true
    }
  }, {
    path: '/project-created',
    component: require('@/pages/class/project-created').default,
    name: 'ProjectCreated',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '创建项目',
      login: true
    }
  }, {
    path: '/project-detail',
    component: require('@/pages/class/project-detail').default,
    name: 'ProjectDetail',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '项目详情',
      login: true
    }
  }, {
    path: '/account-info',
    component: require('@/pages/account/account-info').default,
    name: 'AccountInfo',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '账号信息',
      login: true
    }
  },
  {
    path: '/course-detail',
    component: require('@/pages/course/course-detail').default,
    name: 'CourseDetail',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '课程详情',
    }
  }, {
    path: '/personal-center',
    component: require('@/pages/personal/personal-center').default,
    name: 'PersonalCenter',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '个人中心',
      login: true
    }
  }, {
    path: '/message-center',
    component: require('@/pages/message/message-center').default,
    name: 'MessageCenter',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '消息中心',
      login: true
    }
  }, {
    path: '/create-index',
    component: require('@/pages/create/create-index').default,
    name: 'CreateIndex',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '创作',
      login: true
    }
  },
  {
    path: '/end-detail',
    component: require('@/pages/match/end-detail').default,
    name: 'EndDetail',
    meta: {
      isHeaderMenu: false,
      isFooterMenu: false,
      title: '赛事详情',
      login: true
    }
  }
]

/* let aa = vue._context.config.globalProperties
console.log(aa) */
// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
// 暂时保持简单
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history,
  routes, // `routes: routes` 的缩写
})

// 4. 添加全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = '智多蛙STEAM教育' + (to.meta.title ? '-' + to.meta.title : '');
  }

  window.scrollTo(0, 0);
  console.log(to)
  // 判断该路由是否需要登录权限
  let userInfo = store.getters.getUserInfo
  console.log(userInfo)
  if (userInfo) {
    if ((userInfo.name == '' || userInfo.tel == '') && to.fullPath != '/account-info') {
      next('/account-info');
      // next()

    } else {
      next()
    }
  } else {
    if (to.meta.login) {
      if (!from.name) {
        store.commit('setToIndex', true)
      }
      store.commit('setLoginAlertState', true)
    } else {
      next()
    }
  }
  /* if (to.meta.login) {
    if (userInfo) {
      next();
    } else {
        // ...
        if (!from.name) {
          store.commit('setToIndex', true)
        }
        store.commit('setLoginAlertState', true)
    }
  } else {
    next(); // 确保一定要有next()被调用
  } */
})

export default router