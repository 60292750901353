<template>
  <div class="order-detail">
    <the-header></the-header>
    <div class="content-body warp">
      <the-breadcrumb :breadcrumbList="breadcrumbList"></the-breadcrumb>
      <div class="order-state">
        当前订单状态：<span class="state1">{{stateText}}</span>
      </div>
      <div class="exchange-order ub ub-ver">
        <div class="exchange-order-header ub">
          <div class="exchange-head-wares">物品</div>
          <div class="exchange-head-num">数量</div>
          <div class="exchange-head-price">单价</div>
          <div class="exchange-head-total">合计</div>
        </div>
        <div class="exchange-order-content ub ub-ac">
          <el-image :src="item.materialCover" fit="contain" />
          <div class="exchange-order-info">
            <div class="wares-title">{{ item.materialName }}</div>
            <div class="wares-tip">类型：{{ item.sortName }}</div>
            <div class="wares-tip">稀有等级：{{ item.rare }}</div>
            <div class="wares-tip">{{ item.materialDescribe }}</div>
          </div>
          <div class="exchange-order-num">X{{item.orderCommoditySum}}</div>
          <div class="exchange-order-coin">{{item.exchangeValue}}金币</div>
          <div class="exchange-order-total">{{item.exchangeValue}}金币</div>
        </div>
        <div class="order-total ub ub-pe">
          总金额：<span>{{item.exchangeValue}}<i>金币</i></span>
        </div>
      </div>
      <div class="order-tip">
          <p class="title">注意事项</p>
          <p>1、虚拟物品兑换成功后不予退回。</p>
          <p>2、兑换成功后可以在资源库-兑换记录里查看</p>
      </div>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheBreadcrumb from "@/components/TheBreadcrumb.vue";
export default {
  components: { TheHeader, TheBreadcrumb },
  data() {
    return {
      breadcrumbList: [
        {
          name: "资源库",
          path: "/shopping-index",
        },
        {
          name: "兑换记录",
          path: "/shopping-index/wares-record",
        },
        {
          name: "订单详情",
          path: "/shopping-index/wares-record",
        },
      ],
    };
  },

  created() {
    let orderItem = sessionStorage.getItem("waresOrder");
    if (orderItem) {
      this.item = JSON.parse(orderItem);
      console.log(this.item);
    } else {
      this.$route.go(-1);
    }
  },

  computed: {
    stateText() {
      switch (this.item.state) {
        case -1:
          return "已取消";
        case 1:
          return "待支付";
        case 0:
          return "已完成";
        default:
          return "";
      }
    },
  },
};
</script>

<style lang="scss">
.order-detail {
  .content-body {
    .order-state {
      height: 52px;
      line-height: 52px;
      padding: 0 30px;
      background-color: #ebebeb;

      span.state1 {
        color: var(--el-color-second);
      }
    }

    .exchange-order {
      border-radius: 8px;
      overflow: hidden;
      margin-top: 8px;

      .exchange-order-header {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        font-weight: 500;
        padding: 0 15px;
        background-color: var(--el-color-primary);
        text-align: center;

        .exchange-head-wares {
          width: 404px;
          text-align: left;
        }

        .exchange-head-num {
          width: 100px;
        }

        .exchange-head-price {
          width: 100px;
        }

        .exchange-head-total {
          width: 100px;
        }
      }

      .exchange-order-content {
        height: 137px;
        background-color: #fff;
        box-sizing: border-box;
        padding: 0 15px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;

        .el-image {
          width: 128px;
          height: 98px;
          margin-right: 16px;
        }

        .exchange-order-info {
          width: 260px;
          padding-right: 10px;
          box-sizing: border-box;
          font-size: 12px;
          color: #a0a0a0;
          text-align: left;

          .wares-title {
            font-size: 14px;
            margin-bottom: 10px;
            color: #000000;
          }
        }

        .exchange-order-num {
          width: 100px;
        }

        .exchange-order-coin {
          width: 100px;
        }

        .exchange-order-total {
          width: 100px;
        }
      }

      .order-total {
        height: 67px;
        line-height: 67px;
        background-color: #fff;
        padding: 0 25px;
        border-top: 1px solid #eeeeee;
        font-size: 14px;

        span {
          color: var(--el-color-second);
          font-weight: bold;
          font-size: 22px;

          i {
            font-weight: 400;
            font-size: 12px;
            font-style: normal;
            margin-left: 7px;
          }
        }
      }
    }

    .order-tip {
        p.title{
            font-size: 18px;
            margin-top: 85px;
            margin-bottom: 5px;
        }

        p {
            line-height: 30px;
            font-size: 14px;
        }
    }
  }
}
</style>