<template>
  <el-dialog
      :model-value="isPreviewDialog"
      :close-on-click-modal="false"
      width="1230px"
      title="预览"
      custom-class="default-dialog preview-dialog"
      :before-close="handleClose">
        <div class="detail-work ub ub-be">
            <div class="work-left">
                <div class="work-con">
                    <work-media-other :images="previewFiles"></work-media-other>
                </div>
                <div class="work-info ub ub-ac">

                </div>
            </div>

            <div class="work-right ub ub-ver">
                <div class="work-title ub ub-ac"><div>{{ formData.title }}</div></div>
                <div class="work-author">
                    <div class="ub ub-ac">
                        <user-img class="author-img" :userImg="getUserInfo.user_img"></user-img>
                        <div class="ub ub-ver">
                            <div class="author-name">{{ getUserInfo.nickname }}</div>
                            <div class="update">更新时间：{{date}}</div>
                        </div>
                    </div>
                </div>
                <div class="ub ub-f1 ub-ver">
                    <div class="introduce work-introduce ub-f1-item">
                        <div class="title">作品简介</div>
                        <div class="text">{{ formData.content }}</div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import WorkMediaOther from '@/pages/work/components/WorkMediaOther.vue'
import { mapGetters } from 'vuex'
import UserImg from '@/components/UserImg.vue'
export default {
  components: { WorkMediaOther, UserImg },
    props: {
        isPreviewDialog: {
            type: Boolean,
            default: false
        },

        formData: {
            type: Object,
            default: () => {
                return {}
            }
        },

        previewFiles: {
            type: Array,
            default: () => {
                return []
            }
        }
    },

    data() {
        return {
            date: ''
        }
    },

    created() {
        console.log(this.previewFiles)
    },

    computed: {
        ...mapGetters(['getUserInfo']),

        date() {
            return this.formatDate(new Date())
        }
    },

    methods: {
        handleClose() {
            this.$emit('closeRreviewDialog')
        },

        formatDate(date) {
            var myyear = date.getFullYear();
            var mymonth = date.getMonth() + 1;
            var myweekday = date.getDate();
        
            if (mymonth < 10) {
                mymonth = "0" + mymonth;
            }
            if (myweekday < 10) {
                myweekday = "0" + myweekday;
            }
            return (myyear + "-" + mymonth + "-" + myweekday);//想要什么格式都可以随便自己拼
        }
    },

    watch: {
        isPreviewDialog(newVal) {
            if (newVal) {
                
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.preview-dialog {
    .detail-work {
        .work-left {
            width: 860px;

            .work-con {
                height: 713px;
                background-color: #dcdcdc;
            }
        }

        .work-right {
            width: 310px;
            box-sizing: border-box;
            padding-right: 18px;
            font-size: 16px;
            color: #000000;

            .work-title {
                height: 70px;
            }

            .work-author {
                padding-bottom: 30px;
                border-bottom: 1px solid #dcdcdc;

                .author-img {
                    width: 80px;
                    height: 80px;
                    background-color: #ccc;
                    border-radius: 50%;
                    margin-right: 18px;
                    background-size: cover;
                    background-position: 50%;
                    background-repeat: no-repeat;
                }

                .author-name {
                    line-height: 34px;
                }

                .author-follow-btn {
                    margin-bottom: 10px;
                }

                .update {
                    font-size: 14px;
                    color: var(--el-color-three);
                }
            }

            .introduce {
                border-bottom: 1px solid #dcdcdc;
                .title {
                    line-height: 52px;
                    font-weight: bold;
                    margin-top: 10px;
                }

                .text {
                    font-size: 12px;
                    line-height: 30px;
                    font-weight: 500;
                }
            }
        }
    }
}
</style>