<template>
  <div class="work-detail">
    <the-header></the-header>
    <div class="content-body warp">
      <div class="back iconfont icon-arrow-left" @click="back()">
        返回上一页
      </div>
      <div class="vieo">
        <div class="vieo-left">
          <div class="left-v" v-loading="mp4loading">
            <!-- <iframe id="work-iframe" :src="vieoUrl" frameborder="0" v-if="vieoUrl"></iframe> -->
            <video-player
              class="video-player vjs-custom-skin"
              id="work-iframe"
              ref="videoPlayer"
              :playsinline="true"
              :options="playerOptions"
              @play="onPlayerPlay($event)"
              v-if="mp4loading == false"
            ></video-player>
          </div>
          <div class="work-info ub ub-ac">
            <div class="info-num ub ub-ac">
              <span
                :class="['iconfont', workCollect(detailData.work_collect, 1)]"
                @click="collectWork"
                >{{ workCollect(detailData.work_collect, 2) }}</span
              >
              <like-icon
                :isLike="detailData.IS_LIKE"
                :likeNum="detailData.LIKE_TIMES"
              ></like-icon>
              <span class="relay ub ub-ac iconfont icon-zhuanfa"
                >{{ detailData.SHARE_TIMES }}
                <div class="relay-module">
                  <div class="relay-module-conten ub ub-ver">
                    <div class="relay-title">分享到：</div>
                    <div class="qrcode">
                      <div class="title">扫一扫分享</div>
                      <div class="qrcode-pic" id="relayQrcode"></div>
                    </div>
                    <div class="copy-address">
                      <div class="title">复制视频分享至网页</div>
                      <div class="address-con ub">
                        <el-input disabled v-model="vieoUrl"></el-input>
                        <el-button
                          class="default-button"
                          @click="copyRelayAddress"
                          >复制</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </span>
              <span
                class="iconfont icon-liuyan"
                @click="gotoMessage('#comment-message')"
                >{{ messageInfo.commentNum }}</span
              >
            </div>
            <div class="rate ub ub-ac">
              <span
                class="mask"
                v-if="!getUserInfo || getUserInfo.uid == detailData.USER_ID"
                @click="login"
              ></span>
              评价：<el-rate
                v-model="score"
                :disabled="isScore"
                :colors="rateColors"
              />
            </div>
            <div class="ub-f1 ub ub-pe">
              <div class="">浏览量：{{ detailData.PV }}</div>
            </div>
          </div>
        </div>
        <div class="vieo-rigth">
          <div class="rigth-txt">
            <div class="rigth-name">{{ detailData.NAME }}</div>
            <div class="rigth-time">发布:{{ detailData.CREATE_TIME }}</div>
            <div class="rigth-type">所属分类:{{ detailData.TAG }}</div>
            <div class="rigth-describe">课程简介</div>
            <div class="rigth-t">
              {{
                detailData.WORK_INFO != "" ? detailData.WORK_INFO : "暂无简介"
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="detail-bottom ub ub-be" v-if="messageInfo">
        <div class="detail-message">
          <comment-message
            :authorId="detailData.USER_ID"
            :projectId="detailData.PROJECT_ID"
            ref="commentMessage"
          ></comment-message>
        </div>
        <div class="work-list">
          <div class="tj">推荐课程</div>
          <div v-if="isrecommend">
            <course-item-small
              v-for="(item, index) in recommendData"
              :key="index"
              :item="item"
              style="margin-left: 20px"
            ></course-item-small>
            <div class="more">
              <router-link to="/course-index/course-center">更多>></router-link>
            </div>
          </div>
          <el-empty description="暂无推荐课程" v-else />
        </div>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import config from "../../http/config";
import TheHeader from "@/components/TheHeader.vue";
import { pblWork } from "../../http/api";
import { mapGetters } from "vuex";
import LikeIcon from "@/components/LikeIcon.vue";
import QRCode from "qrcodejs2";
import CommentMessage from "../work/components/CommentMessage.vue";
import CourseItemSmall from "@/components/CourseItemSmall.vue";
import TheFooter from "@/components/TheFooter.vue";

export default {
  components: {
    TheHeader,
    LikeIcon,
    CommentMessage,
    CourseItemSmall,
    TheFooter,
  },
  data() {
    return {
      rateColors: ["#ea4335", "#ea4335", "#ea4335"],
      detailData: "",
      //转发
      relayAddress: "",
      messageInfo: "",
      score: 0,
      commendData: "",
      workUrl: "",
      vieoUrl: "",
      recommendData: "",
      isScore: false,
      isrecommend: false,
      mp4loading: true,
      // 视频播放
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "", //url地址
            // src: "" //url地址
          },
        ],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  created() {
    this.getData();
    console.log(this.$route.query);
    this.getrecommendData();
    this.workUrl = config.scratchUrl.play + `#${this.$route.query.pid}`;
  },

  beforeRouteLeave(to, from, next) {
    console.log("准备离开路由执行");
    if (this.isScore == false) {
        this.rateChange();
        console.log("我执行了");
      }
    setTimeout(() => {
      
      next(console.log("离开路由之后执行"));
    }, 100);
  },
  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    //监听播放
    onPlayerPlay(player) {
      console.log(player);
      if (!this.$util.isDefine(this.getUserInfo)) {
        this.$store.commit("setLoginAlertState", true);
        this.$refs.videoPlayer.player.pause();
        return;
      }
      // this.$refs.videoPlayer.player.play();
    },
    /**
     * 获取作品详情
     * type=5&uid=200000056&pid=2130&uuid=200000044
     */
    getData() {
      let params = {
        type: 5,
        uid: this.getUserInfo ? this.getUserInfo.uid : "",
        uuid: this.$route.query.uuid,
        pid: this.$route.query.pid,
      };
      console.log(params);
      pblWork(params)
        .then((res) => {
          console.log("请求的数据是:", res);
          res.pblwork_info.CREATE_TIME = this.$util.formateDateTimesToZh(
            res.pblwork_info.CREATE_TIME,
            1
          );
          this.detailData = res.pblwork_info;
          this.isScore = this.detailData.score > 0 ? true : false;
          this.score = this.detailData.score;
          this.vieoUrl = this.detailData.CONTENT[0].url;
          this.playerOptions.sources[0].src = this.vieoUrl;
          this.mp4loading = false;
          console.log("score:", this.detailData.score);

          this.$nextTick(() => {
            // this.createQrcode('qrcodeContainer')
            this.createQrcode("relayQrcode");
          });
          this.getMessageData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //type=32&uid&pid
    //获取推荐课程
    getrecommendData() {
      this.isrecommend = false;
      let params = {
        type: 32,
        uid: this.getUserInfo ? this.getUserInfo.uid : "",
        pid: this.$route.query.pid,
      };
      pblWork(params)
        .then((res) => {
          this.isrecommend = res.pblwork_info ? true : false;
          console.log("推荐课程接口请求的数据是:", res);
          this.recommendData = res.pblwork_info;
        })
        .catch((err) => {
          this.isrecommend = false;
          console.log(err);
        });
    },
    likeTapSuccess(type, index) {
      if (type == undefined) {
        console.log(index);
        // console.log()
        this.recommendData[index].IS_LIKE =
          this.recommendData[index].IS_LIKE == 0 ? 1 : 0;
        this.recommendData[index].LIKE_TIMES =
          this.recommendData[index].IS_LIKE == 1
            ? this.recommendData[index].LIKE_TIMES + 1
            : this.recommendData[index].LIKE_TIMES - 1;
      }
    },
    /**
     * 获取留言信息
     * type=11&uid=200000074&pid=2184&sort=desc&view=null
     */
    getMessageData() {
      let params = {
        type: 11,
        uid: this.getUserInfo ? this.getUserInfo.uid : "",
        pid: this.detailData.PROJECT_ID,
        // pid: 2184,
        sort: "desc",
        view: "null",
      };
      pblWork(params)
        .then((res) => {
          // ...
          console.log("评论接口调取成功:", res);
          this.messageInfo = res;
          console.log(this.messageInfo);
        })
        .catch(() => {});
    },

    /**
     * 收藏作品
     */
    collectWork() {
      if (!this.$util.isDefine(this.getUserInfo)) {
        this.$store.commit("setLoginAlertState", true);
        return;
      }
      if (this.detailData.USER_ID != this.getUserInfo.uid) {
        let params = {
          type: 6,
          workType: 2,
          uid: this.getUserInfo ? this.getUserInfo.uid : "",
          pid: this.detailData.PROJECT_ID,
          state: this.detailData.work_collect == 1 ? -1 : 0,
        };
        pblWork(params).then((res) => {
          console.log(res);
          this.detailData.work_collect =
            this.detailData.work_collect == 0 ? 1 : 0;
        });
      } else {
        this.$message({
          type: "success",
          message: "不可以自己收藏自己的课程哦！",
        });
        return;
      }
    },
    /**
     * 评分
     */
    rateChange() {
      /* console.log(val)
           return */
      console.log(this.score);
      if (this.score == 0 || this.isScore) {
        return;
      }
      if (!this.$util.isDefine(this.getUserInfo)) {
        this.$store.commit("setLoginAlertState", true);
        return;
      }
      if (this.isScore == false) {
        let params = {
          type: 7,
          uid: this.getUserInfo ? this.getUserInfo.uid : "",
          pid: this.detailData.PROJECT_ID,
          uuid: this.$route.query.uuid,
          score: this.score,
        };
        pblWork(params)
          .then((res) => {
            // ...
            this.isScore = true;
            console.log("评分接口调取成功:", res);
          })
          .catch((err) => {
            console.log("评分接口调取失败:", err);
            this.score = 0;
          });
      } else {
        return;
      }
    },
    /**
     * 滚动到message
     */
    gotoMessage(id) {
      this.$refs.commentMessage.gotoMessage(id);
      /* console.log(document.querySelector(id))
           document.querySelector(id).scrollIntoView(true); */
    },
    /**
     * 点赞作品
     */
    likeWork() {
      if (!this.$util.isDefine(this.getUserInfo)) {
        this.$store.commit("setLoginAlertState", true);
        return;
      }
      let params = {
        type: 4,
        uid: this.getUserInfo ? this.getUserInfo.uid : "",
        pid: this.detailData.PROJECT_ID,
        state: this.detailData.IS_LIKE == 1 ? -1 : 0,
      };
      pblWork(params).then((res) => {
        console.log(res);
        this.detailData.IS_LIKE = this.detailData.IS_LIKE == 0 ? 1 : 0;
        this.detailData.LIKE_TIMES =
          this.detailData.IS_LIKE == 1
            ? this.detailData.LIKE_TIMES + 1
            : this.detailData.LIKE_TIMES - 1;
      });
    },
    /**
     * 生成二维码
     */
    createQrcode(id) {
      console.log(this.$refs);
      // this.relayAddress = `${config.scratchUrl.phone}&id=${this.$route.query.pid}`;
      let phoneCode = new QRCode(id, {
        text: this.vieoUrl, // 二维码地址
        colorDark: "#000",
        colorLight: "#fff",
      });
      console.log(phoneCode);
    },
    //复制地址
    copyRelayAddress() {
      var temp = document.createElement("textarea");
      temp.value = this.vieoUrl;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      temp.style.display = "none";
      this.$message({
        type: "success",
        message: "复制成功，去分享吧！",
      });
    },
  },
  computed: {
    ...mapGetters(["getUserInfo"]),

    workCollect() {
      return function (isCollect, type) {
        return isCollect == 1
          ? type == 1
            ? "icon-shoucang-act"
            : "已收藏"
          : type == 2
          ? "收藏"
          : "icon-shoucang";
      };
    },

    /**
     * 作品简介
     */
    workInfo() {
      return function (text) {
        return text ? text : "暂无";
      };
    },

    /**
     * 作品操作说明
     */
    workInstruction() {
      return function (text) {
        return text ? text.replace(/\n/g, "<br>") : "暂无";
      };
    },
  },
  watch: {
    $route: {
      handler() {
        this.$router.go(0);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.work-detail {
  .video-js .vjs-icon-placeholder {
    width: 100%;
    height: 100%;
    display: block;
  }
  .back {
    line-height: 103px;
    cursor: pointer;
  }

  .back::before {
    margin-right: 20px;
  }
  .vieo {
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .vieo-left {
      width: 65%;
      .left-v {
        width: 100%;
        height: 430px;
        background-color: #dcdcdc;
        .video-js .vjs-icon-placeholder {
          width: 100%;
          height: 100%;
          display: block;
        }
        video {
          width: 100%;
          height: 100%;
        }
        iframe {
          width: 100%;
          height: 100%;
        }
      }
      .work-info {
        padding: 0 24px;
        height: 55px;
        color: #999999;
        font-size: 12px;
        font-weight: 500;
        border-top: 1px solid #dcdcdc;
        .info-num {
          height: 100%;
          span {
            font-size: 12px;
            display: block;
            line-height: 24px;
            margin: 0 12px;
            font-weight: 500;
            position: relative;
            cursor: pointer;
          }

          span:first-child {
            margin-left: 0;
          }

          span:last-child:after {
            display: none;
          }

          span:before {
            font-size: 18px;
            float: left;
            margin-right: 5px;
            font-weight: 600;
          }

          span:after {
            content: "";
            width: 1px;
            height: 14px;
            background-color: #d3d3d3;
            position: absolute;
            right: -12px;
            top: 50%;
            margin-top: -7px;
          }

          .icon-shoucang-act::before {
            color: var(--el-color-four);
          }

          .relay {
            height: 100%;
            position: relative;
            display: flex;

            .relay-module {
              width: 382px;
              height: 340px;
              position: absolute;
              left: -50px;
              top: -340px;
              z-index: 99;
              display: none;
              .relay-module-conten {
                width: 100%;
                height: 326px;
                background-color: #fff;
                border-radius: var(--item-border-radius);
                padding: 27px 22px;
                box-sizing: border-box;
                color: #000000;
                font-size: 14px;

                .relay-title {
                  font-size: 16px;
                }

                .qrcode {
                  text-align: center;
                  margin-bottom: 15px;

                  .title {
                    line-height: 60px;
                  }

                  .qrcode-pic {
                    img {
                      width: 122px;
                      height: 122px;
                      margin: 0 auto;
                    }
                  }
                }

                .copy-address {
                  .title {
                    margin-bottom: 5px;
                  }
                  .address-con {
                    .el-input {
                      margin-right: 8px;

                      input {
                        padding-right: 11px !important;
                      }
                    }
                    .el-button {
                      span {
                        margin-right: 0;
                        font-size: 14px;
                        line-height: 32px;
                      }
                    }
                  }
                }
              }
            }

            .relay-module::before {
              content: "";
              width: 0;
              height: 0;
              border-left: 14px solid transparent;
              border-right: 14px solid transparent;
              border-top: 25px solid #fff;
              position: absolute;
              bottom: -10px;
              left: 60px;
            }
          }

          .relay:hover {
            .relay-module {
              display: block;
            }
          }
        }

        .rate {
          margin-left: 8px;
          margin-right: 24px;
          position: relative;

          .mask {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;
            z-index: 999;
          }
        }

        .mobile-version {
          cursor: pointer;
          color: #000000;
          position: relative;
          width: 48px;

          span {
            width: 94px;
            height: 94px;
            padding: 10px;
            box-sizing: border-box;
            display: block;
            position: absolute;
            top: -80px;
            left: 48px;
            box-shadow: 0px 0px 5px #cccccc;
            background-color: #fff;
            transform: scale(0);

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .mobile-version:hover {
          span {
            transform: scale(1);
          }
        }

        .icon-quanping {
          font-size: 18px;
          margin-right: 13px;
          cursor: pointer;
        }
      }
    }
    .vieo-rigth {
      margin-left: 10px;
      width: 33%;
      .rigth-name {
        width: 100%;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bolder;
      }
      .rigth-time {
        width: 100%;
        height: 40px;
        line-height: 40px;
        color: #adadad;
        font-size: 10px;
      }
      .rigth-type {
        width: 100%;
        height: 60px;
        line-height: 30px;
        border-bottom: 1px solid #dcdcdc;
      }
      .rigth-describe {
        width: 100%;
        height: 60px;
        line-height: 80px;
        font-weight: bold;
      }
      .rigth-t {
        font-size: 12px;
      }
    }
  }
  .detail-bottom {
    margin-top: 15px;
    width: 100%;

    .detail-message {
      width: 860px;
    }

    .work-list {
      width: 310px;
      background-color: #ffffff;
      box-sizing: border-box;
      padding-bottom: 28px;
      border-radius: 10px 10px 0 0;

      .tj {
        width: 100%;
        height: 45px;
        text-align: center;
        line-height: 45px;
        background-color: #f4c800;
        border-radius: 10px 10px 0 0;
      }
      .more {
        margin-top: 20px;
        margin-right: 90px;
        text-align: right;

        a {
          color: #6e6e6e;
          font-weight: bold;
        }

        a:hover {
          text-decoration: underline;
          color: var(--el-color-primary);
        }
      }

      .work-item-small {
        margin-top: 28px;
      }
    }
  }
}
</style>