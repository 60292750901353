<template>
  <el-dialog
      :model-value="isUserImageDialog"
      :close-on-click-modal="false"
      title="更改头像"
      custom-class="default-dialog type1 user-image-dialog"
      :before-close="handleClose">
        <div class="menu ub tx-c ub ub-ac ub-pc">
            <!-- action="https://pbl.zdw000.com/new_api_test/ycsj_pbl_program_test/api/"https://pbl.zdw000.com/api/ -->
            <el-upload
                class="avatar-uploader ub-f1 ub ub-ac ub-pc"
                :action="url + '/'"
                :show-file-list="false"
                :on-error="handleAvatarError"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <el-button size="small" type="primary">上传本地头像</el-button>
              </el-upload>
              <div class="ub-f1 ub ub-ac ub-pc"><el-button class=" ub-f1 " size="small" type="primary" @click="resetUserImage">恢复默认头像</el-button></div>
            <!-- <li class="ub-f1">上传本地图片</li> -->
            <!-- <li class="ub-f1">恢复默认头像</li> -->
        </div>
        <div class="user-img"  v-loading="userImageLoading">
            <el-image :src="userImage ? userImage : getUserInfo.user_img"></el-image>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="handleClose">取消</el-button>
                <el-button type="primary" @click="modifyAvatar"
                >确认</el-button
                >
            </span>
        </template>
      </el-dialog>
</template>

<script>
import config from '../../../http/config'
import { loginMsgPost } from '../../../http/api'
import { mapGetters } from 'vuex'
export default {
  components: { },
    props: {

        isUserImageDialog: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            userImage: '',
            userImageLoading: false,
            userImageType: 'def'
        }
    },

    created() {
        this.url = config.url
    },

    computed: {
        ...mapGetters(['getUserInfo'])
    },

    methods: {
        handleClose() {
            this.$emit('userImageDialogClose')
        },

        // 获取头像失败
        handleAvatarError() {
            this.$message({
                showClose: true,
                message: '获取图片失败!',
                type: 'error'
            });
            this.userImageLoading = false
        },

        // 获取头像成功
        handleAvatarSuccess(res, file) {
            console.log(res)
            console.log(file)
            this.userImage = URL.createObjectURL(file.raw);
            console.log(this.userImg)
            console.log(JSON.stringify(this.userImg))
            this.fileImg = file.raw;
            this.userImageLoading = false;
            this.userImageType = 'upload';
        },
        
        // 上传图片限制
        beforeAvatarUpload(file) {
            console.log(file)
            this.userImageLoading = true;
            const isJPG = file.type === "image/jpeg";
            const isPNG = file.type === "image/png";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG && !isPNG) {
                this.$message.error("上传头像图片只能是 JPG或者PNG 格式!");
                this.userImageLoading = false;
            }

            console.log(isLt2M)
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
                this.userImageLoading = false;
            }
            return (isJPG || isPNG) && isLt2M;
        },

        resetUserImage() {
            this.userImage = require('../../../assets/image/personal_default_banner2.png')
            this.userImageType = 'def';
        },

        modifyAvatar() {
            this.userImgLoading = true; 
            var params = new FormData();
            if(this.userImageType == 'def') {

            } else {
                params.append("file", this.fileImg);
            }
            // params.append("file", this.userImageType == 'def' ? '' : this.fileImg);
            params.append("type", "8");
            params.append("uid", this.getUserInfo.uid);
            params.append("token", this.getUserInfo.token);
            params.append("state", this.userImageType);
            loginMsgPost(params).then((res) => {
                console.log(res)
                this.$message({
                    message: "修改头像成功！",
                    type: "success",
                    showClose: true
                });
                this.getUserInfo.user_img = res.user_img
                this.$store.commit('setUserInfo', this.getUserInfo)
                this.$emit('userImageDialogClose')
            })
        }
    },

    watch: {
        isUserImageDialog(newVal) {
            console.log(newVal)
            if (newVal) {
                this.userImage = ''
            }
        }
    }
}
</script>

<style lang="scss">
.user-image-dialog {
    width: 400px;

    .el-dialog__body {
        padding-top: 0px;
        .menu {
            margin-bottom: 10px;

            .avatar-uploader {
                .el-upload {
                    width: 100%;
                }
            }

            .el-button {
                width: 100%;
                font-size: 14px;
                background-color: unset;
                color: #000000;
                border: 0px;
            }
        }

        .user-img {
            width: 201px;
            height: 201px;
            margin: 0 auto;
            position: relative;
            overflow: hidden;
            text-align: center;

            .el-image {
                width: 100%;
                height: 100%;
            }
        }

        .user-img::before {
            content: '';
            width: 201px;
            height: 201px;
            border: 100.5px solid rgba(0,0,0,.35);
            border-radius: 50%;
            position: absolute;
            left: -100.5px;
            top: -100.5px;
            z-index: 10;
        }
    }

    .el-dialog__footer {
        text-align: center;
        .el-button {
            width: 120px;
            height: 36px;
        }
    }
}
</style>