<template>
  <div class="work-center">
    <the-header></the-header>
    <div class="content-body">
      <div class="screen-model">
        <div class="work-screen warp ub ub-ac ub-be">
          <div class="work-num">作品（{{total}}个）</div>
          <search-module placeholder="请输入作品名称" @searchTypeTap="searchTypeTap" @inputChange="inputChange" :sort="sort" @clearInput="clearInput"></search-module>
        </div>
        <div class="screen-list ub ub-ac ub-pc">
          <ul class="ub ub-pc">
            <li
              @click="screenTap(0, index, item.rowId)"
              :class="[index == screen[0].active ? 'act' : '']"
              v-for="(item, index) in screen[0].list"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>

      <div class="screen-sub-list warp">
        <ul class="ub ub-wrap">
          <li
            @click="screenTap(1, index, item.typeName)"
            :class="[index == screen[1].active ? 'act' : '']"
            v-for="(item, index) in screen[1].list"
            :key="index"
          >
            {{ item.typeName }}
          </li>
        </ul>
      </div>

      <div class="work-model warp" v-loading.fullscreen.lock="loading">
        <div class="work-model-list" v-if="workList">
          <div class="ub ub-wrap" v-if="workList.length > 0">
            <work-item v-for="(item, index) in workList" :key="index" :item="item" :class="[(index + 1)%5 == 0 ? 'last' : '']"></work-item>
          </div>
          <el-empty description="暂无数据" v-else />
        </div>
        <the-pagination
          :total="total"
          :pageSize="num"
          :currentPage="page"
          @paginationChange="paginationChange"
        ></the-pagination>
      </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { curriculumMsg } from "../../http/api";
import TheHeader from "@/components/TheHeader.vue";
import SearchModule from "@/components/SearchModule.vue";
import ThePagination from "@/components/ThePagination.vue";
import WorkItem from "@/components/WorkItem.vue";
import { mapGetters } from "vuex";
import TheFooter from '@/components/TheFooter.vue';
export default {
  name: 'WorkCenter',
  components: { TheHeader, SearchModule, ThePagination, WorkItem, TheFooter },
  data() {
    return {
      screen: [
        {
          active: 0,
          activeId: 0,
          list: [
            {
              name: "全部",
              rowId: 0,
            },
          ],
        },
        {
          active: 0,
          activeId: 0,
          list: [
            {
              typeName: "全部",
              rowId: 0,
            },
          ],
        },
      ],
      loading: false,
      workList: null,
      sort: '0',
      total: 0,
      searchVal: "",
      page: 1,
      num: 20,
    };
  },

  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    }
  },

  created() {
    this.getData();
  },

  computed: {
    ...mapGetters(["getUserInfo"]),
  },

  methods: {
    screenTap(type, index, id) {
      this.screen[type].active = index;
      this.screen[type].activeId = id;
      if(type == 0) {
        this.screen[1].active = 0;
        this.screen[1].activeId = 0;
        this.sort = '0'
      }
      
      this.page = 1;
      this.getData();
    },

    /**
     * 获取作品数据
     */
    getData() {
      this.loading = true;
      let params = {
        type: 1,
        uid: this.getUserInfo ? this.getUserInfo.uid : "",
        word: this.searchVal,
        workType: this.screen[0].activeId,
        tag: this.screen[1].activeId == '全部' ? 0 : this.screen[1].activeId,
        sort: this.sort,
        page: this.page,
        num: this.num,
      };
      curriculumMsg(params).then((res) => {
        console.log(res);
        if (this.screen[0].list.length == 1) {
          this.screen[0].list = this.screen[0].list.concat(res.data.curriculumLabels);
        }

        let subList = this.screen[1].list.filter((item) => {
          return item.rowId == 0;
        });
        this.screen[1].list = subList.concat(res.data.curriculumTyep);

        this.total = res.data.page.totalCount;
        console.log(this.total)
        this.workList = res.data.page.items
        this.loading = false;  
        window.scrollTo(0,0);
      }).catch(() => {
        this.loading = false;  
      });
    },

    /**
     * 分页
     * @param {Number} page 分页数
     */
    paginationChange(page) {
      console.log(page);
      this.page = page;
      this.getData();
    },

    /**
     * 搜索右侧筛选
     */
    searchTypeTap(val) {
      console.log(val)
      this.sort = val
      this.getData()
    },

    /**
     * 搜索
     */
    inputChange(val) {
      console.log(val)
      this.searchVal = val
      this.getData()
    },

    /**
     * 清空搜索
     */
    clearInput() {
      this.searchVal = ''
      this.getData()
    },

    /**
    * 点赞成功，修改状态
    */
    likeTapSuccess() {
      console.log(arguments)
      let index = this.workList.findIndex((item) => item.PROJECT_ID == arguments[2].PROJECT_ID)
      console.log(index)
      this.workList[index].IS_LIKE = this.workList[index].IS_LIKE == 0 ? 1 : 0
      this.workList[index].LIKE_TIMES = this.workList[index].IS_LIKE == 0 ? this.workList[index].LIKE_TIMES - 1 : this.workList[index].LIKE_TIMES + 1
    }
  },
};
</script>

<style lang='scss'>
.work-center {
  .content-body {
    .screen-model {
      background-color: #fff;

      .work-screen {
        padding: 37px 0 20px;
      }

      .screen-list {
        border-bottom: 1px solid rgb(231, 230, 230);
        background-color: #fff;

        ul {
          li {
            line-height: 52px;
            padding: 0 33px;
            font-weight: 400;
            cursor: pointer;
            position: relative;
            font-size: 20px;
          }

          li.act::before {
            content: "";
            width: 100%;
            height: 5px;
            background-color: var(--el-color-primary);
            font-weight: 500;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    .screen-sub-list {
      margin: 44px auto;

      ul {
        li {
          width: 113px;
          height: 33px;
          line-height: 33px;
          background-color: #fff;
          text-align: center;
          border-radius: 30px;
          box-sizing: border-box;
          padding: 0 5px;
          margin-right: 10px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        li.act {
          background-color: var(--el-color-primary);
        }
      }
    }

    .work-model {
      .work-model-list {
        padding: 5px 0;

        .work-item {
          margin-right: 17px;
          margin-bottom: 15px;
        }

        .work-item.last {
          margin-right: 0;
        }
      }
    }
  }
}
</style>