<template>
    <div class="wares-moduel">
        <div class="list-module ">
          <div class="ub ub-wrap warp" v-if="warsData.length > 0">
            <wares-item v-for="(item, index) in warsData"
                      :key="index"
                      :item="item"
                      :index="index"
                      :isWhetherHave="isWhetherHave"
                      :actPicHoverIndex="actPicHoverIndex"
                      @mouseenter="warePicHover(index, item)"
                      @mouseleave="warePicLeave"
                      :class="index != 0 && (index + 1) % 5 == 0 ? 'last' : ''"
                      @exchangeSuccess="exchangeSuccess"></wares-item>
          </div>
          <el-empty description="暂无数据" v-else />
      </div>
      <div class="tip">部分素材来源自网络，版权归原作者所有。所有素材仅供个人创作娱乐使用，禁止做任何商业用途，由此产生的任何法律纠纷本网站不予承担。</div>
      <the-pagination :total="total" :pageSize="pageSize" :currentPage="currentPage" @paginationChange="paginationChange"></the-pagination>
      <!-- <div class="pagination-model ub ub-pc" ><el-pagination class="default-pagination" background layout="prev, pager, next" :total="1000" @size-change="paginationChange"/></div> -->
    </div>
</template>

<script>
import WaresItem from '@/components/WaresItem.vue'
import ThePagination from '@/components/ThePagination.vue'

export default {
    components: { WaresItem, ThePagination },

    props: {
        warsData: {
            type: Array,
            default: () => {
                return []
            }
        },

        seartchTypes: {
            type: Number,
            default: 0
        },

        total: {
          type: Number,
          default: 0
        },

        pageSize: {
          type: Number,
          default: 15
        },

        currentPage: {
          type:Number,
          default: 1
        },

        isWhetherHave: { // 列表是否显示已兑换等信息
          type: Boolean,
          default: false
        }
    },

    data() {
      return {
        actPicHoverIndex: null,
      }
    },

    methods: {
        paginationChange(val) {
            console.log(val)
            this.$emit('paginationChange', val)
        },

        warePicHover(index, item) {
          if (!this.$util.isDefine(item.materialUrl)) {
            return
          }
          this.actPicHoverIndex = index
        },

        warePicLeave() {
          this.actPicHoverIndex = null
        },

        exchangeSuccess(rowId) {
          this.$emit('exchangeSuccess', rowId)
        }
    },

    watch: {
    }
}
</script>

<style lang="scss">
.wares-moduel {
    background-color: #f4f4f4;

  .list-module {
    .wares-item {
      margin-right: 17px;
      margin-bottom: 18px;
    }

    .wares-item.last {
      margin-right: 0;
    }
  }

  .tip {
    text-align: center;
    background-color: #f4f4f4;
    font-size: 12px;
    color: #787878;
    padding-top: 47px;
    font-weight: 500;
  }

  .pagination-model {
    background-color: #f4f4f4;
    padding: 60px 0;
  }

}
</style>