<template>
  <div class="work-detail">
      <the-header></the-header>
      <div class="content-body warp">
          <div class="back iconfont icon-arrow-left" @click="back()">返回上一页<div class="work-activity" v-if="detailData.activity">作品参赛活动：{{detailData.activity}}<span @click.stop="activityTap">我要参加！</span></div></div>
          <div :class="['work-detail-content', detailData && !loading ? '' : 'no-data'] " v-loading="loading">
              <div class="detail-work ub ub-be" v-if="detailData">
                  <div class="work-left">
                      <div class="work-con">
                          <!-- 1scratch 作品  2公益视频  3机器人编程 4STEAM作品 5绘本馆作品 6科普日视频 7程序蛙课件作品-->
                          <work-media-scratch v-if="detailData.WORK_TYPE == 1 || detailData.WORK_TYPE == 7" :url="workUrl"></work-media-scratch>
                          <!-- 公益视频 -->
                          <work-media-other v-else :video="detailData.CONTENT" :images="detailData.IMG"></work-media-other>
                          <!-- <work-media-scratch v-else-if="detailData.WORK_TYPE == 2" :url="workUrl"></work-media-scratch> -->
                          <!-- 机器人编程 -->
                          <!-- <work-media-scratch v-else-if="detailData.WORK_TYPE == 3" :url="workUrl"></work-media-scratch> -->
                          <!-- STEAM作品 -->
                          <!-- <work-media-scratch v-else-if="detailData.WORK_TYPE == 4" :url="workUrl"></work-media-scratch> -->
                          <!-- 绘本馆作品 -->
                          <!-- <work-media-scratch v-else-if="detailData.WORK_TYPE == 5" :url="workUrl"></work-media-scratch> -->
                          <!-- <iframe id="work-iframe" :src="workUrl" frameborder="0" v-if="workUrl"></iframe> -->
                      </div>
                      <div class="work-info ub ub-ac" v-if="query.releaseState == 1">
                          <div class="info-num ub ub-ac">
                              <span :class="['iconfont', workCollect(detailData.work_collect, 1)]" @click="collectWork" v-if="!isMyWork">{{ workCollect(detailData.work_collect, 2) }}</span>
                              <like-icon :isLike="detailData.IS_LIKE" :likeNum="detailData.LIKE_TIMES"></like-icon>
                              <span class="relay ub ub-ac iconfont icon-zhuanfa">{{ detailData.SHARE_TIMES }}
                                  <div class="relay-module">
                                      <div class="relay-module-conten ub ub-ver">
                                          <div class="relay-title">分享到：</div>
                                          <div class="qrcode">
                                            <div class="title">扫一扫分享</div>
                                            <div class="qrcode-pic" id="relayQrcode"></div>
                                          </div>
                                          <div class="copy-address">
                                              <div class="title">复制代码分享至网页</div>
                                              <div class="address-con ub">
                                                  <el-input disabled v-model="relayAddress">{{relayAddress}}</el-input>
                                                  <el-button class="default-button" @click="copyRelayAddress">复制</el-button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </span>
                              <span class="iconfont icon-liuyan" @click="gotoMessage('#comment-message')">{{ detailData.messageNums }}</span>
                            </div>
                          <div class="rate ub ub-ac" v-if="!isMyWork"><span class="mask" v-if="!getUserInfo" @click="login"></span>评价：<el-rate :model-value="detailData.score" :disabled="detailData.rateDisabled" :colors="rateColors" @change="rateChange"/></div>
                          <div class="mobile-version" >手机版<span id="qrcodeContainer"></span></div>
                          <div class="ub-f1 ub ub-pe">
                              <div class="iconfont icon-quanping" @click="requestFullScreen"></div>
                              <div class="">浏览量：{{ detailData.PV }}</div>
                          </div>
                      </div>
                  </div>
                  <div class="work-right ub ub-ver">
                      <div class="work-title ub ub-ac"><div>{{ detailData.NAME }}（作品ID：{{ detailData.PROJECT_ID }}）</div></div>
                      <div class="work-author">
                          <div class="ub ub-ac">
                              <user-img class="author-img" :userImg="detailData.USER_HEADIMG" :userId="Number(detailData.USER_ID)"></user-img>
                              <!-- <div class="author-img" :style="'background-image: url(' + detailData.USER_HEADIMG + ');'"></div> -->
                              <div class="ub ub-ver">
                                  <div class="author-name">{{ detailData.NICKNAME }}</div>
                                  <follow-author-btn :uuid="detailData.USER_ID" :focus="detailData.work_focus" v-if="!isMyWork"></follow-author-btn>
                                  <!-- <el-button class="author-follow default-button" @click="followAuthor" :loading="followLoading">关注 + </el-button> -->
                                  <div class="update">更新时间：{{ detailData.UPDATE_TIME }}</div>
                              </div>
                          </div>
                      </div>
                      <div class="ub ub-f1 ub-ver">
                            <div class="introduce work-introduce ub-f1-item">
                                <div class="title">作品简介</div>
                                <div class="text" v-html="workInfo(detailData.WORK_INFO)"></div>
                            </div>

                            <div class="introduce handle-introduce ub-f1-item" v-if="detailData.WORK_TYPE == 1">
                                <div class="title">操作说明</div>
                                <div class="text" v-html="workInstruction(detailData.INSTRUCTION)"></div>
                            </div>
                      </div>
                      <work-name :class="['work-code', detailData.activity ? 'gray' : '' ]" :isClick="detailData.activity ? false : true" name="作品代码" :id="detailData.PROJECT_ID" v-if="((isMyWork || detailData.FREE_CODE === 0)) && detailData.WORK_TYPE == 1"></work-name>
                      
                  </div>
              </div>
              <div class="detail-bottom ub ub-be" v-if="detailData && !query.isScore && query.releaseState == 1">
                  <div class="detail-message">
                    <comment-message :authorId="Number(detailData.USER_ID)" 
                                    :projectId="detailData.PROJECT_ID"
                                    ref="commentMessage"
                                    :type="1"></comment-message>
                  </div>
                  <div class="work-list">
                    <work-tab-list :projectId="detailData.PROJECT_ID" :uuid="Number(detailData.USER_ID)" :workType="Number(detailData.WORK_TYPE)"></work-tab-list>
                  </div>
              </div>

              <teacher-rate-model v-if="query.isScore"></teacher-rate-model>
          </div>
          <el-empty v-if="errInfo.show" :description="errInfo.text" />
      </div>
      <the-footer></the-footer>
  </div>
</template>

<script>
import config from '../../http/config'
import { mapGetters } from 'vuex'
import { pblWork } from '../../http/api'
import QRCode from 'qrcodejs2'
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '../../components/TheFooter.vue'
import CommentMessage from './components/CommentMessage.vue'
import WorkTabList from './components/WorkTabList.vue'
import FollowAuthorBtn from '@/components/FollowAuthorBtn.vue'
import LikeIcon from '@/components/LikeIcon.vue'
import UserImg from '@/components/UserImg.vue'
import TeacherRateModel from './components/TeacherRateModel.vue'
import WorkMediaScratch from './components/WorkMediaScratch.vue'
import WorkName from '../../components/WorkName.vue'
import WorkMediaOther from './components/WorkMediaOther.vue'
export default {
  components: { TheHeader, TheFooter, CommentMessage, WorkTabList, FollowAuthorBtn, LikeIcon, UserImg, TeacherRateModel, WorkMediaScratch, WorkName, WorkMediaOther },

  data() {
      return {
        rateColors: ['#ea4335', '#ea4335', '#ea4335'],
        loading: false,
        detailData: '',
        messageInfo: '',
        errInfo: {
            show: false,
            text: ''
        },
        workUrl: '',
        // 关注
        followLoading: false,
        // 转发
        relayAddress: '',
      }
  },

  created() {
      this.query = this.$route.query
      console.log(`${config.scratchUrl.phone}&id=${this.query.pid}`)
      if (this.$util.isMobile()) {
        window.open(`${config.scratchUrl.phone}&id=${this.query.pid}`, "_self");
      } else {
        console.log(config)
        console.log(this.$route)
        this.workUrl = config.scratchUrl.play + `#${this.query.pid}`
        this.getData()
      }
  },

  mounted() {
      
  },

  computed: {
      ...mapGetters([
            'getUserInfo'
        ]),

      isMyWork() {
          return this.getUserInfo ? this.detailData.USER_ID == this.getUserInfo.uid ? true : false : false
      },

      workCollect() {
          return function(isCollect, type) {
              return isCollect == 1 ? type == 1 ? 'icon-shoucang-act' : '已收藏' : type == 2 ? '收藏' : 'icon-shoucang'
          }
      },

      /**
       * 作品简介
       */
      workInfo() {
          return function(text) {
            return text ? text.replace(/\n/g, '<br>') : '这是一个有梦想的作品！'
          }
      },

      /**
       * 作品操作说明
       */
      workInstruction() {
          return function(text) {
            return text ? text.replace(/\n/g, '<br>') : '这是一个需要你去探索的作品！'
          }
      }
  },

  methods: {

      back() {
        if ( window.history.length <= 1 ) {
            this.$router.push('/');   // 这里我返回到home首页
            return;
        }
        this.$router.go(-1)
      },

      login() {
          this.$store.commit('setLoginAlertState', true)
      },

      /**
       * 获取作品详情
       */
      getData() {
        this.loading = true
        let params = {
            type: this.query.releaseState == 1 ? 5 : 33,
            uid: this.getUserInfo ? this.getUserInfo.uid : '',
            pid: this.query.pid,
            uuid: this.query.uuid
        }
        console.log(params)
        pblWork(params).then((res) => {
            console.log(res)
            this.loading = false
            res.pblwork_info.UPDATE_TIME = this.$util.formateDateTimesToZh(res.pblwork_info.UPDATE_TIME, 1)
            res.pblwork_info.isScore = res.pblwork_info.score > 0 ? true : false
            this.detailData = res.pblwork_info
            if(this.detailData.score > 0) {
                this.detailData.rateDisabled = true
            }
            this.$nextTick(() => {
                this.createQrcode('qrcodeContainer')
                this.createQrcode('relayQrcode')
            })
            console.log(this.detailData.WORK_TYPE)
            if (this.detailData.WORK_TYPE != 1) {
                let files = this.detailData.IMG.map((item) => {
                    return {
                        url: item,
                        type: item.indexOf('mp4') != -1 ? 1 : 2
                    }
                })
                this.detailData.IMG = files
            }
            
        }).catch((err) => {
            console.log(err)
            this.loading = false
            this.errInfo = {
                show: true,
                text: err.errText ? err.errText : err.data.msg
            }
        })
      },

      /**
       * 生成二维码
       */
      createQrcode(id) {
          this.relayAddress = `${config.scratchUrl.phone}&id=${this.query.pid}`
          let phoneCode = new QRCode(id, {
                text: this.relayAddress, // 二维码地址
                colorDark: "#000",
                colorLight: "#fff",
          });
      },

      /**
       * 点赞作品
       */
       likeWork() {
            if (!this.$util.isDefine(this.getUserInfo)) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
            let params = {
                type: 4,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                pid: this.detailData.PROJECT_ID,
                state: this.detailData.IS_LIKE == 1 ? -1 : 0
            }
            pblWork(params).then((res) => {
                console.log(res)
                this.detailData.IS_LIKE = this.detailData.IS_LIKE == 0 ? 1 : 0
                this.detailData.LIKE_TIMES = this.detailData.IS_LIKE == 1 ? this.detailData.LIKE_TIMES + 1 : this.detailData.LIKE_TIMES - 1
            })
       },

       /**
        * 收藏作品
        */
       collectWork() {
           if (!this.$util.isDefine(this.getUserInfo)) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
            let params = {
                type: 6,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                pid: this.detailData.PROJECT_ID,
                state: this.detailData.work_collect == 1 ? -1 : 0,
                workType: 1
            }
            pblWork(params).then((res) => {
                console.log(res)
                this.detailData.work_collect = this.detailData.work_collect == 0 ? 1 : 0
            })
       },

       /**
        * 评分
        */
       rateChange(score) {
            if(this.detailData.isScore) {
                return
            }
            if (!this.$util.isDefine(this.getUserInfo)) {
                this.$store.commit('setLoginAlertState', true)
                return
            }
           let params = {
                type: 7,
                uid: this.getUserInfo ? this.getUserInfo.uid : '',
                pid: this.detailData.PROJECT_ID,
                uuid: this.detailData.USER_ID,
                score: score
            }
            pblWork(params).then((res) => {
                // ...
                console.log(res)
                this.detailData.rateDisabled = true
                this.detailData.score = score
            }).catch(() => {
                this.detailData.score = 0
            })
       },

       /**
        * 关注/取消关注状态
        */
       followAuthorSuccess() {
            this.detailData.work_focus = this.detailData.work_focus == 0 ? 1 : 0
       },

       /**
        * 复制地址
        */
       copyRelayAddress() {
          var temp = document.createElement("textarea");
          temp.value = this.relayAddress;
          document.body.appendChild(temp);
          temp.select(); // 选择对象
          document.execCommand("Copy"); // 执行浏览器复制命令
          temp.style.display = "none";
          this.$message({
            type: 'success',
            message: '复制成功，去分享吧！'
          })
       },

       /**
        * 作品代码
        */
       workCode() {
           window.open(`${config.scratchUrl.create}/#${this.detailData.PROJECT_ID}`)
       },

       /**
        * 滚动到message
        */
       gotoMessage(id) {
           this.$refs.commentMessage.gotoMessage(id)
       },

       /**
        * 全屏
        */
      requestFullScreen() {
        var self = this;
        let element = document.getElementById("work-iframe");
        var requestMethod =
            element.requestFullScreen ||
            element.webkitRequestFullScreen ||
            element.mozRequestFullScreen ||
            element.msRequestFullScreen;
        if (requestMethod) {
            requestMethod.call(element);
        } else if (typeof window.ActiveXObject !== "undefined") {
            var wscript = new ActiveXObject("WScript.Shell");
            if (wscript !== null) {
            wscript.SendKeys("{F11}");
            }
        }
        if (self.currentArea == false) {
            element.src = "";
            setTimeout(() => {
            element.src = this.GLOBAL.axiosData.scratch3PlayUrl + this.pid;
            self.oldArea = element.offsetWidth * element.offsetHeight;
            self.currentArea = true;
            }, 200);
        }
      },

      activityTap() {
        this.$router.push({path: '/activity-index/events-activity'})
      }
  },
  watch: {
        $route: {
            handler() {
                this.$router.go(0)
            },
            deep: true
        }
  }
}
</script>

<style lang="scss">
.work-detail {
    .back {
        line-height: 100px;
        cursor: pointer;
        position: relative;

        .work-activity {
            position: absolute;
            right: 0;
            font-weight: bold;
            top: 0;
            z-index: 10;
            span {
                color: red;
                text-decoration: underline;
                margin-left: 10px;
                font-weight: 400;
                font-size: 16px;
            }
        }
    }

    .back::before {
        margin-right: 20px;
    }

    .work-detail-content {
        min-height: 300px;
        .detail-work {
            background-color: #fff;

            .work-left {
                width: 860px;

                .work-con {
                    height: 713px;
                    background-color: #dcdcdc;

                    /* iframe {
                        width: 100%;
                        height: 100%;
                    } */
                }

                .work-info {
                    padding: 0 24px;
                    height: 55px;
                    color: #999999;
                    font-size: 12px;
                    font-weight: 500;

                    .info-num {
                        height: 100%;
                        span {
                            font-size: 12px;
                            display: block;
                            line-height: 24px;
                            margin: 0 12px;
                            font-weight: 500;
                            position: relative;
                            cursor: pointer;
                        }

                        span:first-child {
                            margin-left: 0;
                        }

                        span:last-child:after {
                            display: none;
                        }

                        span:before {
                            font-size: 18px;
                            float: left;
                            margin-right: 5px;
                            font-weight: 600;
                        }

                        span:after {
                            content: '';
                            width: 1px;
                            height: 14px;
                            background-color: #d3d3d3;
                            position: absolute;
                            right: -12px;
                            top: 50%;
                            margin-top: -7px;
                        }

                        .icon-shoucang-act::before {
                            color: var(--el-color-four);
                        }

                        .relay {
                            height: 100%;
                            position: relative;
                            display: flex;

                            .relay-module {
                                width: 382px;
                                height: 340px;
                                position: absolute;
                                left: -50px;
                                top: -340px;
                                z-index: 99;
                                display: none;

                                .relay-module-conten {
                                    width: 100%;
                                    height: 326px;
                                    background-color: #fff;
                                    border-radius: var(--item-border-radius);
                                    padding: 27px 22px;
                                    box-sizing: border-box;
                                    color: #000000;
                                    font-size: 14px;

                                    .relay-title {
                                        font-size: 16px;
                                    }

                                    .qrcode {
                                        text-align: center;
                                        margin-bottom: 15px;
                                        
                                        .title {
                                            line-height: 60px;
                                        }

                                        .qrcode-pic {
                                            height: 122px;
                                            overflow: hidden;
                                            img {
                                                width: 122px;
                                                height: 122px;
                                                margin: 0 auto;
                                            }
                                        }
                                    }

                                    .copy-address {
                                        .title {
                                            margin-bottom: 5px;
                                        }
                                        .address-con {
                                            .el-input {
                                                margin-right: 8px;

                                                input {
                                                    padding-right: 11px !important;
                                                }
                                            }
                                            .el-button {
                                                span {
                                                    margin-right: 0;
                                                    font-size: 14px;
                                                    line-height: 30px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .relay-module::before {
                                content: '';
                                width: 0;
                                height: 0;
                                border-left: 14px solid transparent;
                                border-right: 14px solid transparent;
                                border-top: 25px solid #fff;
                                position: absolute;
                                bottom: -10px;
                                left: 60px;
                            }

                        }

                        .relay:hover {
                            .relay-module {
                                display: block;
                            }
                        }
                    }

                    .rate {
                        margin-left: 8px;
                        margin-right: 24px;
                        position: relative;

                        .mask {
                            position: absolute;
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            z-index: 999;
                        }
                    }

                    .mobile-version {
                        cursor: pointer;
                        color: #000000;
                        position: relative;
                        width: 48px;

                        span {
                            width: 94px;
                            height: 94px;
                            overflow: hidden;
                            padding: 10px;
                            box-sizing: border-box;
                            display: block;
                            position: absolute;
                            top: -80px;
                            left: 48px;
                            box-shadow: 0px 0px 5px #cccccc;
                            background-color: #fff;
                            transform: scale(0);

                            img {
                                width: 100%;
                                height: 100%;
                                display: none;
                            }

                            img:first-child {
                                display: block;
                            }
                        }
                    }

                    .mobile-version:hover {
                        span {
                            transform: scale(1);
                        }
                    }

                    .icon-quanping {
                        font-size: 18px;
                        margin-right: 13px;
                        cursor: pointer;
                    }
                }
            }

            .work-right {
                width: 310px;
                box-sizing: border-box;
                padding-right: 18px;

                .work-title {
                    height: 70px;
                }

                .work-author {
                    padding-bottom: 30px;
                    border-bottom: 1px solid #dcdcdc;

                    .author-img {
                        width: 80px;
                        height: 80px;
                        background-color: #ccc;
                        border-radius: 50%;
                        margin-right: 18px;
                        background-size: cover;
                        background-position: 50%;
                        background-repeat: no-repeat;
                    }

                    .author-name {
                        line-height: 34px;
                    }

                    .author-follow-btn {
                        margin-bottom: 10px;
                    }

                    .update {
                        font-size: 14px;
                        color: var(--el-color-three);
                    }
                }

                .introduce {
                    border-bottom: 1px solid #dcdcdc;
                    .title {
                        line-height: 52px;
                        font-weight: bold;
                        margin-top: 10px;
                    }

                    .text {
                        font-size: 12px;
                        line-height: 30px;
                        font-weight: 500;
                    }
                }

                .introduce:last-child {
                    border: 0px;
                }

                .work-code {
                    height: 55px;
                    line-height: 55px;
                    font-size: 14px;
                    color: var(--el-color-second);
                    text-decoration: underline;
                    font-weight: 500;
                    cursor: pointer;
                    position: relative;
                }

                .work-code.gray::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    z-index: 99;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
        }

        .detail-bottom {
            margin-top: 15px;
            width: 100%;

            .detail-message {
                width: 860px;
            }

            .work-list {
                width: 310px;
                background-color: #ffffff;
                box-sizing: border-box;
                padding-bottom: 28px;

                .work-item-small {
                    margin-top: 28px;
                }
            }
        }
    }
}
</style>