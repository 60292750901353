<template>
  <div class="tab-cell ub">
      <tab-cell-item v-if="componentType == 1" :item="item" @click="selectWork" :isSelf="isSelf" :class="[isManage ? 'iconfont icon-duigou1 manage-cell' : '' ,item.isCheck ? 'act iconfont icon-duigou1 manage-cell' : '']"></tab-cell-item>
      <tab-cell-item-2 v-if="componentType == 2" :item="item" :isSelf="isSelf"></tab-cell-item-2>
      <lesson-item v-if="componentType == 3" :item="item"></lesson-item>
  </div>
</template>

<script>
import LessonItem from '@/components/LessonItem.vue'
import TabCellItem from './TabCellItem.vue'
import TabCellItem2 from './TabCellItem2.vue'

export default {
  components: { TabCellItem, TabCellItem2, LessonItem },
  props: {
      item: {
          type: Object,
          default:  () => {
              return {}
          }
      },

      index: {
          type: Number,
          default: 0
      },

      componentType: {
          type: Number,
          default: 1
      },

      isManage: {
          type: Boolean,
          default: false
      },

      isSelf: {
          type: Boolean,
          default: false
      }
  },

  methods: {

      followAuthorSuccess(uid) {
        this.$emit('followAuthorSuccess', uid)

      },

      /**
       * 管理我的作品，选择作品
       */
      selectWork() {
          this.$emit('checkItem', {index: this.index, val: this.item.isCheck ? false : true})
      }
  }

}
</script>

<style lang="scss">
.tab-cell {
   .tab-cell-item {
        position: relative;
    }

    .tab-cell-item.manage-cell {
        cursor: pointer;
    }

    .tab-cell-item.manage-cell::before {
        width: 85px;
        height: 85px;
        border: 5px solid #fff;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        margin-left: -47.5px;
        top: 50%;
        margin-top: -47.5px;
        z-index: 100;
        text-align: center;
        font-size: 64px;
        color: #fff;
        line-height: 85px;
    }

    .tab-cell-item.manage-cell.act::before {
        color: var(--el-color-primary);
        border-color: var(--el-color-primary);
    }

    .tab-cell-item.manage-cell::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        z-index: 99;
    }
}
</style>