<template>
      <el-cascader class="city-el-cascader iconfont icon-chengshi" v-model="addr" ref="cascaderAddr" :options="options" :props="cityProps" 
			 placeholder="请选择省市区" @change="handleAddrChange"></el-cascader>
</template>

<script>
import cityData from '@/utils/pca-code.json'
export default {
    props: {
        index: {
            type: Number,
            default: null
        },
        item: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            addr: '',
            options: cityData,
            cityProps:{
                value:'code',   // 指定选项的 值 为选项对象的某个属性值
                label:'name',   // 指定选项 标签 为选项对象的某个属性值	
                children: 'children'    //指定选项的 子选项 为选项对象的某个属性值
            }
        }
    },
    methods: {
        handleAddrChange(e){
            // 获取当前选中节点的数组
            let addrNode = this.$refs['cascaderAddr'].getCheckedNodes()[0]
            // 在 vue3 setup 语法糖内请使用 ↓ (tips：需先获取ref  const cascaderAddr = ref() )
            // let addrNode = unref(cascaderAddr).getCheckedNodes()[0].pathLabels

            let addrText = addrNode.pathLabels.join("-")
            console.log('addrNode',addrNode, addrText);
            this.$emit('setData', this.index, addrText)
        }
    },
    watch: {
        item: {
            handler(newVal) {
                console.log(newVal)
                if (newVal.value) {
                    // this.addr = '天津市 / 市辖区 / 河东区'
                }
            },
            deep: true
            
        }
    }
}
</script>

<style>

</style>