<template>
  <div class="user-small-info ub ub-ac">
      <el-image class="work-item-user-pic" :src="image" fit="cover"></el-image>
      <div class="ub ub-f1-item text-hidden" :title="name">{{ name }}</div>
  </div>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: ''
        },

        name: {
            type: String,
            default: '-'
        }
    }
}
</script>

<style lang="scss">
.user-small-info {
    height: 53px;
    padding: 0 15px;
    box-sizing: border-box;
    border-top: 1px solid #f0f0f0;
    cursor: pointer;

    .work-item-user-pic {
      width: 28px;
      height: 28px;
      border-radius: 50%;
      margin-right: 12px;
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      background-color: var(--el-color-primary);
    }
}
</style>