<template>
  <div class="message-item ub ub-ac">
      <user-img class="message-item-pic" :userImg="item.icon" :userId="userId(item.sendUserId)"></user-img>
      <!-- <el-image style="width: 100px; height: 100px" :src="item.icon" fit="cover" /> -->
      <div class="message-item-info ub ub-ver ub-pc">
          <div class="type">{{messageTitleType(item.messageType)}}</div>
          <div class="time">{{item.createTime}}</div>
          <div class="msg" v-html="messageContent(item)"></div>
      </div>
      <el-checkbox class="ub-pe" :model-value="item.isCheck" @change="itemCheck" label="" size="large" v-if="isManage" />
  </div>
</template>

<script>
import UserImg from '@/components/UserImg.vue'
export default {
  components: { UserImg },
  props: {
    index: {
      type: Number,
      default: 0
    },
    
    item: {
      type: Object,
      default: () => {
          return {}
      }
    },

    isManage: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {

    }
  },

  computed: {
      userId() {
        return (id) => {
          return id ? Number(id) : 0
        }
      },

      messageTitleType() {
        return (status) => {
          switch (Number(status)) {
            case 0:
              return '系统消息'
            case 1:
              return '评论消息'
            case 2:
              return '关注消息'
            case 3:
              return '关注消息' //取消关注
            case 4:
              return '班级消息'
            default:
              return '';
          }
        }
      },

      messageContent() {
        return (item) => {
            let contentArr = item.content.split('♆')
            switch (Number(item.teamplateNum)) {
              case 1:
                return '欢迎来到智多蛙！'
              case 2:
                return '班级申请不通过，请联系教师确认邀请码！'
              case 3:
                return '申请已通过，请到我的班级页面查看班级信息。'
              case 4:
                return `你被<span class="user-name">${contentArr[0]}</span>移除了班级：<span class="class-name">${contentArr[1]}</span>`
              case 5:
                return `班级<span class="class-name">${contentArr[0]}</span>更名为<span class="class-name">${contentArr[1]}</span>`
              case 6:
              case 7:
                return `<span class="class-lesson">${contentArr[0]}</span>课程将于<span class="overdue">${contentArr[1]}天后过期</span>，过期后智多蛙将不再提供班级服务，为保证班级正常运行，请尽快联系学校续班。`
              case 8:
                return `<span class="class-lesson">${contentArr[0]}</span>课程将于<span class="overdue">${contentArr[1]}小时后过期</span>，过期后智多蛙将不再提供班级服务，为保证班级正常运行，请尽快联系学校续班。`
              case 9:
                return `<span class="class-lesson">${contentArr[0]}</span>课程<span class="overdue">已过期</span>，智多蛙将不再提供班级服务。`
              case 10:
                return ''
              case 11:
                return ''
              case 12:
                return ''
              case 13:
                return '学校购买的人数已经全部用完，请联系学校进行购买！'
              case 14:
                return ''
              case 15:
                return `用户<span class="user-name">${item.name}</span>关注了你！`
              case 16:
                return `用户<span class="user-name">${item.name}</span>评论了你的作品<span class="work-name"><a href="/#/work-detail?pid=${item.pid}&uuid=${item.uid}&releaseState=${item.releaseState}">${contentArr[0]}</a>：</span>${contentArr[1]}。`
              case 17:
                return ''
              case 18:
                return ''
              case 19:
                return ''
              case 20:
                return ''
              case 21:
                return `班级<span class="class-name">${contentArr[0]}</span>已解散！`
              case 22:
                return ''
              case 23:
                return ''
              case 24:
                if (contentArr[0] == 0) {
                  return `<span class="fitst">恭喜你成为本月榜第一名！</span>`
                } else if (contentArr[0] == 1) {
                  return `<span class="fitst">恭喜你成为本周榜第一名！</span>`
                } else if (contentArr[0] == 2){
                  return `<span class="fitst">恭喜你成为新作品榜第一名！</span>`
                } else {
                  return ''
                }
              case 25:
                return `班级<span class="class-name">${contentArr[0]}</span>创建成功。`
              case 27:
                return `你举报用户<span class="user-name">${contentArr[0]}</span>的评论中含有<span class="class-name">${contentArr[1]}</span>，举报成功，评论已被系统删除，感谢您的配合！`
              case 29:
                return `用户<span class="user-name">${item.name}</span>评论了你的作品<span class="work-name"><a href="/#/work-detail?pid=${item.pid}&uuid=${item.uid}">${contentArr[0]}</a>：</span>${contentArr[1]}。`
              default: 
                return ''
            }
        }
      }
  },

  methods: {
    itemCheck(val) {
      this.$emit('checkItem', {index: this.index, val: val})
    },
  }
}
</script>

<style lang="scss">
.message-item {
  padding: 15px 5px 20px;
  border-bottom: 1px solid #eeeeee;

  .message-item-pic {
    margin-right: 30px;
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }

  .message-item-info {
    color: #a0a0a0;
    .type {
      line-height: 28px;
    }

    .time {
      margin-bottom: 10px;
      font-size: 14px;
    }

    .msg {
      span {
        font-weight: bold;
        margin: 0 3px;
      }

      .fitst {
        color: var(--el-color-four);
      }

      .class-name {
        color: var(--el-color-second);
      }

      .new-class-name {
        color: var(--el-color-four-hover);
      }

      .class-lesson {
        color: #000000;
      }

      .user-name {
        color: #000000;
      }

      .work-name {
        
        a {
          color: var(--el-color-primary);
          text-decoration: underline;
        }
      }

      .overdue {
        color: red;
      }
    }
  }

  .el-checkbox {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99;

      .el-checkbox__inner {
        width: 20px;
        height: 20px;
      }

      .el-checkbox__inner::after {
        height: 12px;
        left: 6px;
        top: 0px;
        width: 6px;
      }
  }
}

.message-item:first-child {
  padding-top: 0;
}
</style>