<template>
  <div class="template-one">
    <div class="activity-header">
        <el-image :src="headerInfo.detailsBanner" class="activity-header-pic" fit="cover" @load="bannerLoadEnd"  v-if="headerInfo">
            <template #placeholder>
                <div class="image-slot">Loading<span class="dot">...</span></div>
            </template>
        </el-image>
        <div class="activity-header-info warp" v-if="headerInfo">
            <div class="activity-header-title">{{headerInfo.title}}</div>
            <!-- <div class="activity-header-num">{{headerInfo.sum}}个作品</div> -->
            <div class="activity-header-summary">{{headerInfo.content}}</div>
            <div class="activity-handle ub">
                <!-- 活动未进行中（未开始/已结束） -->
                <el-button v-if="headerInfo.state != 0" type="info" disabled>{{activityState(headerInfo.state)}}</el-button>
                <!-- 活动进行中 （进行中） -->
                <el-button v-if="headerInfo.state == 0 && headerInfo.contState  == 0" class="default-button" @click="contributionChange">{{headerInfo.whetherContribute == 1 ? '已投稿' : '我要投稿'}}<span class="small-size" v-if="headerInfo.whetherContribute == 1">（更换投稿）</span></el-button>
                <!-- 活动进行中 （进行中）并且用户未投稿并且投稿已结束或者未开始 -->
                <el-button v-if="headerInfo.state == 0 && headerInfo.contState != 0" type="info" disabled>{{headerInfo.contState == -1 ? '投稿未开始' : '投稿已结束'}}</el-button>
                <!-- 活动进行中 （进行中）并且用户未投稿并且投稿进行中 -->
                <el-button v-if="headerInfo.state == 0 && headerInfo.contState  == 0" class="el-button default-button created" @click="createdWork">我要创作</el-button>
                <div class="share iconfont icon-zhuanfa1 ub ub-ac">分享
                    <div class="share-ewm">
                        <div class="text">微信扫一扫分享</div>
                        <div id="qrcodeContainer"></div>
                    </div>
                </div>
                <div class="ub ub-ac ub-pc" v-if="showCanvas">
                    <div class="download-certificate iconfont icon-down ub ub-ac" @click="downloadCertificateTap">证书下载</div>
                </div>
                <canvas id="certificateCanvas" width="1121" height="793"></canvas>
            </div>
            <div class="activity-download ub ub-ver" v-if="headerInfo && headerInfo.resourceDownload">
                  <a class="iconfont icon-down" :href="item.url" v-for="(item, index) in headerInfo.resourceDownload" :key="index">{{item.name}}</a>
              </div>
        </div>
    </div>
    <el-tabs v-model="activeName" :class="['activity-tabs', activeName == 1 ? 'detail' : '']" @tab-change="handleClick">
        <el-tab-pane label="活动介绍" name="1">
            <div class="activity-intro" v-loading="loading  && !loadErr">
                <div class="ub ub-f1 ub-ver" v-if="activityIntro">
                    <el-image lazy :class="[isLInk(item.link) ? 'link' : '']" :src="item.img" v-for="(item) in activityIntro" :key="item.img" @click="openPage(item.link)" />
                </div>
            </div>
            <el-empty v-if="loadErr" description="加载失败，请刷新重试！"></el-empty>
        </el-tab-pane>
        <el-tab-pane class="all-work" label="全部作品" name="2">
            <activity-tab-title title="全部作品">
            </activity-tab-title>
            <div class="work-list ub ub-wrap" v-loading="loading" v-if="!loadErr">
                <div class="ub ub-f1" v-if="dataInfo && activeName == 2">
                    <div class="ub ub-f1  ub-wrap" v-if="dataInfo.list.length > 0">
                        <work-item v-for="(item, index) in dataInfo.list" :key="index" :class="[(index + 1)%5 == 0 ? 'last' : '', item.NICKNAME]" :item="item"></work-item>
                        <the-pagination :total="total" :pageSize="num" @paginationChange="paginationChange"></the-pagination>
                    </div>
                    <the-empty v-else></the-empty>
                </div>
            </div>
            <el-empty v-if="loadErr" description="加载失败，请刷新重试！"></el-empty>
        </el-tab-pane>
        <el-tab-pane label="小组讨论" name="3">
            <comment-message :authorId="''" 
                            :projectId="params.activityId"
                            v-if="activeName == 3"
                            :type="2"></comment-message>
        </el-tab-pane>
        <el-tab-pane class="participant" label="参与者" name="4">
            <activity-tab-title title="参与者"></activity-tab-title>
            <div class="user-list ub ub-wrap" v-loading="loading && !loadErr">
                <div class="ub ub-f1" v-if="dataInfo && activeName == 4">
                    <div class="ub ub-f1  ub-wrap" v-if="dataInfo.list.length > 0">
                        <user-cell v-for="(item, index) in dataInfo.list" :key="index" :class="[(index + 1)%5 == 0 ? 'last' : '']" :item="item" @click="studentTap(item.USER_ID)"></user-cell>
                        <the-pagination :total="total" :pageSize="num" @paginationChange="paginationChange"></the-pagination>
                    </div>
                    <the-empty v-else></the-empty>
                </div>
            </div>
            <el-empty v-if="loadErr" description="加载失败，请刷新重试！"></el-empty>
            
        </el-tab-pane>
    </el-tabs>
    <!-- <el-dialog
        v-model="isCreatedDialog"
        title="选择作品装作类型"
        width="30%"
        :before-close="handleClose"
    >
        <el-button @click="createdWorkTap(1)" v-if="headerInfo.activityWorkType == 1 || headerInfo.activityWorkType == 3">scratch作品创作</el-button>
        <el-button @click="createdWorkTap(2)">其它类型作品创作</el-button>
    </el-dialog> -->
  </div>
</template>

<script>
import { activityMsg } from '@/http/api'
import TheEmpty from '@/components/TheEmpty.vue'
import ThePagination from '@/components/ThePagination.vue'
import UserCell from '../UserCell.vue'
import CommentMessage from '@/pages/work/components/CommentMessage.vue'
import ActivityTabTitle from '@/pages/activity/components/ActivityTabTitle.vue'
import WorkItem from '@/components/WorkItem.vue'
import { mapGetters } from 'vuex'
export default {
    components: {
        TheEmpty,
        ThePagination,
        UserCell,
        CommentMessage,
        ActivityTabTitle,
        WorkItem
    },
    props: {
        headerInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        activityIntro: {
            type: Array,
            default: () => {
                return []
            }
        },
        params: {
            type: Object,
            default: () => {
                return {}
            }
        }

    },


    provide() {
        return {
            likeTapSuccess: this.likeTapSuccess,
        }
    },
    computed: {
        ...mapGetters(['getUserInfo']),
        isLInk() {
            return function(link) {
                return link.indexOf('http') != -1
            }
        },
        activityState() {
            return function(state) {
                switch (Number(state)) {
                    case -2:
                        return '活动已结束';
                    case -1:
                        return '活动暂未开始';
                    case 0:
                        return '进行中';
                    default:
                        break;
                }
            }
        }
    },
    data() {
        return {
            showCanvas: false,
            activeName: "1",
            type: 2,
            dataInfo: null, 
            workData: null,
            loading: false,
            loadErr: false,
            page: 1,
            num: 20,
        }
    },
    methods: {
        init() {
            this.workData = null
            this.loading = false
            this.loadErr = false
            this.page = 1
        },

        handleClick(activeName) {
            console.log(activeName)
            this.init()
            if (activeName == 1) {
                this.getActivityInfo()
            } else if (activeName == 2) {
                // 全部作品
                this.type = 3
                this.getData()
            } else if (activeName == 4) {
                // 参与者
                this.type = 4
                this.getData()
            }
        },
        /**
         * 获取该活动所有作品/参与者
         */
         getData() {
            this.loading = true
            let param = {
                type: this.type,
                activityId: this.params.activityId,
                userId: this.getUserInfo ? this.getUserInfo.uid : '',
                pageNo: this.page,
                pageSize: this.num
            }
            console.log(this.type)
            if (this.type == 3) {
                param.types = this.headerInfo.types
            }
            console.log(param)
            activityMsg(param).then((res) => {
                console.log(res)
                this.loading = false
                // res.data.list = []
                this.total = res.data.sum
                this.dataInfo = res.data
                    
            }).catch((err) => {
                console.log(err)
                this.loading = false
                this.loadErr = true
            })
        },

        bannerLoadEnd() {
            console.log('aaaaaaa')
        }, 


        /**
         * 查看学生信息
         */
         studentTap(uid) {
          this.$router.push('/personal-center?uid=' + uid)
        },

        /**
         * 创建作品
         */
        createdWork() {
            this.$emit('createdWork')
        },

        /**
         * 打开图片链接地址
         */
        openPage(link) {
            this.$emit('openPage', link)
        },

        /**
         * 投稿
         */
        contributionChange() {
            this.$emit('contributionChange')
        },


        /**
        * 点赞成功，修改状态
        */
        likeTapSuccess() {
            console.log(arguments)
            let index = this.dataInfo.list.findIndex((item) => item.PROJECT_ID == arguments[2].PROJECT_ID)
            this.dataInfo.list[index].IS_LIKE = this.dataInfo.list[index].IS_LIKE == 0 ? 1 : 0
            this.dataInfo.list[index].LIKE_TIMES = this.dataInfo.list[index].IS_LIKE == 0 ? this.dataInfo.list[index].LIKE_TIMES - 1 : this.dataInfo.list[index].LIKE_TIMES + 1
        },
    }
}
</script>

<style lang="scss">
.template-one {

}
</style>