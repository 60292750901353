<template>
  <div class="activity-win">
    <the-header></the-header>
    <div class="content-body warp" v-loading="loading">
        <div v-if="activityWinInfo" >
            <div class="screen-sub-list warp">
                <ul class="ub ub-wrap">
                    <li
                        @click="screenTap(index)"
                        :class="[index == active ? 'act' : '']"
                        v-for="(item, index) in activityWinInfo.activityDetailsList"
                        :key="index"
                    >
                        {{ item.activityName }}
                    </li>
                </ul>
            </div>
            <div class="activity-model ub ub-wrap">
                <div class="work-model-list" v-if="activityWinInfo.mapList[0].workCurriculumList">
                    <div class="ub ub-wrap" v-if="activityWinInfo.mapList[0].workCurriculumList.length > 0">
                        <work-item v-for="(item, index) in activityWinInfo.mapList[0].workCurriculumList" :key="index" :item="item" :class="[(index + 1)%5 == 0 ? 'last' : '']"></work-item>
                    </div>
                    <el-empty description="暂无数据" v-else />
                </div>
            </div>
            <the-pagination :total="total"
                :pageSize="num"
                @paginationChange="paginationChange"></the-pagination>
        </div>
    </div>
    <the-footer></the-footer>
  </div>
</template>

<script>
import { activityMsg } from '../../http/api'
import TheHeader from '@/components/TheHeader.vue'
import TheFooter from '@/components/TheFooter.vue'
import ThePagination from '@/components/ThePagination.vue'
import WorkItem from "@/components/WorkItem.vue";
import { mapGetters } from 'vuex'

export default {
  components: { TheHeader, TheFooter, ThePagination, WorkItem },
  data() {
    return {
        loading: false,
        activityWinInfo: null,
        tabs: [{
            typeName: '全部'
        }, {
            typeName: '节日类'
        }],
        active: 0,
        total: 0,
        page: 1,
        num: 20
    }
  },
  provide() {
    return {
      likeTapSuccess: this.likeTapSuccess,
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapGetters(["getUserInfo"])
  },
  methods: {
    screenTap(index) {
        this.active = index
        this.getData()
    },

    getData() {
        this.loading = true
        activityMsg({
            type: 9,
            userId: this.getUserInfo ? this.getUserInfo.uid : "",
            activityId: this.activityWinInfo ? this.activityWinInfo.activityDetailsList[this.active].activityId : 0,
            page: this.page,
            num: this.num
        }).then((res) => {
            console.log(res)
            this.loading = false
            this.activityWinInfo = res.data
            this.total = res.data.mapList[0].sum
        }).catch((err) => {
            console.log(err)
            this.loading = false
            this.loadErr = true
        })
    },

    /**
     * 分页
     * @param {Number} page 分页数
     */
    paginationChange(page) {
      console.log(page);
      this.page = page;
      this.getData();
    },

    /**
    * 点赞成功，修改状态
    */
    likeTapSuccess() {
      console.log(arguments)
      let index = this.activityWinInfo.mapList[0].workCurriculumList.findIndex((item) => item.PROJECT_ID == arguments[2].PROJECT_ID)
      console.log(index)
      this.activityWinInfo.mapList[0].workCurriculumList[index].IS_LIKE = this.activityWinInfo.mapList[0].workCurriculumList[index].IS_LIKE == 0 ? 1 : 0
      this.activityWinInfo.mapList[0].workCurriculumList[index].LIKE_TIMES = this.activityWinInfo.mapList[0].workCurriculumList[index].IS_LIKE == 0 ? this.activityWinInfo.mapList[0].workCurriculumList[index].LIKE_TIMES - 1 : this.activityWinInfo.mapList[0].workCurriculumList[index].LIKE_TIMES + 1
    }
  }
}
</script>

<style lang="scss">
.activity-win {
    .content-body {
        .screen-sub-list {
            margin: 44px auto;
            ul {
                li {
                width: 113px;
                height: 33px;
                line-height: 33px;
                background-color: #fff;
                text-align: center;
                border-radius: 30px;
                box-sizing: border-box;
                padding: 0 5px;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                }

                li.act {
                background-color: var(--el-color-primary);
                }
            }
        }

        .activity-model {
            .work-model-list {
                width: 100%;
            }
            .work-item {
                margin-right: 16px;
                margin-bottom: 16px;
            }

            .work-item.last {
                margin-right: 0;
            }
        }
    }
}
</style>