<template>
  <div class="tab-cell-item ub">
      <div class="activity-title text-hidden" :title="activityTitle" v-if="item.activity">{{activityTitle}}</div>
      <el-image class="tab-img" :src="item.IMG" fit="cover" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)">
          <template #error>
            <div class="image-slot">
                <el-image :src="require('../../../assets/image/work_default_pic.png')" fit="cover" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)" />
            </div>
            </template>
      </el-image>
      <div class="cell-handel ub ub-ver">
          <div class="cell-title ub-f1" @click="workDetail(item.PROJECT_ID, item.USER_ID, item.releaseState)" :title="item.NAME"><div class="text-hidden-2">{{item.NAME}}</div></div>
          <like-info :item="item"  v-if="item.releaseState == 1"></like-info>
          <the-rate :score="item.score" :isDisabled="true"  v-if="item.releaseState == 1"></the-rate>
          <div class="ub" v-if="isSelf">
            <el-button class="default-button copy" type="success" v-if="item.sfKCopy == 0" @click="worlCopy(item.PROJECT_ID)">复制</el-button>
            <el-button class="default-button contribution" type="success" v-if="item.releaseState == 1 && !item.activity" @click="contributionChange(item.PROJECT_ID)">投稿</el-button>
            <el-button class="default-button four work-release" v-if="item.releaseState == 0" @click="workCode(item.PROJECT_ID)">发布</el-button>
        </div>
          <div :class="['work-code', item.activity ? 'gray' : '']" @click="workCode(item.PROJECT_ID)" v-if="(item.free_code == 0 || isSelf) && item.workType == 1">作品代码</div>
      </div>
      <contribution-dialog :isContributionDialog="isContributionDialog" @closeContributionDialog="closeContributionDialog" :projectId="item.PROJECT_ID" :workName="item.NAME"></contribution-dialog>
  </div>
</template>

<script>
import config from '../../../http/config'
import LikeInfo from '@/components/LikeInfo.vue'
import TheRate from '@/components/TheRate.vue'
import ContributionDialog from '@/pages/activity/components/ContributionDialog.vue'
import { pblWork } from '@/http/api'
export default {
  components: { LikeInfo, TheRate, ContributionDialog },
  props: {
      item: {
          type: Object,
          default:  () => {
              return {}
          }
      },

      componentType: {
          type: Number,
          default: 1
      },

      isSelf: {
          type: Boolean,
          default: false
      }
  },

  inject: ['refreshFn'],

  data() {
    return {
        isContributionDialog: false
    }
  },

  computed: {
    activityTitle() {
        return this.item.activity + `-${this.item.trackName}` + `-${this.item.groupName}`
    }
  },

  methods: {
      /**
        * 作品代码
        */
       workCode(projectId) {
            if(this.item.activity) {
                this.$message({
                    message: '已投稿作品不可修改',
                    type: 'warning'
                })
                return
            }
           window.open(`${config.scratchUrl.create}/#${projectId}`)
       },

       /**
        * 复制作品
        */
        worlCopy() {
            this.$messageBox.confirm(
                '是否确认复制?',
                '提示',
                    {
                        customClass: 'reload-page-message',
                        confirmButtonText: '继续',
                        cancelButtonText: '取消',
                        type: 'warning',
                    }
                )
                .then(() => {
                    pblWork({
                        type: 37,
                        pid: this.item.PROJECT_ID
                    }).then((res) => {
                        console.log(res);
                        this.$message({
                            message: "复制成功",
                            type: "success",
                            showClose: true
                        });
                        setTimeout(() => {
                            this.closeContributionDialog(true)
                        }, 1500)
                    })
                    .catch((err) => {
                        console.log(err)
                        this.loading = false;
                    });
                })
                .catch(() => {
                    
                })
        },

       /**
        * 作品详情
        * @param {Number} releaseState 0 未发布 1 已发布
        */
        workDetail(pid, uuid, releaseState) {
            this.$router.push(`/work-detail?pid=${pid}&uuid=${uuid}&releaseState=${releaseState}`)
        },      

        /**
         * 删除作品
         */
        workDelete() {

        },

        /**
         * 投稿
         */
        contributionChange() {
            this.isContributionDialog = true
        },

        /**
         * 关闭投稿弹窗
         */
         closeContributionDialog(type) {
            this.isContributionDialog = false
            if (type) {
                this.refreshFn()
            }
         }
  }

}
</script>

<style lang="scss">
.tab-cell-item {
    width: 385px;
    height: 183px;
    background-color: #fff;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 17px;
    margin-bottom: 17px;

    .activity-title {
        width: 232px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: rgba($color: #000000, $alpha: 0.5);
        position: absolute;
        left: 0;
        top: 0;
        z-index: 99;
        padding: 0 10px;
        box-sizing: border-box;
        color: #fff;
    }

    .tab-img {
        width: 244px;
        height: 183px;
        cursor: pointer;
        img {
            width: 244px;
            height: 183px;
        }
    }

    .cell-handel {
        padding: 0 12px 8px;

        .cell-title {
            font-weight: 700;
            margin-top: 15px;
            width: 129px;
            cursor: pointer;
        }

        .work-release {
            border-color: #ea4335;
            background-color: #ea4335;
            color: #fff;
            letter-spacing: 2px;
        }

        .work-release:hover {
            background-color: #e72715;
        }

        .contribution {
            border-color: #67c23a;
            background-color: #67c23a;
            color: #fff;
            letter-spacing: 2px;

            span {
                color: #ffffff;
            }
        }

        .contribution:hover {
            background-color: #6ad137;
        }

        .like-info {
            height: 20px;
        }

        .work-code {
            font-size: 14px;
            color: var(--el-color-second);
            text-decoration: underline;
            font-weight: 500;
            cursor: pointer;
            margin-top: 5px;
        }
    }
}
</style>