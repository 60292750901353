<template>
  <div class="pagination-model ub ub-pc" ><el-pagination background layout="prev, pager, next" :default-current-page="defaultCurrentPage" :current-page="currentPage" :total="total" :page-size="pageSize" @current-change="paginationChange"/></div>
</template>

<script>
export default {
    props: {
        total: { // 总条数
            type: Number,
            default: 0
        },

        pageSize: { // 每页显示条数
            type: Number,
            default: 10
        },

        defaultCurrentPage: { // 默认当前页数
            type: Number,
            default: 1
        },

        currentPage: { // 当前显示页数
            type: Number,
            default: 1
        }
    },

    methods: {
        paginationChange(e) {
            this.$emit('paginationChange', e)
        }
    }
}
</script>

<style lang="scss">
.pagination-model {
    width: 100%;
    background-color: #f4f4f4;
    padding: 60px 0;

    .is-background button.btn-prev,
    .is-background .btn-prev:disabled,
    .is-background button.btn-next.is-last,
    .is-background .btn-next.is-last:disabled,
    .el-pagination.is-background .el-pager li {
        background-color: #fff;
        min-width: 38px;
        min-height: 38px;
        border: 1px solid #dcdcdc;
        border-radius: 8px;
    }

    .el-pagination .btn-next .el-icon, 
    .el-pagination .btn-prev .el-icon {
        font-weight: bold;
        font-size: 22px;
    }

    .is-background .el-pager li:not(.is-disabled).is-active {
        border: 1px solid var(--el-color-primary);
    }

}
</style>