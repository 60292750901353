<template>
  <div class="breadcrumb">
      <el-breadcrumb :separator="breadcrumbSymbol">
          <el-breadcrumb-item :class="['a'+index, 'b' + (breadcrumbList.length - 1)]" v-for="(item, index) in newBreadcrumbList" :key="index" :to="{ path: item.path ? item.path : '', query: item.params }" @click="index != breadcrumbList.length - 1 && !item.path ? goBack(item) : ''">{{item.name}}</el-breadcrumb-item>
      </el-breadcrumb>
  </div>
</template>

<script>

export default {
    props: {
        breadcrumbSymbol: {
            type: String,
            default: '>'
        },

        breadcrumbList: {
            type: Array,
            default: () => {
                return []
            }
        }
        
    },

    data() {
        return {
            newBreadcrumbList: []
        }
    },

    created() {
        console.log(this.breadcrumbList)
        console.log(this.$route)
        this.newBreadcrumbList = this.breadcrumbList.map((item) => {
            console.log(item)
            let data = {
                name: item.name,
                path: item.path,
                params: {}
            }
            const url = new URL('https://www.example.com' + item.path);
            const searchParams = new URLSearchParams(url.search);
            if (searchParams.has) {
                // 提取查询参数并返回对象
                const params = {};
                for (const [key, value] of searchParams.entries()) {
                    params[key] = value;
                }
                console.log(params); // 输出 {q: "javascript", lang: "en", page: "2"}
                data.params = params
            }
            return data
        })
        console.log(this.newBreadcrumbList)
    },

    methods: {
        goBack(item) {
            console.log(item)
            // this.$router.push(item.fullPath)
            
        }
    }
}
</script>

<style lang="scss">
.breadcrumb {
    .el-breadcrumb {
        height: 100px;
        line-height: 100px;
        font-size: 16px;
        background-color: #f4f4f4;
    }
}
</style>